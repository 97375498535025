import React from "react";
import { Form, Button, Card, Container } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";

export default function DeleteAccount() {
  const maxW = isMobile ? "100%" : "90%";

  function onSubmitDeletion(e) {
    window.alert("Your request is submitted");
  }

  return (
    <>
      <Helmet>
        <title>Riley Delete Account</title>
        <meta property="og:title" content="Riley pricing" />
        <meta
          property="og:description"
          content="Explore the best events and groups in your area"
        />
        <meta property="og:url" content={"https://rileyevents.com/"} />
        <meta
          property="og:site_name"
          content="Riley - Explore the best events and groups in your area"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container
        className="d-flex justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: "white",
        }}
      >
        <div style={{ width: maxW, marginTop: "25px", marginBottom: "25px" }}>
          <Card className="border-0" style={{ backgroundColor: "transparent" }}>
            <Card.Body className="align-items-center justify-content-center">
              <div style={{ marginTop: "25px", marginBottom: "45px" }}>
                <Card.Title className="text-center">
                  <b>Delete Account?</b>
                </Card.Title>
                <Card.Text className="text-center">
                  Please fill out the following form if you want to delete
                  account. We will send you your account info to your email in 5
                  business days
                </Card.Text>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <Form onSubmit={onSubmitDeletion} style={{ width: "50%" }}>
                  <Form.Group style={{ marginTop: "15px" }}>
                    <Form.Label>
                      What's your account ID (email/phone) *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="We will locate your account"
                      required
                    />
                  </Form.Group>
                  <Form.Group style={{ marginTop: "15px" }}>
                    <Form.Label>What's your email address? *</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="We will send you your data"
                      required
                    />
                  </Form.Group>
                  <Form.Group style={{ marginTop: "15px" }}>
                    <Form.Label>What's your name?</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                  <Button
                    type="submit"
                    variant="dark"
                    style={{ marginTop: "15px" }}
                  >
                    Submit my request
                  </Button>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
