import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  Spinner,
  ListGroup,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  ButtonGroup,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { fires } from "../firebase";
import { useParams } from "react-router-dom";
import firebase from "firebase";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "firebase/firestore";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function SendGroupEmails() {
  const { currentUser, logout } = useAuth();
  const [emailMessage, setEmailMessage] = useState("");

  const fullScreenHandle = useFullScreenHandle();
  const maxW = "100%";
  const uid = currentUser.uid;

  const [orgData, setOrgData] = useState({});
  const [emailData, setEmailData] = useState({});
  const [emailList, setEmailList] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [emailMembers, setEmailMembers] = useState([]);

  const [emailSentCount, setEmailSentCount] = useState(0);
  const [emailSentConfirmed, setEmailSentConfirmed] = useState(false);
  const [emailLang, setEmailLang] = useState("zh");
  const [emailSentType, setEmailSentType] = useState("text");
  const [emailPreview, setEmailPreview] = useState({ title: "", content: "" });

  const emailTitleRef = useRef();
  const emailContentRef = useRef();

  let { id } = useParams();

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const routeBack = () => {
    let path = "/";
    window.location.href = path;
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  function sendEmailTo(emails, messageSubject, messageTexts) {
    const messageObj = {
      subject: messageSubject,
      text: messageTexts,
    };
    fires
      .collection("mail")
      .add({
        to: emails,
        message: messageObj,
      })
      .then((docRef) => {
        const sentId = docRef.id;
      });
  }

  function handleEmailSend() {
    const subject = emailTitleRef.current.value;
    const emailContent = emailContentRef.current.value;
    var newSubject = "";
    var newContent = "";

    const sendConfirm = window.confirm(
      `您即将发送给${emailList.length}个参与者，您要发送电子邮件吗？`
    );
    setEmailSentConfirmed(sendConfirm);
    if (sendConfirm) {
      if (emailList.length > 0) {
        var newEmailList = [];
        var emailGroups = [];
        if (emailList.length >= 25) {
          //break into groups
          newEmailList = emailList.slice(0, 25);
        }
        const chunkSize = 25;
        for (let i = 0; i < emailList.length; i += chunkSize) {
          const chunk = emailList.slice(i, i + chunkSize);
          emailGroups.push(chunk);
        }

        fires
          .collection("groups")
          .doc(orgData.id)
          .collection("emails")
          .doc(id)
          .update({ subject: subject, content: emailContent });

        newEmailList.forEach((memberItm) => {
          if (memberItm.email != null) {
            const emailStr = memberItm.email;
            var nameStr = "";

            if (memberItm.name != null) {
              nameStr = memberItm.name;
            }

            newContent = emailContent
              .replace("<EMAIL>", emailStr)
              .replace("<NAME>", nameStr);
            newSubject = subject
              .replace("<EMAIL>", emailStr)
              .replace("<NAME>", nameStr);

            let messageObj = { text: newContent, subject: newSubject };
            if (emailSentType === "text") {
              messageObj = { text: newContent, subject: newSubject };
            } else if (emailSentType === "html") {
              messageObj = { html: newContent, subject: newSubject };
            }

            if (selectedEmail.includes(emailStr)) {
              fires
                .collection("mail")
                .add({
                  to: [emailStr],
                  message: messageObj,
                })
                .then((docRef) => {
                  const sentId = docRef.id;
                  fires
                    .collection("groups")
                    .doc(orgData.id)
                    .collection("emails")
                    .doc(id)
                    .collection("members")
                    .doc(emailStr)
                    .set({
                      feedbackId: sentId,
                      email: emailStr,
                      timestamp: firebase.firestore.Timestamp.fromDate(
                        new Date()
                      ),
                    });
                });
            }
          }
        });
      } else {
      }
    }
  }

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const userData = snapshot.data();
          const orgId = userData.orgId;
          if (orgId == null) {
            //route back
            routeBack();
          } else if (orgId === "") {
            routeBack();
          } else {
            fires
              .collection("groups")
              .doc(orgId)
              .get()
              .then((snapshotA) => {
                if (snapshotA.exists) {
                  const groupG = snapshotA.data();
                  setOrgData({ ...groupG });
                }
              });

            fires
              .collection("groups")
              .doc(orgId)
              .collection("emails")
              .doc(id)
              .get()
              .then((snapshotA) => {
                if (snapshotA.exists) {
                  const emailL = snapshotA.data();
                  fires
                    .collection("groups")
                    .doc(orgId)
                    .collection("emails")
                    .doc(id)
                    .collection("members")
                    .onSnapshot((eventSnap) => {
                      const members = [];
                      eventSnap.docs.forEach((eventMem) => {
                        const memberData = eventMem.data();
                        members.push({ ...memberData, email: eventMem.id });
                      });
                      setEmailMembers(members);
                      setEmailSentCount(members.length);
                    });
                  if (emailL.emails != null) {
                    setEmailList(emailL.emails);
                    var eemails = [];
                    var eemailL = [];
                    eemails = emailL.emails;
                    eemails.forEach((emailItm) => {
                      if (emailItm.email !== "") {
                        eemailL.push(emailItm.email);
                      }
                    });
                    setSelectedEmail(eemailL);
                  }
                  if (emailL.lang != null) {
                    if (emailL.lang === "") {
                      setEmailLang("zh");
                    } else {
                      setEmailLang(emailL.lang);
                    }
                  }
                  setEmailData({ ...emailL });
                  return;
                }
                routeBack();
              });
          }
          return;
        }
        //deleted
        routeToLogout();
      });
  }, []);

  return (
    <>
      <FullScreen handle={fullScreenHandle}>
        <Container
          className="d-flex justify-content-center"
          style={{ minHeight: "100vh", minWidth: "100vw" }}
        >
          <div
            className="w-100"
            style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
          >
            <Card className="border-0">
              <Card.Body className="align-items-center justify-content-center">
                <div className="d-flex justify-content-between align-items-start">
                  <Card.Title
                    className="text-center"
                    style={{
                      fontSize: "35px",
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    发送反馈电子邮件
                  </Card.Title>
                  <div style={{ display: "flex" }}>
                    <ButtonGroup
                      style={{ marginRight: "15px" }}
                      className="align-items-center justify-content-center"
                    >
                      <Button
                        variant={
                          emailSentType == "text" ? "dark" : "outline-dark"
                        }
                        onClick={() => {
                          setEmailSentType("text");
                          setEmailMessage("使用text模式发送邮件");
                        }}
                      >
                        TEXT
                      </Button>
                      <Button
                        variant={
                          emailSentType === "html" ? "dark" : "outline-dark"
                        }
                        onClick={() => {
                          setEmailSentType("html");
                          setEmailMessage("使用html模式发送邮件");
                        }}
                      >
                        HTML
                      </Button>
                    </ButtonGroup>

                    <DropdownButton
                      style={{ marginRight: "10px" }}
                      title="发送"
                      id=""
                      variant="outline-dark"
                      onSelect={(e) => {
                        if (e === "send") {
                          handleEmailSend();
                        }
                      }}
                    >
                      <Dropdown.Item eventKey="save">保存</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item eventKey="send" style={{ color: "red" }}>
                        发送
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>

                <div style={{ marginTop: "15px", marginBottom: "45px" }}>
                  <Card.Text
                    className="text-center"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginTop: "15px",
                      marginBottom: "0px",
                      color: "black",
                    }}
                  >
                    {emailLang === "zh"
                      ? "提示: 请用中文发送电子邮件"
                      : "提示: 请用英语发送电子邮件"}
                  </Card.Text>
                </div>

                <Row style={{ marginTop: "20px" }}>
                  <Col sm={9}>
                    <Form>
                      <Form.Group id="subject" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          邮件标题
                        </Form.Label>
                        <Form.Control
                          style={{
                            backgroundColor: "transparent",
                            height: "50px",
                          }}
                          type="text"
                          ref={emailTitleRef}
                          required
                        />
                      </Form.Group>

                      <Form.Group id="content" style={{ marginTop: "15px" }}>
                        <div
                          className="d-flex justify-content-between align-items-start"
                          style={{ marginTop: "5px", marginBottom: "15px" }}
                        >
                          <Form.Label
                            style={{
                              fontSize: "17px",
                              fontWeight: "bold",
                            }}
                          >
                            正文内容
                          </Form.Label>
                          <div>
                            <Button
                              variant="success"
                              style={{ marginLeft: "5px" }}
                              onClick={() => {
                                const template1 =
                                  "您好, \n\n很感谢您在百忙之中参加了「" +
                                  emailData.eventTitle +
                                  "」，我们非常高兴在活动中见到您，也希望能在之后的活动中再次遇到您!";
                                const template2 =
                                  "\n\n如果您方便，请您填写一下本次活动的反馈信息，请您点击这个链接 https://rileyevents.com/submit-feedback/" +
                                  emailData.eventId;
                                const template3 =
                                  "\n\n再次感谢您的到来，也希望在之后的活动中见到您! \n\n\n";
                                if (orgData.name !== "") {
                                  var totalResponse =
                                    template1 +
                                    template2 +
                                    template3 +
                                    orgData.name;
                                  emailTitleRef.current.value =
                                    "感谢您来参加" +
                                    emailData.eventTitle +
                                    "的活动，请您提供反馈";
                                  emailContentRef.current.value = totalResponse;
                                }
                              }}
                            >
                              使用乐源反馈模版
                            </Button>
                          </div>
                        </div>
                        <Form.Control
                          style={{}}
                          as="textarea"
                          rows={10}
                          ref={emailContentRef}
                          required
                        />
                      </Form.Group>
                      <Form.Group />
                      <Form.Group
                        className="d-flex align-items-center justify-content-between"
                        style={{ marginTop: "15px" }}
                      >
                        <Form.Label
                          style={{
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          预览邮件
                        </Form.Label>
                        <Button
                          variant="dark"
                          onClick={() => {
                            var emailTitle = emailTitleRef.current.value;
                            var emailContent = emailContentRef.current.value;

                            setEmailPreview({
                              title: emailTitle,
                              content: emailContent,
                            });
                            setEmailMessage("Content preview is ready");
                          }}
                        >
                          预览
                        </Button>
                      </Form.Group>
                      <Form.Group>
                        <Card.Title
                          className="text-center"
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                            color: "#000000",
                          }}
                        >
                          {emailPreview.title}
                        </Card.Title>
                        <Card.Text
                          style={{
                            fontSize: "18px",
                            fontWeight: "normal",
                            color: "#000000",
                          }}
                        >
                          {emailPreview.content}
                        </Card.Text>
                      </Form.Group>

                      <Form.Group
                        className="align-items-start"
                        style={{ marginTop: "25px" }}
                      >
                        <Form.Label
                          style={{
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          使用特殊符
                        </Form.Label>
                        <br />
                        <Form.Label
                          style={{
                            marginRight: "5px",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          {"<NAME>"}
                        </Form.Label>
                        <Form.Label
                          style={{
                            marginRight: "5px",
                            fontSize: "15px",
                            fontWeight: "normal",
                          }}
                        >
                          收件人姓名(全名)
                        </Form.Label>
                        <Card.Link
                          onClick={() => {
                            navigator.clipboard.writeText("<NAME>");
                          }}
                        >
                          复制
                        </Card.Link>
                        <br />
                        <Form.Label
                          style={{
                            marginRight: "5px",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          {"<EMAIL>"}
                        </Form.Label>
                        <Form.Label
                          style={{
                            marginRight: "5px",
                            fontSize: "15px",
                            fontWeight: "normal",
                          }}
                        >
                          收件人邮箱
                        </Form.Label>
                        <Card.Link
                          onClick={() => {
                            navigator.clipboard.writeText("<EMAIL>");
                          }}
                        >
                          复制
                        </Card.Link>
                      </Form.Group>
                    </Form>
                  </Col>

                  <Col sm={3}>
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ marginTop: "35px" }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        hidden={
                          emailSentCount === selectedEmail.length ||
                          !emailSentConfirmed
                        }
                      />
                      <Card.Text
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        {emailSentCount === 0
                          ? "发送邮件至"
                          : emailSentCount +
                            "/" +
                            emailList.length +
                            "邮件已发送"}
                      </Card.Text>
                    </div>

                    {emailMessage && (
                      <Alert variant="success">{emailMessage}</Alert>
                    )}

                    <ListGroup hidden={emailSentCount !== 0}>
                      {emailList.map((memberItm) => {
                        const langOption = memberItm.lang ?? "zh";
                        return (
                          <ListGroup.Item className="align-items-start">
                            <div>
                              <div>{memberItm.name}</div>
                              <div>{memberItm.email}</div>
                              <div>
                                {langOption === "zh" ? "<中文>" : "<英语>"}
                              </div>
                              <Button
                                variant={
                                  selectedEmail.includes(memberItm.email)
                                    ? "dark"
                                    : "outline-dark"
                                }
                                style={{ marginTop: "15px" }}
                                onClick={() => {
                                  if (selectedEmail.includes(memberItm.email)) {
                                    var newEmails = selectedEmail;
                                    const cIndex = newEmails.indexOf(
                                      memberItm.email
                                    );
                                    if (
                                      cIndex < newEmails.length &&
                                      cIndex >= 0
                                    ) {
                                      newEmails.splice(cIndex, 1);
                                    }
                                    setSelectedEmail(newEmails);
                                    setEmailMessage(
                                      `选择了${newEmails.length}封电子邮件`
                                    );
                                    return;
                                  }
                                  var newEmails = selectedEmail;
                                  newEmails.push(memberItm.email);
                                  setSelectedEmail(newEmails);
                                  setEmailMessage(
                                    "选择了" + newEmails.length + "封电子邮件"
                                  );
                                }}
                              >
                                {selectedEmail.includes(memberItm.email)
                                  ? "已选择"
                                  : "未选择"}
                              </Button>
                            </div>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>

                    <ListGroup
                      style={{ marginTop: "10px" }}
                      hidden={emailSentCount === 0}
                    >
                      {emailMembers.map((memberItm) => {
                        return (
                          <ListGroup.Item className="align-items-start">
                            <div>
                              <div>{memberItm.email}</div>
                              <Card.Text style={{ color: "green" }}>
                                发送反馈：
                                {memberItm.feedbackId != null
                                  ? "发送成功"
                                  : "未发送"}
                              </Card.Text>
                              <Card.Text style={{ color: "black" }}>
                                确认号：{memberItm.feedbackId}
                              </Card.Text>
                            </div>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </FullScreen>
    </>
  );
}
