import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "react-image-crop/dist/ReactCrop.css";
import { auth } from "../firebase";

export default function UpdateProfile() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, updatePassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "40%";

  async function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }
    try {
      setError("");
      setLoading(true);
      auth.currentUser.updatePassword(passwordRef.current.value).then((userR)=>{
        navigate("/");
      })
    } catch {
      setError("Password can not be modified");
    }
  }

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: maxW }}>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Modify Password</h2>
            {error && <Alert variant="danger">{error}</Alert>}

            <Form onSubmit={handleSubmit}>
              <Form.Group
                id="email"
                style={{
                  marginBottom: "15px",
                }}
              >
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  ref={emailRef}
                  required
                  defaultValue={currentUser.email}
                  disabled
                />
              </Form.Group>
              <Form.Group
                id="password"
                style={{
                  marginBottom: "15px",
                }}
              >
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  placeholder="Enter password"
                />
              </Form.Group>
              <Form.Group
                id="password-confirm"
                style={{
                  marginBottom: "15px",
                }}
              >
                <Form.Label>Repeat Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  placeholder="Repeat your password"
                />
              </Form.Group>

              <Button
                className="w-100 mt3"
                type="submit"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "black",
                  borderRadius: "25px",
                  borderColor: "#ffffff",
                  borderWidth: "3px",
                  fontSize: "15px",
                  fontWeight: "bold",
                  height: "50px",
                  marginTop: "25px",
                  marginBottom: "15px",
                }}
              >
                Update Password
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}
