import React, { useState, useEffect } from "react";
import { Card, Row, Col, Container, Image, ListGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { auth, fires } from "../firebase";
import { QRCodeCanvas } from "qrcode.react";

export default function Posts() {
  let { topic } = useParams();

  const [posts, setPosts] = useState([]);
  var uid = auth.currentUser.uid;

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          fires
            .collection("posts")
            .orderBy("timestamp", "desc")
            .limit(20)
            .onSnapshot((snapshotA) => {
              const postData = [];
              snapshotA.forEach((doc) => {
                const pData = doc.data();
                var aTopics = {};
                if (topic != null && pData.topics != null) {
                  aTopics = pData.topics;
                  if (Object.keys(aTopics).includes(topic)) {
                    postData.push({ ...pData, id: doc.id });
                  }
                }
              });

              setPosts(postData);
            });
        }
      });
  }, []);

  return (
    <Container
      className="d-flex justify-content-center"
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        backgroundColor: "#EEEEEE",
      }}
    >
      <div
        className="w-100"
        style={{ marginTop: "25px", marginBottom: "25px" }}
      >
        <Row>
          <Col sm={2}>
            <ListGroup style={{ backgroundColor: "#EEEEEE" }}>
              <ListGroup.Item
                action
                href="#posts-info"
                className="d-flex justify-content-between align-items-center"
                style={{}}
              >
                刷新更新
              </ListGroup.Item>
              <ListGroup.Item
                action
                href="#posts-topics"
                className="d-flex justify-content-between align-items-center"
                style={{}}
              >
                即时更新
              </ListGroup.Item>
            </ListGroup>

            <div style={{ padding: "8px", marginBottom: "15px" }}>
              <Card.Title
                className="text-center"
                style={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
              >
                参与方法
              </Card.Title>
              <Card.Text
                className=""
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                第一步: 扫描左边二维码下载乐源APP
              </Card.Text>
              <Card.Text
                className=""
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                第二步: 进入广场点击任意动态并关注话题
              </Card.Text>
              <Card.Text
                className=""
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                第三步: 发布动态
              </Card.Text>
            </div>

            <div
              className="d-flex align-items-center justify-content-center"
              style={{ padding: "8px" }}
            >
              <QRCodeCanvas
                id="qrCodeEl"
                value={"https://leyuan.page.link/app"}
                size={128}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={false}
              />
            </div>
          </Col>
          <Col sm={10}>
            <Card.Title
              className="text-center"
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              乐源话题发布
            </Card.Title>
            <Card.Text
              className="text-center"
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              #{topic}
            </Card.Text>
            <Row md={4}>
              {posts.map((post) => {
                let postId = post.id;
                return (
                  <Col>
                    <Card
                      className="border-0"
                      onClick={() => {
                        window.location.href = "/post/" + postId;
                      }}
                    >
                      <Card.Img variant="top" src={post.imageURL} />
                      <Card.Body>
                        <Card.Title
                          style={{ fontSize: "15px", fontWeight: "bold" }}
                        >
                          {post.postContent.substring(0, 20)}
                        </Card.Title>
                        <Card.Text
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          #{topic}
                        </Card.Text>
                        <div
                          className="d-flex align-items-center"
                          style={{ alignSelf: "center", display: "flex" }}
                        >
                          <Image
                            roundedCircle
                            src={post.user.profileURL}
                            width="35px"
                            height="35px"
                          />
                          <div style={{ marginLeft: "5px" }}>
                            <Card.Text
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                marginTop: "15px",
                                marginBottom: "0px",
                                color: "black",
                              }}
                            >
                              {post.user.userName}
                            </Card.Text>
                            <Card.Text
                              class="text-secondary"
                              style={{ fontSize: "12px", fontWeight: "normal" }}
                            >
                              发布于{" "}
                              {post.timestamp.toDate().toLocaleDateString("zh")}
                            </Card.Text>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
