import React, { useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { fires } from "../firebase";
import { useParams, Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function BizDetails() {
  const [posts, setPosts] = useState([]);

  let { id } = useParams();
  const maxW = isMobile ? "100%" : "60%";

  useEffect(() => {
    return fires
      .collection("business")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const posts = [];
          const postData = snapshot.data();
          if (postData.length === 0) {
            posts.push({
              id: id,
              error: "NoAccess",
              error_message: "没有访问权限",
              error_code: 404,
            });
            setPosts(posts);
            document.title = "没有访问权限";
            document.description = "请您查看是否具有访问权限";
          } else {
            posts.push({ ...postData, id: id });
            setPosts(posts);
            document.title = postData.name;
            document.description = postData.name;
          }
          return;
        }
        //deleted
        posts.push({
          id: id,
          error: "NotFound",
          error_message: "没有访问权限",
          error_code: 404,
        });
        setPosts(posts);
        document.title = "没有访问权限";
        document.description = "请您查看是否具有访问权限";
      });
  }, []);

  return (
    <div>
      {posts.map((post) => {
        const error = post.error;
        if (error == null) {
          return isMobile ? (
            <Card
              class="border-0 align-items-center justify-content-center"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Card.Img
                className="rounded-circle shadow-4-strong"
                variant="top"
                src={post.image_url}
                style={{ height: "250px", width: "250px" }}
              />
              <Card.Body>
                <Card.Title className="text-center">{post.name}</Card.Title>
                <Card.Text>{post.description}</Card.Text>
              </Card.Body>
            </Card>
          ) : (
            <Container
              className="d-flex align-items-center justify-content-center"
              style={{ minHeight: "100vh" }}
            >
              <div className="w-100" style={{ maxWidth: maxW }}>
                <Card
                  class="border-0 align-items-center justify-content-center"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Card.Img
                    className="rounded-circle shadow-4-strong"
                    variant="top"
                    src={post.image_url}
                    style={{ height: "250px", width: "250px" }}
                  />
                  <Card.Body>
                    <Card.Title className="text-center">{post.name}</Card.Title>
                    <Card.Text>{post.description}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Container>
          );
        } else if (error === "NotFound") {
          return <Navigate to="/404" />;
        } else if (error === "NoAccess") {
          return <Navigate to="/404" />;
        }
      })}
    </div>
  );
}
