import React, { useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { auth, fires } from "../firebase";
import { useParams } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import YouTube from "react-youtube";
import firebase from "firebase/app";
import "firebase/firestore";
import { Helmet } from "react-helmet";

import ReactAudioPlayer from "react-audio-player";

export default function ArticleDetails() {
  const [posts, setPosts] = useState([]);
  const [contents, setContents] = useState([]);

  let { id } = useParams();
  const maxW = isMobile ? "100%" : "60%";

  const opts = {
    height: isMobile ? "200px" : "350px",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  useEffect(() => {
    fires
      .collection("exps")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const posts = [];
          const postData = snapshot.data();
          let viewCount = postData.viewCount;
          const timestampNow = firebase.firestore.Timestamp.fromDate(
            new Date()
          );
          const platformName = "web";
          let deviceName = "ios";
          if (isAndroid) {
            deviceName = "android";
          } else if (isIOS) {
            deviceName = "ios";
          } else {
            deviceName = "web";
          }
          const fuid = auth.currentUser != null ? auth.currentUser.uid : "";
          const ua = window.navigator.userAgent.toLowerCase();
          const viewData = {
            fromUID: fuid,
            type: "page",
            fromUA: ua,
            platform: platformName,
            device: deviceName,
            timestamp: timestampNow,
          };
          fires.collection("exps").doc(id).collection("views").add(viewData);
          viewCount = viewCount + 1;
          fires.collection("exps").doc(id).update({ viewCount: viewCount });

          fires
            .collection("exps")
            .doc(id)
            .collection("contents")
            .orderBy("id", "asc")
            .get()
            .then((snapshotA) => {
              var contents = [];
              snapshotA.docs.forEach((doc) => {
                const contentId = doc.id;
                const contentData = doc.data();
                contents.push({ ...contentData, cid: contentId });
              });
              setContents(contents);
            });

          if (postData.length === 0) {
            posts.push({
              id: id,
              error: "NoAccess",
              error_message: "没有访问权限",
              error_code: 404,
            });
            setPosts(posts);
            document.title = "没有访问权限";
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", "请您查看是否具有访问权限");
            document.description = "请您查看是否具有访问权限";
          } else {
            if (auth.currentUser != null) {
              posts.push({ ...postData, id: id });
              setPosts(posts);
              document.title = postData.title;
              document
                .querySelector('meta[name="description"]')
                .setAttribute("content", postData.description);
            } else if (postData.status === null) {
              //deleted
              posts.push({
                id: id,
                error: "NotFound",
                error_message: "没有访问权限",
                error_code: 404,
              });
              setPosts(posts);
              document.title = "没有访问权限";
              document.description = "请您查看是否具有访问权限";
            } else if (postData.status === "live") {
              posts.push({ ...postData, id });
              setPosts(posts);
              document.title = postData.title;
              document
                .querySelector('meta[name="description"]')
                .setAttribute("content", postData.description);
            } else {
              //deleted
              posts.push({
                id,
                error: "NotFound",
                error_message: "没有访问权限",
                error_code: 404,
              });
              setPosts(posts);
              document.title = "没有访问权限";
              document.description = "请您查看是否具有访问权限";
            }
          }
          return;
        }
        //deleted
        posts.push({
          id: id,
          error: "NotFound",
          error_message: "没有访问权限",
          error_code: 404,
        });
        setPosts(posts);
        document.title = "没有访问权限";
        document.description = "请您查看是否具有访问权限";
      });
  }, []);

  return (
    <div>
      {posts.map((post) => {
        const error = post.error;
        const postTopics = post.topics;
        return error === "NotFound" || error === "NoAccess" ? (
          <Container
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <div className="w-100" style={{ maxWidth: maxW }}>
              <Card>
                <Helmet>
                  <title>无法显示</title>
                  <meta name="description" content="无法显示" />
                </Helmet>
                <Card.Body>
                  <Card.Title>404</Card.Title>
                  <Card.Text>该用户已删除或内容不存在</Card.Text>
                  <Card.Text>User deleted or the post doesn't exist</Card.Text>
                  <Card.Link href="/">返回首页</Card.Link>
                </Card.Body>
              </Card>
            </div>
          </Container>
        ) : (
          <>
            <Helmet>
              <title>{post.title}</title>
              <meta property="og:title" content={post.title} />
              <meta property="og:description" content={post.description} />
              <meta
                property="og:url"
                content={"https://letjoy.app/exp/" + post.id}
              />
              <meta property="og:site_name" content={post.title} />
              <meta property="og:locale" content="en_US" />
              <meta property="og:type" content="article" />
              <meta property="og:image" content={post.imageURL} />
            </Helmet>
            <Container
              className="d-flex justify-content-center"
              style={{ minHeight: "100vh" }}
            >
              <div
                className="w-100"
                style={{
                  maxWidth: maxW,
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
              >
                <div>
                  <Card className="border-0">
                    <Card.Body>
                      <Card.Title
                        style={{ fontSize: "25px", fontWeight: "bold" }}
                      >
                        {post.title}
                      </Card.Title>
                      <Card.Link hidden={post.orgId == null}>
                        {post.user.userOrg}
                      </Card.Link>
                      <Card.Text>
                        {post.user.userName} 发布于{" "}
                        {post.timestamp.toDate().toLocaleString("zh")}{" "}
                        {post.country}
                      </Card.Text>
                      <br />
                      {contents.map((content) => {
                        var contentType = content.type;
                        var linkDesc = "链接";
                        var linkImg = "";
                        var textType = 2;

                        if (content.linkDesc != null) {
                          linkDesc = content.linkDesc;
                        }
                        if (content.linkImg != null) {
                          linkImg = content.linkImg;
                        }
                        if (content.text_type != null) {
                          textType = content.text_type;
                        }

                        if (contentType === "image") {
                          return (
                            <>
                              <Card.Img variant="top" src={content.content} />
                              <br />
                              <br />
                            </>
                          );
                        } else if (contentType === "text") {
                          return (
                            <>
                              <Card.Text
                                style={{
                                  fontSize:
                                    textType === 0
                                      ? "35px"
                                      : textType === 1
                                      ? "25px"
                                      : textType === 2
                                      ? "17px"
                                      : "17px",
                                  fontWeight:
                                    textType === 0
                                      ? "bold"
                                      : textType === 1
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {content.content}
                              </Card.Text>
                              <br />
                            </>
                          );
                        } else if (contentType === "url") {
                          return (
                            <>
                              <Card.Link href={content.content}>
                                {linkDesc}
                              </Card.Link>
                              <br />
                              <br />
                            </>
                          );
                        } else if (contentType === "audio") {
                          return (
                            <>
                              <ReactAudioPlayer
                                src={content.content}
                                muted={true}
                                controls
                              />
                              <br />
                            </>
                          );
                        } else if (contentType === "video") {
                          return (
                            <>
                              <YouTube videoId={content.content} opts={opts} />
                              <br />
                            </>
                          );
                        } else if (contentType === "divider") {
                          return (
                            <>
                              <div className="border-top my-3"> </div>
                            </>
                          );
                        } else {
                          return <Card.Text />;
                        }
                      })}

                      <br />
                      <br />
                      <br />

                      {postTopics.map((topicItem) => {
                        return <Card.Link>#{topicItem}</Card.Link>;
                      })}
                      <br />
                      <br />
                      <Card.Text>阅读量 {post.viewCount}</Card.Text>
                      <br />
                      <br />
                      <Card.Link href="/">留言</Card.Link>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Container>
          </>
        );
      })}
    </div>
  );
}
