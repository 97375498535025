import React, { useState, useEffect } from "react";
import { Button, Card, Container, ListGroup } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { fires } from "../firebase";
import { Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function Experiences() {
  const [error, setError] = useState("");
  const [index, setIndex] = useState(0);
  const { currentUser, logout } = useAuth();

  const [posts, setPosts] = useState([]);
  const [orgPosts, setOrgPosts] = useState([]);
  const [orgExps, setOrgExps] = useState([]);

  const maxW = isMobile ? "100%" : "60%";

  const id = "test";
  var orgId = "";

  const routeChangeLogin = () => {
    let path = "/login";
    window.location.href = path;
  };
  const routeChangeSignup = () => {
    let path = "/signup";
    window.location.href = path;
  };

  useEffect(() => {
    return fires
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((snapshott) => {
        if (snapshott.exists) {
          var users = [];
          const userData = snapshott.data();
          orgId = userData.orgId;
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshot) => {
              if (snapshot.exists) {
                const posts = [];
                const postData = snapshot.data();
                const uid = postData.fromUID;
                if (postData.length == 0) {
                  posts.push({
                    id: id,
                    error: "NoAccess",
                    error_message: "没有访问权限",
                    error_code: 404,
                  });
                  setPosts(posts);
                  document.title = "乐源 LETJOY";
                  document.description = "开启你的海外生活";
                } else {
                  posts.push({ ...postData, id: id });
                  setPosts(posts);
                  fires
                    .collection("exps")
                    .where("orgId", "==", orgId)
                    .orderBy("timestamp", "desc")
                    .get()
                    .then((snapshotA) => {
                      var orgExps = [];
                      snapshotA.docs.forEach((doc) => {
                        const contentId = doc.id;
                        const contentData = doc.data();
                        orgExps.push({ ...contentData, cid: contentId });
                      });
                      setOrgExps(orgExps);
                    });

                  document.title = "乐源 LETJOY";
                  document.description = "开启你的海外生活";
                }
              } else {
                //deleted
                posts.push({
                  id: id,
                  error: "NotFound",
                  error_message: "没有访问权限",
                  error_code: 404,
                });
                setPosts(posts);
                document.title = "乐源 LETJOY";
                document.description = "开启你的海外生活";
              }
            });
        } else {
        }
      });
  }, []);

  return (
    <div>
      {posts.map((post) => {
        var error = post.error;
        var coverImgURL = "";
        var profileURL = "";
        var professionalType = "";
        var promoStr = "";
        var orgContacts = [];
        if (post.coverURL == null) {
          coverImgURL =
            "url(https://images.unsplash.com/photo-1487088678257-3a541e6e3922?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80)";
        } else {
          coverImgURL = "url(" + post.coverURL + ")";
        }
        if (post.promo != null) {
          promoStr = post.promo;
        }
        profileURL = post.profileURL;
        professionalType = post.typeCN;

        if (post.contacts != null) {
          orgContacts = post.contacts;
        }

        if (error == null) {
          return (
            <>
              <Container
                className="d-flex justify-content-center"
                style={{ minHeight: "100vh" }}
              >
                <div
                  className="w-100"
                  style={{
                    maxWidth: maxW,
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <Card>
                    <Card.Body className="align-items-center justify-content-center">
                      <Card.Title
                        className="text-center"
                        style={{
                          fontSize: "40px",
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        {post.name}的文章
                      </Card.Title>
                      <ListGroup style={{ marginTop: "25px" }}>
                        {orgExps.map((orgExp) => {
                          return (
                            <ListGroup.Item
                              className="align-items-start"
                              onClick={() => {
                                window.open(
                                  "https://letjoy.app/exp/" + orgExp.cid
                                );
                              }}
                            >
                              <div className="fw-bold">
                                (点击打开) {orgExp.title}
                              </div>
                              <div className="fw-normal">
                                发布于
                                {orgExp.timestamp
                                  .toDate()
                                  .toLocaleDateString("zh")}
                              </div>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                      <Button
                        className="w-100 mt3"
                        style={{
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: "#000000",
                          borderRadius: "25px",
                          borderColor: "#ffffff",
                          borderWidth: "3px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          height: "50px",
                          marginTop: "25px",
                          marginBottom: "15px",
                        }}
                        onClick={() => {}}
                      >
                        写文章
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
              </Container>
            </>
          );

          if (isMobile) {
            return (
              <Container
                className="d-flex justify-content-center"
                style={{ minHeight: "100vh", backgroundImage: coverImgURL }}
              >
                <Card
                  className="border-0 align-items-center justify-content-center"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "15px!important",
                    backgroundColor: "transparent",
                  }}
                >
                  <Card.Img
                    className="rounded-circle shadow-4-strong"
                    variant="top"
                    src={post.profileURL}
                    style={{
                      height: "100px",
                      width: "100px",
                      marginTop: "25px",
                      borderColor: "#ffffff",
                    }}
                  />
                  <Card.Body className="align-items-center justify-content-center">
                    <Card.Title
                      className="text-center"
                      style={{
                        fontSize: "40px",
                        fontWeight: "bold",
                        color: "#ffffff",
                      }}
                    >
                      {post.name}
                    </Card.Title>
                    <div className="d-flex justify-content-center">
                      <Card.Text
                        className="text-center"
                        style={{
                          marginTop: "25px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#000000",
                          padding: "10px",
                          backgroundColor: "#ffffff",
                          borderRadius: "12px",
                        }}
                      >
                        我的文章(乐源经验)
                      </Card.Text>
                    </div>
                    {orgExps.map((orgExp) => {
                      return (
                        <Card
                          className="border-0 justify-content-center"
                          onClick={() =>
                            window.open("https://letjoy.app/exp/" + orgExp.cid)
                          }
                          style={{
                            marginTop: "25px",
                            backgroundColor: "#ffffff",
                            borderRadius: "15px",
                          }}
                        >
                          <Card.Img
                            variant="left"
                            src={orgExp.imageURL}
                            style={{
                              height: "50vh",
                              width: "100%",
                              backgroundPosition: "center",
                              borderColor: "#ffffff",
                              borderTopRightRadius: "15px",
                              borderTopLeftRadius: "15px",
                            }}
                          />
                          <Card.Title style={{ margin: "15px" }}>
                            {orgExp.title}
                          </Card.Title>
                          <Card.Text
                            style={{ margin: "15px", fontSize: "12px" }}
                          >
                            {orgExp.description}
                          </Card.Text>
                        </Card>
                      );
                    })}
                  </Card.Body>
                </Card>
              </Container>
            );
          } else {
            return (
              <div style={{ backgroundImage: coverImgURL }}>
                <Container
                  className="d-flex justify-content-center"
                  style={{
                    backgroundImage: coverImgURL,
                    minHeight: "100vh",
                    minWidth: "100vh",
                  }}
                >
                  <div
                    className="w-100"
                    style={{
                      maxWidth: maxW,
                      borderRadius: "35px!important",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Card
                      className="border-0 align-items-center justify-content-center"
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "15px!important",
                        backgroundColor: "transparent",
                      }}
                    >
                      <Card.Img
                        className="rounded-circle shadow-4-strong"
                        variant="top"
                        src={post.profileURL}
                        style={{
                          height: "100px",
                          width: "100px",
                          marginTop: "25px",
                          borderColor: "#ffffff",
                        }}
                      />
                      <Card.Body className="align-items-center justify-content-center">
                        <Card.Title
                          className="text-center"
                          style={{
                            fontSize: "40px",
                            fontWeight: "bold",
                            color: "#ffffff",
                          }}
                        >
                          {post.name}
                        </Card.Title>
                        <div className="d-flex justify-content-center">
                          <Card.Text
                            className="text-center"
                            style={{
                              marginTop: "25px",
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#000000",
                              padding: "10px",
                              backgroundColor: "#ffffff",
                              borderRadius: "12px",
                            }}
                          >
                            我的文章(乐源经验)
                          </Card.Text>
                        </div>

                        {orgExps.map((orgExp) => {
                          return (
                            <Card
                              className="border-0 d-flex justify-content-center"
                              onClick={() =>
                                window.open(
                                  "https://letjoy.app/exp/" + orgExp.cid
                                )
                              }
                              style={{
                                height: "50vh",
                                width: "100%",
                                backgroundPosition: "center",
                                borderColor: "#ffffff",
                                borderTopRightRadius: "15px",
                                borderTopLeftRadius: "15px",
                                marginTop: "25px",
                                marginBottom: "15px",
                              }}
                            >
                              <Card.Img
                                variant="left"
                                src={orgExp.imageURL}
                                style={{
                                  height: "40vh",
                                  width: "100%",
                                  backgroundPosition: "center",
                                  borderColor: "#ffffff",
                                  borderTopRightRadius: "15px",
                                  borderTopLeftRadius: "15px",
                                }}
                              />
                              <Card.Title style={{ margin: "15px" }}>
                                {orgExp.title}
                              </Card.Title>
                              <Card.Text
                                style={{ margin: "15px", fontSize: "12px" }}
                              >
                                {orgExp.description}
                              </Card.Text>
                            </Card>
                          );
                        })}
                      </Card.Body>
                    </Card>
                  </div>
                </Container>
              </div>
            );
          }
        } else {
          if (error == "NotFound") {
            return <Navigate to="/404" />;
          } else if (error == "NoAccess") {
            return <Navigate to="/404" />;
          }
        }
      })}
    </div>
  );
}
