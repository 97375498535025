import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  ListGroup,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { fires } from "../firebase";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function DataAnalytics() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [viewTypes, setViewTypes] = useState([]);
  const [views, setViews] = useState([]);
  const [todayViews, setTodayViews] = useState([]);
  const [gData, setGData] = useState([]);
  const [links, setLinks] = useState([]);

  const [linkCounts, setLinkCounts] = useState({});

  const maxW = isMobile ? "100%" : "100%";

  var uid = currentUser.uid;
  var cEmail = currentUser.email;

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          var orgId = userData.orgId;
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotB) => {
              if (snapshotB.exists) {
                var gData = [];
                var groupData = snapshotB.data();
                gData.push({ ...groupData, id: orgId });
                setGData(groupData);

                if (groupData.contacts != null) {
                  setLinks(groupData.contacts);
                }

                fires
                  .collection("groups")
                  .doc(orgId)
                  .collection("views")
                  .get()
                  .then((snapshotA) => {
                    var allViews = [];
                    var todayView = [];

                    var viewTypes = [];

                    var linkeCounts = {};

                    var todayTotal = 0;
                    var todayWeb = 0;
                    var hisTotal = 0;
                    var hisWeb = 0;

                    var todayLinkTotal = 0;
                    var todayLinkWeb = 0;
                    var hisLinkTotal = 0;
                    var hisLinkWeb = 0;

                    snapshotA.docs.forEach((doc) => {
                      const viewId = doc.id;
                      const viewData = doc.data();
                      allViews.push({ ...viewData, vid: viewId });
                    });

                    allViews.forEach((vItm) => {
                      const vItmDate = vItm.timestamp.toDate();
                      const vURL = vItm.linkId;
                      if (
                        isToday(vItmDate) &&
                        vItm.platform == "web" &&
                        vItm.type == "page"
                      ) {
                        todayWeb += 1;
                      }
                      if (isToday(vItmDate) && vItm.type == "page") {
                        todayTotal += 1;
                        todayView.push(vItm);
                      }
                      if (vItm.platform == "web" && vItm.type == "page") {
                        hisWeb += 1;
                      }
                      if (vItm.type == "page") {
                        hisTotal += 1;
                      }

                      if (
                        isToday(vItmDate) &&
                        vItm.platform == "web" &&
                        vItm.type == "link"
                      ) {
                        todayLinkWeb += 1;
                      }
                      if (isToday(vItmDate) && vItm.type == "link") {
                        todayLinkTotal += 1;
                        todayView.push(vItm);
                      }
                      if (vItm.platform == "web" && vItm.type == "link") {
                        hisLinkWeb += 1;
                      }
                      if (vItm.type == "link") {
                        hisLinkTotal += 1;

                        if (vURL != null) {
                          if (linkeCounts[vURL] != null) {
                            const currentCount = linkeCounts[vURL];
                            linkeCounts[vURL] = currentCount + 1;
                          } else {
                            linkeCounts[vURL] = 0;
                          }
                        }
                      }
                    });
                    viewTypes.push(todayWeb);
                    viewTypes.push(todayTotal);
                    viewTypes.push(hisWeb);
                    viewTypes.push(hisTotal);
                    viewTypes.push(todayLinkWeb);
                    viewTypes.push(todayLinkTotal);
                    viewTypes.push(hisLinkWeb);
                    viewTypes.push(hisLinkTotal);

                    setViewTypes(viewTypes);

                    setViews(allViews);

                    setLinkCounts(linkeCounts);

                    setTodayViews(todayView);
                  });
              }
            });
        } else {
          //deleted
          posts.push({
            id: uid,
            error: "NotFound",
            error_message: "没有访问权限",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "乐源 LETJOY";
          document.description = "开启你的海外生活";
          routeToLogout();
        }
      });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    Promise.all(promises)
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div
        className="w-100"
        style={{ maxWidth: maxW, marginTop: "15px", marginBottom: "15px" }}
      >
        <Card className="border-0">
          <Card.Body className="align-items-center justify-content-center">
            <div className="d-flex justify-content-between align-items-start">
              <Card.Title
                className="text-center"
                style={{
                  fontSize: "45px",
                  fontWeight: "bold",
                  color: "#000000",
                }}
              >
                数据分析
              </Card.Title>
              <div style={{ display: "flex" }}>
                <Button
                  variant="dark"
                  style={{ marginRight: "15px" }}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  刷新
                </Button>

                <DropdownButton
                  style={{ marginRight: "10px" }}
                  title="筛选"
                  id=""
                  variant="outline-dark"
                  onSelect={(e) => {}}
                >
                  <Dropdown.Item eventKey="all">全部</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="today">今天</Dropdown.Item>
                  <Dropdown.Item eventKey="yesterday">昨天</Dropdown.Item>
                  <Dropdown.Item eventKey="this-week">本周</Dropdown.Item>
                  <Dropdown.Item eventKey="this-month">本月</Dropdown.Item>
                  <Dropdown.Item eventKey="three-month">三个月内</Dropdown.Item>
                  <Dropdown.Item eventKey="half-year">半年内</Dropdown.Item>
                  <Dropdown.Item eventKey="this-year">一年内</Dropdown.Item>
                </DropdownButton>

                <DropdownButton
                  style={{ marginRight: "10px" }}
                  title="排序"
                  id=""
                  variant="outline-dark"
                  onSelect={(e) => {}}
                >
                  <Dropdown.Item
                    eventKey="default"
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>默认</div>
                    <div>✓</div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    eventKey="time"
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>按时间</div>
                    <div></div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="name"
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>按名字</div>
                    <div></div>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>

            <Tab.Container
              id="list-group-tabs-example"
              defaultActiveKey="#page-visits"
            >
              <Row style={{ marginTop: "30px" }}>
                <Col sm={2} style={{}}>
                  <ListGroup>
                    <ListGroup.Item
                      className="align-items-start border-0"
                      action
                      href="#page-visits"
                    >
                      <div className="fw-bold text-center">网页点击量</div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="align-items-start border-0"
                      action
                      href="#link-visits"
                    >
                      <div className="fw-bold text-center">链接访问量</div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="align-items-start border-0"
                      action
                      href="#messages"
                    >
                      <div className="fw-bold text-center">留言</div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="align-items-start border-0"
                      action
                      href="#articles"
                    >
                      <div className="fw-bold text-center">文章阅读量</div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="align-items-start border-0"
                      action
                      href="#events"
                    >
                      <div className="fw-bold text-center">活动访问量</div>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>

                <Col sm={10} style={{}}>
                  <Tab.Content>
                    <Tab.Pane eventKey="#page-visits">
                      <div className="align-items-start">
                        <Card.Title
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                        >
                          网页点击量
                        </Card.Title>
                        <Card.Text
                          style={{
                            fontSize: "15px",
                            fontWeight: "normal",
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                        >
                          此分析可为您提供乐源页面的访问量分析，包括具体源头和构成的分析
                        </Card.Text>
                      </div>
                      <Row md={3} style={{ marginTop: "10px" }}>
                        <Col>
                          <Card style={{ marginTop: "15px" }}>
                            <Card.Text
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                              }}
                            >
                              今日手机点击
                            </Card.Text>
                            <Card.Title
                              style={{
                                marginLeft: "15px",
                                fontSize: "45px",
                                fontWeight: "bold",
                              }}
                            >
                              {viewTypes[1] - viewTypes[0]}次
                            </Card.Title>
                            <Card.Text
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                                marginBottom: "15px",
                              }}
                            >
                              -
                            </Card.Text>
                          </Card>
                        </Col>

                        <Col>
                          <Card style={{ marginTop: "15px" }}>
                            <Card.Text
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                              }}
                            >
                              今日网页点击
                            </Card.Text>
                            <Card.Title
                              style={{
                                marginLeft: "15px",
                                fontSize: "45px",
                                fontWeight: "bold",
                              }}
                            >
                              {viewTypes[0]}次
                            </Card.Title>
                            <Card.Text
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                                marginBottom: "15px",
                              }}
                            >
                              -
                            </Card.Text>
                          </Card>
                        </Col>

                        <Col>
                          <Card style={{ marginTop: "15px" }}>
                            <Card.Text
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                              }}
                            >
                              今日总点击
                            </Card.Text>
                            <Card.Title
                              style={{
                                marginLeft: "15px",
                                fontSize: "45px",
                                fontWeight: "bold",
                              }}
                            >
                              {viewTypes[1]}次
                            </Card.Title>
                            <Card.Text
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                                marginBottom: "15px",
                              }}
                            >
                              -
                            </Card.Text>
                          </Card>
                        </Col>

                        <Col>
                          <Card style={{ marginTop: "15px" }}>
                            <Card.Text
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                              }}
                            >
                              历史手机点击
                            </Card.Text>
                            <Card.Title
                              style={{
                                marginLeft: "15px",
                                fontSize: "45px",
                                fontWeight: "bold",
                              }}
                            >
                              {viewTypes[3] - viewTypes[2]}次
                            </Card.Title>
                            <Card.Text
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                                marginBottom: "15px",
                              }}
                            >
                              -
                            </Card.Text>
                          </Card>
                        </Col>

                        <Col>
                          <Card style={{ marginTop: "15px" }}>
                            <Card.Text
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                              }}
                            >
                              历史网页点击
                            </Card.Text>
                            <Card.Title
                              style={{
                                marginLeft: "15px",
                                fontSize: "45px",
                                fontWeight: "bold",
                              }}
                            >
                              {viewTypes[2]}次
                            </Card.Title>
                            <Card.Text
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                                marginBottom: "15px",
                              }}
                            >
                              -
                            </Card.Text>
                          </Card>
                        </Col>

                        <Col>
                          <Card style={{ marginTop: "15px" }}>
                            <Card.Text
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                              }}
                            >
                              历史总点击
                            </Card.Text>
                            <Card.Title
                              style={{
                                marginLeft: "15px",
                                fontSize: "45px",
                                fontWeight: "bold",
                              }}
                            >
                              {viewTypes[3]}次
                            </Card.Title>
                            <Card.Text
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                                marginBottom: "15px",
                              }}
                            >
                              -
                            </Card.Text>
                          </Card>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#link-visits">
                      <div className="align-items-start">
                        <Card.Title
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                        >
                          链接点击量
                        </Card.Title>
                        <Card.Text
                          style={{
                            fontSize: "15px",
                            fontWeight: "normal",
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                        >
                          链接点击量
                        </Card.Text>
                      </div>

                      <Row md={3} style={{ marginTop: "10px" }}>
                        {links.map((linkItem) => {
                          var currentC = 0;
                          if (linkItem.url != null) {
                            if (linkCounts[linkItem.url] != null) {
                              currentC = linkCounts[linkItem.url];
                            }
                          }
                          return (
                            <Col>
                              <Card style={{ marginTop: "15px" }}>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  {linkItem.name}
                                </Card.Text>
                                <Card.Title
                                  style={{
                                    marginLeft: "15px",
                                    fontSize: "45px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {currentC}次
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  链接访问量
                                </Card.Text>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#messages">
                      <div className="align-items-start">
                        <Card.Title
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                        >
                          留言
                        </Card.Title>
                        <Card.Text
                          style={{
                            fontSize: "15px",
                            fontWeight: "normal",
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                        >
                          留言
                        </Card.Text>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#articles">
                      <div className="align-items-start">
                        <Card.Title
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                        >
                          文章
                        </Card.Title>
                        <Card.Text
                          style={{
                            fontSize: "15px",
                            fontWeight: "normal",
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                        >
                          请您前往文章分析页面查看
                        </Card.Text>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="#events">
                      <div className="align-items-start">
                        <Card.Title
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                        >
                          活动
                        </Card.Title>
                        <Card.Text
                          style={{
                            fontSize: "15px",
                            fontWeight: "normal",
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                        >
                          请您前往活动分析页面查看
                        </Card.Text>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}
