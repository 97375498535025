import firebase from "firebase";

export function createTicketId() {
  const fNumber = Math.floor(1000 + Math.random() * 9000);
  const sNumber = Math.floor(1000 + Math.random() * 9000);
  const tNumber = Math.floor(1000 + Math.random() * 9000);
  const ttNumber = Math.floor(1000 + Math.random() * 9000);
  return (
    fNumber.toString() +
    "-" +
    sNumber.toString() +
    "-" +
    tNumber.toString() +
    "-" +
    ttNumber.toString()
  );
}

export function getNow() {
  return firebase.firestore.Timestamp.fromDate(new Date());
}
