import React, { useState } from "react";
import { Card, Alert, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function NotFound() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const maxW = isMobile ? "100%" : "60%";

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(false);
  }

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: maxW }}>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">404 Something Wrong</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <br />
            <Card.Text className="text-center">
              Your page can not be found
            </Card.Text>
            <br />
            <div className="w-100 text-center mt-3">
              <Link to="/">Back Home</Link>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}
