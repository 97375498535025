import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Carousel,
  Image,
  Container,
  Navbar,
} from "react-bootstrap";
import { auth, fires } from "../firebase";
import { useParams } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import uuid from "react-uuid";
import Youtube from "react-youtube";
import ReactPlayer from "react-player";
import firebase from "firebase";
import { Helmet } from "react-helmet";

export default function PostDetails() {
  const [posts, setPosts] = useState([]);
  const [comments, setComments] = useState([]);
  const [postType, setPostType] = useState([]);

  const [postOrgDetails, setPostOrgDetails] = useState({
    id: "",
    profileURL: "",
    name: "",
  });
  const [postEventDetails, setPostEventDetails] = useState({
    id: "",
    photoURL: "",
    title: "",
  });

  let { id } = useParams();
  const maxW = isMobile ? "100%" : "50%";

  useEffect(() => {
    fires
      .collection("posts")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const posts = [];
          const postData = snapshot.data();

          if (postData.organization != null && postData.orgId != null) {
            setPostOrgDetails({
              ...postData.organization,
              id: postData.orgId,
            });
          }

          if (postData.eventDetails != null && postData.eventId != null) {
            setPostEventDetails({
              ...postData.eventDetails,
              id: postData.eventId,
            });
          }

          const viewByData = postData.viewBy;
          const timestampNow = firebase.firestore.Timestamp.fromDate(
            new Date()
          );
          const platformName = "web";
          let deviceName = "ios";
          if (isAndroid) {
            deviceName = "android";
          } else if (isIOS) {
            deviceName = "ios";
          } else {
            deviceName = "web";
          }
          const fuid = auth.currentUser != null ? auth.currentUser.uid : "";
          const ua = window.navigator.userAgent.toLowerCase();
          const viewData = {
            fromUID: fuid,
            platform: platformName,
            fromUA: ua,
            device: deviceName,
            timestamp: timestampNow,
          };
          viewByData[uuid()] = viewData;
          fires.collection("posts").doc(id).collection("views").add(viewData);
          fires.collection("posts").doc(id).update({ viewBy: viewByData });

          fires
            .collection("post_comments")
            .where("postId", "==", id)
            .orderBy("timestamp", "desc")
            .limit(5)
            .get()
            .then((snapshotA) => {
              const comments = [];
              snapshotA.docs.forEach((doc) => {
                const commentId = doc.id;
                const commentData = doc.data();
                comments.push({ ...commentData, id: commentId });
              });
              setComments(comments);
            });

          const postType = [];
          if (postData.youtubeId !== "") {
            postType.push({ type: "Youtube" });
          } else if (postData.videoURL !== "") {
            postType.push({ type: "Video" });
          } else if (postData.imageURL === "") {
            postType.push({ type: "Text" });
          } else {
            postType.push({ type: "Images" });
          }

          setPostType(postType);

          if (postData.length === 0) {
            posts.push({
              id,
              error: "NoAccess",
              error_message: "没有访问权限",
              error_code: 404,
            });
            setPosts(posts);
            document.title = "没有访问权限";
            document.description = "请您查看是否具有访问权限";
          } else {
            posts.push({ ...postData, id });
            setPosts(posts);
            document.title = postData.postContent;
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", postData.postContent);
          }
          return;
        }
        //deleted
        posts.push({
          id,
          error: "NotFound",
          error_message: "没有访问权限",
          error_code: 404,
        });
        setPosts(posts);
        document.title = "没有访问权限";
        document.description = "请您查看是否具有访问权限";
      });
  }, []);

  return (
    <div>
      {posts.map((post) => {
        var error = post.error;

        var youtubeId = post.youtubeId != null ? post.youtubeId : "";
        var youtubeURL = `https://youtu.be/${youtubeId}`;
        var videoURL = post.videoURL != null ? post.videoURL : "";
        var postImages = [];
        postImages = post.imageURLs;
        var postTopics = [];

        const opts = {
          height: "350",
          width: "100%",
          playerVars: {
            autoplay: 0,
          },
        };

        if (post.topics != null) {
          Object.entries(post.topics).forEach(([key, value]) => {
            postTopics.push(key);
          });
        }

        return error === "NotFound" ? (
          <Card>
            <Card.Body>
              <Card.Title>404</Card.Title>
              <Card.Text>该用户已删除或内容不存在</Card.Text>
              <Card.Text>User deleted or the post doesn't exist</Card.Text>
              <Card.Link href="/">返回首页</Card.Link>
            </Card.Body>
          </Card>
        ) : (
          <>
            <Helmet>
              <title>{post.postContent}</title>
              <meta property="og:title" content={post.postContent} />
              <meta
                property="og:description"
                content={post.user.userName + "的动态"}
              />
              <meta
                property="og:url"
                content={`https://letjoy.app/post/${post.id}`}
              />
              <meta property="og:site_name" content={post.postContent} />
              <meta property="og:locale" content="en_US" />
              <meta property="og:type" content="article" />
              <meta property="og:image" content={post.user.profileURL} />
            </Helmet>
            <Navbar
              hidden={!isMobile}
              bg="dark"
              variant="dark"
              expand="lg"
              sticky="top"
            >
              <Container fluid>
                <Navbar.Brand href="/">乐源</Navbar.Brand>
                <Button
                  style={{
                    backgroundColor: "#F57828",
                    borderColor: "#000000",
                    height: "30px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    if (post.shareURL != null && post.shareURL !== "") {
                      window.open(post.shareURL);
                    }
                  }}
                >
                  打开看看
                </Button>
              </Container>
            </Navbar>
            <Container
              className="d-flex justify-content-center"
              style={{ minHeight: "100vh", minWidth: "100vw" }}
            >
              <div
                className="w-100"
                style={{
                  maxWidth: maxW,
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
              >
                <Card className={isMobile ? "border-0" : ""}>
                  {postType.map((typeItm) => {
                    if (typeItm.type === "Youtube") {
                      return <Youtube videoId={youtubeId} opts={opts} />;
                    } else if (typeItm.type === "Video") {
                      return (
                        <ReactPlayer
                          url={videoURL}
                          style={{ maxWidth: "100%" }}
                          playing={true}
                          muted={true}
                          controls
                        />
                      );
                    } else if (typeItm.type === "Images") {
                      return (
                        <Carousel variant="top">
                          {postImages.map((imageURL) => {
                            return (
                              <Carousel.Item interval={5000}>
                                <img
                                  className="d-block w-100"
                                  src={imageURL}
                                  alt="-"
                                  style={{}}
                                />
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      );
                    } else {
                      return <></>;
                    }
                  })}

                  <Card.Body>
                    <div
                      className="d-flex align-items-center"
                      style={{ alignSelf: "center", display: "flex" }}
                    >
                      <Image
                        roundedCircle
                        src={post.user.profileURL}
                        width="50px"
                        height="50px"
                      />
                      <div style={{ marginLeft: "5px" }}>
                        <Card.Text
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginTop: "15px",
                            marginBottom: "0px",
                            color: "black",
                          }}
                        >
                          {post.user.userName}
                        </Card.Text>
                        <Card.Text
                          class="text-secondary"
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          发布于{" "}
                          {post.timestamp.toDate().toLocaleDateString("zh")}{" "}
                          {post.postLocation}
                        </Card.Text>
                      </div>
                    </div>

                    <br />
                    <Card.Title>{post.user.userName}的动态</Card.Title>
                    <Card.Text>{post.postContent}</Card.Text>

                    <br />

                    {postTopics.map((topicItem) => {
                      return <Card.Link>#{topicItem}</Card.Link>;
                    })}

                    <Card
                      className="border-0"
                      style={{
                        backgroundColor: "#EEEEEE",
                        marginTop: "15px",
                      }}
                      hidden={postOrgDetails.id === ""}
                      onClick={() => {
                        window.location.href = "/" + postOrgDetails.id;
                      }}
                    >
                      <Card.Body>
                        <div
                          className="d-flex align-items-center"
                          style={{ alignSelf: "center", display: "flex" }}
                        >
                          <Image
                            roundedCircle
                            src={postOrgDetails.profileURL}
                            width="50px"
                            height="50px"
                          />
                          <div style={{ marginLeft: "10px" }}>
                            <Card.Text
                              class="text-secondary"
                              style={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                marginTop: "15px",
                                marginBottom: "0px",
                                color: "black",
                              }}
                            >
                              {postOrgDetails.name}
                            </Card.Text>
                            <Card.Text
                              class="text-secondary"
                              style={{
                                fontSize: "12px",
                                fontWeight: "normal",
                              }}
                            >
                              {"点击查看该组织的信息"}
                            </Card.Text>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>

                    <Card
                      className="border-0"
                      style={{
                        backgroundColor: "#EEEEEE",
                        marginTop: "15px",
                      }}
                      hidden={postEventDetails.id === ""}
                      onClick={() => {
                        window.location.href =
                          "https://rileyevents.com/" + postEventDetails.id;
                      }}
                    >
                      <Card.Body>
                        <div
                          className="d-flex align-items-center"
                          style={{ alignSelf: "center", display: "flex" }}
                        >
                          <Image
                            roundedCircle
                            src={postEventDetails.photoURL}
                            width="50px"
                            height="50px"
                          />
                          <div style={{ marginLeft: "10px" }}>
                            <Card.Text
                              class="text-secondary"
                              style={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                marginTop: "15px",
                                marginBottom: "0px",
                                color: "black",
                              }}
                            >
                              {postEventDetails.title}
                            </Card.Text>
                            <Card.Text
                              class="text-secondary"
                              style={{
                                fontSize: "12px",
                                fontWeight: "normal",
                              }}
                            >
                              {"点击查看该活动信息"}
                            </Card.Text>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>

                    <br />
                    <br />
                    <Card.Subtitle>{comments.length}条精选评论</Card.Subtitle>

                    {comments.map((comment) => {
                      return (
                        <Card className="border-0">
                          <Card.Body>
                            <div
                              className="d-flex align-items-center"
                              style={{ alignSelf: "center", display: "flex" }}
                            >
                              <Image
                                roundedCircle
                                src={comment.postFromProfileURL}
                                width="50px"
                                height="50px"
                              />
                              <div style={{ marginLeft: "10px" }}>
                                <Card.Text
                                  class="text-secondary"
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    marginTop: "15px",
                                    marginBottom: "0px",
                                    color: "black",
                                  }}
                                >
                                  {comment.postFromName}
                                </Card.Text>
                                <Card.Text
                                  class="text-secondary"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {comment.timestamp
                                    .toDate()
                                    .toLocaleDateString("zh")}
                                </Card.Text>
                              </div>
                            </div>
                            <Card.Subtitle style={{ marginLeft: "60px" }}>
                              {comment.content}
                            </Card.Subtitle>
                          </Card.Body>
                        </Card>
                      );
                    })}
                    <Button
                      className="w-100 mt3"
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "#F57828",
                        borderRadius: "20px",
                        borderColor: "#ffffff",
                        fontSize: "12px",
                        fontWeight: "bold",
                        height: "40px",
                        marginTop: "5px",
                      }}
                      onClick={() => {
                        if (post.shareURL != null) {
                          if (post.shareURL != "") {
                            window.open(post.shareURL);
                          }
                        }
                      }}
                    >
                      打开乐源APP查看全部
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </Container>
          </>
        );

        if (youtubeId != "") {
          //youtube card
          return (
            <Card>
              <Youtube videoId={youtubeId} opts={opts} />
              <Card.Body>
                <Card.Title>[YouTube]来自{post.user.userName}的分享</Card.Title>
                <Card.Text>{post.postContent}</Card.Text>
                <div>
                  <Image
                    roundedCircle
                    src={post.user.profileURL}
                    width="50"
                    height="50"
                  />
                  <Card.Link class="text-secondary">
                    {" "}
                    {post.user.userName}
                  </Card.Link>
                </div>
                <Card.Text />
                <Card.Text>
                  发布于 {post.timestamp.toDate().toLocaleDateString("zh")}{" "}
                  {post.postLocation}
                </Card.Text>
                <Card.Text />
                {postTopics.map((topicItem) => {
                  return <Card.Link>#{topicItem}</Card.Link>;
                })}
                <Card.Text />
                <Card.Subtitle>评论({comments.length})</Card.Subtitle>
                <Card.Text />
                {comments.map((comment) => {
                  return (
                    <Card>
                      <Card.Body>
                        <Image
                          roundedCircle
                          src={comment.postFromProfileURL}
                          width="50"
                          height="50"
                        />
                        <Card.Link class="text-secondary">
                          {" "}
                          {comment.postFromName}
                        </Card.Link>
                        <Card.Text />
                        <Card.Subtitle>{comment.content}</Card.Subtitle>
                        <Card.Text />
                        <Card.Text>
                          评论于{" "}
                          {comment.timestamp.toDate().toLocaleDateString("zh")}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  );
                })}
                <Card.Text />
              </Card.Body>
            </Card>
          );
        } else {
          if (postImages.length > 0) {
            //image cards
            return (
              <Card class="border-0">
                <Carousel variant="top">
                  {postImages.map((imageURL) => {
                    return (
                      <Carousel.Item interval={1000}>
                        <img className="d-block w-100" src={imageURL} alt="-" />
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
                <Card.Body>
                  <Image
                    roundedCircle
                    src={post.user.profileURL}
                    width="50"
                    height="50"
                  />
                  <Card.Link class="text-secondary">
                    {" "}
                    {post.user.userName}
                  </Card.Link>

                  <Card.Text />
                  <Card.Title>来自{post.user.userName}的动态</Card.Title>
                  <Card.Text>{post.postContent}</Card.Text>

                  <Card.Text />
                  {postTopics.map((topicItem) => {
                    return <Card.Link>#{topicItem}</Card.Link>;
                  })}
                  <Card.Text />

                  <Card.Text>
                    发布于 {post.timestamp.toDate().toLocaleDateString("zh")}
                  </Card.Text>

                  <Card.Subtitle>评论({comments.length})</Card.Subtitle>
                  <Card.Text />
                  {comments.map((comment) => {
                    return (
                      <Card>
                        <Card.Body>
                          <Image
                            roundedCircle
                            src={comment.postFromProfileURL}
                            width="50"
                            height="50"
                          />
                          <Card.Link class="text-secondary">
                            {" "}
                            {comment.postFromName}
                          </Card.Link>
                          <Card.Text />
                          <Card.Subtitle>{comment.content}</Card.Subtitle>
                          <Card.Text />
                          <Card.Text>
                            评论于{" "}
                            {comment.timestamp
                              .toDate()
                              .toLocaleDateString("zh")}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    );
                  })}

                  <Card.Text />
                </Card.Body>
              </Card>
            );
          } else {
            return (
              <Card class="border-0">
                <Card.Img variant="top" src={post.imageURL} />
                <Card.Body>
                  <Card.Title>来自{post.user.userName}的动态</Card.Title>
                  <Card.Text>{post.postContent}</Card.Text>
                  <Image
                    roundedCircle
                    src={post.user.profileURL}
                    width="50"
                    height="50"
                  />
                  <Card.Link>{post.user.userName}</Card.Link>

                  <Card.Text />
                  {postTopics.map((topicItem) => {
                    return <Card.Link>#{topicItem}</Card.Link>;
                  })}
                  <Card.Text />

                  <Card.Text>
                    发布于{post.timestamp.toDate().toLocaleDateString("zh")}
                  </Card.Text>

                  <Card.Subtitle>评论({comments.length})</Card.Subtitle>
                  <Card.Text />
                  {comments.map((comment) => {
                    return (
                      <Card>
                        <Card.Body>
                          <Image
                            roundedCircle
                            src={comment.postFromProfileURL}
                            width="50"
                            height="50"
                          />
                          <Card.Link class="text-secondary">
                            {" "}
                            {comment.postFromName}
                          </Card.Link>
                          <Card.Text />
                          <Card.Subtitle>{comment.content}</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    );
                  })}
                  <Card.Text />
                </Card.Body>
              </Card>
            );
          }
        }
      })}
    </div>
  );
}
