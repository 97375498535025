import React, { useContext, useState, useEffect } from "react";
import app, { auth, fires } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";

//mail services

export function sendNewMemberEmail(
  email,
  groupName,
  groupId,
  lang,
  coverImageURL,
  memberName
) {
  if (lang == "zh") {
    const messageSubject = "<" + groupName + ">" + " 欢迎您成为永久会员！";
    const htmlM =
      "<img src='" + coverImageURL + "' alt='group logo' width='100%'><br>";
    const body01 = "<p>您好 " + memberName + ",</p><br>";
    const body02 =
      "<p>我代表<b>" +
      groupName +
      "</b>的全体工作人员欢迎您加入我们组织，以后您将会通过电子邮件的形式收到我们的活动预告，您将有机会参加我们的会员专享活动！</p><br><br>";
    const body03 =
      "<h4>详细内容请点击<a href='https://tickets.evtgo.com/organizer/" +
      groupId +
      "'>组织链接</a></h4><br>";
    const body04 = "<br><p><b>" + groupName + "</b></p>";
    const htmlString = htmlM + body01 + body02 + body03 + body04;
    sendHTMLEmailTo(email, messageSubject, htmlString);
  } else if (lang == "en") {
    const messageSubject = "<" + groupName + ">" + " 欢迎您成为永久会员！";
    const htmlM =
      "<img src='" + coverImageURL + "' alt='group logo' width='100%'><br>";
    const body01 = "<p>您好 " + memberName + ",</p><br>";
    const body02 =
      "<p>我代表<b>" +
      groupName +
      "</b>的全体工作人员欢迎您加入我们组织，以后您将会通过电子邮件的形式收到我们的活动预告，您将有机会参加我们的会员专享活动！</p><br><br>";
    const body03 =
      "<h4>详细内容请点击<a href='https://tickets.evtgo.com/organizer/" +
      groupId +
      "'>组织链接</a></h4><br>";
    const body04 = "<br><p><b>" + groupName + "</b></p>";
    const htmlString = htmlM + body01 + body02 + body03 + body04;
    sendHTMLEmailTo(email, messageSubject, htmlString);
  } else {
    const messageSubject = "<" + groupName + ">" + " 欢迎您成为永久会员！";
    const htmlM =
      "<img src='" + coverImageURL + "' alt='group logo' width='100%'><br>";
    const body01 = "<p>您好 " + memberName + ",</p><br>";
    const body02 =
      "<p>我代表<b>" +
      groupName +
      "</b>的全体工作人员欢迎您加入我们组织，以后您将会通过电子邮件的形式收到我们的活动预告，您将有机会参加我们的会员专享活动！</p><br><br>";
    const body03 =
      "<h4>详细内容请点击<a href='https://tickets.evtgo.com/organizer/" +
      groupId +
      "'>组织链接</a></h4><br>";
    const body04 = "<br><p><b>" + groupName + "</b></p>";
    const htmlString = htmlM + body01 + body02 + body03 + body04;
    sendHTMLEmailTo(email, messageSubject, htmlString);
  }
}

export function sendHTMLEmailTo(email, messageSubject, htmlTexts) {
  const messageObj = {
    subject: messageSubject,
    html: htmlTexts,
  };
  var emailObj = [];
  emailObj.push(email);
  fires
    .collection("mail")
    .add({
      to: emailObj,
      message: messageObj,
    })
    .then((docRef) => {
      const sentId = docRef.id;
    });
}

export function organizerAccountLogin(organizerId) {
  if (auth.currentUser.uid != "") {
    fires
      .collection("users")
      .doc(auth.currentUser.uid)
      .collection("logs")
      .add({
        device: window.location.origin,
        deviceName: "Website",
        type: "Login",
        timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      });
  }
  if (organizerId != "") {
    fires
      .collection("groups")
      .doc(organizerId)
      .collection("logs")
      .add({
        device: window.location.origin,
        deviceName: "Website",
        type: "Login",
        timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      });
  }
}

export function stripeAPICreateProduct(orgId) {
  auth.currentUser.getIdToken(false).then((idToken) => {
    const dataBody = `org_id=${orgId}&admin_uid=${auth.currentUser.uid}&admin_token=${idToken}`;
    fetch(
      "https://organizerapicreatestripeproduct-verqhozorq-uc.a.run.app?" +
        dataBody,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((response) => {
      response.json().then((data) => {
        var dataS = { success: false, status: "", productId: "" };
        dataS = data;
        if (dataS.success) {
        }
      });
    });
  });
}

export function stripeAPICreatePrice(orgId, unit_amount, product_id, currency) {
  auth.currentUser.getIdToken(false).then((idToken) => {
    const dataBody = `org_id=${orgId}&unit_amount=${unit_amount}&currency=${currency}&product_id=${product_id}&user_uid=${auth.currentUser.uid}&user_token=${idToken}`;
    fetch(
      "https://organizerapicreatestripeonetimeprice-verqhozorq-uc.a.run.app?" +
        dataBody,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((response) => {
      response.json().then((data) => {
        var dataS = { success: false, status: "", productId: "", priceId: "" };
        dataS = data;
        if (dataS.success) {
          if (dataS.priceId != "") {
            window.alert("Your price request is approved! Reloading now...");
            window.location.reload();
          }
        } else {
          window.alert("Error: can't create price");
        }
      });
    });
  });
}

export async function stripeAPICreatePriceInEvent(
  orgId,
  unit_amount,
  product_id,
  currency
) {
  auth.currentUser.getIdToken(false).then((idToken) => {
    const dataBody = `org_id=${orgId}&unit_amount=${unit_amount}&currency=${currency}&product_id=${product_id}&user_uid=${auth.currentUser.uid}&user_token=${idToken}`;
    fetch(
      "https://organizerapicreatestripeonetimeprice-verqhozorq-uc.a.run.app?" +
        dataBody,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((response) => {
      response.json().then((data) => {
        var dataS = { success: false, status: "", productId: "", priceId: "" };
        dataS = data;
        if (dataS.success) {
          if (dataS.priceId != "") {
            return dataS.priceId;
          } else {
            return "";
          }
        } else {
          return "";
        }
      });
    });
  });
}
