import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  ListGroup,
  Accordion,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { fires } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { getNow } from "./Helper";

export default function InteractionEditor() {
  const interTitleRef = useRef();
  const interDescriptionRef = useRef();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [posts, setPosts] = useState({});
  const [gData, setGData] = useState({});
  const [qData, setQData] = useState({});
  const [questions, setQuestions] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedExpImage, setSelectedExpImage] = useState(null);

  const [lang, setLang] = useState("zh");
  const maxW = isMobile ? "100%" : "60%";

  const { currentUser } = useAuth();
  let { id } = useParams();

  var timestampNow = getNow();

  var orgId = "";

  const routeBack = () => {
    let path = "/";
    window.location.href = path;
  };
  const routePreview = () => {
    let path = "/interaction/";
    window.location.href = path;
  };

  useEffect(() => {
    return fires
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          var users = [];
          const userData = snapshot.data();
          orgId = userData.orgId;
          setPosts({ ...userData });
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotA) => {
              if (snapshotA.exists) {
                var gData = [];
                var groupData = snapshotA.data();
                orgId = groupData.id;
                setGData({ ...groupData });
                if (lang == "en") {
                  interTitleRef.current.value =
                    groupData.name + " Anonymous Questions";
                  interDescriptionRef.current.value =
                    "Welcome to " + groupData.name + " Anonymous Q&A";
                } else if (lang == "zh") {
                  interTitleRef.current.value = groupData.name + " 的匿名提问";
                  interDescriptionRef.current.value = "请给我匿名提问吧";
                }

                if (id != "") {
                  fires
                    .collection("interactions")
                    .doc(id)
                    .get()
                    .then((snapshotB) => {
                      if (snapshotB.exists) {
                        const interData = snapshotB.data();
                        setQData({ ...interData, id: id });
                        if (interData.lang != null) {
                          setLang(interData.lang);
                        }
                        interTitleRef.current.value = interData.title;
                        interDescriptionRef.current.value =
                          interData.description;
                      }
                    });
                  fires
                    .collection("interactions")
                    .doc(id)
                    .collection("questions")
                    .get()
                    .then((snapshotC) => {
                      var allQuestions = [];
                      snapshotC.docs.forEach((doc) => {
                        const qId = doc.id;
                        const qData = doc.data();
                        allQuestions.push({ ...qData, id: qId });
                      });
                      setQuestions(allQuestions);
                    });
                }
              }
            });
        } else {
          routeBack();
        }
      });
  }, []);

  async function handleQASubmit() {
    const interTitle = interTitleRef.current.value;
    const interDescription = interDescriptionRef.current.value;
    const fromUID = currentUser.uid;
    timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    var imageURL = "";
    var imgURLs = [];

    var interactionContents = {
      lang: lang,
      title: interTitle,
      description: interDescription,
      orgId: gData.id,
      fromUID: fromUID,
      viewCount: 0,
      replyCount: 0,
      coverURL: gData.coverURL,
      imageURL: gData.profileURL,
      acceptQuestion: true,
      isAnonymous: true,
      type: "q&a",
      user: {
        name: gData.name,
        profileURL: gData.profileURL,
        timestamp: timestampNow,
      },
      timestamp: timestampNow,
    };
    if (id == null) {
    } else {
    }
    if (interTitle != "") {
      const interFSItm = fires
        .collection("interactions")
        .add(interactionContents);
      const interId = (await interFSItm).id;
      timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
      fires
        .collection("interactions")
        .doc(interId)
        .update({ timestamp: timestampNow, id: interId })
        .then((error) => {
          if (error == null) {
            setError("");
            setSuccess("匿名提问发送成功");
            window.location.href = "/interaction/" + interId;
          }
        });
    } else {
      setError("无法创建新动态，请输入标题，上传图片等等");
    }
  }

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleExpImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedExpImage(file);
  };

  return (
    <>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="w-100"
          style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
        >
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">{"创建Q&A"}</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}

              <Form>
                <Accordion id="all-control" style={{ margin: "25px" }}>
                  <Accordion.Item eventKey="A">
                    <Accordion.Header>{"添加Q&A信息"}</Accordion.Header>
                    <Accordion.Body>
                      <Form.Group id="qa-language" style={{ margin: "15px" }}>
                        <Form.Label style={{ fontWeight: "bold" }}>
                          提问语言
                        </Form.Label>
                        <DropdownButton
                          alignRight
                          title={lang == "zh" ? "简体中文" : "English"}
                          id="dropdown-menu-align-right"
                          variant="outline-dark"
                          onSelect={(e) => {
                            setLang(e);
                            if (e == "en") {
                              interTitleRef.current.value =
                                gData.name + " Anonymous Questions";
                              interDescriptionRef.current.value =
                                "Welcome to " + gData.name + " Anonymous Q&A";
                            } else {
                              interTitleRef.current.value =
                                gData.name + "的匿名提问";
                              interDescriptionRef.current.value =
                                "请给我匿名提问吧";
                            }
                          }}
                        >
                          <Dropdown.Item eventKey="en">English</Dropdown.Item>
                          <Dropdown.Item eventKey="zh">简体中文</Dropdown.Item>
                        </DropdownButton>
                      </Form.Group>

                      <Form.Group id="qa-title" style={{ margin: "15px" }}>
                        <Form.Label style={{ fontWeight: "bold" }}>
                          提问名称
                        </Form.Label>
                        <Form.Control
                          type="text"
                          rows={1}
                          ref={interTitleRef}
                        />
                      </Form.Group>

                      <Form.Group
                        id="qa-description"
                        style={{ margin: "15px" }}
                      >
                        <Form.Label style={{ fontWeight: "bold" }}>
                          提问内容
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          ref={interDescriptionRef}
                        />
                      </Form.Group>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item hidden={id == null} eventKey="C">
                    <Accordion.Header>
                      已收到的提问[{questions.length}]
                    </Accordion.Header>
                    <Accordion.Body>
                      <Accordion id="all-content" style={{ margin: "25px" }}>
                        {questions.map((quesItem) => {
                          return (
                            <ListGroup.Item className="align-items-start">
                              <div
                                className="fw-bold"
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                Q: {quesItem.question}
                              </div>
                              <div
                                className="fw-normal"
                                style={{ marginTop: "10px" }}
                              >
                                {quesItem.answer == null
                                  ? "还未回复"
                                  : quesItem.answer}
                              </div>
                              <div className="fw-normal">
                                提问于{" "}
                                {quesItem.timestamp
                                  .toDate()
                                  .toLocaleString("zh")}
                              </div>
                            </ListGroup.Item>
                          );
                        })}
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Button
                  className="w-100 p3"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#111",
                    borderRadius: "25px",
                    borderColor: "#ffffff",
                    borderWidth: "3px",
                    fontSize: "15px",
                    fontWeight: "bold",
                    height: "50px",
                    marginTop: "15px",
                  }}
                  onClick={() => {
                    handleQASubmit();
                  }}
                >
                  {lang == "en" ? "Ask Anonymous Questions" : "发起匿名提问"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
