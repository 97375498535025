import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { fires } from "../firebase";
import { isMobile } from "react-device-detect";

export default function GroupContactInfo() {
  const emailRef = useRef();
  const firstNRef = useRef();
  const lastNRef = useRef();
  const phoneRef = useRef();
  const workRef = useRef();
  const wechatRef = useRef();

  const { currentUser } = useAuth();
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const [posts, setPosts] = useState([]);
  const [gData, setGData] = useState([]);
  const [contactInfo, setContactInfo] = useState({});

  const maxW = isMobile ? "100%" : "60%";

  var uid = currentUser.uid;
  var orgId = "";
  var cEmail = currentUser.email;

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const userData = snapshot.data();
          orgId = userData.orgId;
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotA) => {
              if (snapshotA.exists) {
                var gData = [];
                var groupData = snapshotA.data();
                gData.push({ ...groupData, id: orgId });
                setPosts(groupData);
                setGData(groupData);

                if (groupData.info != null) {
                  if (groupData.info.firstName != null) {
                    firstNRef.current.value = groupData.info.firstName;
                  }
                  if (groupData.info.lastName != null) {
                    lastNRef.current.value = groupData.info.lastName;
                  }
                  if (groupData.info.phone != null) {
                    phoneRef.current.value = groupData.info.phone;
                  }
                  emailRef.current.value = groupData.info.email ?? cEmail;
                  if (groupData.info.wechat != null) {
                    wechatRef.current.value = groupData.info.wechat;
                  }
                  if (groupData.info.work != null) {
                    workRef.current.value = groupData.info.work;
                  }
                  setContactInfo(groupData.info);
                }
              }
            });
          return;
        }
        //deleted
        posts.push({
          id: uid,
          error: "NotFound",
          error_message: "没有访问权限",
          error_code: 404,
        });
        setPosts(posts);
        document.title = "乐源 LETJOY";
        document.description = "开启你的海外生活";
      });
  }, []);

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <div
        className="w-100"
        style={{ maxWidth: maxW, marginTop: "45px", marginBottom: "45px" }}
      >
        <Card className="border-0">
          <Card.Body className="align-items-center justify-content-center">
            <Card.Title
              className="text-center"
              style={{ fontSize: "25px", fontWeight: "bold" }}
            >
              我的联系方式
            </Card.Title>
            {success && <Alert variant="success">{success}</Alert>}
            <Form>
              <Form.Group id="email" style={{ marginTop: "15px" }}>
                <Form.Label>姓名(First Name) *</Form.Label>
                <Form.Control type="text" ref={firstNRef} required />
              </Form.Group>
              <Form.Group id="email" style={{ marginTop: "15px" }}>
                <Form.Label>姓名(Last Name) *</Form.Label>
                <Form.Control type="text" ref={lastNRef} required />
              </Form.Group>
              <Form.Group id="email" style={{ marginTop: "15px" }}>
                <Form.Label>电子邮箱 *</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="email" style={{ marginTop: "15px" }}>
                <Form.Label>电话 *</Form.Label>
                <Form.Control type="tel" ref={phoneRef} required />
              </Form.Group>
              <Form.Group id="email" style={{ marginTop: "15px" }}>
                <Form.Label>微信号</Form.Label>
                <Form.Control type="text" ref={wechatRef} />
              </Form.Group>
              <Form.Group id="email" style={{ marginTop: "15px" }}>
                <Form.Label>公司 *</Form.Label>
                <Form.Control type="text" ref={workRef} required />
              </Form.Group>
              <Button
                className="w-100"
                disabled={loading}
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#000000",
                  borderRadius: "25px",
                  borderColor: "#ffffff",
                  borderWidth: "3px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  height: "50px",
                  marginTop: "25px",
                  marginBottom: "15px",
                }}
                onClick={() => {
                  const email = emailRef.current.value;
                  const firstN = firstNRef.current.value;
                  const lastN = lastNRef.current.value;
                  const phone = phoneRef.current.value;
                  const work = workRef.current.value;
                  const wechat = wechatRef.current.value;

                  const updateDa = {
                    info: {
                      firstName: firstN,
                      lastName: lastN,
                      email: email,
                      phone: phone,
                      wechat: wechat,
                      work: work,
                    },
                  };

                  fires
                    .collection("groups")
                    .doc(gData.id)
                    .update(updateDa)
                    .then((error) => {
                      if (error != null) {
                        setSuccess("联系方式更新失败");
                      } else {
                        setSuccess("您的联系方式已更新完成");
                      }
                    });
                }}
              >
                保存
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}
