import React, { useRef, useState } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  DropdownButton,
  Dropdown,
  Modal,
  ButtonGroup,
  FloatingLabel,
  Row,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { fires, auth } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import uuid from "react-uuid";
import Toggle from "react-toggle";

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const orgIdRef = useRef();
  const orgNameRef = useRef();
  const orgDescriptionRef = useRef();
  const orgTypeCustomRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const codeRef = useRef();

  const { signup } = useAuth();
  const [orgTypeCN, setOrgTypeCN] = useState("Not selected");
  const [orgType, setOrgType] = useState("-");
  const [error, setError] = useState("");
  const [orgId, setOrgId] = useState(uuid());
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [validPromo, setValidPromo] = useState("");
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "40%";

  const [value, setValue] = useState("选择下列类型");

  const [signupType, setSignupType] = useState(0);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const coverImg =
    "https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80";
  const profileImg =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";

  const orgTypes = {
    realtor: "Realtor",
    accounting: "Accountant",
    morgage: "Morgage Specialist",
    immigration: "Immigration Advisor",
    issurance: "Insurance Advisor",
    financial: "Financial Analyst",
    restaurant: "Restaurant",
    travel: "Travel",
    software: "Software Company",
    cleaning: "Cleaning",
    other: "Others",
  };

  const handleSelect = (e) => {
    setOrgTypeCN(orgTypes[e]);
    setOrgType(e);
    if (e == "other") {
      setShow(true);
    }
  };

  function sendEmailTO(emailAddress, orgName, userName) {
    const messageSubject = "欢迎您使用乐源的服务";
    const body01 = "<p>您好 " + userName + ",</p><br>";
    const body02 =
      "<p>我是乐源的创始人Sam，首先感谢您使用乐源的服务！作为海外华人活动平台的集大成者，乐源一直致力于帮助海外的活动组织者寻找一体化的活动解决方案。</p>";
    const body03 =
      "<p>如果您对我们的产品有任何的意见和建议，请您联系我们的工作邮箱 <b>admin@letjoy.app</b></p>";
    const body04 =
      "<p>再次感谢您和" +
      orgName +
      "对乐源平台的大力支持，请您访问<a href='https://letjoy.app/'>此链接</a>进入乐源平台发布文章和活动</p>";
    const body05 =
      "<p>您的客户可以使用我们的手机客户端，欢迎下载<a href='https://leyuan.page.link/app'>乐源APP</a></p>";
    const body06 = "<br><h3><b>乐源团队</b></h3>";
    const htmlString = body01 + body02 + body03 + body04 + body05 + body06;

    if (emailAddress != "") {
      const messageObj = {
        subject: messageSubject,
        html: htmlString,
      };
      fires
        .collection("mail")
        .add({
          to: [emailAddress],
          message: messageObj,
        })
        .then((docRef) => {
          const sentId = docRef.id;
        });
    }
  }

  function sendEmailToAdmin(orgName, userEmail) {
    const messageSubject = "新的机构已入驻EVENTGO - " + orgName;
    const textString =
      "您好Sam，新的企业已经成功落户EVENTGO\n\n名称: " +
      orgName +
      "\n电子邮箱: " +
      userEmail +
      "\n";

    const messageObj = {
      subject: messageSubject,
      text: textString,
    };
    fires
      .collection("mail")
      .add({
        to: ["contact@evtgo.com"],
        message: messageObj,
      })
      .then((docRef) => {
        const sentId = docRef.id;
      });
  }

  async function createStripeProduct(orgId) {
    auth.currentUser.getIdToken(true).then((idToken) => {
      const dataBody = `org_id=${orgId}&user_uid=${auth.currentUser.uid}&user_token=${idToken}`;
      fetch(
        "https://organizerapicreatestripeproduct-verqhozorq-uc.a.run.app/?" +
          dataBody,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      ).then((response) => {});
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Password not matched");
    }

    const orgId = orgIdRef.current.value;
    var orgName = orgNameRef.current.value;
    const orgDescription = orgDescriptionRef.current.value;
    const emailA = emailRef.current.value;
    const phoneA = "";
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    const wechat = "";
    const workInfo = "";
    const referralCode =
      codeRef.current.value !== "" && validPromo === codeRef.current.value
        ? codeRef.current.value
        : "";

    if (signupType === 0) {
      //personal
      orgName = firstName + " " + lastName;
    } else {
    }

    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    const dataSet = {
      name: orgName,
      description: orgDescription,
      id: orgId,
      profileURL: profileImg,
      coverURL: coverImg,
      fromUID: "",
      expTitle: "",
      postTitle: "",
      location: "-",
      country: "-",
      groupType: "events",
      groupStatus: "public",
      typeCN: orgTypeCN,
      type: orgType,
      live: false,
      bean: 500,
      lang: "zh",
      namecardType: signupType,

      info: {
        email: emailA,
        phone: phoneA,
        name: `${firstName} ${lastName}`,
        firstName,
        lastName,
        wechat,
        work: workInfo,
      },

      referral: referralCode,

      options: {
        email_rate: 10,
        membership: false,
      },

      liveAt: timestampNow,
      status: "pending",
      timestamp: timestampNow,
    };

    setOrgId(orgIdRef.current.value);

    if (orgId === "") {
      setError("");
      setLoading(true);
      try {
        await signup(emailRef.current.value, passwordRef.current.value);
        const uid = auth.currentUser.uid;
        const emailCurrent = auth.currentUser.email;
        dataSet.fromUID = uid;
        const groupRef = fires.collection("groups").add(dataSet);
        const newOrgId = (await groupRef).id;
        fires
          .collection("groups")
          .doc(newOrgId)
          .collection("teams")
          .doc(emailCurrent)
          .set({
            email: emailCurrent,
            isAdmin: true,
            role: "admin",
            status: "approved",
            statusComplete: true,
            name: orgName,
            timestamp: timestampNow,
          });
        fires.collection("users").doc(uid).set({
          userName: orgName,
          orgName: orgName,
          orgId: newOrgId,
          email: emailCurrent,
        });

        sendEmailToAdmin(orgName, emailA);
        createStripeProduct(newOrgId);

        navigate("/onboarding");
      } catch {
        setError("无法创建账户");
      }
    } else {
      try {
        setError("");
        setLoading(true);

        if (!(await fires.collection("groups").doc(orgId).get()).exists) {
          await signup(emailRef.current.value, passwordRef.current.value);
          const uid = auth.currentUser.uid;
          dataSet.fromUID = uid;
          const emailCurrent = emailRef.current.value;

          fires.collection("groups").doc(orgId).set(dataSet);

          fires
            .collection("groups")
            .doc(orgId)
            .collection("teams")
            .doc(emailCurrent)
            .set({
              email: emailCurrent,
              isAdmin: true,
              role: "admin",
              status: "approved",
              statusComplete: true,
              name: orgName,
              timestamp: timestampNow,
            });

          fires.collection("organizers").doc(uid).set({
            userName: orgName,
            orgName,
            orgId,
            email: emailA,
            admin: true,
            isAdmin: true,
            currentEvent: "",
            firstName,
            lastName,
          });

          fires
            .collection("users")
            .doc(uid)
            .set({
              userName: orgName,
              orgName,
              orgId,
              email: emailA,
              admin: true,
              isAdmin: true,
              currentEvent: "",
              firstName,
              lastName,
            })
            .then((error) => {
              if (error != null) {
              }

              sendEmailToAdmin(orgName, emailA);
              createStripeProduct(orgId);
              navigate("/onboarding");
            });
        } else {
          setError(
            "Your eventgo ID is already existed, please regenerate new ID"
          );
        }
      } catch {
        setError("Can't create account, please contact admin");
      }
    }

    setLoading(false);
  }

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        backgroundImage:
          "url(https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)",
      }}
    >
      <div className="w-100" style={{ maxWidth: maxW }}>
        <Card
          className="border-0"
          style={{ marginTop: "35px", marginBottom: "35px" }}
        >
          <Card.Body>
            <h2 className="text-center mb-4">Signup</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group
                className="d-flex align-items-center justify-content-center"
                id="select-tyoe"
                style={{ marginTop: "15px" }}
              >
                <ButtonGroup className="align-items-center justify-content-center">
                  <Button
                    variant={signupType === 0 ? "dark" : "outline-dark"}
                    onClick={() => {
                      setSignupType(0);
                    }}
                  >
                    Personal
                  </Button>
                  <Button
                    variant={signupType === 1 ? "dark" : "outline-dark"}
                    onClick={() => {
                      setSignupType(1);
                    }}
                  >
                    Enterprise
                  </Button>
                </ButtonGroup>
              </Form.Group>
              <Form.Group id="org-id" style={{ marginTop: "35px" }}>
                <Form.Label>
                  Please fill in your EventGo ID (sites.evtgo.com/xxx-xxx)
                </Form.Label>
                <FloatingLabel
                  controlId="floatingInput"
                  label="EVENTGO ID (Recommended using xxx-xxx format)"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    ref={orgIdRef}
                    rows={1}
                    maxLength={35}
                    placeholder="xxx-xxx"
                    required
                  />
                </FloatingLabel>
                <Button
                  variant="dark"
                  style={{ marginTop: "5px" }}
                  onClick={() => {
                    const nextUUID = uuid();
                    orgIdRef.current.value = nextUUID;
                  }}
                >
                  Auto Generated
                </Button>
              </Form.Group>

              <div hidden={signupType !== 0} style={{ marginTop: "25px" }}>
                <Row md={2} style={{ marginTop: "15px" }}>
                  <Form.Group id="fn">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="First Name *"
                      className="mb-3"
                    >
                      <Form.Control
                        type="name"
                        placeholder="First Name"
                        ref={firstNameRef}
                      />
                    </FloatingLabel>
                    <Form.Label></Form.Label>
                  </Form.Group>
                  <Form.Group id="ln">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Last Name *"
                      className="mb-3"
                    >
                      <Form.Control
                        type="name"
                        placeholder="Last Name *"
                        ref={lastNameRef}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Row>

                <Form.Group id="exp-title" style={{}}>
                  <Form.Label>Your industry *</Form.Label>
                  <DropdownButton
                    variant="outline-dark"
                    alignRight
                    title={orgTypeCN}
                    id="dropdown-menu-align-right"
                    onSelect={handleSelect}
                  >
                    <Dropdown.Item eventKey="realtor">
                      {orgTypes.realtor}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="accounting">
                      {orgTypes.accounting}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="morgage">
                      {orgTypes.morgage}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="immigration">
                      {orgTypes.immigration}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="issurance">
                      {orgTypes.issurance}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="financial">
                      {orgTypes.financial}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="restaurant">
                      {orgTypes.restaurant}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="travel">
                      {orgTypes.travel}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="software">
                      {orgTypes.software}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="cleaning">
                      {orgTypes.cleaning}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="other">
                      {orgTypes.other}
                    </Dropdown.Item>
                  </DropdownButton>
                </Form.Group>
                <Form.Group id="org-description" style={{ marginTop: "15px" }}>
                  <FloatingLabel label="Introduction">
                    <Form.Control
                      as="textarea"
                      placeholder="Please introduce yourself"
                      ref={orgDescriptionRef}
                      rows={3}
                    />
                  </FloatingLabel>
                </Form.Group>
              </div>

              <div hidden={signupType === 0} style={{ marginTop: "25px" }}>
                <Form.Group id="org-name" style={{ marginTop: "15px" }}>
                  <FloatingLabel label="Company Name *">
                    <Form.Control
                      type="text"
                      ref={orgNameRef}
                      placeholder="Company Name"
                      rows={1}
                      maxLength={50}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group id="org-description" style={{}}>
                  <Form.Label></Form.Label>
                  <FloatingLabel label="Company Intro *">
                    <Form.Control
                      as="textarea"
                      placeholder="Introduce your company"
                      ref={orgDescriptionRef}
                      rows={3}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group id="exp-title" style={{ marginTop: "15px" }}>
                  <Form.Label>Company type</Form.Label>
                  <DropdownButton
                    variant="outline-dark"
                    alignRight
                    title={orgTypeCN}
                    id="dropdown-menu-align-right"
                    onSelect={handleSelect}
                  >
                    <Dropdown.Item eventKey="realtor">
                      {orgTypes.realtor}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="accounting">
                      {orgTypes.accounting}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="morgage">
                      {orgTypes.morgage}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="immigration">
                      {orgTypes.immigration}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="issurance">
                      {orgTypes.issurance}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="financial">
                      {orgTypes.financial}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="restaurant">
                      {orgTypes.restaurant}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="travel">
                      {orgTypes.travel}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="software">
                      {orgTypes.software}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="cleaning">
                      {orgTypes.cleaning}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="other">
                      {orgTypes.other}
                    </Dropdown.Item>
                  </DropdownButton>
                </Form.Group>
              </div>

              <Form.Group id="email" style={{ marginTop: "45px" }}>
                <FloatingLabel label="Email Address *">
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    ref={emailRef}
                    required
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group id="password" style={{ marginTop: "15px" }}>
                <FloatingLabel label="Password *">
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    ref={passwordRef}
                    required
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group id="password-repeat" style={{ marginTop: "15px" }}>
                <FloatingLabel label="Repeat Password *">
                  <Form.Control
                    type="password"
                    placeholder="Repeat your password"
                    ref={passwordConfirmRef}
                    required
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group id="refercode" style={{ marginTop: "35px" }}>
                <Form.Label>Your Referral Code (If any)</Form.Label>
                <FloatingLabel label="Referral Code | Coupons">
                  <Form.Control
                    type="text"
                    placeholder="Please enter any referral code or coupons"
                    ref={codeRef}
                  />
                </FloatingLabel>
                <Form.Label
                  style={{
                    fontSize: validPromo === "" ? 12 : 15,
                    fontWeight: validPromo === "" ? "normal" : "bold",
                    color: validPromo === "" ? "black" : "red",
                  }}
                >
                  {validPromo === ""
                    ? " Your Referral Code will get you free trial for 3 or 6 months"
                    : "Promo Added: " + validPromo}
                </Form.Label>
                <br />
                <Button
                  variant="dark"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    const enteredCode = codeRef.current.value;
                    fires
                      .collection("promos")
                      .doc(enteredCode)
                      .get()
                      .then((snap) => {
                        if (!snap.exists) {
                          //not exist
                          codeRef.current.value = "";
                          alert("Promo code is not exist");
                        } else {
                          const promoData = snap.data();
                          if (promoData.valid != null) {
                            if (promoData.valid) {
                              setValidPromo(enteredCode);
                              alert("Your promocode is added");
                            } else {
                              codeRef.current.value = "";
                              alert("Promo code is not exist");
                            }
                          } else {
                            codeRef.current.value = "";
                            alert("Promo code is not exist");
                          }
                        }
                      });
                  }}
                >
                  Validate
                </Button>
              </Form.Group>

              <Form.Group
                id="terms"
                className="align-items-center justify-content-center"
              >
                <Card.Link
                  alignRight
                  style={{ color: "black" }}
                  href="https://leyuanreview.com/privacy"
                >
                  <Toggle defaultChecked={false} />
                  You are agree to our terms of services
                </Card.Link>
              </Form.Group>

              <Button
                variant="dark"
                disabled={loading}
                className="w-100"
                type="submit"
                style={{ marginTop: "35px", height: "50px" }}
              >
                Create EventGo Organizer Account
              </Button>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Others</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Company Type</Form.Label>
                      <Form.Control
                        as="textarea"
                        ref={orgTypeCustomRef}
                        placeholder="Please fill in your company type"
                        rows={1}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Closed
                  </Button>
                  <Button
                    variant="dark"
                    onClick={() => {
                      const orgCustomType = orgTypeCustomRef.current.value;
                      setOrgType("other");
                      setOrgTypeCN(orgCustomType);
                      setShow(false);
                    }}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}
