import React, { useState, useEffect } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { fires } from "../firebase";
import { isMobile } from "react-device-detect";
import { useParams, Navigate } from "react-router-dom";

export default function GroupDetails() {
  const [posts, setPosts] = useState([]);

  let { id } = useParams();
  const maxW = isMobile ? "100%" : "60%";

  useEffect(() => {
    return fires
      .collection("group_messages")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const posts = [];
          const postData = snapshot.data();
          if (postData.length === 0) {
            posts.push({
              id,
              error: "NoAccess",
              error_message: "没有访问权限",
              error_code: 404,
            });
            setPosts(posts);
            document.title = "没有访问权限";
            document.description = "请您查看是否具有访问权限";
          } else {
            posts.push({ ...postData, id: id });
            setPosts(posts);
            document.title = postData.roomTitle;
            document.description = postData.roomDescription;
          }
          return;
        }
        //deleted
        posts.push({
          id,
          error: "NotFound",
          error_message: "没有访问权限",
          error_code: 404,
        });
        setPosts(posts);
        document.title = "没有访问权限";
        document.description = "请您查看是否具有访问权限";
      });
  }, []);

  return (
    <div>
      {posts.map((post) => {
        var error = post.error;
        var coverImgURL = "";
        if (post.roomBgURL == null) {
          coverImgURL =
            "url(https://images.unsplash.com/photo-1487088678257-3a541e6e3922?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80)";
        } else {
          coverImgURL = "url(" + post.roomBgURL + ")";
        }

        if (error === "NotFound") {
          return <Navigate to="/404" />;
        }
        if (error === "NoAccess") {
          return <Navigate to="/404" />;
        }
        return isMobile ? (
          <Container
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh", backgroundImage: coverImgURL }}
          >
            <Card
              className="border-0 align-items-center justify-content-center"
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "15px!important",
              }}
            >
              <Card.Img
                className="rounded-circle shadow-4-strong"
                variant="top"
                src={post.roomImageURL}
                style={{
                  height: "100px",
                  width: "100px",
                  marginTop: "15px",
                }}
              />
              <Card.Body className="align-items-center justify-content-center">
                <Card.Title className="text-center">
                  {post.roomTitle}
                </Card.Title>
                <Card.Text className="text-center">
                  {post.roomDescription}
                </Card.Text>
                <Button
                  className="w-100 mt3"
                  style={{ backgroundColor: "#F57828" }}
                  onClick={() =>
                    window.open(`https://leyuan.page.link/group/${id}`)
                  }
                >
                  加入群聊
                </Button>
              </Card.Body>
            </Card>
          </Container>
        ) : (
          <Container
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh", backgroundImage: coverImgURL }}
          >
            <div className="w-100" style={{ maxWidth: maxW }}>
              <Card
                className="border-0 align-items-center justify-content-center"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "15px!important",
                }}
              >
                <Card.Img
                  className="rounded-circle shadow-4-strong"
                  variant="top"
                  src={post.roomImageURL}
                  style={{
                    height: "100px",
                    width: "100px",
                    marginTop: "15px",
                  }}
                />
                <Card.Body className="align-items-center justify-content-center">
                  <Card.Title className="text-center">
                    {post.roomTitle}
                  </Card.Title>
                  <Card.Text className="text-center">
                    {post.roomDescription}
                  </Card.Text>
                  <Button
                    className="w-100 mt3"
                    style={{ backgroundColor: "#F57828" }}
                    onClick={() =>
                      window.open(`https://leyuan.page.link/group/${id}`)
                    }
                  >
                    加入群聊
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </Container>
        );
      })}
    </div>
  );
}
