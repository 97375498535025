import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  Spinner,
  ListGroup,
  Badge,
  Dropdown,
  DropdownButton,
  Row,
  Col,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { auth, fires, storageRef } from "../firebase";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import firebase from "firebase";
import "firebase/firestore";

export default function GroupNameInfoEdit() {
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [viewTypes, setViewTypes] = useState([]);
  const [views, setViews] = useState([]);
  const [gData, setGData] = useState([]);
  const [links, setLinks] = useState([]);

  const [orgName, setOrgName] = useState("");
  const [orgDescription, setOrgDescription] = useState("");
  const orgNameRef = useRef();
  const orgDescriptionRef = useRef();
  const orgCountryRegionRef = useRef();
  const orgKeywordRef = useRef();
  const orgBackgroundImgRef = useRef();

  const orgContactFNRef = useRef();
  const orgContactLNRef = useRef();
  const orgContactEmailRef = useRef();
  const orgContactPhoneRef = useRef();
  const orgContactWechatRef = useRef();
  const orgContactWorkRef = useRef();

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const [uploadS, setUploadS] = useState("请点击上传图片开始上传");

  const [coverURL, setCoverURL] = useState("");

  const orgLogoRef = useRef();
  const { signup } = useAuth();
  const [orgTypeCN, setOrgTypeCN] = useState("未选择");
  const [orgType, setOrgType] = useState("-");
  const [orgContactInfo, setOrgContactInfo] = useState({
    email: "",
    firstName: "",
    lastName: "",
    name: "",
    phone: "",
    wechat: "",
    work: "",
  });

  var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

  const maxW = isMobile ? "100%" : "75%";

  var uid = currentUser.uid;
  var cEmail = currentUser.email;
  var orgId = "";

  const orgTypes = {
    realtor: "地产经纪",
    accounting: "会计师",
    morgage: "贷款经纪",
    immigration: "移民顾问",
    issurance: "保险经纪",
    financial: "理财顾问",
    restaurant: "餐饮服务",
    travel: "旅游资讯",
    software: "软件公司",
    cleaning: "清洁公司",
    personal: "个人博主",
    other: "其他",
  };

  const handleSelect = (e) => {
    setOrgTypeCN(orgTypes[e]);
    setOrgType(e);
  };

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const routeToDataAnalytic = () => {
    let path = "/data-analytic";
    window.location.href = path;
  };

  const routeToDashboard = () => {
    let path = "/dashboard";
    window.location.href = path;
  };

  const routeToPage = () => {
    let path = "/org/" + orgId;
    window.location.href = path;
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleImageSubmit = (e) => {
    e.preventDefault();
    const file = selectedImage;
    if (!file) return;
    var uploadTask = storageRef
      .child("expresources")
      .child(gData.name + "_" + currentUser.uid + ".jpg")
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            setUploadS("上传已停止");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            setUploadS("已上传: " + progress + "%");
            break;
        }
      },
      (error) => {
        setError("上传错误");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setSelectedImageURL(downloadURL);
          setSelectedImage(null);
          fires
            .collection("users")
            .doc(currentUser.uid)
            .collection("resources")
            .add({ type: "image", url: downloadURL, timestamp: timestampNow });
        });
      }
    );
  };

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          orgId = userData.orgId;
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotB) => {
              if (snapshotB.exists) {
                var gData = [];
                var groupData = snapshotB.data();
                gData.push({ ...groupData, id: orgId });
                orgNameRef.current.value = groupData.name;
                orgDescriptionRef.current.value = groupData.description;
                if (groupData.info != null) {
                  setOrgContactInfo(groupData.info);
                  if (groupData.info.firstName != null) {
                    orgContactFNRef.current.value = groupData.info.firstName;
                  }
                  if (groupData.info.lastName != null) {
                    orgContactLNRef.current.value = groupData.info.lastName;
                  }
                  if (groupData.info.email != null) {
                    orgContactEmailRef.current.value = groupData.info.email;
                  }
                  if (groupData.info.work != null) {
                    orgContactWorkRef.current.value = groupData.info.work;
                  }
                  if (groupData.info.wechat != null) {
                    orgContactWechatRef.current.value = groupData.info.wechat;
                  }
                }
                if (groupData.coverURL != null) {
                  setCoverURL(groupData.coverURL);
                  orgBackgroundImgRef.current.value = groupData.coverURL;
                }
                if (groupData.country != null) {
                  orgCountryRegionRef.current.value = groupData.country;
                }
                if (groupData.keywords != null) {
                  var keywords = "";
                  groupData.keywords.map((wItm) => {
                    keywords += wItm + ",";
                  });
                  orgKeywordRef.current.value = keywords;
                }
                setSelectedImageURL(groupData.profileURL);
                setOrgTypeCN(groupData.orgTypeCN);
                setOrgType(groupData.orgType);
                setGData(groupData);
              }
            });
        } else {
          //deleted
          posts.push({
            id: uid,
            error: "NotFound",
            error_message: "没有访问权限",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "Event Go";
          document.description = "Love your event";
          routeToLogout();
        }
      });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    const orgName = orgNameRef.current.value;
    const orgDescription = orgDescriptionRef.current.value;
    const orgCountry = orgCountryRegionRef.current.value;
    const orgBGImage = orgBackgroundImgRef.current.value;
    var orgKeywords = "";
    orgKeywords = orgKeywordRef.current.value;
    var orgKeywordOptions = [];

    if (orgKeywords.includes(",")) {
      orgKeywordOptions = orgKeywords.split(",");
    } else if (orgKeywords.includes("，")) {
      orgKeywordOptions = orgKeywords.split("，");
    }

    var orgDescriptions = [];
    orgDescriptions = orgDescription.split("\n");
    orgDescriptions = orgDescriptions.filter(Boolean);

    const orgInfo = {
      name: orgName,
      description: orgDescription,
      descriptions: orgDescriptions,
      profileURL: selectedImageURL,
      country: orgCountry,
      coverURL: orgBGImage,
      keywords: orgKeywordOptions,
      info: {
        email: orgContactEmailRef.current.value,
        firstName: orgContactFNRef.current.value,
        lastName: orgContactLNRef.current.value,
        name: orgName,
        phone: orgContactPhoneRef.current.value,
        wechat: orgContactWechatRef.current.value,
        work: orgContactWorkRef.current.value,
      },
    };
    fires
      .collection("groups")
      .doc(gData.id)
      .update(orgInfo)
      .then(() => {
        routeToDashboard();
      });
  }

  return (
    <>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="w-100"
          style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
        >
          <Card className="border-0">
            <Card.Body className="align-items-center justify-content-center">
              <h2 className="text-center">Modify Organization Information</h2>
              <Card.Text
                className="text-center"
                style={{ marginTop: "10px", fontWeight: "bold" }}
              >
                {gData.name}
              </Card.Text>

              <Form>
                <Form.Group id="org-name" style={{ marginTop: "15px" }}>
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Organization Name (Required)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    ref={orgNameRef}
                    rows={1}
                    maxLength={25}
                    required
                  />
                </Form.Group>
                <Form.Group id="org-description" style={{ marginTop: "15px" }}>
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Description (Required)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    ref={orgDescriptionRef}
                    rows={3}
                    maxLength={500}
                  />
                </Form.Group>
                <Form.Group id="org-contact-name" style={{ marginTop: "15px" }}>
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Contact Email (Required)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    rows={1}
                    defaultValue={orgContactInfo.email}
                    ref={orgContactEmailRef}
                  />
                </Form.Group>
                <Row md={2}>
                  <Col>
                    <Form.Group
                      id="org-contact-name"
                      style={{ marginTop: "15px" }}
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        First Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        rows={1}
                        defaultValue={orgContactInfo.firstName}
                        ref={orgContactFNRef}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      id="org-contact-name"
                      style={{ marginTop: "15px" }}
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Last Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        rows={1}
                        defaultValue={orgContactInfo.lastName}
                        ref={orgContactLNRef}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row md={3}>
                  <Col>
                    <Form.Group
                      id="org-contact-name"
                      style={{ marginTop: "15px" }}
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        联系人电话(Phone)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        rows={1}
                        defaultValue={orgContactInfo.phone}
                        ref={orgContactPhoneRef}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      id="org-contact-name"
                      style={{ marginTop: "15px" }}
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        联系人微信(Wechat)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        rows={1}
                        defaultValue={orgContactInfo.wechat}
                        ref={orgContactWechatRef}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      id="org-contact-name"
                      style={{ marginTop: "15px" }}
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        联系人工作(Work)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        rows={1}
                        defaultValue={orgContactInfo.work}
                        ref={orgContactWorkRef}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group id="exp-type" style={{ marginTop: "15px" }}>
                  <Form.Label style={{ fontWeight: "bold" }}>
                    机构类型(必填)
                  </Form.Label>
                  <DropdownButton
                    alignRight
                    variant="dark"
                    title={gData.typeCN}
                    id="dropdown-menu-align-right"
                    onSelect={handleSelect}
                  >
                    <Dropdown.Item eventKey="realtor">
                      {orgTypes.realtor}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="accounting">
                      {orgTypes.accounting}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="morgage">
                      {orgTypes.morgage}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="immigration">
                      {orgTypes.immigration}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="issurance">
                      {orgTypes.issurance}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="financial">
                      {orgTypes.financial}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="restaurant">
                      {orgTypes.restaurant}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="travel">
                      {orgTypes.travel}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="software">
                      {orgTypes.software}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="cleaning">
                      {orgTypes.cleaning}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="personal">
                      {orgTypes.personal}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="other">
                      {orgTypes.other}
                    </Dropdown.Item>
                  </DropdownButton>
                  <Form.Label>{orgTypeCN}</Form.Label>
                </Form.Group>
                <Form.Group id="org-logo" style={{ marginTop: "15px" }}>
                  <Form.Label style={{ fontWeight: "bold" }}>
                    机构LOGO(必填)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    value={"已上传的图片"}
                    rows={1}
                    maxLength={50}
                    disabled
                  />
                  {selectedImage && (
                    <div>
                      <img
                        alt="not fount"
                        width={"250px"}
                        src={URL.createObjectURL(selectedImage)}
                      />
                      <br />
                      <Alert variant="primary">{uploadS}</Alert>
                      <div>
                        <button onClick={() => setSelectedImage(null)}>
                          移除
                        </button>
                        <button onClick={handleImageSubmit}>上传图片</button>
                      </div>
                    </div>
                  )}
                  <br />
                  <input
                    type="file"
                    name="coverImage"
                    title="选择图片"
                    onChange={handleImageChange}
                  />
                  <br />
                  <br />
                  <div>
                    <img
                      alt="cover"
                      width={"100px"}
                      height={"100px"}
                      src={selectedImageURL}
                    />
                  </div>
                </Form.Group>

                <Form.Group
                  id="org-background-image"
                  style={{ marginTop: "15px" }}
                >
                  <Form.Label style={{ fontWeight: "bold" }}>
                    背景图片
                  </Form.Label>
                  <Form.Control
                    type="url"
                    ref={orgBackgroundImgRef}
                    rows={1}
                    onChange={() => {
                      const changedURL = orgBackgroundImgRef.current.value;
                      setCoverURL(changedURL);
                    }}
                  />
                  <Card.Img
                    src={coverURL}
                    style={{
                      maxHeight: "500px",
                      width: "100px",
                      marginTop: "25px",
                    }}
                  />
                </Form.Group>

                <Form.Group
                  id="org-country-region"
                  style={{ marginTop: "15px" }}
                >
                  <Form.Label style={{ fontWeight: "bold" }}>
                    国家或地区
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    ref={orgCountryRegionRef}
                    rows={1}
                    maxLength={20}
                  />
                </Form.Group>

                <Form.Group id="org-keyword" style={{ marginTop: "15px" }}>
                  <Form.Label style={{ fontWeight: "bold" }}>
                    关键词(用逗号隔开) 用于搜索
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    ref={orgKeywordRef}
                    rows={1}
                    maxLength={50}
                  />
                </Form.Group>

                <Button
                  className="w-100 mt3"
                  variant="success"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "25px",
                    borderColor: "#ffffff",
                    borderWidth: "3px",
                    fontSize: "15px",
                    fontWeight: "bold",
                    height: "50px",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                  onClick={handleSubmit}
                >
                  修改以上信息
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
