import React, { useRef, useState, useEffect } from "react";
import { Button, Card, Container, ListGroup } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { fires } from "../firebase";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function GroupInfoEdit() {
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [viewTypes, setViewTypes] = useState([]);
  const [views, setViews] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [contactus, setContactus] = useState([]);

  const [gData, setGData] = useState([]);
  const [links, setLinks] = useState([]);

  const maxW = isMobile ? "100%" : "60%";

  var uid = currentUser.uid;
  var orgId = "";

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const routeToChangeGroupInfo = () => {
    let path = "/update-group-info";
    window.location.href = path;
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  useEffect(() => {
    return fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const userData = snapshot.data();
          orgId = userData.orgId;
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotB) => {
              if (snapshotB.exists) {
                var gData = [];
                var groupData = snapshotB.data();
                gData.push({ ...groupData, id: orgId });
                setGData(groupData);

                var links = [];
                if (groupData.contacts != null) {
                  links = groupData.contacts;
                  setLinks(links);
                }
              }
            });
          fires
            .collection("groups")
            .doc(orgId)
            .collection("views")
            .get()
            .then((snapshotA) => {
              var allViews = [];

              var viewTypes = [];

              var todayTotal = 0;
              var todayWeb = 0;
              var hisTotal = 0;
              var hisWeb = 0;

              var todayLinkTotal = 0;
              var todayLinkWeb = 0;
              var hisLinkTotal = 0;
              var hisLinkWeb = 0;

              snapshotA.docs.forEach((doc) => {
                const viewId = doc.id;
                const viewData = doc.data();
                allViews.push({ ...viewData, vid: viewId });
              });
              allViews.forEach((vItm) => {
                const vItmDate = vItm.timestamp.toDate();
                if (
                  isToday(vItmDate) &&
                  vItm.platform == "web" &&
                  vItm.type == "page"
                ) {
                  todayWeb += 1;
                }
                if (isToday(vItmDate) && vItm.type == "page") {
                  todayTotal += 1;
                }
                if (vItm.platform == "web" && vItm.type == "page") {
                  hisWeb += 1;
                }
                if (vItm.type == "page") {
                  hisTotal += 1;
                }

                if (
                  isToday(vItmDate) &&
                  vItm.platform == "web" &&
                  vItm.type == "link"
                ) {
                  todayLinkWeb += 1;
                }
                if (isToday(vItmDate) && vItm.type == "link") {
                  todayLinkTotal += 1;
                }
                if (vItm.platform == "web" && vItm.type == "link") {
                  hisLinkWeb += 1;
                }
                if (vItm.type == "link") {
                  hisLinkTotal += 1;
                }
              });
              viewTypes.push(todayWeb);
              viewTypes.push(todayTotal);
              viewTypes.push(hisWeb);
              viewTypes.push(hisTotal);
              viewTypes.push(todayLinkWeb);
              viewTypes.push(todayLinkTotal);
              viewTypes.push(hisLinkWeb);
              viewTypes.push(hisLinkTotal);

              setViewTypes(viewTypes);

              setViews(allViews);
            });

          fires
            .collection("groups")
            .doc(orgId)
            .collection("quotes")
            .get()
            .then((snapshotB) => {
              var allQuotes = [];
              snapshotB.docs.forEach((doc) => {
                const quoId = doc.id;
                const quoData = doc.data();
                allQuotes.push({ ...quoData, qid: quoId });
              });
              setQuotes(allQuotes);
            });
        } else {
          //deleted
          posts.push({
            id: uid,
            error: "NotFound",
            error_message: "没有访问权限",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "乐源 LETJOY";
          document.description = "开启你的海外生活";
          routeToLogout();
        }
      });
  }, []);

  return (
    <>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="w-100"
          style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
        >
          <Card className="border-0">
            <Card.Body className="align-items-center justify-content-center">
              <Card.Title className="text-center">网页信息</Card.Title>
              <Card.Text
                className="text-center"
                style={{ marginTop: "10px", fontWeight: "bold" }}
              >
                {gData.name}
              </Card.Text>

              <Card.Text
                className="text-center"
                style={{
                  marginTop: "15px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#000000",
                  padding: "8px",
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                }}
              >
                基本信息
              </Card.Text>
              <ListGroup>
                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                  <div className="fw-bold">机构名称</div>
                  <div className="fw-bold">{gData.name}</div>
                </ListGroup.Item>
                <ListGroup.Item className="align-items-start">
                  <div className="fw-bold" style={{ marginBottom: "15px" }}>
                    机构简介
                  </div>
                  <div className="fw-bold">{gData.description}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                  <div className="fw-bold">机构ID</div>
                  <div className="fw-bold">{gData.id}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                  <div className="fw-bold">机构类型</div>
                  <div className="fw-bold">{gData.typeCN}</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                  <div className="fw-bold">头像/LOGO</div>
                  <div className="fw-bold">已选择</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                  <div className="fw-bold">背景图片</div>
                  <div className="fw-bold">已选择</div>
                </ListGroup.Item>
              </ListGroup>
              <Button
                className="w-100 mt3"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "black",
                  borderRadius: "25px",
                  borderColor: "#ffffff",
                  borderWidth: "3px",
                  fontSize: "15px",
                  fontWeight: "bold",
                  height: "50px",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
                onClick={() => {
                  routeToChangeGroupInfo();
                }}
              >
                修改以上信息
              </Button>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
