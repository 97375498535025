import React from "react";
import { Button } from "react-bootstrap";
import { fires } from "../../firebase";
import "firebase/firestore";
import { getNow } from "../Helper";

export default function ButtonCancelCheckinAll({ memberItm, id }) {
  return (
    <Button
      variant="dark"
      style={{}}
      onClick={() => {
        // Cancel checkin
        // For dev/test only
        const checkinAlert = window.confirm(
          `你想要帮${memberItm.name}取消签到吗？`
        );
        if (checkinAlert && memberItm.email) {
          const memberTickets = memberItm.tickets ?? [];
          memberTickets.forEach((ticketId) => {
            fires
              .collection("events")
              .doc(id)
              .collection("tickets")
              .doc(ticketId)
              .update({
                checkin: false,
                checkinTimestamp: null,
                checkinBy: "",
                checkinByUID: "",
              });
          });
          fires
            .collection("events")
            .doc(id)
            .collection("members")
            .doc(memberItm.email)
            .update({
              checkinStatus: false,
              checkinBy: "admin",
              checkinMethod: "Click",
              checkinTickets: [],
              checkinCount: 0,
              checkinTimestamp: getNow(),
            });
        }
      }}
    >
      取消签到全部
    </Button>
  );
}
