import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  ListGroup,
  Accordion,
  Dropdown,
  DropdownButton,
  FloatingLabel,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { isMobile } from "react-device-detect";
import { fires, storageRef } from "../firebase";
import firebase from "firebase/app";
import uuid from "react-uuid";
import "firebase/firestore";

export default function PostEditor() {
  const expTitleRef = useRef();
  const expContentRef = useRef();
  const expDescriptionRef = useRef();
  const expLinkURLRef = useRef();
  const expCountryRef = useRef();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState({});
  const [gData, setGData] = useState({});

  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedExpImage, setSelectedExpImage] = useState(null);
  const [selectedExpImageURL, setSelectedExpImageURL] = useState("");

  const maxW = isMobile ? "100%" : "75%";

  const { currentUser } = useAuth();

  const [images, setImages] = useState([]);
  const [contentList, setContentList] = useState([]);

  var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
  const [imgUrl, setImgUrl] = useState(null);
  const [uploadS, setUploadS] = useState("请点击上传图片开始上传");
  const [progresspercent, setProgresspercent] = useState(0);

  var orgId = "";

  const routeBack = () => {
    let path = "/";
    window.location.href = path;
  };
  const routePreview = () => {
    let path = "/exp/";
    window.location.href = path;
  };

  useEffect(() => {
    return fires
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          var users = [];
          const userData = snapshot.data();
          orgId = userData.orgId;
          setPosts({ ...userData });
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotB) => {
              if (snapshotB.exists) {
                var gData = [];
                var groupData = snapshotB.data();
                orgId = groupData.id;
                setGData({ ...groupData });
              }
            });
        } else {
          routeBack();
        }
      });
  }, []);

  async function handlePostSubmit() {
    const postContent = expDescriptionRef.current.value;
    const fromUID = currentUser.uid;
    timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    var imageURL = "";
    var imgURLs = [];

    contentList.forEach((cont) => {
      let imgU = cont.content;
      imgURLs.push(imgU);
    });
    if (imgURLs.length != 0) {
      imageURL = imgURLs[0];
    }
    var postContents = {
      approved: "no",
      recommended: "no",
      visibility: "all",
      visibilityType: "所有人",
      country: "加拿大",
      postContent: postContent,
      orgId: gData.id,
      fromUID: fromUID,
      postLocation: "",
      eventId: "",
      imageURL: imageURL,
      imageURLs: imgURLs,
      status: "normal",
      voiceURL: "",
      youtubeId: "",
      videoURL: "",
      user: {
        userName: posts.userName,
        userOrg: gData.name,
        profileURL: gData.profileURL,
      },
      topics: [],
      viewBy: [],
      postCoords: {},
      viewCount: 0,
      timestamp: timestampNow,
    };

    if (postContent != "") {
      const postFSItm = fires.collection("posts").add(postContents);
      const postId = (await postFSItm).id;
      timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
      fires
        .collection("posts")
        .doc(postId)
        .update({ timestamp: timestampNow })
        .then((error) => {
          if (error == null) {
            setError("");
            setSuccess("动态发送成功");
            window.location.href = "/post/" + postId;
          }
        });
    } else {
      setError("无法创建新动态，请输入标题，上传图片等等");
    }
  }

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleExpImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedExpImage(file);
  };

  function handleTextChange(e) {
    e.preventDefault();
    setSelectedImage(null);
    const wordCountent = expContentRef.current.value;
    if (wordCountent != "") {
      contentList.push({
        content: wordCountent,
        type: "text",
        type_name: "文字",
        id: contentList.length,
        timestamp: timestampNow,
      });
      setContentList(contentList);
      expContentRef.current.value = "";
      setSuccess("文字已添加[" + contentList.length + "]");
    } else {
      setError("无法添加文字，不能为空");
    }
  }

  const handleExpImageSubmit = (e) => {
    e.preventDefault();
    const file = selectedExpImage;
    if (!file) return;
    var uploadTask = storageRef
      .child("expresources")
      .child(currentUser.uid + "_" + uuid() + "_cover" + ".jpg")
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            setUploadS("上传已停止");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            setUploadS("已上传: " + progress + "%");
            break;
        }
      },
      (error) => {
        setError("上传错误");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setSelectedExpImageURL(downloadURL);
          setSelectedExpImage(null);
          fires
            .collection("users")
            .doc(currentUser.uid)
            .collection("resources")
            .add({ type: "image", url: downloadURL, timestamp: timestampNow });
        });
      }
    );
  };

  function isImgUrl(url) {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onerror = () => resolve(false);
      img.onload = () => resolve(true);
    });
  }

  const handleImageURLSubmit = (e) => {
    e.preventDefault();
    const imgURL = expLinkURLRef.current.value;
    var validImg = false;
    validImg = isImgUrl(imgURL);

    if (imgURL != "" && validImg) {
      contentList.push({
        content: imgURL,
        type: "image",
        type_name: "图片已上传",
        id: contentList.length,
        timestamp: timestampNow,
      });
      setContentList(contentList);
      expLinkURLRef.current.value = "";
      setSuccess("图片[" + contentList.length + "]");
    } else {
      setError("无法添加图片链接 - 不合规");
    }
  };

  const handleImageSubmit = (e) => {
    e.preventDefault();
    const file = selectedImage;

    if (!file) return;

    var uploadTask = storageRef
      .child("expresources")
      .child(currentUser.uid + "_" + uuid() + ".jpg")
      .put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            setUploadS("上传已停止");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            setUploadS("已上传: " + progress + "%");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        setError("上传错误");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          contentList.push({
            content: downloadURL,
            type: "image",
            type_name: "图片已上传",
            id: contentList.length,
            timestamp: timestampNow,
          });
          setContentList(contentList);
          setSelectedImage(null);
          fires
            .collection("users")
            .doc(currentUser.uid)
            .collection("resources")
            .add({ type: "image", url: downloadURL, timestamp: timestampNow });
        });
      }
    );
  };

  return (
    <>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh", minWidth: "100vw" }}
      >
        <div
          className="w-100"
          style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
        >
          <Card className="border-0">
            <Card.Body>
              <ListGroup.Item className="d-flex justify-content-between align-items-start">
                <Card.Title
                  className="text-center"
                  style={{
                    fontSize: "45px",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  创建新动态
                </Card.Title>
                <DropdownButton
                  alignRight
                  hidden={loading}
                  title={"创建新动态"}
                  id="dropdown-menu-align-right"
                  variant="outline-success"
                  onSelect={(e) => {
                    if (e == "publish") {
                      handlePostSubmit();
                    }
                  }}
                >
                  <Dropdown.Item
                    eventKey="publish"
                    style={{
                      color: "black",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    预览并发送
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    eventKey="savequit"
                    style={{
                      color: "red",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    保存并退出
                  </Dropdown.Item>
                </DropdownButton>
              </ListGroup.Item>

              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}

              <Accordion id="all-control" style={{ marginTop: "15px" }}>
                <Accordion.Item eventKey="A">
                  <Accordion.Header>发动态</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group id="post-content" style={{ margin: "15px" }}>
                        <Form.Label>文字内容</Form.Label>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="请您输入想说的话"
                          className="mb-3"
                        >
                          <Form.Control
                            as="textarea"
                            ref={expDescriptionRef}
                            rows={10}
                            placeholder="您想说点什么"
                            required
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group id="exp-image" style={{ margin: "15px" }}>
                        <Form.Label style={{ fontWeight: "bold" }}>
                          图片内容(链接或选择图片上传)
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          ref={expLinkURLRef}
                          rows={1}
                          placeholder="添加图片链接"
                        />
                        <br />
                        <button onClick={handleImageURLSubmit}>
                          添加图片链接
                        </button>
                        <div>
                          {selectedImage && (
                            <div>
                              <img
                                alt="not fount"
                                width={"250px"}
                                src={URL.createObjectURL(selectedImage)}
                              />
                              <br />
                              <Alert variant="primary">{uploadS}</Alert>
                              <div>
                                <button onClick={() => setSelectedImage(null)}>
                                  移除
                                </button>
                                <button onClick={handleImageSubmit}>
                                  上传图片
                                </button>
                              </div>
                            </div>
                          )}
                          <br />
                          <input
                            type="file"
                            name="myImage"
                            title="选择图片"
                            onChange={handleImageChange}
                          />
                        </div>
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="C">
                  <Accordion.Header>
                    已添加的图片[{contentList.length}]
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion id="all-content" style={{ margin: "25px" }}>
                      {contentList.map((contentItm) => {
                        if (contentItm.type == "image") {
                          return (
                            <Accordion.Item eventKey={contentItm.id}>
                              <Accordion.Header>
                                [{contentItm.id + 1}]{contentItm.type_name}
                              </Accordion.Header>
                              <Accordion.Body>
                                <img
                                  alt=""
                                  width={"250px"}
                                  src={contentItm.content}
                                />
                                <br />
                                <Button
                                  variant="danger"
                                  style={{ marginTop: "15px" }}
                                  onClick={() => {
                                    const currentId = contentItm.id;
                                    var currentContent = contentList;
                                    currentContent.splice(currentId, 1);
                                    var recount = 0;
                                    var newSet = [];
                                    currentContent.forEach((cItm) => {
                                      cItm.id = recount;
                                      newSet.push({ ...cItm, id: recount });
                                      recount += 1;
                                    });
                                    setContentList(newSet);
                                    setSuccess("图片[" + currentId + "]已删除");
                                  }}
                                >
                                  删除
                                </Button>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        } else if (contentItm.type == "text") {
                          return (
                            <Accordion.Item eventKey={contentItm.id}>
                              <Accordion.Header>
                                [{contentItm.id + 1}]{contentItm.type_name}
                              </Accordion.Header>
                              <Accordion.Body>
                                <h5>{contentItm.content}</h5>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        } else if (contentItm.type == "link") {
                          return (
                            <Accordion.Item eventKey={contentItm.id}>
                              <Accordion.Header>
                                [{contentItm.id + 1}]{contentItm.type_name}
                              </Accordion.Header>
                              <Accordion.Body>
                                <h4>{contentItm.content}</h4>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        } else {
                          return (
                            <Accordion.Item eventKey={contentItm.id}>
                              <Accordion.Header>
                                {contentItm.type_name}
                              </Accordion.Header>
                              <Accordion.Body></Accordion.Body>
                            </Accordion.Item>
                          );
                        }
                      })}
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
