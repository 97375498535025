import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  ListGroup,
  Dropdown,
  DropdownButton,
  Accordion,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { fires } from "../firebase";
import { isMobile } from "react-device-detect";
import ReactAudioPlayer from "react-audio-player";

export default function AllResources() {
  const { currentUser, logout } = useAuth();
  const [posts, setPosts] = useState([]);
  const [gData, setGData] = useState([]);
  const [links, setLinks] = useState([]);

  const maxW = isMobile ? "100%" : "60%";

  const uid = currentUser.uid;

  const routeToLogout = () => {
    logout();
    window.location.href = "/";
  };

  useEffect(() => {
    return fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          const orgId = userData.orgId;
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotA) => {
              if (!snapshotA.exists) return;
              const gData = [];
              const groupData = snapshotA.data();
              gData.push({ ...groupData, id: orgId });
              setGData(groupData);
            });
          fires
            .collection("users")
            .doc(uid)
            .collection("resources")
            .orderBy("timestamp", "desc")
            .get()
            .then((snapshotB) => {
              const liks = [];
              snapshotB.forEach((docItm) => {
                const vId = docItm.id;
                const vData = docItm.data();
                liks.push({ ...vData, id: vId });
              });
              setLinks(liks);
            });
          return;
        }
        //deleted
        posts.push({
          id: uid,
          error: "NotFound",
          error_message: "没有访问权限",
          error_code: 404,
        });
        setPosts(posts);
        document.title = "乐源 LETJOY";
        document.description = "开启你的海外生活";
        routeToLogout();
      });
  }, []);

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div
        className="w-100"
        style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
      >
        <Card>
          <Card.Body className="align-items-center justify-content-center">
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
              <Card.Text
                className="text-center"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#000000",
                  marginBottom: "45px",
                }}
              >
                我的资源
              </Card.Text>
              <div style={{ display: "flex" }}>
                <Button
                  variant="success"
                  style={{ marginRight: "5px" }}
                  onClick={() => {}}
                >
                  上传新的资源
                </Button>
                <DropdownButton
                  alignRight
                  title="排序"
                  id="dropdown-menu-align-right"
                  variant={"outline-dark"}
                  onSelect={(e) => {}}
                >
                  <Dropdown.Item eventKey="audio">音频</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="photo">图片</Dropdown.Item>
                </DropdownButton>
              </div>
            </ListGroup.Item>

            <Accordion style={{ marginTop: "25px" }}>
              {links.map((linkItm) => {
                if (linkItm.type === "image") {
                  return (
                    <Accordion.Item eventKey={linkItm.id}>
                      <Accordion.Header>
                        {"[图片] " +
                          "上传于 " +
                          linkItm.timestamp.toDate().toLocaleString("zh")}
                      </Accordion.Header>
                      <Accordion.Body className="d-flex justify-content-between align-items-start">
                        <img
                          alt="图片正在加载"
                          src={linkItm.url}
                          width="70%"
                          height="50%"
                        />
                        <DropdownButton
                          alignRight
                          title={"选项"}
                          id="dropdown-menu-align-right"
                          variant="outline-dark"
                          onSelect={(e) => {
                            if (e === "delete" && linkItm.id !== "") {
                              fires
                                .collection("users")
                                .doc(uid)
                                .collection("resources")
                                .doc(linkItm.id)
                                .delete()
                                .then((result) => {
                                  window.location.reload();
                                });
                            }
                          }}
                        >
                          <Dropdown.Item
                            eventKey="modify"
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontWeight: "bold",
                            }}
                          >
                            修改
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            eventKey="delete"
                            style={{
                              color: "red",
                              fontSize: "17px",
                              fontWeight: "bold",
                            }}
                          >
                            删除
                          </Dropdown.Item>
                        </DropdownButton>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                }
                if (linkItm.type === "audio") {
                  return (
                    <Accordion.Item eventKey={linkItm.id}>
                      <Accordion.Header>
                        {"[音频] " +
                          "上传于 " +
                          linkItm.timestamp.toDate().toLocaleString("zh")}
                      </Accordion.Header>
                      <Accordion.Body className="d-flex justify-content-between align-items-start">
                        <ReactAudioPlayer src={linkItm.url} controls />
                        <DropdownButton
                          alignRight
                          title={"选项"}
                          id="dropdown-menu-align-right"
                          variant="outline-dark"
                          onSelect={(e) => {
                            if (e == "delete") {
                              if (linkItm.id != "") {
                                fires
                                  .collection("users")
                                  .doc(uid)
                                  .collection("resources")
                                  .doc(linkItm.id)
                                  .delete()
                                  .then((result) => {
                                    window.location.reload();
                                  });
                              }
                            }
                          }}
                        >
                          <Dropdown.Item
                            eventKey="modify"
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontWeight: "bold",
                            }}
                          >
                            修改
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            eventKey="delete"
                            style={{
                              color: "red",
                              fontSize: "17px",
                              fontWeight: "bold",
                            }}
                          >
                            删除
                          </Dropdown.Item>
                        </DropdownButton>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                }
                return (
                  <Accordion.Item eventKey={linkItm.id}>
                    <Accordion.Header>[未知] 上传于</Accordion.Header>
                    <Accordion.Body className="d-flex justify-content-between align-items-start" />
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}
