import React, { useRef, useState, useEffect } from "react"
import { 
    Form, 
    Button, 
    Card, 
    Alert, 
    Container, 
    Spinner, 
    Accordion,
    ListGroup, 
    Badge, 
    Dropdown, 
    DropdownButton, 
    Row, 
    Col, 
    Image, 
    Modal } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { auth, fires} from "../firebase"
import { Link, useNavigate, useParams } from "react-router-dom"
import {isMobile} from 'react-device-detect';
import firebase from "firebase"
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import 'firebase/firestore';
import Spreadsheet from "react-spreadsheet";
import { CSVLink, CSVDownload } from "react-csv";
import { jsPDF } from "jspdf";

export default function EventDashboard() {

  const { currentUser, logout } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [posts, setPosts] = useState([]);
  const [viewTypes, setViewTypes] = useState([])
  const [views, setViews] = useState([]);
  const [todayViews, setTodayViews] = useState([]); 

  const [eventData, setEventData] = useState({});
  const [eventComments, setEventComments] = useState([]);
  const [eventLikes, setEventLikes] = useState([]);

  const [eventSearchableMembers, setEventSearchableMembers] = useState([]);
  const [eventMembers, setEventMembers] = useState([]);
  const [eventCheckInMembers, setEventCheckInMembers] = useState([]);

  const [eventInvitees, setEventInvitees] = useState([]);
  const [eventInviteeEmails, setEventInviteeEmails] = useState([]);
  const [eventPics, setEventPics] = useState([]);

  const [showInvitees, setShowInvitees] = useState(false);
  const [showAddMembers, setShowAddMembers] = useState(false);
  const [showUnInvitees, setShowUnInvitees] = useState(false);

  const eventInviteeEmailRef = useRef()
  const eventInviteeFirstNameRef = useRef()
  const eventInviteeLastNameRef = useRef()

  const eventMemberEmailRef = useRef()
  const eventMemberFirstNameRef = useRef()
  const eventMemberLastNameRef = useRef()
  const eventMemberInviteeEmailRef = useRef()
  const eventMemberNumberRef = useRef()

  const eventCheckinAddEmailRef = useRef()
  const eventCheckinAddFirstNameRef = useRef()
  const eventCheckinAddLastNameRef = useRef()
  
  const fullScreenHandle = useFullScreenHandle();

  const [links, setLinks] = useState([]);

  const [linkCounts, setLinkCounts] = useState({})

  const maxW = isMobile ? "100%" : "100%"

  var uid = currentUser.uid;
  var cEmail = currentUser.email;

  const [inviteeDisplayType, setInviteeDisplayType] = useState(0)

  const [orgData, setOrgData] = useState({})

  let { id } = useParams();

  const [csvData,setCSVData] = useState([
    ["example", "example", "email"],
    ["a", "a", "a@gmail.com"],
    ["b", "b", "b@gmail.com"],
    ["c", "c", "c@gmail.com"]
  ]);
  
  const [csvViewsData,setCSVViewsData] = useState([]);

  const routeToLogout= () =>{ 
    logout()
    let path = '/' ; 
    window.location.href = path;
  }

  const routeToBack= () =>{ 
    let path = '/' ; 
    window.location.href = path;
  }

  function createTicketId(){
    const fNumber = Math.floor(1000 + Math.random() * 9000)
    const sNumber = Math.floor(1000 + Math.random() * 9000)
    const tNumber = Math.floor(1000 + Math.random() * 9000)
    const ttNumber = Math.floor(1000 + Math.random() * 9000)
    const idString = fNumber.toString() + '-' + sNumber.toString() + '-' + tNumber.toString() + '-' + ttNumber.toString()
    return idString
  }

  function createTicketPDF(ticketId){
    const doc = new jsPDF();
    //add front and line
    if (ticketId != ""){
        doc.setFont('times','bold','30')
        doc.text('LETJOY EVENT',20,20)
        doc.setFont('times','normal','20')
        doc.text('Order #'+ticketId,140,20)
        doc.line(20,25,200,25)
        
        //add content
        doc.save('event-receipt-'+ticketId+'.pdf')
    }
    
  }

  function registerTicket(ticket, eTitle, eId, eOrgName){
    if (ticket != ""){
      const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
      var ticketInfo = {
        validated: true, 
        eventTitle: eTitle, 
        eventId: eId, 
        eventOrgName: eOrgName,
        timestamp: timestampNow}
      fires
        .collection("event_tickets")
        .doc(ticket)
        .set(ticketInfo)
    }
  }

  function sendEmailTo(email,orgName,eventTitle,eventStartDate,eventAddress,userName,ticketId,eventId,ticketIds){
    const messageSubject = "<" + orgName + ">"+"的活动 - "+eventTitle+" 马上就要开始啦"

    const htmlSubject = "<h2>您即将参加" + orgName + "的活动<b>"+eventTitle+"</b></h2>"
    const body01 = "<p>您好 " + userName + ",</p><br>"
    const body02 = "<p>您的活动<b>" + eventTitle + "</b>马上就要开始啦，收到这封信意味着您将参加活动并有机会参与抽奖。请认准本次的门票号码「" + ticketId + "」</p>"
    const body03 = "<p>以下是本次活动的详细信息</p>"
    const body04 = "<p>时间: <b>" + eventStartDate + "</b></p>"
    const body05 = "<p>地址: <b>" + eventAddress + "</b></p>"
    const body06 = "<p>门票: <b>" + ticketId + "</b></p>"
    const bodyAdd = "<p>门票数量: <b>" + (ticketIds.length+1) + "</b></p>"
    var ticketBody = ""
    ticketIds.map((ticketN) => {
        ticketBody += "<p> "+ticketN+" </p>"
    })
    const body07 = "<h4>详细内容请点击<a href='https://events.letjoy.app/" + eventId  +"'>活动链接</a>并于活动当天展示此信息签到</h4><br>"
    const body08 = "<p>发现更多活动，欢迎下载<a href='https://leyuan.page.link/app'>乐源APP</a></p>"
    const body09 = "<br><h3><b>" + orgName + "</b></h3>"
    const htmlString = htmlSubject + body01 + body02 + body03 + body04 + body05 + bodyAdd + body06 + ticketBody + body07 + body08 + body09
    

    if (email != ''){
        const messageObj = {
            'subject':messageSubject, 
            'html':htmlString
        }
        fires
        .collection('mail')
        .add({
            'to':[email],
            'message':messageObj
        }).then((docRef)=>{
            const sentId = docRef.id
            fires
            .collection('events')
            .doc(id)
            .collection('members')
            .doc(email)
            .update({mailSentId: sentId})
        })
      }
  }


  const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  function loadAllMessages(){
      //messages
      fires.collection('events').doc(id).collection('messages').get().then((snapshotA) => {
        var allComments = [];
        snapshotA.docs.forEach((doc) => {
            const commentId = doc.id;
            const commentData = doc.data();

            var userName = ""
            var profileURL = ""
            if (commentData.fromName != null){
                userName = commentData.fromName
            }
            if (commentData.profileURL != null){
                profileURL = commentData.profileURL
            }

            allComments.push({ ...commentData, id: commentId, fromName: userName, profileURL: profileURL })
        })
        setEventComments(allComments)
    })

  }

  function loadAllPics(){
    //pictures
    fires.collection('events').doc(id).collection('pictures').get().then((snapshotA) => {
        var allPics = [];
        snapshotA.docs.forEach((doc) => {
        const picId = doc.id;
        const picData = doc.data();
        allPics.push({ ...picData, id: picId })
        })
        setEventPics(allPics)
    })
  }

  function loadInviteeRealtime(){
      //invitees
      fires.collection('events').doc(id).collection('invitees').onSnapshot((snapshotA) => {
        var allInvitees = [];
        var allEmails = [];
        snapshotA.docs.forEach((doc) => {
            const inviteeEmail = doc.id;
            const inviteeData = doc.data();
            allInvitees.push({ ...inviteeData, email: inviteeEmail })
            allEmails.push(inviteeEmail)
        })
        setEventInvitees(allInvitees)
        setEventInviteeEmails(allEmails)
    })
  }

  function loadAllMembersRealTime(filterBy,orderBy){
      //members
      fires
      .collection('events')
      .doc(id)
      .collection('members')
      .orderBy(filterBy,orderBy).onSnapshot((snapshotA) => {
        var allMembers = [];
        var checkinMembers = [];
        snapshotA.docs.forEach((doc) => {
            const likeId = doc.id;
            const likeData = doc.data();

            var userName = ""
            var userProfileURL = ""
            if (likeData.userName != null){
                userName = likeData.userName
            }
            if (likeData.userProfileURL != null){
                userProfileURL = likeData.userProfileURL
            } 
            if (likeData.checkinStatus != null){
                if (likeData.checkinStatus){
                    checkinMembers.push({ ...likeData, id: likeId, userName: userName, userProfileURL: userProfileURL})
                }
            }
            allMembers.push({ ...likeData, id: likeId, userName: userName, userProfileURL: userProfileURL})
        })
        setEventMembers(allMembers)
        setEventCheckInMembers(checkinMembers)

        var memberData = []
        memberData.push(
            [
            'name',
            'first_name', 
            'last_name', 
            'email', 
            'check_in',
            'quantity', 
            'ticket_id', 
            'register_time',
            'checkin_time',
            'work',
            'wechat',
            'form-question'
        ])
        allMembers.map((memItm)=>{
            var checki = "no"
            if (memItm.checkinStatus){
                checki = 'yes'
            }
            var checkinTime = ""
            var registerTime = ""
            var forms = []
            var formqna = ""
            if (memItm.checkinTimestamp != null){
                checkinTime = memItm.checkinTimestamp.toDate().toLocaleString('zh')
            }
            if (memItm.timestamp != null){
                registerTime = memItm.timestamp.toDate().toLocaleString('zh')
            }
            var quantity = 1
            if (memItm.quantity != null){
                quantity = memItm.quantity
            }
            if (memItm.forms != null){
                forms = memItm.forms
                forms.forEach((formItm) => {
                    formqna += formItm.title + ' - ' + formItm.value + '\n'
                })
            }
            memberData.push([
                memItm.name,
                memItm.firstName,
                memItm.lastName,
                memItm.email, 
                checki, 
                quantity,
                memItm.ticketId,
                registerTime,
                checkinTime,
                memItm.work,
                memItm.wechat,
                formqna])
        })
        setCSVData(memberData)
    })
  }

  function loadAllViews(){
      //views
      fires.collection('events').doc(id).collection('views').orderBy('timestamp','desc').get().then((snapshotA) => {
        var allViews = [];
        var todayView = [];
        
        var viewTypes = [];

        var todayTotal = 0
        var todayWeb = 0
        var hisTotal = 0
        var hisWeb = 0
        snapshotA.docs.forEach((doc) => {
            const viewId = doc.id;
            const viewData = doc.data();
            allViews.push({ ...viewData, vid: viewId })
        })
        allViews.forEach((vItm) => {
            const vItmDate = vItm.timestamp.toDate()
            const vURL = vItm.linkId
            var vUA = ""
            var uaString = ""
            if (vItm.fromUA != null){
                vUA = vItm.fromUA
                
                if (vUA.includes('safari')) {
                    uaString = "Safari浏览器"
                }
                else if (vUA.includes('micromessenger')) {
                    uaString = "微信"
                }
                else if (vUA.includes('instagram')) {
                    uaString = "Instagram应用"
                }
                else if (vUA.includes('fb')) {
                    uaString = "Facebook应用"
                }
                else if (vUA.includes('linkedin')) {
                    uaString = "领英应用LinkedIn"
                }
                else{

                }
            }

            if (isToday(vItmDate) && vItm.platform == 'web'){
                todayWeb += 1
            }
            if (isToday(vItmDate)) {
                todayTotal += 1
                todayView.push({...vItm, fromUA: vUA, uaType: uaString})
            }
            if (vItm.platform == 'web'){
                hisWeb += 1
            }

            
            


        })
        viewTypes.push(todayWeb)
        viewTypes.push(todayTotal)
        viewTypes.push(hisWeb)
        viewTypes.push(hisTotal)
        

        setViewTypes(viewTypes)

        setViews(allViews)

        setTodayViews(todayView)
    })

  }



  useEffect(() => {
    fires.collection('users').doc(uid).get().then((snapshot) => {
        if (snapshot.exists) {
            const users = [];
            const userData = snapshot.data();
            var uorgId = userData.orgId;
            fires.collection('events').doc(id).get().then((snapshotB) => {
                if (snapshotB.exists) {
                    var aData = {};
                    var artiData = snapshotB.data()
                    aData = artiData
                    setEventData(aData);
                    const orgId = aData.orgId
                    if (orgId != null){
                        if (orgId != ""){
                            if (uorgId == orgId){
                                fires.collection('groups').doc(orgId).get().then((snapshotA) => {
                                    if (snapshotA.exists){
                                        const orgD = snapshotA.data()
                                        setOrgData({...orgD})
                                        loadAllMessages()
                                        loadAllPics()
                                        loadAllViews()

                                        //real-time
                                        loadInviteeRealtime()
                                        loadAllMembersRealTime('name','asc')
                                    }
                                    else{
                                        routeToBack()
                                    }
                                })
                            }
                            else{
                                //id is not match
                                routeToBack()
                            }
                        }
                        else{
                            //event orgId empty
                            routeToBack()
                        }
                    }
                    else{

                        routeToBack()
                    }

                    

                    

                    
                }
            })
            
        }
        else{
            //deleted
            posts.push({ id: uid, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
            setPosts(posts)
            document.title = "乐源 LETJOY"
            document.description = "开启你的海外生活"
            routeToLogout()
        }
    });
  }, []);

  
  function updateViewCount(){
    const cViewCount = views.length
    fires.collection('events').doc(id).update({viewCount: cViewCount})
  }
  

  return (
    <>
    <FullScreen handle={fullScreenHandle}>
    
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
    
      <div className="w-100" style={{ maxWidth: maxW, marginTop: '25px', marginBottom: '25px' }}>
      <Card className="border-0">
        <Card.Body className = "align-items-center justify-content-center">
        
        <div className="d-flex justify-content-between align-items-start">
            <Card.Title className="text-center" style={{ fontSize: "45px", fontWeight:'bold', color: "#000000"}}>活动数据分析</Card.Title>
            <div style={{display: 'flex'}}>

            <Button 
            variant="dark" 
            style={{marginRight: '15px'}} onClick={()=>{
                window.location.reload()
            }}>刷新</Button>

            <CSVLink 
            filename={"event-attendence" + ".csv"}
            data={csvData}
            className="btn btn-dark"
            style={{color: 'white', marginRight: '15px'}}>下载参与者数据</CSVLink>

            <CSVLink 
            filename={"event-views-" + ".csv"}
            data={csvViewsData}
            className="btn btn-dark"
            style={{color: 'white', marginRight: '15px'}}>下载点击量数据</CSVLink>

            <DropdownButton
                  style={{marginRight: '10px'}}
                  title='活动选项'
                  id=""
                  variant="outline-dark"
                  onSelect={(e)=>{
                    if (e == 'end-event'){
                        const endConfirm = window.confirm('您确定要结束活动吗？结束活动并创建Email List')
                        if (endConfirm){
                            var emailList = []
                            eventMembers.map((eveItm) => {
                                if (eveItm.email != null){
                                    if (eveItm.checkinStatus && !emailList.includes(eveItm.email)){
                                        emailList.push(
                                            {
                                                name: eveItm.name, 
                                                email: eveItm.email
                                            })
                                    }
                                }
                            })
                            if (orgData.id != null){
                                fires.collection('groups')
                                .doc(orgData.id)
                                .collection('emails')
                                .add({
                                    emails: emailList, 
                                    eventId: id, 
                                    eventTitle: eventData.title, 
                                    eventStartDate: eventData.startDate, 
                                    eventEndData: eventData.endDate}).then((eventR) => {
                                    const emailListId = eventR.id
                                    var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                                    fires
                                    .collection('events')
                                    .doc(id)
                                    .update({
                                        status: 'ended',
                                        statusTimestamp: timestampNow, 
                                        endedTime: timestampNow
                                    }).then(()=>{
                                        window.location.href = "/send-emails/"+emailListId
                                    })
                                })
                            }
                            
                        }
                    }
                    else if (e == 'create-event-ticket'){
                        const ticket = createTicketId()
                        createTicketPDF(ticket)
                    }
                    else if (e == 'create-article-marketing'){}
                    else if (e == 'create-article-growth'){}
                    // else if (e == 'add-fake-users'){
                    //     var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                    //     for (let i = 0; i < 300; i++) {
                    //         var fakeEmail = "letjoyfake+"+i+'@letjoy.app'
                    //         fires.collection('events').doc(id).collection('members').doc(fakeEmail)
                    //         .set({
                    //       name: 'fake+'+i.toString(),
                    //       email: fakeEmail,
                    //       firstName: 'fake',
                    //       lastName: 'email'+i.toString(),
                    //       checkinStatus: true,
                    //       wechat: 'fakeid',
                    //       work: 'Letjoy',
                    //       ticketId: 'fake+'+i.toString(),
                    //       timestamp: timestampNow,
                    //     })
                    //     } 
                        
                    // }
                  }}
                >
                    <Dropdown.Item eventKey='create-article-marketing'>写推广文案(AI)</Dropdown.Item>
                    <Dropdown.Item eventKey='create-article-growth'>写复盘分析(AI)</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey='add-fake-users'>添加虚拟用户(300已签到)</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey='create-event-ticket'>创建活动收据</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey='end-event' style={{color: 'red'}}>结束活动并发送反馈</Dropdown.Item>
                </DropdownButton>

            </div>
            
        </div>


          <div 
          className="d-flex justify-content-center align-items-center" 
          style={{
              marginTop: '15px', 
              marginBottom: '45px'}}>
              <Card.Link 
              className="text-center"
              href={'https://events.letjoy.app/' + eventData.id}
              style={{
                  fontSize: '22px', 
                  fontWeight: 'bold', 
                  marginTop: '15px', 
                  marginBottom: '0px', 
                  color: 'black'
                  }}>{eventData.title}</Card.Link>
            </div>
        
          <Row  style={{marginTop: '20px'}}>
        
            <Col sm={3}>
            <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <Card.Text className="text-center" style={{ fontSize: "20px", fontWeight:'bold', color: "#000000", marginBottom: '45px'}}>{"活动点击量(" + views.length +")"}</Card.Text>
            <div style={{display: 'flex'}}>
            <DropdownButton
                  alignRight
                  title={'点击量选项'}
                  id="dropdown-menu-align-right"
                  variant={'outline-dark'}
                  onSelect={(e)=>{
                      if (e == 'refresh'){
                          loadAllViews()
                      }
                      else if (e == 'view-count'){
                          updateViewCount()
                      }
                  }}
                >
                  <Dropdown.Item eventKey='view-count'>更新点击量</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey='refresh'>刷新</Dropdown.Item>
                </DropdownButton>
            </div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
                <div className="fw-bold">今日手机点击</div>
                <Badge bg="primary" pill>{viewTypes[1] - viewTypes[0]}</Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
                <div className="fw-bold">今日网页点击</div>
                <Badge bg="primary" pill>{viewTypes[0]}</Badge>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
                <div className="fw-bold">今日总点击</div>
                <Badge bg="primary" pill>{viewTypes[1]}</Badge>
            </ListGroup.Item>
        </ListGroup>
        <Card.Text className="text-center" style={{ marginTop: "15px", fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"8px", backgroundColor: "#ffffff", borderRadius: "12px"}}>{'今日点击详情(' + todayViews.length + ')'}</Card.Text>
        <ListGroup>
                {
                    todayViews.map((vItm)=>{
                        return <ListGroup.Item className="d-flex justify-content-between align-items-start">
                            <div className="fw-bold">{vItm.platform == 'web' ? '网页点击' : 'APP点击'} ({vItm.uaType})</div>
                            <div className="fw-normal">{vItm.timestamp.toDate().toLocaleString("zh")}</div>
                    </ListGroup.Item>
                    })
                }
            
            </ListGroup>

            </Col>


            <Col sm={3}>
            <Modal show={showInvitees} onHide={()=>{
                setShowInvitees(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>添加邀请人</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card.Text>您可以添加受邀人员名单，这个名单将保证您的活动参与者中不会出现未受邀的成员</Card.Text>
                    <Form hidden={inviteeDisplayType == 1}>
                    <Form.Group id="invitee-email" style={{marginTop: '5px'}}>
                        <Form.Label style={{fontWeight: 'bold'}}>受邀请人的邮箱 *</Form.Label>
                        <Form.Control type="email" rows={1} ref={eventInviteeEmailRef} required />
                    </Form.Group>
                    <Row md={2}>
                        <Col>
                        <Form.Group id="invitee-fn" style={{marginTop: '5px'}}>
                            <Form.Label style={{fontWeight: 'bold'}}>受邀请人的名 First Name</Form.Label>
                            <Form.Control type="name" rows={1} ref={eventInviteeFirstNameRef} />
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group id="invitee-ln" style={{marginTop: '5px'}}>
                            <Form.Label style={{fontWeight: 'bold'}}>受邀请人的姓 Last Name</Form.Label>
                            <Form.Control type="name" rows={1} ref={eventInviteeLastNameRef} />
                        </Form.Group>
                        </Col>
                    </Row>
                    
                    <Form.Group className="d-flex justify-content-center align-items-center" style={{marginTop: '25px'}}>
                        <Button variant="success" onClick={()=>{
                            const emailU = eventInviteeEmailRef.current.value
                            const firstNameU = eventInviteeFirstNameRef.current.value
                            const lastNameU = eventInviteeLastNameRef.current.value

                            var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                            if (emailU != ""){
                                const inviteeSet = {
                                    email: emailU, 
                                    firstName: firstNameU, 
                                    lastName: lastNameU, 
                                    timestamp: timestampNow}
                                fires
                                .collection('events')
                                .doc(id)
                                .collection('invitees')
                                .doc(emailU)
                                .set(inviteeSet).then(()=>{
                                    eventInviteeEmailRef.current.value = ""
                                    eventInviteeFirstNameRef.current.value = ""
                                    eventInviteeLastNameRef.current.value = ""
                                })
                            }
                        }}>
                            添加邀请人
                        </Button>
                    </Form.Group>
                    </Form>

                    <Accordion defaultActiveKey={inviteeDisplayType == 1 ? "show-invitee" : "A"} style={{marginTop: '45px'}}>
                        <Accordion.Item eventKey="show-invitee">
                            <Accordion.Header>受邀人员({eventInvitees.length})</Accordion.Header>
                            <Accordion.Body>
                                <ListGroup>
                                    {
                                        eventInvitees.map((inviteeItm)=>{
                                            return <ListGroup.Item>
                                                    <div className="d-flex justify-content-between align-items-start">
                                                    <div className="fw-bold">{inviteeItm.email}</div>
                                                    <div className="fw-normal">{inviteeItm.firstName} {inviteeItm.lastName}</div>
                                                    </div>
                                                    <div style={{marginTop: '10px'}}>
                                                        <Card.Link style={{color: 'red'}} onClick={()=>{
                                                            if (inviteeItm.email != ""){
                                                                const agreeDelete = window.confirm('你确定要删除这个人吗？')
                                                                if (agreeDelete){
                                                                    fires
                                                                .collection('events')
                                                                .doc(id)
                                                                .collection('invitees')
                                                                .doc(inviteeItm.email).delete(()=>{
                                                                    
                                                                })
                                                                }
                                                            }
                                                        }}>删除</Card.Link>
                                                    </div>
                                            </ListGroup.Item>
                                        })
                                    }
                                    
                                </ListGroup>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=>{
                    setShowInvitees(false)
                }}>
                    关闭
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddMembers} onHide={()=>{
                setShowAddMembers(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>添加活动参与者</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card.Text></Card.Text>
                    <Form>
                    <Form.Group id="member-email" style={{marginTop: '5px'}}>
                        <Form.Label style={{fontWeight: 'bold'}}>参与者的邮箱 *</Form.Label>
                        <Form.Control type="email" rows={1} ref={eventMemberEmailRef} required />
                    </Form.Group>
                    <Row md={2}>
                        <Col>
                        <Form.Group id="member-fn" style={{marginTop: '5px'}}>
                            <Form.Label style={{fontWeight: 'bold'}}>参与者的名 First Name</Form.Label>
                            <Form.Control type="name" rows={1} ref={eventMemberFirstNameRef} />
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group id="member-ln" style={{marginTop: '5px'}}>
                            <Form.Label style={{fontWeight: 'bold'}}>参与者的姓 Last Name</Form.Label>
                            <Form.Control type="name" rows={1} ref={eventMemberLastNameRef} />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group id="member-invitee-email" style={{marginTop: '5px'}}>
                        <Form.Label style={{fontWeight: 'bold'}}>(选填) 邀请人的邮箱</Form.Label>
                        <Form.Control type="email" rows={1} ref={eventMemberInviteeEmailRef} />
                    </Form.Group>
                    <Form.Group id="member-email" style={{marginTop: '5px'}}>
                        <Form.Label style={{fontWeight: 'bold'}}>参与者的数量 *</Form.Label>
                        <Form.Control type="number" rows={1} ref={eventMemberNumberRef} min={1} defaultValue={1} required />
                    </Form.Group>
                    
                    <Form.Group className="d-flex justify-content-center align-items-center" style={{marginTop: '25px'}}>
                        <Button variant="success" onClick={()=>{
                            const emailU = eventMemberEmailRef.current.value
                            const firstNameU = eventMemberFirstNameRef.current.value
                            const lastNameU = eventMemberLastNameRef.current.value
                            const inviteeEmail = eventMemberInviteeEmailRef.current.value
                            const eventMemberNumber = eventMemberNumberRef.current.value
                            
                            var allTickets = []
                            if (eventMemberNumber > 1){
                                for (let i = 0; i < eventMemberNumber-1; i++) {
                                    const cTicket = createTicketId()
                                    allTickets.push(cTicket)    
                                }
                            }
                            const ticketId = createTicketId()
                            
                            var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                            if (emailU != ""){
                                var memberSet = {
                                    email: emailU, 
                                    firstName: firstNameU, 
                                    lastName: lastNameU,
                                    name: firstNameU + ' ' + lastNameU,
                                    checkinStatus: false,
                                    handsup: false,
                                    thumbup: false,
                                    thumbdown: false,
                                    handsclap: false,
                                    handsup: false,
                                    ticketId: ticketId,
                                    invitedBy: inviteeEmail,
                                    tickets: allTickets,
                                    quantity: allTickets.length+1,
                                    wechat: '',
                                    work: '',
                                    timestamp: timestampNow}
                                fires
                                .collection('events')
                                .doc(id)
                                .collection('members')
                                .doc(emailU)
                                .set(memberSet).then(()=>{
                                    eventMemberEmailRef.current.value = ""
                                    eventMemberFirstNameRef.current.value = ""
                                    eventMemberLastNameRef.current.value = ""
                                    eventMemberInviteeEmailRef.current.value = ""
                                    const fullName = firstNameU + ' ' + lastNameU

                                    //register ticket
                                    registerTicket(
                                        ticketId,
                                        eventData.title,
                                        eventData.id,
                                        orgData.name)

                                    //add to invite list
                                    const inviteeSet = {
                                        email: emailU, 
                                        firstName: firstNameU, 
                                        lastName: lastNameU, 
                                        timestamp: timestampNow}
                                    fires
                                    .collection('events')
                                    .doc(id)
                                    .collection('invitees')
                                    .doc(emailU)
                                    .set(inviteeSet).then(()=>{
                                        
                                    })

                                    //send email
                                    const dateString = eventData.startDate.toDate().toLocaleDateString("zh") + ' ' + eventData.startDate.toDate().toLocaleTimeString("zh")
                                    const addr = eventData.address.fullAddress == '' ? '网络直播' : eventData.address.fullAddress
                                    sendEmailTo(
                                        emailU,
                                        orgData.name,
                                        eventData.title,
                                        dateString,
                                        addr,
                                        fullName,
                                        ticketId,
                                        id,
                                        allTickets)
                                })
                            }
                        }}>
                            添加参与者并发送邮件
                        </Button>
                    </Form.Group>
                    </Form>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=>{
                    setShowAddMembers(false)
                }}>
                    关闭
                </Button>
                </Modal.Footer>
            </Modal>


            <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <Card.Text className="text-center" style={{ fontSize: "20px", fontWeight:'bold', color: "#000000", marginBottom: '25px'}}>{"实时活动参与人数"+"(" + eventMembers.length + ")"}</Card.Text>
            <div style={{display: 'flex'}}>
            <DropdownButton
                  alignRight
                  title={'活动选项'}
                  id="dropdown-menu-align-right"
                  variant={'outline-dark'}
                  onSelect={(e)=>{
                      if (e == 'create-new'){
                          setInviteeDisplayType(0)
                          setShowInvitees(true)
                      }
                      else if (e == 'existing'){
                        setInviteeDisplayType(1)
                          setShowInvitees(true)
                      }
                      else if (e == 'new-members'){
                          setShowAddMembers(true)
                      }
                      else if (e == 'filter-by-name'){
                        loadAllMembersRealTime('name','asc')
                      }
                      else if (e == 'filter-by-timestamp'){
                        loadAllMembersRealTime('timestamp','desc')
                      }
                      else if (e == 'search'){
                          if (eventSearchableMembers.length > 0){
                            setEventSearchableMembers([])
                          }
                          else{
                            const searchText = window.prompt('请输入要搜索的活动参与者')
                            if (searchText != ""){
                                var searchMems = []
                                eventMembers.map((eveItm) => {
                                    if (eveItm.name.includes(searchText) || eveItm.email.includes(searchText)){
                                        searchMems.push({...eveItm})
                                    }
                                })
                                setEventSearchableMembers(searchMems)
                                if (searchMems.length == 0){
                                    window.alert('没有找到参与者')
                                }
                                
                            }
                          }
                          
                      }
                      else if (e == 'delete-uninvitees'){
                          var uninvitedEmails = []
                          eventMembers.map((memItm)=>{
                              if (!eventInviteeEmails.includes(memItm.email)){
                                  uninvitedEmails.push(memItm.email)
                              }
                          })
                          const deleteAllRequest = window.confirm("您想要删除"+uninvitedEmails.length+"个未受邀请的参与者吗？")
                          if (deleteAllRequest){
                            const deleteAllARequest = window.confirm("请您再次确认！您想要删除"+uninvitedEmails.length+"个未受邀请的参与者吗？")
                            if (deleteAllARequest){

                                uninvitedEmails.map((unemail)=>{
                                    if (unemail != ''){
                                      fires
                                      .collection('events')
                                      .doc(id)
                                      .collection('members')
                                      .doc(unemail)
                                      .delete()
                                      .then(()=>{
  
                                      })
                                    }
                                })

                            }
                          }
                      }
                  }}
                >
                  <Dropdown.Item eventKey='create-new'>增加邀请人</Dropdown.Item>
                  <Dropdown.Item eventKey='existing'>我的邀请人({eventInviteeEmails.length})</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey='new-members'>增加活动参与者</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey='search'>{eventSearchableMembers.length > 0 ? '取消搜索' : '搜索'}</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey='filter-by-name'>{'按名字过滤'}</Dropdown.Item>
                  <Dropdown.Item eventKey='filter-by-timestamp'>{'按时间过滤'}</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey='delete-uninvitees' style={{color: 'red'}}>批量删除未邀请人</Dropdown.Item>
                  
                </DropdownButton>
            </div>
            </ListGroup.Item>
            <ListGroup.Item>
            <div className="d-flex justify-content-between align-items-start">
            <Card.Text style={{ 
                fontSize: "15px", 
                fontWeight:'bold', 
                color: "#000000", 
                marginBottom: '25px'}}>
                    {"报名签到比"}</Card.Text>
                    <Card.Text style={{ 
                fontSize: "15px", 
                fontWeight:'bold', 
                color: "#000000", 
                marginBottom: '25px'}}>
                    {Math.round((eventCheckInMembers.length / eventMembers.length) * 100)+'%'}</Card.Text>
            </div>
            <div className="d-flex justify-content-between align-items-start">
            <Card.Text style={{ 
                fontSize: "15px", 
                fontWeight:'bold', 
                color: "#000000", 
                marginBottom: '25px'}}>
                    {"报名签到比"}</Card.Text>
                    <Card.Text style={{ 
                fontSize: "15px", 
                fontWeight:'bold', 
                color: "#000000", 
                marginBottom: '25px'}}>
                    {Math.round((eventCheckInMembers.length / eventMembers.length) * 100)+'%'}</Card.Text>
            </div>
            </ListGroup.Item>


            {
                    eventSearchableMembers.map((memberItm)=>{
                        return <ListGroup.Item style={{marginTop: '15px'}}>
                        <div 
                        className="align-items-start" 
                        style={{
                            alignSelf: 'center', 
                            display: 'flex'
                            }}>
                            <Image 
                            roundedCircle 
                            src={memberItm.profileURL} 
                            width='50px' 
                            height='50px'/>
                            <div style={{marginLeft: '5px'}}>
                                <Card.Text 
                                style={{
                                    fontSize: '18px', 
                                    fontWeight: 'bold', 
                                    marginBottom: '0px', 
                                    color: 'black'}}
                                    >{'(搜索)' + memberItm.name}
                                    </Card.Text>
                                <Card.Text 
                                class="text-secondary" 
                                style={{
                                    fontSize: '12px', 
                                    fontWeight: 'normal'
                                    }}>{memberItm.timestamp.toDate().toLocaleString("zh")}</Card.Text>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-start">
                            <Card.Text style={{}}>电子邮箱: {memberItm.email}</Card.Text>
                        </div>
                        <div className="d-flex justify-content-between align-items-start">
                            <Card.Text style={{}}>出票ID: {memberItm.ticketId}</Card.Text>
                        </div>
                        <div className="d-flex justify-content-between align-items-start" style={{marginTop: '5px'}}>
                            <Card.Text style={{color: eventInviteeEmails.includes(memberItm.email) ? 'green' : 'red', fontWeight: 'bold'}}>{eventInviteeEmails.includes(memberItm.email) ? '已邀请' : '未邀请'}</Card.Text>
                        </div>
                        <div className="d-flex justify-content-between align-items-start" style={{marginTop: '5px'}}>
                            <Card.Text style={{}}>{memberItm.drawOptions == null ? '未抽奖' : '已抽中'}</Card.Text>
                        </div>
                        <div className="d-flex justify-content-between align-items-start" style={{marginTop: '5px'}}>
                            <Card.Text style={{color: memberItm.checkinStatus ? 'green' : 'black', fontWeight: 'bold'}}>{memberItm.checkinStatus ? '已签到' : '未签到'}</Card.Text>
                            <div>
                                <Card.Link onClick={()=>{
                                    if (!memberItm.checkinStatus){
                                        //checkin
                                        const checkinAlert = window.confirm('你想要帮' + memberItm.name + '签到吗?')
                                        if (checkinAlert){
                                        var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                                        if (memberItm.email != ""){
                                            fires.
                                            collection('events').
                                            doc(id).
                                            collection('members').
                                            doc(memberItm.email).
                                            update({
                                                checkinStatus: true,
                                                checkinBy: 'admin',
                                                checkinTimestamp: timestampNow
                                            }).
                                                then(()=>{
                                            })
                                        }
                                    }
                                    }
                                    else{
                                        //cancel checkin
                                        const checkinAlert = window.confirm('你要取消' + memberItm.name + '的签到吗?')
                                        if (checkinAlert){

                                            var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                                            if (memberItm.email != ""){
                                            fires.
                                            collection('events').
                                            doc(id).
                                            collection('members').
                                            doc(memberItm.email).
                                            update({
                                                checkinStatus: false,
                                                checkinBy: 'admin',
                                                checkinTimestamp: timestampNow
                                            }).
                                                then(()=>{

                                            })
                                        }

                                        }
                                    }
                                    
                                }}>{!memberItm.checkinStatus ? '替TA签到' : '取消签到'}</Card.Link>
                                <Card.Link>联系</Card.Link>
                                <Card.Link style={{color: 'red'}} 
                                onClick={()=>{
                                    if (memberItm.email != ""){
                                        const deleteConfirm = window.confirm('名字: ' + memberItm.name + '\n电子邮箱: ' + memberItm.email + '\n警告⚠️ 您确定要删除吗？删除后不可恢复')
                                        if (deleteConfirm){
                                            fires.
                                            collection('events').
                                            doc(id).
                                            collection('members').
                                            doc(memberItm.email)
                                            .delete()
                                            .then(()=>{

                                            })
                                        }
                                    }
                                }}>删除</Card.Link>
                            </div>
                        </div>
                    </ListGroup.Item>
                    })
            }
            




            {
                    eventMembers.map((memberItm)=>{
                        var memberTickets = []
                        var memberQuantity = 1
                        if (memberItm.tickets != null){
                            memberTickets = memberItm.tickets
                        }
                        if (memberItm.quantity != null){
                            memberQuantity = memberItm.quantity
                        }

                        return <ListGroup.Item>
                        <div 
                        className="align-items-start" 
                        style={{
                            alignSelf: 'center', 
                            display: 'flex'
                            }}>
                            <Image 
                            roundedCircle 
                            src={memberItm.profileURL} 
                            width='50px' 
                            height='50px'/>
                            <div style={{marginLeft: '5px'}}>
                                <Card.Text 
                                style={{
                                    fontSize: '18px', 
                                    fontWeight: 'bold', 
                                    marginBottom: '0px', 
                                    color: 'black'}}
                                    >{memberItm.name}
                                    </Card.Text>
                                <Card.Text 
                                class="text-secondary" 
                                style={{
                                    fontSize: '12px', 
                                    fontWeight: 'normal'
                                    }}>{memberItm.timestamp.toDate().toLocaleString("zh")}</Card.Text>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-start">
                            <Card.Text style={{}}>电子邮箱: {memberItm.email}</Card.Text>
                        </div>
                        <div className="d-flex justify-content-between align-items-start">
                            <Card.Text style={{}}>出票ID: {memberItm.ticketId}</Card.Text>
                        </div>
                        <div className="d-flex justify-content-between align-items-start">
                            <Card.Text style={{}}>总人数: {memberQuantity}</Card.Text>
                        </div>
                        <div className="d-flex justify-content-between align-items-start">
                            <Card.Text style={{}}>待签到人数: {memberTickets.length}</Card.Text>
                        </div>
                        <div className="d-flex justify-content-between align-items-start" style={{marginTop: '5px'}}>
                            <Card.Text style={{color: eventInviteeEmails.includes(memberItm.email) ? 'green' : 'red', fontWeight: 'bold'}}>{eventInviteeEmails.includes(memberItm.email) ? '已邀请' : '未邀请'}</Card.Text>
                        </div>
                        
                        <Accordion defaultActiveKey="A" style={{marginTop: '15px', marginBottom: '15px'}}>
                            {
                                memberTickets.map((ticketItm) => {
                                    return (
                                        <Accordion.Item key={ticketItm}>
                                            <Accordion.Header>{ticketItm}</Accordion.Header>
                                            <Accordion.Body>
                                            <div>
                                                
                                                <Card.Link onClick={()=>{
                                                    var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                                                    
                                                    var newEmailId = memberItm.email + '_'+ ticketItm
                                                    
                                                        var memberSet = {
                                                        email: memberItm.email, 
                                                        firstName: memberItm.firstName, 
                                                        lastName: memberItm.lastName,
                                                        name: memberItm.name,
                                                        checkinStatus: true,
                                                        checkinTimestamp: timestampNow,
                                                        handsup: false,
                                                        thumbup: false,
                                                        thumbdown: false,
                                                        handsclap: false,
                                                        handsup: false,
                                                        ticketId: ticketItm,
                                                        invitedBy: memberItm.email,
                                                        emailId: newEmailId,
                                                        wechat: '',
                                                        work: '',
                                                        timestamp: timestampNow}

                                                        let confirmA = window.confirm('确定添加 '+newEmailId)
                                                        let confirmName = window.prompt('输入您的名字？')
                                                        if (confirmA){
                                                            if (confirmName != ""){
                                                                memberSet.name = confirmName
                                                            }
                                                            fires.
                                                            collection('events').
                                                            doc(id).
                                                            collection('members').
                                                            doc(newEmailId).
                                                            set(memberSet).
                                                            then(()=>{
                                                            let cIndex = memberTickets.indexOf(ticketItm)
                                                            if (cIndex >= 0 && cIndex < memberTickets.length){
                                                                memberTickets.splice(cIndex,1)
                                                                fires.
                                                                collection('events').
                                                                doc(id).
                                                                collection('members').
                                                                doc(memberItm.email).update({tickets: memberTickets})
                                                            } 
                                                            })
                                                        }
                                                }}>签到</Card.Link>
                                            </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion>
                        <div className="d-flex justify-content-between align-items-start" style={{marginTop: '5px'}}>
                            <Card.Text style={{}}>{memberItm.drawOptions == null ? '未抽奖' : '已抽中'}</Card.Text>
                        </div>
                        <div className="d-flex justify-content-between align-items-start" style={{marginTop: '5px'}}>
                            <Card.Text style={{color: memberItm.checkinStatus ? 'green' : 'black', fontWeight: 'bold'}}>{memberItm.checkinStatus ? '已签到' : '未签到'}</Card.Text>
                            <div>
                                <Card.Link onClick={()=>{
                                    if (!memberItm.checkinStatus){
                                        //checkin
                                        const checkinAlert = window.confirm('你想要帮' + memberItm.name + '签到吗?')
                                        if (checkinAlert){
                                        var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                                        if (memberItm.email != ""){
                                            fires.
                                            collection('events').
                                            doc(id).
                                            collection('members').
                                            doc(memberItm.email).
                                            update({
                                                checkinStatus: true,
                                                checkinBy: 'admin',
                                                checkinTimestamp: timestampNow
                                            }).
                                                then(()=>{
                                            })
                                        }
                                    }
                                    }
                                    else{
                                        //cancel checkin
                                        var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                                        const checkinAlert = window.confirm('你要取消' + memberItm.name + '的签到吗?')
                                        if (checkinAlert){
                                            var emailId = ""
                                            if (memberItm.emailId != null){
                                                emailId = memberItm.emailId
                                                fires.
                                            collection('events').
                                            doc(id).
                                            collection('members').
                                            doc(emailId).
                                            update({
                                                checkinStatus: false,
                                                checkinBy: 'admin',
                                                checkinTimestamp: timestampNow
                                            }).
                                                then(()=>{

                                            })

                                            }
                                            else{
                                                if (memberItm.email != ""){

                                                    fires.
                                            collection('events').
                                            doc(id).
                                            collection('members').
                                            doc(memberItm.email).
                                            update({
                                                checkinStatus: false,
                                                checkinBy: 'admin',
                                                checkinTimestamp: timestampNow
                                            }).
                                                then(()=>{

                                            })

                                                }
                                            }
                                            
                                            

                                        }
                                    }
                                    
                                }}>{!memberItm.checkinStatus ? '替TA签到' : '取消签到'}</Card.Link>
                                <Card.Link>联系</Card.Link>
                                <Card.Link style={{color: 'red'}} 
                                onClick={()=>{
                                    if (memberItm.email != ""){
                                        const deleteConfirm = window.confirm('名字: ' + memberItm.name + '\n电子邮箱: ' + memberItm.email + '\n警告⚠️ 您确定要删除吗？删除后不可恢复')
                                        if (deleteConfirm){

                                            var emailId = ""
                                            if (memberItm.emailId != null){
                                                emailId = memberItm.emailId
                                                fires.
                                            collection('events').
                                            doc(id).
                                            collection('members').
                                            doc(emailId)
                                            .delete()
                                            .then(()=>{

                                            })
                                            }
                                            else{
                                                fires.
                                            collection('events').
                                            doc(id).
                                            collection('members').
                                            doc(memberItm.email)
                                            .delete()
                                            .then(()=>{

                                            })
                                            }
                                            
                                        }
                                    }
                                }}>删除</Card.Link>
                            </div>
                        </div>
                    </ListGroup.Item>
                    })
            }
            
            </ListGroup>

            </Col>

            <Col sm={3}>
            
            <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <Card.Text 
            className="text-center" 
            style={{ 
                fontSize: "20px", 
                fontWeight:'bold', 
                color: "#000000", 
                marginBottom: '25px'}}>
                    {"活动评论数("+ eventComments.length +")"}
            </Card.Text>
            <div style={{display: 'flex'}}>
            <DropdownButton
                  alignRight
                  title={'评论选项'}
                  id="dropdown-menu-align-right"
                  variant={'outline-dark'}
                  onSelect={(e)=>{
                      if (e == 'refresh'){
                          loadAllMessages()
                      }
                  }}
                >
                  <Dropdown.Item eventKey='comment-action'>打开/关闭评论</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey='refresh'>刷新评论</Dropdown.Item>
                </DropdownButton>
            </div>
            </ListGroup.Item>
                {
                    eventComments.map((commentItm)=>{
                        return <ListGroup.Item>
                            <div className="align-items-start" style={{alignSelf: 'center', display: 'flex'}}>
                                <Image roundedCircle src={commentItm.profileURL} width='50px' height='50px'/>
                                <div style={{marginLeft: '5px'}}>
                                    <Card.Text style={{fontSize: '18px', fontWeight: 'bold', marginBottom: '0px', color: 'black'}}>{commentItm.name}</Card.Text>
                                    <Card.Text class="text-secondary" style={{fontSize: '12px', fontWeight: 'normal'}}>{"评论于 " + commentItm.timestamp.toDate().toLocaleString("zh")}</Card.Text>
                                 </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-start">
                            <div className="fw-bold">{commentItm.message}</div>
                            </div>

                            <div style={{marginTop: '15px'}}>
                          <Card.Link style={{color: 'black', fontWeight: 'bold'}} onClick={()=>{
                            if (commentItm.top != null){
                                if (commentItm.id != ""){
                                    fires
                                    .collection('events')
                                    .doc(id)
                                    .collection('messages')
                                    .doc(commentItm.id)
                                    .update({top: true})
                                }
                            }
                          }
                          }>置顶评论</Card.Link>
                          <Card.Link style={{color: 'black', fontWeight: 'bold'}} onClick={()=>{
                            if (commentItm.approved != null){
                                if (commentItm.id != ""){
                                    fires
                                    .collection('events')
                                    .doc(id)
                                    .collection('messages')
                                    .doc(commentItm.id)
                                    .update({approved: !commentItm.approved})
                                }
                            }
                          }
                          }>{"公共/隐藏评论"}</Card.Link>
                        </div>
                    </ListGroup.Item>
                    })
                }
            
            </ListGroup>
            
            </Col>
            <Col sm={3}>
            <ListGroup>
            <ListGroup.Item 
            className="d-flex justify-content-between align-items-start">
            <Card.Text 
            className="text-center" 
            style={{ 
                fontSize: "20px", 
                fontWeight:'bold', 
                color: "#000000", 
                marginBottom: '25px'}}>
                    {"活动照片数("+ eventPics.length +")"}
            </Card.Text>
            <div style={{display: 'flex'}}>
            <DropdownButton
                  alignRight
                  title={'照片选项'}
                  id="dropdown-menu-align-right"
                  variant={'outline-dark'}
                  onSelect={(e)=>{
                    if (e == 'refresh'){
                        loadAllPics()
                    }
                  }}
                >
                  <Dropdown.Item eventKey='create-new'>添加活动展示照片</Dropdown.Item>
                  <Dropdown.Item eventKey='create-new-user'>添加用户照片</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey='refresh'>刷新照片</Dropdown.Item>
                </DropdownButton>
            </div>
            </ListGroup.Item>
            {
                    eventPics.map((picItm)=>{
                        return <ListGroup.Item>
                            <div  style={{alignSelf: 'center', display: 'flex'}}>
                                <Image src={picItm.imageURL} width='100%' height='50%'/>
                            </div>
                            <div style={{marginTop: '15px'}}>
                          <Card.Link style={{color: 'black', fontWeight: 'bold'}} onClick={()=>{
                              if (picItm.id != ""){
                                if (picItm.approved != null){

                                }
                                else{
                                    fires
                                .collection('events')
                                .doc(id)
                                .collection('pictures')
                                .doc(picItm.id)
                                .update({approved: true}).then(()=>{
                                    window.alert('已精选')
                                })
                                }
                                
                            }
                          }
                          }>{picItm.approved != null ? '已精选' : '精选'}</Card.Link>
                          <Card.Link style={{color: 'red', fontWeight: 'bold'}} onClick={()=>{
                              fires
                              .collection('events')
                              .doc(id)
                              .collection('pictures')
                              .doc(picItm.id).delete(()=>{
                                window.alert('已删除')
                              })
                          }
                          }>删除</Card.Link>
                        </div>
                    </ListGroup.Item>
                    })
                }
            </ListGroup>
            </Col>
        </Row>
          

        </Card.Body>
      </Card>
      </div>
      </Container>
      </FullScreen>
    </>
  )
}
