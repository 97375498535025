import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  DropdownButton,
  Dropdown,
  Row,
  Col,
  Tab,
  ListGroup,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { fires, auth, storageRef } from "../firebase";
import YouTube from "react-youtube";
import firebase from "firebase/app";
import "firebase/firestore";
import uuid from "react-uuid";
import { QRCodeSVG } from "qrcode.react";

export default function OnBoarding() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const orgIdRef = useRef();
  const orgNameRef = useRef();
  const orgDescriptionRef = useRef();
  const orgTypeCustomRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const phoneNumRef = useRef();
  const wechatRef = useRef();
  const workInfoRef = useRef();

  const youtubeRef = useRef();

  const { currentUser, logout } = useAuth();
  const [orgTypeCN, setOrgTypeCN] = useState("未选择");
  const [orgType, setOrgType] = useState("-");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [buttonStyle, setButtonStyle] = useState("default");
  const [orgId, setOrgId] = useState(uuid());
  const [loading, setLoading] = useState(false);
  const [namecardType, setNameCardType] = useState(0);
  const [posts, setPosts] = useState([]);

  const [socialM, setSocialM] = useState([]);

  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "60%";

  const [value, setValue] = useState("选择下列类型");

  const [stepType, setStepType] = useState(1);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const coverImg =
    "https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80";
  const profileImg =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState(profileImg);
  const [uploadS, setUploadS] = useState("请点击上传图片开始上传");
  const [gData, setGData] = useState({});
  var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

  var uid = auth.currentUser.uid;

  const [gStyles, setGStyles] = useState({
    youtube: false,
    feedback: false,
    gallery: false,
    youtubeId: "",
    feedbacks: [{ id: "" }],
    qa: false,
    qaId: "",
  });
  const [orgInteractions, setOrgInteractions] = useState([]);

  const opts = {
    height: isMobile ? "200px" : "350px",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  const [planInfo, setPlanInfo] = useState({
    email: "",
    bean: 200,
    type: "free",
    typeCN: "免费",
    expiredOn: timestampNow,
    timestamp: timestampNow,
  });

  const orgTypes = {
    realtor: "地产经纪",
    accounting: "会计师",
    morgage: "贷款经纪",
    immigration: "移民顾问",
    issurance: "保险经纪",
    financial: "理财顾问",
    restaurant: "餐饮服务",
    travel: "旅游资讯",
    software: "软件公司",
    cleaning: "清洁公司",
    other: "其他",
  };

  function sendWelcomeEmail(email, accountName) {
    const toInfo = [email];
    const mailSubject = "欢迎使用乐源电子名片和开放平台";
    const mailContent =
      "欢迎使用乐源Letjoy及其产品，您的账户" +
      accountName +
      "已经创建完成，如果您没有进行账户创建的操作，请联系我们！\n\n祝您愉快！\n\n乐源团队";
    const mailMessage = { subject: mailSubject, text: mailContent };
    fires.collection("mail").add({ to: toInfo, message: mailMessage });
  }

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          var orgId = userData.orgId;
          fires
            .collection("groups")
            .doc(orgId)
            .collection("private")
            .doc("plan")
            .get()
            .then((snapshotA) => {
              if (snapshotA.exists) {
                const planData = snapshotA.data();
                setPlanInfo(planData);
              }
            });
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotA) => {
              if (snapshotA.exists) {
                const posts = [];
                const postData = snapshotA.data();
                setGData(postData);
                if (postData.style != null) {
                  setGStyles(postData.style);
                }
                if (postData.contacts != null) {
                  setSocialM(postData.contacts);
                }
                if (postData.profileURL != null) {
                  setSelectedImageURL(postData.profileURL);
                }
                posts.push({ ...postData, id: orgId });
                setPosts(posts);

                fires
                  .collection("interactions")
                  .where("orgId", "==", orgId)
                  .orderBy("timestamp", "desc")
                  .get()
                  .then((snapshotB) => {
                    var orgInteractions = [];
                    snapshotB.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgInteractions.push({ ...contentData, id: contentId });
                    });
                    setOrgInteractions(orgInteractions);
                  });
              }
            });
        }
      });
  }, []);

  async function handleAllLinksSubmit(e) {
    e.preventDefault();
    const linkXHS = e.target[0].value;
    const linkWB = e.target[1].value;
    const linkWX = e.target[2].value;
    const linkTT = e.target[3].value;
    const linkFB = e.target[4].value;
    const linkTWITTER = e.target[5].value;
    const linkINS = e.target[6].value;
    const linkDY = e.target[7].value;
    const linkKS = e.target[8].value;
    const linkTikT = e.target[9].value;

    var socialMedias = [];
    if (linkXHS != "") {
      socialMedias.push({ name: "关注我的小红书", type: "url", url: linkXHS });
    }
    if (linkWB != "") {
      socialMedias.push({ name: "关注我的微博", type: "url", url: linkWB });
    }
    if (linkWX != "") {
      socialMedias.push({
        name: "关注我的微信公众号",
        type: "url",
        url: linkWX,
      });
    }
    if (linkTT != "") {
      socialMedias.push({ name: "关注我的头条号", type: "url", url: linkTT });
    }
    if (linkFB != "") {
      socialMedias.push({ name: "关注我的Facebook", type: "url", url: linkFB });
    }
    if (linkTWITTER != "") {
      socialMedias.push({
        name: "关注我的Twitter",
        type: "url",
        url: linkTWITTER,
      });
    }
    if (linkINS != "") {
      socialMedias.push({
        name: "关注我的Instagram",
        type: "url",
        url: linkINS,
      });
    }
    if (linkDY != "") {
      socialMedias.push({ name: "关注我的抖音", type: "url", url: linkDY });
    }
    if (linkKS != "") {
      socialMedias.push({ name: "关注我的快手", type: "url", url: linkKS });
    }
    if (linkTikT != "") {
      socialMedias.push({ name: "关注我的Tiktok", type: "url", url: linkTikT });
    }

    var socialMM = socialM;
    socialMedias.forEach((mediaItm) => {
      socialMM.push(mediaItm);
    });

    fires
      .collection("groups")
      .doc(gData.id)
      .update({ contacts: socialMM })
      .then((error) => {
        setSuccess("上传链接成功");
      });
  }

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleImageSubmit = (e) => {
    e.preventDefault();
    const file = selectedImage;
    if (!file) return;
    var uploadTask = storageRef
      .child("expresources")
      .child(gData.name + "_" + currentUser.uid + ".jpg")
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            setUploadS("上传已停止");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            setUploadS("已上传: " + progress + "%");
            break;
        }
      },
      (error) => {
        setError("上传错误");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setSelectedImageURL(downloadURL);
          setSelectedImage(null);
          fires
            .collection("users")
            .doc(currentUser.uid)
            .collection("resources")
            .add({ type: "image", url: downloadURL, timestamp: timestampNow });
          fires
            .collection("groups")
            .doc(gData.id)
            .update({ profileURL: downloadURL })
            .then((error) => {
              setSuccess("上传链接成功");
            });
        });
      }
    );
  };

  function handlePlanSelection(planType, planTypeCN) {
    if (gData.referral != null) {
      var referralCode = gData.referral;
      fires
        .collection("promos")
        .doc(referralCode)
        .get()
        .then((snap) => {
          if (snap.exists) {
            if (snap.data().valid != null) {
              if (snap.data().valid) {
                //redeem
                let dt = new Date();
                dt.setMonth(dt.getMonth() + 6);
                setPlanInfo({
                  email: auth.currentUser.email,
                  bean: 500,
                  type: planType,
                  typeCN: planTypeCN,
                  expiredOn: firebase.firestore.Timestamp.fromDate(dt),
                });
                fires
                  .collection("promos")
                  .doc(referralCode)
                  .collection("redeemed")
                  .add({
                    orgName: gData.name,
                    orgId: gData.id,
                    email: auth.currentUser.email,
                    expiredOn: firebase.firestore.Timestamp.fromDate(dt),
                    timestamp: timestampNow,
                  })
                  .then((ref) => {
                    const promoRefId = ref.id;
                    fires
                      .collection("groups")
                      .doc(gData.id)
                      .collection("private")
                      .doc("plan")
                      .set({
                        email: auth.currentUser.email,
                        bean: 500,
                        type: planType,
                        typeCN: planTypeCN,
                        promoRefId: promoRefId,
                        referral: referralCode,
                        expiredOn: firebase.firestore.Timestamp.fromDate(dt),
                      })
                      .then((snapR) => {
                        window.location.href = "/";
                      });
                  });
              }
            }
          } else {
          }
        });
    } else {
      window.alert("Please contact us to select this plan");
    }
  }

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundImage:
            "url(https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)",
        }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0"
            style={{ marginTop: "45px", marginBottom: "35px" }}
          >
            <Card.Body>
              <h2 className="text-center mb-4" style={{ marginTop: "15px" }}>
                <b>Welcome to EventGo Organizer</b>
              </h2>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <Tab.Container
                id="list-group-tabs-example"
                defaultActiveKey="#1"
                style={{ marginTop: "50px" }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <ListGroup
                    variant="dark"
                    style={{
                      overflowX: "scroll",
                      whiteSpace: "nowrap",
                      overflow: "scroll",
                    }}
                    horizontal
                  >
                    <ListGroup.Item action href="#1">
                      Basic Info
                    </ListGroup.Item>
                    <ListGroup.Item action href="#2">
                      Social Media
                    </ListGroup.Item>
                    <ListGroup.Item action href="#3">
                      Feedback
                    </ListGroup.Item>
                    <ListGroup.Item action href="#4">
                      Add Youtube
                    </ListGroup.Item>
                    <ListGroup.Item action href="#5">
                      Purchased Plan
                    </ListGroup.Item>
                  </ListGroup>
                </div>

                <Tab.Content>
                  <Tab.Pane
                    eventKey="#1"
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      padding: "35px",
                    }}
                  >
                    <Card.Title className="text-center">
                      Basic Information
                    </Card.Title>
                    <Form>
                      <Form.Group
                        id="org-profile"
                        style={{ marginTop: "15px" }}
                      >
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Profile Picture(100x100)
                        </Form.Label>
                        {selectedImage && (
                          <div>
                            <img
                              alt="not fount"
                              width={"250px"}
                              src={URL.createObjectURL(selectedImage)}
                            />
                            <br />
                            <Alert variant="primary">{uploadS}</Alert>
                            <div>
                              <button onClick={() => setSelectedImage(null)}>
                                Remove
                              </button>
                              <button onClick={handleImageSubmit}>
                                Upload
                              </button>
                            </div>
                          </div>
                        )}
                        <br />
                        <input
                          type="file"
                          name="coverImage"
                          title="Choose"
                          onChange={handleImageChange}
                        />
                        <br />
                        <br />
                        <div>
                          <img
                            alt="cover"
                            width={"100px"}
                            height={"100px"}
                            src={selectedImageURL}
                          />
                        </div>
                      </Form.Group>

                      <Form.Group id="org-bg" style={{ marginTop: "35px" }}>
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Language
                        </Form.Label>
                        <Card.Text style={{ marginTop: "0px" }}>
                          Please select your preferred language
                        </Card.Text>
                      </Form.Group>
                      <DropdownButton
                        title={
                          gData.lang == "en"
                            ? "English"
                            : gData.lang == "zhT"
                            ? "繁体中文"
                            : "简体中文"
                        }
                        id="dropdown-menu-align-right"
                        variant={"outline-dark"}
                        style={{ marginTop: "15px" }}
                        onSelect={(e) => {
                          if (e == "zh") {
                            fires
                              .collection("groups")
                              .doc(gData.id)
                              .update({ lang: "zh" })
                              .then((error) => {
                                setSuccess("Set to 简体中文");
                              });
                          } else if (e == "en") {
                            fires
                              .collection("groups")
                              .doc(gData.id)
                              .update({ lang: "en" })
                              .then((error) => {
                                setSuccess("Set to English");
                              });
                          } else if (e == "zhT") {
                            fires
                              .collection("groups")
                              .doc(gData.id)
                              .update({ lang: "zhT" })
                              .then((error) => {
                                setSuccess("Set to 繁体中文");
                              });
                          }
                        }}
                      >
                        <Dropdown.Item
                          eventKey="en"
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          English
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="zh"
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          简体中文
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="zhT"
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          繁体中文
                        </Dropdown.Item>
                      </DropdownButton>

                      <Form.Group
                        id="org-recommend"
                        style={{ marginTop: "35px" }}
                      >
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Do you allow us to recommend your group?
                        </Form.Label>
                        <Card.Text style={{ marginTop: "0px" }}>
                          Your group will appear on our homepage, so you have
                          more visitors
                        </Card.Text>
                      </Form.Group>
                      <DropdownButton
                        title={
                          gData.status == "live" ? "Allowed" : "Not Allowed"
                        }
                        id="dropdown-menu-align-right"
                        variant={
                          gData.status == "live"
                            ? "outline-success"
                            : "outline-danger"
                        }
                        style={{ marginTop: "15px" }}
                        onSelect={(e) => {
                          if (e == "yes") {
                            fires
                              .collection("groups")
                              .doc(gData.id)
                              .update({ status: "live" })
                              .then((error) => {
                                setSuccess("Allowed to be recommended");
                              });
                          } else {
                            fires
                              .collection("groups")
                              .doc(gData.id)
                              .update({ status: "pending" })
                              .then((error) => {
                                setSuccess("Not allowed to be recommended");
                              });
                          }
                        }}
                      >
                        <Dropdown.Item
                          eventKey="yes"
                          style={{ color: "green", fontWeight: "bold" }}
                        >
                          Allowed
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          eventKey="no"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          Not Allowed
                        </Dropdown.Item>
                      </DropdownButton>
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="#2"
                    style={{ margin: "15px", padding: "35px" }}
                  >
                    <Card.Title className="text-center">
                      Social Media
                    </Card.Title>
                    <Form onSubmit={handleAllLinksSubmit}>
                      <Card.Text
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginTop: "15px",
                        }}
                      >
                        Social Media
                      </Card.Text>
                      <Form.Group id="email" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          小红书
                        </Form.Label>
                        <Form.Control
                          type="url"
                          placeholder="https://xiaohongshu.com/user/profile/"
                        />
                        <Form.Label style={{ fontSize: "12px" }}>
                          请粘贴小红书的链接
                        </Form.Label>
                      </Form.Group>

                      <Form.Group id="email" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          微博
                        </Form.Label>
                        <Form.Control
                          type="url"
                          placeholder="https://weibo.cn/u/"
                        />
                        <Form.Label style={{ fontSize: "12px" }}>
                          微博的链接
                        </Form.Label>
                      </Form.Group>

                      <Form.Group id="email" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          微信公众号
                        </Form.Label>
                        <Form.Control
                          type="url"
                          placeholder="https://mp.weixin.qq.com/s/"
                        />
                        <Form.Label style={{ fontSize: "12px" }}>
                          微信公众号的链接
                        </Form.Label>
                      </Form.Group>

                      <Form.Group id="email" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          今日头条
                        </Form.Label>
                        <Form.Control
                          type="url"
                          placeholder="https://toutiao.com/"
                        />
                        <Form.Label style={{ fontSize: "12px" }}>
                          头条号的链接
                        </Form.Label>
                      </Form.Group>

                      <Form.Group id="email" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          Facebook
                        </Form.Label>
                        <Form.Control
                          type="url"
                          placeholder="https://facebook.com/u/"
                        />
                        <Form.Label style={{ fontSize: "12px" }}>
                          脸书的链接
                        </Form.Label>
                      </Form.Group>

                      <Form.Group id="email" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          Twitter/X
                        </Form.Label>
                        <Form.Control
                          type="url"
                          placeholder="https://twitter.com/u/"
                        />
                        <Form.Label style={{ fontSize: "12px" }}>
                          推特的链接
                        </Form.Label>
                      </Form.Group>

                      <Form.Group id="email" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          Instagram
                        </Form.Label>
                        <Form.Control
                          type="url"
                          placeholder="https://instagram.com/u/"
                        />
                        <Form.Label style={{ fontSize: "12px" }}>
                          Instagram的链接
                        </Form.Label>
                      </Form.Group>

                      <Card.Text
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginTop: "15px",
                        }}
                      >
                        Short Video
                      </Card.Text>

                      <Form.Group id="email" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          douyin抖音
                        </Form.Label>
                        <Form.Control
                          type="url"
                          placeholder="https://douyin.com/u/"
                        />
                        <Form.Label style={{ fontSize: "12px" }}>
                          抖音的链接
                        </Form.Label>
                      </Form.Group>

                      <Form.Group id="email" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          快手
                        </Form.Label>
                        <Form.Control
                          type="url"
                          placeholder="https://kuaishou.com/"
                        />
                        <Form.Label style={{ fontSize: "12px" }}>
                          快手的链接
                        </Form.Label>
                      </Form.Group>

                      <Form.Group id="email" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          TikTok
                        </Form.Label>
                        <Form.Control
                          type="url"
                          placeholder="https://tiktok.com/"
                        />
                        <Form.Label style={{ fontSize: "12px" }}>
                          Tiktok
                        </Form.Label>
                      </Form.Group>

                      <Button
                        className="w-100 p3"
                        type="submit"
                        style={{
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: "#111",
                          borderRadius: "25px",
                          borderColor: "#EEE",
                          borderWidth: "3px",
                          fontSize: "15px",
                          fontWeight: "bold",
                          height: "50px",
                          marginTop: "15px",
                        }}
                      >
                        Save All Links
                      </Button>
                    </Form>
                  </Tab.Pane>

                  <Tab.Pane
                    eventKey="#3"
                    style={{ margin: "15px", padding: "35px" }}
                  >
                    <Card.Title className="text-center">Feedback</Card.Title>
                    <ListGroup.Item className="d-flex justify-content-between align-items-start">
                      <Card.Text
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          marginTop: "15px",
                        }}
                      >
                        {gStyles.feedback ? "回复表单已启动" : "回复表单未启动"}
                      </Card.Text>
                      <DropdownButton
                        title={gStyles.feedback ? "已启动" : "未启动"}
                        alignRight
                        id="dropdown-menu-align-right"
                        variant={
                          gStyles.feedback
                            ? "outline-success"
                            : "outline-danger"
                        }
                        style={{ marginTop: "15px" }}
                        onSelect={(e) => {
                          if (e == "start") {
                            gStyles.feedback = true;
                            setGStyles(gStyles);
                            fires
                              .collection("groups")
                              .doc(gData.id)
                              .update({ style: gStyles })
                              .then((error) => {
                                setSuccess("已启动回复表单");
                              });
                          } else if (e == "stop") {
                            gStyles.feedback = false;
                            setGStyles(gStyles);
                            fires
                              .collection("groups")
                              .doc(gData.id)
                              .update({ style: gStyles })
                              .then((error) => {
                                setSuccess("已禁止回复表单");
                              });
                          }
                        }}
                      >
                        <Dropdown.Item
                          eventKey="start"
                          style={{ color: "green", fontWeight: "bold" }}
                        >
                          启动
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          eventKey="stop"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          禁止
                        </Dropdown.Item>
                      </DropdownButton>
                    </ListGroup.Item>

                    <Form
                      hidden={!gStyles.feedback}
                      style={{
                        marginTop: "50px",
                        padding: "15px",
                        borderRadius: "25px",
                        borderColor: "white",
                        borderWidth: "3px",
                        padding: "10px",
                      }}
                    >
                      <h5
                        className="text-center mb-4"
                        style={{ color: "black" }}
                      >
                        联系我们
                      </h5>
                      <Form.Group id="email" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          电子邮箱
                        </Form.Label>
                        <Form.Control
                          style={{
                            backgroundColor: "transparent",
                            borderWidth: "3px",
                            color: "black",
                            height: "50px",
                            borderRadius: "25px",
                          }}
                          type="email"
                          placeholder="请输入您的电子邮箱"
                          required
                        />
                      </Form.Group>
                      <Form.Group id="name" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          姓名
                        </Form.Label>
                        <Form.Control
                          style={{
                            backgroundColor: "transparent",
                            borderWidth: "3px",
                            color: "black",
                            height: "50px",
                            borderRadius: "25px",
                          }}
                          type="text"
                          placeholder="请输入您的名字"
                          required
                        />
                      </Form.Group>
                      <Form.Group id="content" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "bold",
                          }}
                        >
                          请输入内容
                        </Form.Label>
                        <Form.Control
                          style={{
                            backgroundColor: "transparent",
                            borderWidth: "3px",
                            color: "black",
                            height: "120px",
                            borderRadius: "25px",
                          }}
                          as="textarea"
                          rows={10}
                          required
                        />
                      </Form.Group>
                      <Button
                        className="w-100 p3"
                        style={{
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: "#111",
                          borderRadius: "25px",
                          borderColor: "#EEE",
                          borderWidth: "3px",
                          fontSize: "15px",
                          fontWeight: "bold",
                          height: "50px",
                          marginTop: "15px",
                        }}
                      >
                        提交
                      </Button>
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="#4"
                    style={{ margin: "15px", padding: "35px" }}
                  >
                    <Card.Title className="text-center">
                      添加YouTube视频
                    </Card.Title>
                    <ListGroup.Item className="d-flex justify-content-between align-items-start">
                      <Card.Text
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          marginTop: "15px",
                        }}
                      >
                        {gStyles.youtube
                          ? "YouTube视频已添加"
                          : "YouTube视频未添加"}
                      </Card.Text>
                      <DropdownButton
                        title={gStyles.youtube ? "已添加" : "未添加"}
                        alignRight
                        id="dropdown-menu-align-right"
                        variant={
                          gStyles.youtube ? "outline-success" : "outline-danger"
                        }
                        style={{ marginTop: "15px" }}
                        onSelect={(e) => {
                          if (e == "start") {
                            gStyles.youtube = true;
                            setGStyles(gStyles);
                            fires
                              .collection("groups")
                              .doc(gData.id)
                              .update({ style: gStyles })
                              .then((error) => {
                                setSuccess("已启动YouTube视频");
                              });
                          } else if (e == "stop") {
                            gStyles.youtube = false;
                            setGStyles(gStyles);
                            fires
                              .collection("groups")
                              .doc(gData.id)
                              .update({ style: gStyles })
                              .then((error) => {
                                setSuccess("已禁止YouTube视频");
                              });
                          }
                        }}
                      >
                        <Dropdown.Item
                          eventKey="start"
                          style={{ color: "green", fontWeight: "bold" }}
                        >
                          添加
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          eventKey="stop"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          移除
                        </Dropdown.Item>
                      </DropdownButton>
                    </ListGroup.Item>
                    <div hidden={!gStyles.youtube}>
                      <Form.Group id="email" style={{ marginTop: "15px" }}>
                        <Form.Label
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          YouTube链接
                        </Form.Label>
                        <Form.Control
                          type="url"
                          placeholder="https://youtube.com/"
                          ref={youtubeRef}
                        />
                        <Button
                          variant="dark"
                          style={{ marginTop: "15px" }}
                          onClick={() => {
                            var pasteText = navigator.clipboard
                              .readText()
                              .then((ee) => {
                                youtubeRef.current.value = ee;
                                const youtubeURL = youtubeRef.current.value;
                                var video_id = youtubeURL.split("v=")[1];
                                var ampersandPosition = video_id.indexOf("&");
                                if (ampersandPosition != -1) {
                                  video_id = video_id.substring(
                                    0,
                                    ampersandPosition
                                  );
                                }
                                gStyles.youtubeId = video_id;
                                setGStyles(gStyles);
                                fires
                                  .collection("groups")
                                  .doc(gData.id)
                                  .update({ style: gStyles })
                                  .then((error) => {
                                    youtubeRef.current.value = "";
                                    setSuccess("视频添加成功 - " + video_id);
                                  });
                              });
                          }}
                        >
                          粘贴
                        </Button>
                        <Button
                          variant="primary"
                          style={{ marginTop: "15px", marginLeft: "20px" }}
                          onClick={() => {
                            const youtubeURL = youtubeRef.current.value;
                            var video_id = youtubeURL.split("v=")[1];
                            var ampersandPosition = video_id.indexOf("&");
                            if (ampersandPosition != -1) {
                              video_id = video_id.substring(
                                0,
                                ampersandPosition
                              );
                            }
                            gStyles.youtubeId = video_id;
                            setGStyles(gStyles);
                            fires
                              .collection("groups")
                              .doc(gData.id)
                              .update({ style: gStyles })
                              .then((error) => {
                                youtubeRef.current.value = "";
                                setSuccess("视频添加成功 - " + video_id);
                              });
                          }}
                        >
                          确定
                        </Button>
                      </Form.Group>
                      <YouTube
                        videoId={gStyles.youtubeId}
                        opts={opts}
                        style={{ marginTop: "50px", borderRadius: "50px" }}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#5" style={{ margin: "15px" }}>
                    <Card.Title
                      className="text-center"
                      style={{
                        marginTop: "20px",
                        marginBottom: "35px",
                        fontWeight: "bold",
                      }}
                    >
                      Select Plan to Continue
                    </Card.Title>

                    <Row md={3}>
                      <Col>
                        <Card>
                          <Card.Body className="align-items-center justify-content-center">
                            <Card.Title
                              className="text-center"
                              style={{
                                marginTop: "15px",
                                fontWeight: "bold",
                                fontSize: "25px",
                              }}
                            >
                              FREE PLAN
                            </Card.Title>
                            <Card.Text
                              className="text-center"
                              style={{
                                marginTop: "5px",
                                fontWeight: "bold",
                                fontSize: "17px",
                              }}
                            >
                              $0 / MONTH
                            </Card.Text>
                            <ListGroup className="border-0">
                              <ListGroup.Item className="border-0">
                                • Handly crafted event pages (events and
                                agendas)
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Unlimited event attendees
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Team workspace with 5 team members
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Membership with 100 members
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Exclusive organizer events and parties
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • FAQ pages support
                              </ListGroup.Item>
                            </ListGroup>
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ marginTop: "15px" }}
                            >
                              <Button
                                className="w-100"
                                variant="dark"
                                style={{ height: "40px", borderRadius: "20px" }}
                                onClick={() => {
                                  window.location.href = "/";
                                }}
                              >
                                Go to dashboard
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <Card.Body className="align-items-center justify-content-center">
                            <Card.Title
                              className="text-center"
                              style={{
                                marginTop: "15px",
                                fontWeight: "bold",
                                fontSize: "25px",
                              }}
                            >
                              ORGANIZER PLUS
                            </Card.Title>
                            <Card.Text
                              className="text-center"
                              style={{
                                marginTop: "5px",
                                fontWeight: "bold",
                                fontSize: "17px",
                              }}
                            >
                              $19.99 / MONTH
                            </Card.Text>
                            <ListGroup className="border-0">
                              <ListGroup.Item className="border-0">
                                • Handly crafted event pages (events and
                                agendas)
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Unlimited event attendees
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Team workspace with 20 team members
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Membership with 500 members
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Exclusive organizer events and parties
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Create lucky draw within event
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Add sponsor information
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Add speaker information
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Email support
                              </ListGroup.Item>
                            </ListGroup>
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ marginTop: "15px" }}
                            >
                              <Button
                                className="w-100"
                                variant="dark"
                                style={{ height: "40px", borderRadius: "20px" }}
                                onClick={() => {
                                  handlePlanSelection("plus", "Organizer Plus");
                                }}
                              >
                                Select this plan
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <Card.Body className="align-items-center justify-content-center">
                            <Card.Title
                              className="text-center"
                              style={{
                                marginTop: "15px",
                                fontWeight: "bold",
                                fontSize: "25px",
                              }}
                            >
                              PROFESSIONAL ORGANIZER
                            </Card.Title>
                            <Card.Text
                              className="text-center"
                              style={{
                                marginTop: "5px",
                                fontWeight: "bold",
                                fontSize: "17px",
                              }}
                            >
                              $59.99 / MONTH
                            </Card.Text>
                            <ListGroup className="border-0">
                              <ListGroup.Item className="border-0">
                                • Handly crafted event pages (events and
                                agendas)
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Unlimited event attendees
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Team workspace with 20 team members
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Membership with 500 members
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Exclusive organizer events and parties
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Create lucky draw within event
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Add sponsor information
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Add speaker information
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Add multiple location/address information
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Customization on organizer page
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Event marketplace access
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • AI support (Planning)
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                • Email/Phone support
                              </ListGroup.Item>
                            </ListGroup>
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ marginTop: "15px" }}
                            >
                              <Button
                                className="w-100"
                                variant="dark"
                                style={{ height: "40px", borderRadius: "20px" }}
                                onClick={() => {
                                  handlePlanSelection(
                                    "professional",
                                    "Professional Organizer"
                                  );
                                }}
                              >
                                Select this plan
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <ListGroup.Item
                      className="d-flex justify-content-between align-items-start"
                      style={{ marginTop: "50px" }}
                    >
                      <Card.Link
                        className="text-center"
                        style={{
                          fontSize: "17px",
                          fontWeight: "bold",
                          color: "#000000",
                          marginBottom: "20px",
                        }}
                        href={"https://rileyevents.com/organizer/" + gData.id}
                      >
                        {"https://rileyevents.com/organizer/" + gData.id}
                      </Card.Link>
                      <QRCodeSVG
                        alignRight
                        id="qrcode-svg"
                        value={"https://rileyevents.com/organizer/" + gData.id}
                        size={128}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        imageSettings={{
                          src: gData.profileURL,
                          height: 24,
                          width: 24,
                          excavate: true,
                        }}
                      />
                    </ListGroup.Item>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
