import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  Spinner,
  ListGroup,
  Badge,
  Accordion,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  Image,
  Nav,
  NavDropdown,
  Carousel,
  Navbar,
  Modal,
  FormGroup,
} from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { auth, fires } from "../../firebase";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import firebase from "firebase";
import "firebase/firestore";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { QRCodeCanvas } from "qrcode.react";
import Iframe from "react-iframe";
import { LuckyWheel, LuckyGrid } from "@lucky-canvas/react";

export default function EventLuckyDrawDashboard() {
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [viewTypes, setViewTypes] = useState([]);
  const [views, setViews] = useState([]);
  const [todayViews, setTodayViews] = useState([]);

  const [eventData, setEventData] = useState({});
  const [eventMembers, setEventMembers] = useState([]);

  //draw and winner
  const [eventDrawMembers, setEventDrawMembers] = useState([]);
  const [eventDrawAvailableMembers, setEventDrawAvailableMembers] = useState(
    []
  );
  const [eventWinnerMembers, setEventWinnderMembers] = useState([]);

  const [eventDrawPlaceholders, setEventDrawPlaceholders] = useState([]);

  const [eventLuckyDrawMember, setEventLuckyDrawMember] = useState({
    name: "",
    ticketId: "",
    email: "",
    emailId: "",
  });
  const [drawItems, setDrawItems] = useState({});
  const [currentDrawItem, setCurrentDrawItem] = useState({
    name: "",
    description: "",
    url: "",
    sponsorImageURL: "",
    imageURL: "",
    sponsor: "",
    order: 0,
    total: 0,
    remaining: 0,
    id: "",
  });

  const [tempPrizes, setTempPrizes] = useState([]);
  const [tempIdString, setTempIdString] = useState("");
  const [drawNum, setDrawNum] = useState(10);

  //playing type
  const [eventPlayType, setEventPlayType] = useState(0);

  const fullScreenHandle = useFullScreenHandle();
  const [enteredFullScreen, setEnteredFullScreen] = useState(false);

  const myLucky = useRef();
  const [blocks] = useState([{ padding: "10px", background: "#869cfa" }]);
  const [prizes, setPrizes] = useState([
    { background: "#e9e8fe", fonts: [{ text: "0" }] },
    { background: "#b8c5f2", fonts: [{ text: "1" }] },
    { background: "#e9e8fe", fonts: [{ text: "2" }] },
    { background: "#b8c5f2", fonts: [{ text: "3" }] },
    { background: "#e9e8fe", fonts: [{ text: "4" }] },
    { background: "#b8c5f2", fonts: [{ text: "5" }] },
  ]);
  const [buttons] = useState([
    { radius: "40%", background: "#617df2" },
    { radius: "35%", background: "#afc8ff" },
    {
      radius: "30%",
      background: "#869cfa",
      pointer: true,
      fonts: [{ text: "开始", top: "-10px" }],
    },
  ]);

  function drawShuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  //draw
  const [showDrawModal, setShowDrawModal] = useState(false);
  const handleDrawCloseModal = () => setShowDrawModal(false);
  const handleDrawShowModal = () => setShowDrawModal(true);

  const [showWinnerModal, setShowWinnerModal] = useState(false);
  const handleWinnerCloseModal = () => setShowWinnerModal(false);
  const handleWinnerShowModal = () => setShowWinnerModal(true);

  //presenting
  const presentingLinkRef = useRef();

  //poll
  const [poll, setPoll] = useState({
    title: "",
    description: "",
    type: "options",
    options: 0,
  });

  //options

  const [eventOptions, setEventOptions] = useState({
    poll: false,
    draw: false,
    namecard: false,
    review: false,
    comment: true,
    picture: true,
    "register-feedback": false,
    max: 500,
  });

  const maxW = isMobile ? "100%" : "100%";

  var uid = currentUser.uid;
  var cEmail = currentUser.email;
  var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

  let { id, drawId } = useParams();

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          var orgId = userData.orgId;
          fires
            .collection("events")
            .doc(id)
            .onSnapshot((snapshotA) => {
              if (snapshotA.exists) {
                var aData = {};
                var artiData = snapshotA.data();
                aData = artiData;
                if (orgId == artiData.orgId) {
                  setEventData(aData);

                  if (aData.drawItems != null) {
                    const allDraws = aData.drawItems;

                    setDrawItems(allDraws);
                    if (drawId != null) {
                      if (drawId != "") {
                        let cDraw = allDraws[drawId];

                        setCurrentDrawItem(allDraws[drawId]);
                      }
                    }
                  }
                  if (aData.options != null) {
                    setEventOptions(aData.options);
                  }
                } else {
                  routeToLogout();
                }
              } else {
                routeToLogout();
              }
            });
          fires
            .collection("events")
            .doc(id)
            .get()
            .then((snapshotB) => {
              if (snapshotB.exists) {
                var aData = {};
                var artiData = snapshotB.data();
                aData = artiData;
                setEventData(aData);
                if (orgId == artiData.orgId) {
                  //eventData

                  fires
                    .collection("events")
                    .doc(id)
                    .collection("draw-members")
                    .onSnapshot((snapshotA) => {
                      var drawMembers = [];
                      var drawWinnerMembers = [];
                      var drawAvailableMembers = [];
                      snapshotA.docs.forEach((doc) => {
                        const memberId = doc.id;
                        const memberData = doc.data();
                        drawMembers.push({ ...memberData, id: memberId });

                        if (memberData.drawWinnerId != null) {
                          if (memberData.drawWinnerId != "") {
                            drawWinnerMembers.push({
                              ...memberData,
                              id: memberId,
                            });
                          }
                        } else {
                          drawAvailableMembers.push({
                            ...memberData,
                            id: memberId,
                          });
                        }
                      });
                      setEventDrawMembers(drawMembers);
                      setEventWinnderMembers(drawWinnerMembers);
                      setEventDrawAvailableMembers(drawAvailableMembers);

                      var priz = [];
                      var prizC = 0;
                      drawAvailableMembers.map((memItm) => {
                        var cBackground = "#e9e8fe";
                        prizC += 1;
                        if (prizC % 2 == 0) {
                          cBackground = "#b8c5f2";
                        }
                        var textName = "";
                        if (drawAvailableMembers.length <= 50) {
                          textName = memItm.name;
                        }
                        var emailId = "";
                        if (memItm.emailId != null) {
                          emailId = memItm.emailId;
                        } else {
                          emailId = memItm.email;
                        }
                        let drawItm = {
                          background: cBackground,
                          fonts: [
                            {
                              ticketId: memItm.ticket,
                              id: memItm.id,
                              emailId: emailId,
                              text: textName,
                              email: memItm.email,
                              name: memItm.name,
                            },
                          ],
                        };
                        priz.push(drawItm);
                      });
                      setPrizes(drawShuffle(priz));
                    });
                } else {
                  //quit
                  //deleted
                  navigate(-1);
                }
              }
            });
          return;
        }
        //deleted
        posts.push({
          id: uid,
          error: "NotFound",
          error_message: "没有访问权限",
          error_code: 404,
        });
        setPosts(posts);
        document.title = "乐源 LETJOY";
        document.description = "开启你的海外生活";
        routeToLogout();
      });
  }, []);

  function sendDrawWinnerEmailTo(email, ticketId, name, eventName, prizeName) {
    const emailTitle = "Congratulations, you won the prize in " + eventName;

    var eventOrg = "Moose Knuckles";

    const htmlSubject =
      "<h2>Congratulations, you won the prize in <b>" + eventName + "</b></h2>";
    const body01 = "<p>Hello " + name + ",</p><br>";
    const body02 =
      "<p>Receiving this email indicated you have just won the prize in <b>" +
      eventName +
      "</b>, Please redeem the prize by showing this email, or telling us your ticket number: " +
      ticketId +
      "</p>";
    const body03 = "<p>The followings are detailed information</p>";
    const body04 = "<p>Name of prize: <b>" + prizeName + "</b></p>";
    const body05 = "<p>Email of winner: <b>" + email + "</b></p>";
    const body06 = "<p>Ticket of winner: <b>" + ticketId + "</b></p>";
    const body07 =
      "<h4><b>Please click <a href='https://events.letjoy.app/" +
      eventData.id +
      "/en'>this link</a> for details</h4><br>";
    const body09 = "<br><h3><b>" + eventOrg + "</b></h3>";
    const htmlString =
      htmlSubject +
      body01 +
      body02 +
      body03 +
      body04 +
      body05 +
      body06 +
      body07 +
      body09;
    const messageObj = {
      subject: emailTitle,
      html: htmlString,
    };
    fires
      .collection("mail")
      .add({
        to: [email],
        message: messageObj,
      })
      .then((docRef) => {
        const sentId = docRef.id;
      });
  }

  return (
    <FullScreen handle={fullScreenHandle}>
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
        <Container fluid>
          <Navbar.Brand href="/">乐源抽奖系统</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <NavDropdown title="每次抽奖数量" id="basic-nav-dropdown">
                <NavDropdown.Item
                  onClick={() => {
                    setDrawNum(1);
                  }}
                >
                  1 (默认) {drawNum == 1 ? "✓" : ""}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    setDrawNum(10);
                  }}
                >
                  10 {drawNum == 10 ? "✓" : ""}
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="奖品选择" id="basic-nav-dropdown">
                {Object.keys(drawItems).map((kItm) => {
                  return (
                    <NavDropdown.Item
                      href={
                        window.origin +
                        "/live-lucky-draw-dashboard/" +
                        id +
                        "/" +
                        kItm
                      }
                    >
                      {drawItems[kItm].name +
                        "(剩余" +
                        drawItems[kItm].remaining +
                        "个)"}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
              <NavDropdown title="抽奖设置" id="basic-nav-dropdown">
                <NavDropdown.Item
                  onClick={() => {
                    let resetConfirm =
                      window.confirm("您想要重置所有的抽奖信息吗?");
                    if (resetConfirm) {
                      eventWinnerMembers.map((memberItm) => {
                        let memberEmail = memberItm.email;
                        if (memberEmail != null && memberEmail != "") {
                          fires
                            .collection("events")
                            .doc(id)
                            .collection("draw-members")
                            .doc(memberEmail)
                            .update({ drawWinnerId: null });
                        }
                      });
                      var drawwItems = drawItems;
                      Object.keys(drawwItems).map((dKey) => {
                        var cDrawItem = drawwItems[dKey];
                        cDrawItem.remaining = cDrawItem.total;
                        drawwItems[dKey] = cDrawItem;
                      });
                      fires
                        .collection("events")
                        .doc(id)
                        .update({ drawItems: drawwItems })
                        .then(() => {});
                    }
                  }}
                >
                  重置抽奖
                </NavDropdown.Item>
                <NavDropdown.Item>抽奖配置</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <div>
            <Button
              variant="dark"
              onClick={() => {
                if (enteredFullScreen) {
                  setEnteredFullScreen(false);
                  fullScreenHandle.exit();
                } else {
                  setEnteredFullScreen(true);
                  fullScreenHandle.enter();
                }
              }}
            >
              {enteredFullScreen ? "取消全屏" : "全屏"}
            </Button>
          </div>
        </Container>
      </Navbar>
      <Container
        className="d-flex justify-content-center"
        style={{
          height: "calc(100% - 60px)",
          maxWidth: "100vw",
          paddingBottom: "60px",
        }}
      >
        <div className="w-100" style={{}}>
          <Modal show={showWinnerModal} onHide={handleWinnerCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                中奖啦{" "}
                {currentDrawItem.name +
                  "(剩余" +
                  currentDrawItem.remaining +
                  ")"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card.Title className="text-center">以下是中奖号码</Card.Title>
              {tempPrizes.map((prizeItm) => {
                return <Card.Text>{prizeItm}</Card.Text>;
              })}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  tempPrizes.map((prizeId) => {
                    if (prizeId !== null) {
                      if (prizeId != "") {
                        fires
                          .collection("events")
                          .doc(id)
                          .collection("draw-members")
                          .doc(prizeId)
                          .update({ drawWinnerId: drawId })
                          .then(() => {});
                      }
                    }
                  });
                  var drawwItems = drawItems;
                  var cDrawItem = currentDrawItem;
                  if (cDrawItem.remaining > 0) {
                    cDrawItem.remaining =
                      cDrawItem.remaining - tempPrizes.length;
                    drawwItems[drawId] = cDrawItem;
                    fires
                      .collection("events")
                      .doc(id)
                      .update({ drawItems: drawwItems })
                      .then(() => {
                        setTempPrizes([]);
                        setTempIdString("");
                        handleWinnerCloseModal();
                      });
                  }
                }}
              >
                开奖
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setEventLuckyDrawMember({
                    name: "",
                    ticketId: "",
                    email: "",
                    emailId: "",
                  });
                  setTempPrizes([]);
                  setTempIdString("");
                  handleWinnerCloseModal();
                }}
              >
                关闭
              </Button>
            </Modal.Footer>
          </Modal>

          <Card className="border-0">
            <Card.Body className="align-items-center justify-content-center">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "10vh" }}
              >
                <Card.Text
                  className="text-center"
                  style={{
                    fontSize: "45px",
                    fontWeight: "bold",
                    color: "#000000",
                    marginBottom: "45px",
                  }}
                >
                  {eventData.title}
                </Card.Text>
              </div>
              <Row style={{ height: "80vh" }}>
                <Col sm={5} style={{}}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ marginTop: "15px" }}
                      >
                        <LuckyWheel
                          ref={myLucky}
                          height="450px"
                          width="450px"
                          blocks={blocks}
                          prizes={prizes}
                          buttons={buttons}
                          onStart={() => {
                            // 点击抽奖按钮会触发star回调
                            if (currentDrawItem.id != "") {
                              if (currentDrawItem.remaining > 0) {
                                myLucky.current.play();
                                setTimeout(() => {
                                  const index = (Math.random() * 6) >> 0;
                                  myLucky.current.stop(index);
                                }, 1500);
                              }
                            }
                          }}
                          onEnd={(prize) => {
                            const dEmail = prize.fonts[0].email;
                            const dTicketId = prize.fonts[0].ticketId;
                            const dName = prize.fonts[0].name;
                            const dEmailId = prize.fonts[0].emailId;
                            const currentDDID = drawId;
                            const currentPrizeName = currentDrawItem.name;

                            var tempPrizes = prizes;
                            var tempWinners = [];
                            var idStrings = "";

                            if (drawNum == 1) {
                              //default
                              tempPrizes.push(dEmail);
                              idStrings += dEmail + "\n";
                            } else {
                              //10 or 20
                              if (prizes.length >= drawNum) {
                                let newSort = tempPrizes
                                  .sort(() => Math.random() - Math.random())
                                  .slice(0, drawNum);
                                newSort.map((prizeItm) => {
                                  let emailR = prizeItm.fonts[0].email;
                                  tempWinners.push(emailR);
                                  idStrings += emailR + "\n";
                                });
                              } else {
                                let newSort = tempPrizes
                                  .sort(() => Math.random() - Math.random())
                                  .slice(0, drawNum);
                                newSort.map((prizeItm) => {
                                  let emailR = prizeItm.fonts[0].email;
                                  tempWinners.push(emailR);
                                  idStrings += emailR + "\n";
                                });
                              }
                            }

                            setTempPrizes(tempWinners);
                            setTempIdString(idStrings);
                            //setShowWinnerModal(true)

                            // let confirmPrize = window.confirm('请您确定中奖的人:\n'+idStrings)
                            // if (confirmPrize){
                            //     if (drawId != ""){
                            //       tempWinners.forEach((winnerId) => {
                            //         fires
                            //         .collection('events')
                            //         .doc(id)
                            //         .collection('draw-members')
                            //         .doc(winnerId)
                            //         .update({drawWinnerId:drawId}).then(()=>{

                            //         })
                            //       })

                            // }

                            // }
                            // else{

                            // }
                          }}
                        />
                      </div>
                      <div className="align-items-start">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ marginTop: "5px" }}
                        >
                          <Card.Text
                            className="text-center"
                            style={{
                              fontSize: "15px",
                              fontWeight: "normal",
                              color: "#000000",
                              marginTop: "15px",
                            }}
                          >
                            剩余{currentDrawItem.remaining}个, 每次抽{drawNum}
                            个, {eventDrawAvailableMembers.length}个人可抽奖
                          </Card.Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col sm={2} style={{ overflowY: "scroll" }}>
                  <div>
                    <Card.Title
                      className="text-center"
                      style={{ fontWeight: "bold", fontSize: "22px" }}
                      hidden={tempPrizes.length == 0}
                    >
                      以下是中奖号码
                    </Card.Title>
                    <ListGroup style={{ marginTop: "10px" }}>
                      {tempPrizes.map((prizeItm) => {
                        return (
                          <ListGroup.Item>
                            <Card.Text style={{ fontWeight: "bold" }}>
                              {prizeItm}
                            </Card.Text>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </div>
                  <Button
                    variant="primary"
                    style={{ width: "100%", marginTop: "15px" }}
                    hidden={tempPrizes.length == 0}
                    onClick={() => {
                      tempPrizes.map((prizeId) => {
                        if (prizeId !== null) {
                          if (prizeId != "") {
                            fires
                              .collection("events")
                              .doc(id)
                              .collection("draw-members")
                              .doc(prizeId)
                              .update({ drawWinnerId: drawId })
                              .then(() => {});
                          }
                        }
                      });
                      var drawwItems = drawItems;
                      var cDrawItem = currentDrawItem;
                      if (cDrawItem.remaining > 0) {
                        cDrawItem.remaining =
                          cDrawItem.remaining - tempPrizes.length;
                        drawwItems[drawId] = cDrawItem;
                        fires
                          .collection("events")
                          .doc(id)
                          .update({ drawItems: drawwItems })
                          .then(() => {
                            setTempPrizes([]);
                            setTempIdString("");
                            handleWinnerCloseModal();
                          });
                      }
                    }}
                  >
                    开奖
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ width: "100%", marginTop: "15px" }}
                    hidden={tempPrizes.length == 0}
                    onClick={() => {
                      setEventLuckyDrawMember({
                        name: "",
                        ticketId: "",
                        email: "",
                        emailId: "",
                      });
                      setTempPrizes([]);
                      setTempIdString("");
                    }}
                  >
                    取消
                  </Button>

                  {/* <Accordion id="all-control" defaultActiveKey="A" style={{marginTop: '15px'}}>
      <Accordion.Item eventKey="A">
              <Accordion.Header className="d-flex justify-content-between align-items-start" style={{backgroundColor: '#EEE'}}>
                  <div className="fw-bold">{'奖品'}</div>
                  <div className="fw-bold">({Object.entries(drawItems).length})</div>
              </Accordion.Header>
              <Accordion.Body>

              <ListGroup>
      {
                  Object.keys(drawItems).map((kItm)=>{
                      let entryName = drawItems[kItm].name
                      return <ListGroup.Item>
                      <div>
                      <Card.Text style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '5px', color: 'black'}}>{entryName}</Card.Text>
                      <Card.Text style={{fontSize: '15px', fontWeight: 'normal', marginBottom: '0px', color: 'black'}}>{kItm}</Card.Text>
                      </div>
                  </ListGroup.Item>
                  })
          }
            
      </ListGroup>

              </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="B">
              <Accordion.Header className="d-flex justify-content-between align-items-start" style={{backgroundColor: '#EEE'}}>
                  <div className="fw-bold">{'参与人数'}</div>
                  <div className="fw-bold">({eventDrawMembers.length})</div>
              </Accordion.Header>
              <Accordion.Body>

              <ListGroup>
      {
                  eventDrawMembers.map((memberItm)=>{
                      return <ListGroup.Item>
                      <div>
                      <Card.Text style={{fontSize: '15px', fontWeight: 'normal', marginBottom: '5px', color: 'black'}}>{memberItm.email}</Card.Text>
                      <Card.Text style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '0px', color: 'black'}}>{memberItm.drawWinnerId == null || memberItm.drawWinnerId == '' ? '' : memberItm.drawWinnerId == 'no-show' ? 'No-Show' : '中奖了 - ' + drawItems[memberItm.drawWinnerId].name}</Card.Text>
                      </div>
                  </ListGroup.Item>
                  })
          }
            
      </ListGroup>

              </Accordion.Body>
          </Accordion.Item>

            
      </Accordion> */}
                </Col>

                <Col sm={5} style={{ overflowY: "scroll" }}>
                  <Card.Title
                    className="text-center"
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#000000",
                      marginBottom: "5px",
                    }}
                  >
                    {currentDrawItem.name}
                  </Card.Title>
                  <div>
                    <Card.Text
                      className="text-center"
                      style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        color: "#000000",
                        marginTop: "15px",
                      }}
                    >
                      赞助商{currentDrawItem.sponsor}
                    </Card.Text>
                    <div className="d-flex align-items-center justify-content-center">
                      <Image
                        src={currentDrawItem.imageURL}
                        style={{ maxHeight: "350px" }}
                      />
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <Image
                        src={currentDrawItem.sponsorImageURL}
                        style={{
                          maxHeight: "100px",
                          maxWidth: "100%",
                          marginTop: "10px",
                          marginBottom: "5px",
                        }}
                      />
                    </div>
                    <Card.Title
                      className="text-center"
                      style={{
                        fontSize: "17px",
                        fontWeight: "normal",
                        color: "#000000",
                        marginTop: "20px",
                      }}
                    >
                      感谢赞助商<b>{currentDrawItem.sponsor}</b>提供的
                      {currentDrawItem.total}份精美礼物
                    </Card.Title>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </Container>
      <Navbar
        bg="transparent"
        variant="light"
        expand="lg"
        sticky="bottom"
        className="d-flex align-items-center justify-content-center"
        style={{ height: "250px", backgroundColor: "transparent" }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ margin: "15px" }}
        >
          <Image
            src={
              "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-wanghong-host.jpg"
            }
            width="650px"
            height="180px"
            style={{ marginRight: "25px" }}
          />
          <Image
            src={
              "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-wanghong-sponsor.jpg"
            }
            width="650px"
            height="180px"
            style={{ marginLeft: "25px" }}
          />
        </div>
      </Navbar>
    </FullScreen>
  );
}
