import React, { useState, useEffect } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { auth, fires } from "../firebase";
import { useParams } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { Helmet } from "react-helmet";

import firebase from "firebase/app";
import "firebase/firestore";

export default function OrgnizationProfile() {
  const [error, setError] = useState("");
  const [index, setIndex] = useState(0);
  const { currentUser, logout } = useAuth();

  const [posts, setPosts] = useState([]);
  const [orgPosts, setOrgPosts] = useState([]);
  const [orgExps, setOrgExps] = useState([]);

  let { id } = useParams();
  const maxW = isMobile ? "100%" : "60%";

  //pager component
  let pagerMethods = null;
  const next_page_handle = () => {
    if (pagerMethods !== null) pagerMethods.next();
  };
  const previous_page_handle = () => {
    if (pagerMethods !== null) pagerMethods.previous();
  };
  const set_page_handle = (pageIndex) => {
    if (pagerMethods !== null) pagerMethods.setPage(pageIndex);
  };

  const routeRedirect404 = () => {
    let path = "/404";
    window.location.href = path;
  };

  function onClickLink(contactL) {
    var ua = window.navigator.userAgent.toLowerCase();

    var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    const platformName = "web";
    var deviceName = "ios";
    if (isAndroid) {
      deviceName = "android";
    } else if (isIOS) {
      deviceName = "ios";
    } else {
      deviceName = "web";
    }
    var fuid = "";
    if (auth.currentUser != null) {
      fuid = auth.currentUser.uid;
    }
    const viewData = {
      fromUID: fuid,
      type: "link",
      linkId: contactL.url,
      fromUA: ua,
      platform: platformName,
      device: deviceName,
      timestamp: timestampNow,
    };
    fires.collection("groups").doc(id).collection("views").add(viewData);
    window.location.href = contactL.url;
    //window.open(contactL.url)
  }

  useEffect(() => {
    return fires
      .collection("groups")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const posts = [];
          const postData = snapshot.data();
          const uid = postData.fromUID;
          if (postData.live != null) {
            if (postData.live) {
              // live user
              // add views
              var ua = window.navigator.userAgent.toLowerCase();
              var timestampNow = firebase.firestore.Timestamp.fromDate(
                new Date()
              );
              const platformName = "web";
              var deviceName = "ios";
              if (isAndroid) {
                deviceName = "android";
              } else if (isIOS) {
                deviceName = "ios";
              } else {
                deviceName = "web";
              }
              var fuid = "";
              if (auth.currentUser != null) {
                fuid = auth.currentUser.uid;
              }
              const viewData = {
                fromUID: fuid,
                type: "page",
                fromUA: ua,
                platform: platformName,
                device: deviceName,
                timestamp: timestampNow,
              };
              fires
                .collection("groups")
                .doc(id)
                .collection("views")
                .add(viewData);

              if (postData.length == 0) {
                posts.push({
                  id: id,
                  error: "NoAccess",
                  error_message: "没有访问权限",
                  error_code: 404,
                });
                setPosts(posts);
              } else {
                posts.push({ ...postData, id: id });
                setPosts(posts);

                fires
                  .collection("posts")
                  .where("orgId", "==", id)
                  .orderBy("timestamp", "desc")
                  .get()
                  .then((snapshotA) => {
                    var orgPosts = [];
                    snapshotA.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgPosts.push({ ...contentData, cid: contentId });
                    });
                    setOrgPosts(orgPosts);
                  });
                fires
                  .collection("exps")
                  .where("orgId", "==", id)
                  .orderBy("timestamp", "desc")
                  .get()
                  .then((snapshotA) => {
                    var orgExps = [];
                    snapshotA.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgExps.push({ ...contentData, cid: contentId });
                    });
                    setOrgExps(orgExps);
                  });
              }
            } else {
              //not live
              routeRedirect404();
              // posts.push({ id: id, error: "NotLive", error_message: "没有访问权限", error_code: 404 })
              // setPosts(posts)
            }
          } else {
            //not found
            //deleted
            routeRedirect404();
            // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
            // setPosts(posts)
          }
        } else {
          //deleted
          routeRedirect404();
          // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
          // setPosts(posts)
        }
      });
  }, []);

  return (
    <div>
      {posts.map((post) => {
        var error = post.error;
        var coverImgURL = "";
        var profileURL = "";
        var professionalType = "";
        var orgContacts = [];

        var expCount = 0;
        var postCount = 0;
        expCount = orgExps.length;
        postCount = orgPosts.length;
        var locationStr = "";
        var countryStr = "";

        var expItems = [];
        var postItems = [];

        if (post.coverURL == null) {
          coverImgURL =
            "url(https://images.unsplash.com/photo-1487088678257-3a541e6e3922?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80)";
        } else {
          coverImgURL = "url(" + post.coverURL + ")";
        }
        profileURL = post.profileURL;
        professionalType = post.typeCN;

        if (profileURL != "") {
          let link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
          }
          link.href = profileURL;
        }

        if (post.contacts != null) {
          orgContacts = post.contacts;
        }

        if (post.location != null) {
          locationStr = post.location;
        }
        if (post.country != null) {
          countryStr = post.country;
        }

        if (error == null) {
          if (isMobile) {
            return (
              <Container
                className="d-flex justify-content-center"
                style={{ minHeight: "100vh", backgroundImage: coverImgURL }}
              >
                <Helmet>
                  <title>{post.name}</title>
                  <meta name="description" content={post.description} />
                  <link
                    rel="icon"
                    type="image/png"
                    href={profileURL}
                    sizes="16x16"
                  />
                </Helmet>

                <Card
                  className="border-0 align-items-center justify-content-center"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "15px!important",
                    backgroundColor: "transparent",
                  }}
                >
                  <Card.Img
                    className="rounded-circle shadow-4-strong"
                    variant="top"
                    src={post.profileURL}
                    style={{
                      height: "100px",
                      width: "100px",
                      marginTop: "25px",
                      borderColor: "#ffffff",
                    }}
                  />
                  <Card.Body className="align-items-center justify-content-center">
                    <Card.Title
                      className="text-center"
                      style={{
                        fontSize: "40px",
                        fontWeight: "bold",
                        color: "#ffffff",
                      }}
                    >
                      {post.name}
                    </Card.Title>
                    <div className="d-flex justify-content-center">
                      <Card.Text
                        className="text-center"
                        style={{
                          marginTop: "15px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#000000",
                          padding: "8px",
                          backgroundColor: "#ffffff",
                          borderRadius: "12px",
                        }}
                      >
                        {professionalType}
                      </Card.Text>
                    </div>
                    <Card.Text
                      style={{
                        marginTop: "15px",
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#000000",
                        backgroundColor: "#ffffff",
                        padding: "8px",
                        borderRadius: "12px",
                        textAlign: "start",
                      }}
                    >
                      {post.description}
                    </Card.Text>
                    {orgContacts.map((contact) => {
                      return (
                        <Button
                          className="w-100 mt3"
                          style={{
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: "transparent",
                            borderRadius: "25px",
                            borderColor: "#ffffff",
                            borderWidth: "3px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            height: "50px",
                            marginTop: "15px",
                          }}
                          onClick={() => onClickLink(contact)}
                        >
                          {contact.name}
                        </Button>
                      );
                    })}
                    {/* <div className="d-flex justify-content-center">
                <Card.Text className="text-center" style={{ marginTop: "25px", fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"10px", backgroundColor: "#ffffff", borderRadius: "12px"}}>{post.name}的{post.postTitle}</Card.Text>
                </div>
                <Carousel>
                    {
                        orgPosts.map((orgPost) => {
                            return <Carousel.Item><Card className = "border-0 justify-content-center" onClick={()=> window.open("https://letjoy.app/post/"+orgPost.cid) } style={{ marginTop: "25px", backgroundColor:"#ffffff", borderRadius: "15px"}}>
                    <Card.Img variant="left" src={orgPost.imageURL} style={{height: '40vh', width: '100%', backgroundPosition: 'center', borderColor: '#ffffff', borderTopRightRadius: "15px", borderTopLeftRadius: "15px"}}/>
                    <Card.Title style={{margin: "15px", maxHeight: "60px", overflow: 'hidden'}}>{orgPost.postContent}</Card.Title>
                    </Card></Carousel.Item>
                        })
                    }
                </Carousel> */}
                    {/* {
                    orgPosts.map((orgPost) => {
                        return <Card className = "border-0 justify-content-center" onClick={()=> window.open("https://letjoy.app/post/"+orgPost.cid) } style={{ marginTop: "25px", backgroundColor:"#ffffff", borderRadius: "15px"}}>
                <Card.Img variant="left" src={orgPost.imageURL} style={{height: '40vh', width: '100%', backgroundPosition: 'center', borderColor: '#ffffff', borderTopRightRadius: "15px", borderTopLeftRadius: "15px"}}/>
                <Card.Title style={{margin: "15px", maxHeight: "60px", overflow: 'hidden'}}>{orgPost.postContent}</Card.Title>
                </Card>
                    })
                } */}

                    {/* <Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'transparent', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> window.open("https://leyuan.page.link/u/"+id) }>查看全部{post.postTitle}</Button>

                <div className="d-flex justify-content-center">
                <Card.Text className="text-center" style={{ marginTop: "25px", fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"10px", backgroundColor: "#ffffff", borderRadius: "12px"}}>{post.name}的{post.expTitle}</Card.Text>
                </div>

                {
                    orgExps.map((orgExp) => {
                        return <Card className = "border-0 justify-content-center" onClick={()=> window.open("https://letjoy.app/exp/"+orgExp.cid) } style={{ marginTop: "25px", backgroundColor:"#ffffff", borderRadius: "15px"}}>
                <Card.Img variant="left" src={orgExp.imageURL} style={{height: '40vh', width: '100%', backgroundPosition: 'center', borderColor: '#ffffff', borderTopRightRadius: "15px", borderTopLeftRadius: "15px"}}/>
                <Card.Title style={{margin: "15px"}}>{orgExp.title}</Card.Title>
                <Card.Text style={{margin: "15px", fontSize: '12px'}}>{orgExp.description}</Card.Text>
                </Card>
                    })
                }



                <Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'transparent', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> window.open("https://letjoy.app/exp/test") }>查看全部{post.expTitle}</Button> */}
                  </Card.Body>
                </Card>
              </Container>
            );
          } else {
            return (
              <div style={{ backgroundImage: coverImgURL }}>
                <Helmet>
                  <title>{post.name}</title>
                  <meta name="description" content={post.description} />
                  <link
                    rel="icon"
                    type="image"
                    href={profileURL}
                    sizes="16x16"
                  />
                </Helmet>

                <Container
                  className="d-flex justify-content-center"
                  style={{
                    backgroundImage: coverImgURL,
                    minHeight: "100vh",
                    minWidth: "100vh",
                  }}
                >
                  <div
                    className="w-100"
                    style={{
                      maxWidth: maxW,
                      borderRadius: "35px!important",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Card
                      className="border-0 align-items-center justify-content-center"
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "15px!important",
                        backgroundColor: "transparent",
                      }}
                    >
                      <Card.Img
                        className="rounded-circle shadow-4-strong"
                        variant="top"
                        src={post.profileURL}
                        style={{
                          height: "100px",
                          width: "100px",
                          marginTop: "25px",
                          borderColor: "#ffffff",
                        }}
                      />
                      <Card.Body className="align-items-center justify-content-center">
                        <Card.Title
                          className="text-center"
                          style={{
                            fontSize: "40px",
                            fontWeight: "bold",
                            color: "#ffffff",
                          }}
                        >
                          {post.name}
                        </Card.Title>

                        <div className="d-flex justify-content-center">
                          <Card.Text
                            className="text-center"
                            style={{
                              marginTop: "15px",
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#000000",
                              padding: "8px",
                              backgroundColor: "#ffffff",
                              borderRadius: "12px",
                            }}
                          >
                            {professionalType}
                          </Card.Text>
                        </div>

                        {/* <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3 border-0 color-white"
    >
      <Tab eventKey="home" title="介绍" style={{borderRadius: "25px", marginTop: "15px"}} color="white">
      <Card.Text style={{ marginTop: "15px", fontSize: "15px", fontWeight: "bold", color: "#000000", backgroundColor: "#ffffff", padding:"8px", borderRadius: "12px", textAlign:'start'}} >{post.description}</Card.Text>
                {
                    orgContacts.map((contact) => {
                        return <Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'transparent', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "15px"}} onClick={()=> onClickLink(contact) }>{contact.name}</Button>
                    })
            }
      </Tab>
      <Tab eventKey="profile" title="文章" style={{borderRadius: "25px", color: 'white'}}>
        
      </Tab>
      <Tab eventKey="contact" title="动态">
        
      </Tab>
    </Tabs> */}
                        <Card.Text
                          style={{
                            marginTop: "15px",
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            padding: "8px",
                            borderRadius: "12px",
                            textAlign: "start",
                          }}
                        >
                          {post.description}
                        </Card.Text>
                        {orgContacts.map((contact) => {
                          return (
                            <Button
                              className="w-100 mt3"
                              style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                                backgroundColor: "transparent",
                                borderRadius: "25px",
                                borderColor: "#ffffff",
                                borderWidth: "3px",
                                fontSize: "20px",
                                fontWeight: "bold",
                                height: "50px",
                                marginTop: "15px",
                              }}
                              onClick={() => onClickLink(contact)}
                            >
                              {contact.name}
                            </Button>
                          );
                        })}

                        {/* <div className="d-flex justify-content-center">
                <Card.Text className="text-center" style={{ marginTop: "25px", fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"10px", backgroundColor: "#ffffff", borderRadius: "12px"}}>{post.name}的{post.postTitle}</Card.Text>
                </div>
                <Row md={2}>
                {
                    orgPosts.map((orgPost) => {
                        return <Col><Card className = "border-0 justify-content-center" onClick={()=> window.open("https://letjoy.app/post/"+orgPost.cid) } style={{ marginTop: "25px", backgroundColor:"#ffffff", borderRadius: "15px"}}>
                <Card.Img variant="left" src={orgPost.imageURL} style={{height: '40vh', width: '100%', backgroundPosition: 'center', borderColor: '#ffffff', borderTopRightRadius: "15px", borderTopLeftRadius: "15px"}}/>
                <Card.Title style={{margin: "15px", maxHeight: "60px", overflow: 'hidden'}}>{orgPost.postContent}</Card.Title>
                </Card></Col>
                    })
                }
                </Row>

                
                
                <Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'transparent', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "15px"}} onClick={()=> window.open("https://leyuan.page.link/u/"+id) }>查看全部{post.postTitle}</Button>

                <div className="d-flex justify-content-center">
                <Card.Text className="text-center" style={{ marginTop: "25px", fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"10px", backgroundColor: "#ffffff", borderRadius: "12px"}}>{post.name}的{post.expTitle}</Card.Text>
                </div>

                <Row md={2}>
                {
                    orgExps.map((orgExp) => {
                        return <Col><Card className = "border-0 justify-content-center" onClick={()=> window.open("https://letjoy.app/exp/"+orgExp.cid) } style={{ marginTop: "25px", backgroundColor:"#ffffff", borderRadius: "15px"}}>
                <Card.Img variant="left" src={orgExp.imageURL} style={{height: '40vh', width: '100%', backgroundPosition: 'center', borderColor: '#ffffff', borderTopRightRadius: "15px", borderTopLeftRadius: "15px"}}/>
                <Card.Title style={{margin: "15px"}}>{orgExp.title}</Card.Title>
                <Card.Text style={{margin: "15px", fontSize: '12px'}}>{orgExp.description}</Card.Text>
                </Card></Col>
                    })
                }
                </Row>

                


                <Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'transparent', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> window.open("https://letjoy.app/exp/test") }>查看全部{post.expTitle}</Button> */}
                      </Card.Body>
                    </Card>
                  </div>
                </Container>

                {/* <Navbar bg="dark" variant="dark" expand="lg" sticky="bottom">
        <Container fluid>
          <Navbar.Brand>{post.name}</Navbar.Brand>
          <Button variant="outline-success">Contact Me</Button>
          <Button variant="outline-success">下载APP</Button>
        </Container>
      </Navbar> */}
              </div>
            );
          }
        } else {
          if (error == "NotFound") {
            return (
              <div>
                <Helmet>
                  <title>Not Found</title>
                  <meta name="description" content="" />
                </Helmet>
              </div>
            );
          } else if (error == "NoAccess") {
            return (
              <div>
                <Helmet>
                  <title>Permission Denied</title>
                  <meta name="description" content="" />
                </Helmet>
              </div>
            );
          }
        }
      })}
    </div>
  );
}
