import React, { useRef, useState, useEffect } from "react";
import { Card, Container, ListGroup, Accordion } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { fires } from "../firebase";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function GroupQuotes() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [viewTypes, setViewTypes] = useState([]);

  const [quotes, setQuotes] = useState([]);
  const [gData, setGData] = useState([]);
  const [links, setLinks] = useState([]);

  const [linkCounts, setLinkCounts] = useState({});

  const maxW = isMobile ? "100%" : "60%";

  var uid = currentUser.uid;
  var cEmail = currentUser.email;

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  useEffect(() => {
    return fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          var orgId = userData.orgId;
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotB) => {
              if (snapshotB.exists) {
                var gData = [];
                var groupData = snapshotB.data();
                gData.push({ ...groupData, id: orgId });
                setGData(groupData);

                if (groupData.contacts != null) {
                  setLinks(groupData.contacts);
                }

                fires
                  .collection("groups")
                  .doc(orgId)
                  .collection("quotes")
                  .get()
                  .then((snapshotA) => {
                    var allQuotes = [];
                    snapshotA.docs.forEach((doc) => {
                      const quoId = doc.id;
                      const quoData = doc.data();
                      allQuotes.push({ ...quoData, vid: quoId });
                    });

                    setQuotes(allQuotes);
                  });
              }
            });
        } else {
          //deleted
          posts.push({
            id: uid,
            error: "NotFound",
            error_message: "没有访问权限",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "乐源 LETJOY";
          document.description = "开启你的海外生活";
          routeToLogout();
        }
      });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    Promise.all(promises)
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="w-100"
          style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
        >
          <Card>
            <Card.Body className="align-items-center justify-content-center">
              <Card.Title className="text-center">资讯报价</Card.Title>
              <Card.Text
                className="text-center"
                style={{ marginTop: "10px", fontWeight: "bold" }}
              >
                {quotes.length}个报价
              </Card.Text>

              {quotes.map((quoItem) => {
                return (
                  <Accordion id="all-control" style={{ marginTop: "20px" }}>
                    <Accordion.Item eventKey="A">
                      <Accordion.Header>Name: {quoItem.name}</Accordion.Header>
                      <Accordion.Body>
                        <ListGroup style={{ marginTop: "20px" }}>
                          <ListGroup.Item className="d-flex justify-content-between align-items-start">
                            <div className="fw-bold">Name:</div>
                            <div className="fw-bold">{quoItem.name}</div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between align-items-start">
                            <div className="fw-bold">Email Address:</div>
                            <div className="fw-bold">{quoItem.email}</div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between align-items-start">
                            <div className="fw-bold">Phone:</div>
                            <div className="fw-bold">{quoItem.phone}</div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between align-items-start">
                            <div className="fw-bold">Address:</div>
                            <div className="fw-bold">{quoItem.address}</div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between align-items-start">
                            <div className="fw-bold">Size of lots:</div>
                            <div className="fw-bold">{quoItem.lotSize}</div>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between align-items-start">
                            <div className="fw-bold">Size of lawn:</div>
                            <div className="fw-bold">{quoItem.lawnSize}</div>
                          </ListGroup.Item>
                        </ListGroup>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })}
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
