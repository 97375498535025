import React, { useState, useEffect } from "react";
import { Form, Button, Card, Container, ListGroup } from "react-bootstrap";
import { auth, fires } from "../firebase";
import { useParams } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import firebase from "firebase/app";
import "firebase/firestore";
import { Helmet } from "react-helmet";

export default function InteractionDetails() {
  const [posts, setPosts] = useState([]);
  const [qData, setQData] = useState([]);
  const [questions, setQuestions] = useState([]);

  let { id } = useParams();
  const maxW = isMobile ? "100%" : "50%";

  useEffect(() => {
    return fires
      .collection("interactions")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const posts = [];
          const postData = snapshot.data();

          var viewCount = postData.viewCount;

          var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
          const platformName = "web";
          var deviceName = "ios";
          if (isAndroid) {
            deviceName = "android";
          } else if (isIOS) {
            deviceName = "ios";
          } else {
            deviceName = "web";
          }
          const fuid = auth.currentUser != null ? auth.currentUser.uid : "";
          const viewData = {
            fromUID: fuid,
            platform: platformName,
            device: deviceName,
            timestamp: timestampNow,
          };
          viewCount = viewCount + 1;
          fires
            .collection("interactions")
            .doc(id)
            .collection("views")
            .add(viewData);
          fires
            .collection("interactions")
            .doc(id)
            .update({ viewCount: viewCount });

          fires
            .collection("interactions")
            .doc(id)
            .collection("questions")
            .get()
            .then((snapshotA) => {
              const allQuestions = [];
              snapshotA.docs.forEach((doc) => {
                const qId = doc.id;
                const qData = doc.data();
                if (qData.answer != null) {
                  allQuestions.push({ ...qData, id: qId });
                }
              });
              setQuestions(allQuestions);
            });

          if (postData.length === 0) {
            posts.push({
              id,
              error: "NoAccess",
              error_message: "没有访问权限",
              error_code: 404,
            });
            setPosts(posts);
            document.title = "没有访问权限";
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", "请您查看是否具有访问权限");
            document.description = "请您查看是否具有访问权限";
          } else {
            posts.push({ ...postData, id });
            setPosts(posts);
            setQData({ ...postData, id });
            document.title = postData.title;
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", postData.description);

            if (postData.photoURL !== "") {
              let link = document.querySelector("link[rel~='icon']");
              if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);
              }
              link.href = postData.imageURL;
            }
          }
        } else {
          //deleted
          posts.push({
            id: id,
            error: "NotFound",
            error_message: "没有访问权限",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "没有访问权限";
          document.description = "请您查看是否具有访问权限";
        }
      });
  }, []);

  async function handleQuestionFormSubmit(e) {
    e.preventDefault();
    const questionVal = e.target[0].value;
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    try {
      await fires
        .collection("interactions")
        .doc(id)
        .collection("questions")
        .add({ question: questionVal, timestamp: timestampNow });
      e.target[0].value = "";
      if (qData.lang === "en") {
        alert(
          "Submit successful. After I responded, your question will be posted shortly"
        );
      } else {
        alert("提交成功！如果我回答后会显示在主页");
      }
    } catch {
      if (qData.lang === "en") {
        alert("Submit failed. Please try again");
      } else {
        alert("提交失败！请重试");
      }
    }
  }

  return (
    <div>
      {posts.map((post) => {
        const error = post.error;

        return error === "NotFound" || error === "NoAccess" ? (
          <Container
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <div className="w-100" style={{ maxWidth: maxW }}>
              <Card>
                <Helmet>
                  <title>无法显示</title>
                  <meta name="description" content="无法显示" />
                </Helmet>
                <Card.Body>
                  <Card.Title>404</Card.Title>
                  <Card.Text>该用户已删除或内容不存在</Card.Text>
                  <Card.Text>User deleted or the post doesn't exist</Card.Text>
                  <Card.Link href="/">返回首页</Card.Link>
                </Card.Body>
              </Card>
              ;
            </div>
          </Container>
        ) : (
          <>
            <Helmet>
              <title>{post.title}</title>
              <meta name="description" content={post.description} />
              <meta name="twitter:card" content={post.imageURL} />
              <meta name="twitter:site" content="@user" />
              <meta name="twitter:creator" content="@user" />
              <meta name="twitter:title" content={post.title} />
              <meta name="twitter:description" content={post.description} />
              <meta name="twitter:image" content={post.imageURL} />
              <meta property="og:title" content={post.title} />
              <meta property="og:description" content={post.description} />
              <meta property="og:image" content={post.photoURL} />
              <meta
                property="og:url"
                content={`https://letjoy.app/interaction/${post.id}`}
              />
              <meta property="og:site_name" content={post.title} />
              <meta property="og:locale" content="en_US" />
              <meta property="og:type" content="article" />
            </Helmet>
            <Container
              className="d-flex justify-content-center"
              style={{
                minHeight: "100vh",
                minWidth: "100vw",
                backgroundImage: `url(${post.coverURL})`,
              }}
            >
              <div
                className="w-100"
                style={{
                  maxWidth: maxW,
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
              >
                <div>
                  <Card
                    className="border-0 align-items-center justify-content-center"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "15px!important",
                      backgroundColor: "white",
                    }}
                  >
                    <Card.Img
                      className="rounded-circle shadow-4-strong"
                      variant="top"
                      src={post.imageURL}
                      style={{
                        height: "100px",
                        width: "100px",
                        marginTop: "25px",
                        borderColor: "#ffffff",
                      }}
                    />
                    <Card.Body>
                      <Card.Title
                        className="text-center"
                        style={{
                          fontSize: "40px",
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        {post.title}
                      </Card.Title>

                      <Card.Text className="text-center">
                        {post.user.name}{" "}
                        {post.lang === "en" ? "published at" : "发布于"}{" "}
                        {post.timestamp.toDate().toLocaleDateString("zh")}
                      </Card.Text>

                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ marginTop: "25px", marginBottom: "10px" }}
                      >
                        <Card.Text
                          className="text-center"
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#000000",
                          }}
                        >
                          {post.lang === "en"
                            ? "Anonymous Questions"
                            : "匿名提问"}
                        </Card.Text>
                      </div>

                      <ListGroup>
                        {questions.map((queItm) => {
                          return (
                            <ListGroup.Item
                              className="align-items-start"
                              style={{ marginTop: "10px" }}
                              onClick={() => {}}
                            >
                              <div className="fw-bold">
                                Q: {queItm.question}
                              </div>
                              <div className="fw-normal">
                                A: {queItm.answer}
                              </div>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>

                      <Form
                        onSubmit={handleQuestionFormSubmit}
                        style={{
                          marginTop: "50px",
                          borderRadius: "25px",
                          borderColor: "white",
                          borderWidth: "3px",
                          padding: "10px",
                        }}
                      >
                        <h5
                          className="text-center mb-4"
                          style={{ color: "white" }}
                        >
                          {post.lang === "en"
                            ? "Anonymous Questions"
                            : "匿名提问"}
                        </h5>
                        <Form.Group id="content" style={{ marginTop: "15px" }}>
                          <Form.Label
                            style={{
                              color: "black",
                              fontSize: "17px",
                              fontWeight: "bold",
                            }}
                          >
                            {post.description}
                          </Form.Label>
                          <Form.Control
                            style={{
                              backgroundColor: "transparent",
                              borderWidth: "3px",
                              color: "black",
                              height: "120px",
                              borderRadius: "25px",
                            }}
                            as="textarea"
                            rows={10}
                            required
                          />
                        </Form.Group>
                        <Button
                          className="w-100 p3"
                          type="submit"
                          style={{
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: "#111",
                            borderRadius: "25px",
                            borderColor: "#ffffff",
                            borderWidth: "3px",
                            fontSize: "15px",
                            fontWeight: "bold",
                            height: "50px",
                            marginTop: "15px",
                          }}
                        >
                          {post.lang === "en"
                            ? "Ask Anonymous Questions"
                            : "匿名提问"}
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Container>
          </>
        );
      })}
    </div>
  );
}
