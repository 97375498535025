import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Card, Alert, Container, Modal } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { fires } from "../firebase";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Stepper, Step } from "react-form-stepper";

export default function CreateNewEvent() {
  const newLinkTitleRef = useRef();
  const newLinkURLRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [viewTypes, setViewTypes] = useState([]);
  const [views, setViews] = useState([]);
  const [gData, setGData] = useState([]);
  const [links, setLinks] = useState([]);

  const [show, setShow] = useState(false);

  const [modifyId, setModifyId] = useState(-1);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const maxW = isMobile ? "100%" : "60%";

  var uid = currentUser.uid;
  var cEmail = currentUser.email;
  var orgId = "";

  const routeToHome = () => {
    let path = "/";
    window.location.href = path;
  };

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          orgId = userData.orgId;
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotB) => {
              if (snapshotB.exists) {
                var gData = [];
                var groupData = snapshotB.data();
                gData.push({ ...groupData, id: orgId });
                setGData(groupData);
                var links = [];
                if (groupData.contacts != null) {
                  links = groupData.contacts;
                  setLinks(links);
                }
              }
            });
        } else {
          //deleted
          posts.push({
            id: uid,
            error: "NotFound",
            error_message: "没有访问权限",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "乐源 LETJOY";
          document.description = "开启你的海外生活";
          routeToLogout();
        }
      });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    Promise.all(promises)
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="w-100"
          style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
        >
          {error && <Alert variant="danger">{error}</Alert>}
          <Card className="border-0">
            <Card.Body className="align-items-center justify-content-center">
              <Card.Title className="text-center">创建活动</Card.Title>
              <Stepper activeStep={0}>
                <Step label="活动基本信息" />
                <Step label="活动时间" />
                <Step label="活动地点" />
                <Step label="确认" />
              </Stepper>
            </Card.Body>
          </Card>

          <Modal
            show={show}
            onHide={() => {
              setModifyId(-1);
              handleClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {modifyId == -1 ? "增加链接" : "修改链接"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>链接名称</Form.Label>
                  <Form.Control
                    type="textarea"
                    placeholder="请输入链接名称"
                    ref={newLinkTitleRef}
                    defaultValue={modifyId != -1 ? links[modifyId].name : ""}
                    autoFocus
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>链接</Form.Label>
                  <Form.Control
                    as="textarea"
                    ref={newLinkURLRef}
                    defaultValue={modifyId != -1 ? links[modifyId].url : ""}
                    placeholder="https://"
                    rows={3}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  navigator.clipboard.readText().then((text) => {
                    newLinkURLRef.current.value = text;
                  });
                }}
              >
                粘贴
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setModifyId(-1);
                  handleClose();
                }}
              >
                关闭
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (modifyId == -1) {
                    links.push({
                      name: newLinkTitleRef.current.value,
                      url: newLinkURLRef.current.value,
                      type: "url",
                    });
                    setModifyId(-1);
                    setLinks(links);
                    setShow(false);
                  } else {
                    links[modifyId] = {
                      name: newLinkTitleRef.current.value,
                      url: newLinkURLRef.current.value,
                      type: "url",
                    };
                    setModifyId(-1);
                    setLinks(links);
                    setShow(false);
                  }
                }}
              >
                保存
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
    </>
  );
}
