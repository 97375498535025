import React, { useEffect, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { Form, Button, Card, Container, ListGroup, FloatingLabel, Row, Col, Carousel, Navbar, Offcanvas, Alert } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";
import GoogleMapReact from 'google-map-react';
import Autocomplete from "react-google-autocomplete";
import { useAuth } from "../../contexts/AuthContext";
import { auth, fires } from "../../firebase";
import firebase from "firebase/app";
import uuid from "react-uuid";
import "firebase/firestore";

export default function CreateTestEvent() {
  const maxW = isMobile ? "100%" : "50%";


  const eventTitleRef = useRef();
  const eventPhotoURLRef = useRef();
  const eventDescriptionRef = useRef();
  const eventDescriptionENRef = useRef();
  const eventIDRef = useRef();
  //address
  const addressSearchRef = useRef();
  const addressNameRef = useRef();
  const addressAddressRef = useRef();
  const addressCityRef = useRef();
  const addressProvRef = useRef();
  const addressCountryRef = useRef();
  const addressUnitRef = useRef();
  const addressPostalRef = useRef();
  //time
  const eventTimeStartRef = useRef();
  const eventTimeEndRef = useRef();
  //other info
  const eventPINRef = useRef();
  const eventLiveRef = useRef();
  const eventTicketRef = useRef();

  //sponsor info
  const eventSponsorNameRef = useRef();
  const eventSponsorDescriptionRef = useRef();
  const eventSponsorBannerRef = useRef();
  const eventSponsorURLRef = useRef();


  //speakers
  const eventSpeakerNameRef = useRef();
  const eventSpeakerIntroRef = useRef();
  const [eventSpeakerImageURL, setEventSpeakerImageURL] = useState("");

  //invitees
  const inviteeName = useRef();
  const inviteeEmail = useRef();
  const inviteePhone = useRef();

  //draws
  const drawNameRef = useRef();
  const drawDescriptionRef = useRef();
  const drawImageURLRef = useRef();
  const drawURLRef = useRef();
  const drawCountRef = useRef();

  const [uploadS, setUploadS] = useState('select and upload image')
  const [selectedExpImage, setSelectedExpImage] = useState(null);
  const [selectedBannerImageURL, setSelectedBannerImageURL] = useState("");
  const handleExpImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedExpImage(file);
  };

  const handleExpImageSubmit = (e) => {
    e.preventDefault();
    const file = selectedExpImage;
    if (!file) return;
    
  };


  const [startDateString, setStartDateString] = useState("")
  const [startTimeString, setStartTimeString] = useState("")
  const [endDateString, setEndDateString] = useState("")
  const [endTimeString, setEndTimeString] = useState("")

  const [startDate, setStartDate] = useState(
    firebase.firestore.Timestamp.fromDate(new Date())
  );
  const [endDate, setEndDate] = useState(
    firebase.firestore.Timestamp.fromDate(new Date())
  );
  const [eventContent, setEventContent] = useState({
    approved: false,
    address: {
      fullAddress: "",
      name: "",
      city: "",
      country: "",
      state: "",
      street: "",
      zipCode: "",
      unit: "",
      placeId: "",
      online: false,
      lat: 0.0,
      lng: 0.0,
    },
    agenda: [],
    sponsor: [],
    speakers: [],
    invitees: [],
    inviteeSet: [],
    drawItems: {},
    country: "",
    title: "",
    description: "",
    descriptions: {},
    orgId: "",
    id: "",
    fromUID: "",
    type: "free-event",
    priceType: "FREE",
    price: 0,
    priceOriginal: 0,
    priceCurrency: "CAD",
    priceEMTEmail: "",
    priceId: "",
    productId: "",
    priceDescription: "",
    saleStart: firebase.firestore.Timestamp.fromDate(new Date()),
    saleEnd: startDate,
    startDate: startDate,
    endDate: endDate,
    startDateString: "",
    endDateString: "",
    duration: "",
    prices: {
      "default-price": {
        priceType: "FREE",
        id: "default-price",
        order: 0,
        price: 0,
        priceOriginal: 0,
        priceTax: 0,
        priceCurrency: "CAD",
        priceEMTEmail: "",
        priceId: "",
        productId: "",
        max: 25,
        min: 1,
        priceDescription: "Free Admission",
        priceDescriptions: [],
        priceIncludeTax: false,
        saleStart: firebase.firestore.Timestamp.fromDate(new Date()),
        saleEnd: startDate,
      },
    },
    location: "",
    photoURL: "",
    photoURLs: [],
    status: "live",
    pin: "",
    live: false,
    liveURL: "",
    ticket: false,
    ticketURL: "",
    user: {
      userName: "",
      userOrg: "",
      imageURL: "",
      uid: "",
      cohosts: [],
    },
    topics: [],
    options: {
      poll: false,
      draw: false,
      namecard: false,
      review: false,
      comment: true,
      picture: true,
      coupon: false,
      "register-feedback": false,
      "multi-ticket-checkout": false,
      max: 500,
    },
    questions: [],
    viewCount: 0,
    timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
  });

  
  useEffect(() => {
      loadGalleryItems()
  },[])

  const defaultProps = {
    center: {
      lat: 43.6532,
      lng: -79.3832
    },
    zoom: 11
  };
  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const [steps, setSteps] = useState([
    "Details",
    "Tickets",
    "Forms",
    "Settings",
    "Confirm"
  ])
  const [currentStep, setCurrentStep] = useState(0)

  const [showGallery, setShowGallery] = useState(false)
  const [showCoupon, setShowCoupon] = useState(false)
  const [showTicket, setShowTicket] = useState(false)
  const [showForm, setShowForm] = useState(false)

  const [galleryItems,setGalleryItems] = useState([])

  async function loadGalleryItems(){
    fires
            .collection("users")
            .doc(auth.currentUser.uid)
            .collection("resources")
            .get()
            .then((snapshotA) => {
              var galleryItms = [];
              snapshotA.docs.map((docItm) => {
                const docData = docItm.data();
                let imageUrl = docData.url;
                if (imageUrl != null) {
                  galleryItms.push(imageUrl);
                }
              });
              setGalleryItems(galleryItms);
            });
  }

  function sectionGalleryPicker(){
      return <Offcanvas 
      show={showGallery} 
      
      onHide={()=>{setShowGallery(false)}}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Gallary</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        
      <div
                                style={{ margin: "15px" }}
                                hidden={selectedExpImage == ""}
                              >
                                {selectedExpImage && (
                                  <div>
                                    <img
                                      alt="banner"
                                      width={"250px"}
                                      src={URL.createObjectURL(
                                        selectedExpImage
                                      )}
                                    />
                                    <br />
                                    <Alert variant="primary">{uploadS}</Alert>
                                    <div>
                                      <button
                                        onClick={() =>
                                          setSelectedExpImage(null)
                                        }
                                      >
                                        remove
                                      </button>
                                      <button onClick={handleExpImageSubmit}>
                                        upload
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <br />
                                <input
                                  type="file"
                                  name="coverImage"
                                  title="选择图片"
                                  onChange={handleExpImageChange}
                                />
                                <div>
                                  <img
                                    alt="cover"
                                    width={"100%"}
                                    maxHeight={"500px"}
                                    style={{ marginTop: "15px" }}
                                    src={selectedBannerImageURL}
                                  />
                                </div>
                              </div>

                              <Row
                                md={2}
                                style={{ rowGap: "0px", columnGap: "0px" }}
                              >
                                {
                                  galleryItems.map((imgItm) => {
                                    return <Col style={{marginTop: '5px'}}>
                                    <img 
                                     src={imgItm}
                                     width="100%"
                                     height="100%"
                                     style={{ objectFit: "cover" }}/>
                                    </Col>
                                  })
                                }
                              </Row>

      </Offcanvas.Body>
    </Offcanvas>
  }

  function sectionCoupon(){
    return <Offcanvas show={showCoupon} onHide={()=>{setShowCoupon(false)}}>
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>New Coupon</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      
    </Offcanvas.Body>
  </Offcanvas>
}

function sectionTicket(){
    return <Offcanvas show={showTicket} onHide={()=>{setShowTicket(false)}}>
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>New Ticket</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      
    </Offcanvas.Body>
  </Offcanvas>
}

function sectionFormQuestion(){
    return <Offcanvas show={showForm} onHide={()=>{setShowForm(false)}}>
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>Add a question</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      Some text as placeholder. In real life you can have the elements you
      have chosen. Like, text, images, lists, etc.
    </Offcanvas.Body>
  </Offcanvas>
}


function eventDetailsSection(){

    return <Form hidden={currentStep != 0}>
    <Card.Title style={{margin: 0}}>Event Title *</Card.Title>
    <Card.Text style={{margin: 0, color: 'gray'}}>Be clear and descriptive with a title that tells people what your event is about.</Card.Text>
    <Form.Group style={{marginTop: 10, marginBottom: 35}}>
      <FloatingLabel label="Event Title *">
          <Form.Control 
            type="text"
            defaultValue={eventContent.title}
            ref={eventTitleRef}
            placeholder="Event title, please be procise"/>
      </FloatingLabel>
    </Form.Group>
    
    <Card.Title style={{margin: 0}}>Description *</Card.Title>
    <Card.Text style={{margin: 0, color: 'gray'}}>Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)</Card.Text>
    <Form.Group style={{marginTop: 10, marginBottom: 35}}>
    <Form.Control 
            as="textarea"
            rows={8}
            defaultValue={eventContent.description}
            ref={eventDescriptionENRef}
            placeholder="Event description, 140 characters max"/>
    </Form.Group>

    <Card.Title style={{margin: 0}}>Date And Time *</Card.Title>
    <Card.Text style={{margin: 0, color: 'gray'}}>Choose your event's start and end date</Card.Text>
    <Form.Group style={{marginTop: 10, marginBottom: 35}}>
      <Row md={2} style={{marginTop: 10}}>
          <Col>
            <FloatingLabel label="Start Date *">
            <Form.Control 
                type="date"
                defaultValue={eventContent.startDate.toDate().toDateString()}
                min={(new Date())}
                onChange={(e)=>{
                  let dateVal = e.target.value
                  if (dateVal != ""){
                    setStartDateString(dateVal)
                  }
                }}
                placeholder="Start Date"/>
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="Start Time *">
            <Form.Control 
                type="time"
                defaultValue={eventContent.startDate.toDate().toTimeString()}
                onChange={(e)=>{
                  let timeVal = e.target.value
                  if (timeVal != ""){
                    setStartTimeString(timeVal)
                  }
                }}
                placeholder="Start Time"/>
            </FloatingLabel>
          </Col>
      </Row>
      <Row md={2} style={{marginTop: 10}}>
          <Col>
            <FloatingLabel label="End Date *">
            <Form.Control 
                type="date"
                defaultValue={eventContent.endDate.toDate().toDateString()}
                onChange={(e)=>{
                  let dateVal = e.target.value
                  if (dateVal != ""){
                    setEndDateString(dateVal)
                  }
                }}
                placeholder="End Date"/>
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="End Time *">
            <Form.Control 
                type="time"
                defaultValue={eventContent.endDate.toDate().toTimeString()}
                onChange={(e)=>{
                  let dateVal = e.target.value
                  if (dateVal != ""){
                    setEndTimeString(dateVal)
                  }
                }}
                placeholder="End Time"/>
            </FloatingLabel>
          </Col>
      </Row>
    </Form.Group>

    <Card.Title style={{margin: 0}}>Location</Card.Title>
    <Card.Text style={{margin: 0, color: 'gray'}}>Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)</Card.Text>
    <Form.Group style={{marginTop: 10, marginBottom: 35}}>
    <Autocomplete
            style={{width: '100%', padding: '5px', borderRadius: '15px', borderColor: 'grey', height: '50px'}}
            apiKey={process.env.REACT_APP_GOOGLE_API}
            placeholder={"Search for location"}
            options={{
              types: [],
              componentRestrictions: { country: "ca" },
            }}
            onPlaceSelected={(place) => {
              const placeAddress = place.formatted_address
              const placeId = place.place_id
              const { lat, lng } =
                        place.geometry
                          .location;
              let city = ""
              let state = ""
              let country = ""
              let street_name = ""
              let street_number = ""
              let unit_number = ""
              let zipcode = ""

              console.log(place.address_components)

              place.address_components.forEach((component) => {

                if (
                  component.types.includes(
                    "locality"
                  )
                )
                  city =
                    component.long_name;
                else if (
                  component.types.includes(
                    "administrative_area_level_1"
                  )
                )
                  state =
                    component.long_name;
                else if (
                  component.types.includes(
                    "country"
                  )
                )
                  country =
                    component.long_name;
                else if (
                  component.types.includes(
                    "postal_code"
                  )
                )
                  zipcode =
                    component.long_name;
                else if (
                  component.types.includes(
                    "street_number"
                  )
                )
                  street_number =
                    component.long_name;

                else if (
                      component.types.includes(
                        "route"
                      )
                    )
                      street_name =
                        component.long_name;
                

                
              })
              
                const streetConfirm = window.confirm(
                  "Are you sure to import address " +
                    placeAddress +
                    " ?"
                );
                if (streetConfirm){
                  addressAddressRef.current.value = street_number + " " + street_name;
                  addressCityRef.current.value = city;
                  addressProvRef.current.value =
                  state;
                  addressCountryRef.current.value =
                  country;
                  addressPostalRef.current.value =
                  zipcode;

                  let newContent = eventContent
                  newContent.address = {
                    name: '',
                    street: street_number + " " + street_name,
                    fullAddress: placeAddress,
                    city: city,
                    state: state,
                    country: country,
                    zipCode: zipcode,
                    unit: '',
                    placeId: placeId,
                    lat: lat,
                    lng: lng,
                    online: false
                  }
                  setEventContent(newContent)
                }
            }}
    />

{/* <div style={{ height: isMobile ? '150px' : '300px', width: '100%', marginTop: 25 }}>
    <GoogleMapReact
bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API, language: 'en' }}
defaultCenter={defaultProps.center}
defaultZoom={defaultProps.zoom}

>
<AnyReactComponent
lat={0.0}
lng={0.0}
text="My Marker"
/>
</GoogleMapReact>
</div> */}

      <Row md={2} style={{marginTop: 25}}>
          <Col>
            <FloatingLabel label="Street Address *">
            <Form.Control 
                type="text"
                ref={addressAddressRef}
                placeholder="Search For Location"/>
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="Unit # (Optional)">
            <Form.Control 
                type="text"
                ref={addressUnitRef}
                placeholder="Search For Location"/>
            </FloatingLabel>
          </Col>
      </Row>
      <FloatingLabel label="City *" style={{marginTop: 10, marginBottom: 10}}>
      <Form.Control 
                type="text"
                ref={addressCityRef}
                placeholder="Search For Location"/>
      </FloatingLabel>
      <FloatingLabel label="State *" style={{marginTop: 10, marginBottom: 10}}>
      <Form.Control 
                type="text"
                ref={addressProvRef}
                placeholder="Search For Location"/>
      </FloatingLabel>
      <FloatingLabel label="Country *" style={{marginTop: 10, marginBottom: 10}}>
      <Form.Control 
                type="text"
                ref={addressCountryRef}
                placeholder="Search For Location"/>
      </FloatingLabel>
      <FloatingLabel label="Postal Code *" style={{marginTop: 10, marginBottom: 10}}>
      <Form.Control 
                type="text"
                ref={addressPostalRef}
                placeholder="Search For Location"/>
      </FloatingLabel>
      <FloatingLabel label="Name *" style={{marginTop: 10, marginBottom: 10}}>
      <Form.Control 
                type="text"
                ref={addressNameRef}
                placeholder="Search For Location"/>
      </FloatingLabel>
    </Form.Group>

    


    <Form.Group style={{marginTop: 35, marginBottom: 35}}>
    <div className="d-flex align-items-start justify-content-between">
        <div>
            <Card.Title style={{margin: 0}}>Gallary</Card.Title>
            <Card.Text style={{margin: 0, color: 'gray'}}>Add at least one picture to gallary</Card.Text>
        </div>
        <div>
            <Button variant="outline-success" onClick={(e)=>{
                setShowGallery(true)
            }}>Add</Button>
        </div>
    </div>
    
    <Carousel style={{marginTop: 25,height: isMobile ? '150px' : '300px', width: '100%', backgroundColor: '#000000'}}>
<Carousel.Item>

<Carousel.Caption>
<h3>First slide label</h3>
<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
</Carousel.Caption>
</Carousel.Item>


</Carousel>
    </Form.Group>

  </Form>

}

function eventTicketsSection(){
    return <Form hidden={currentStep != 1}>

    <Form.Group style={{marginTop: 35, marginBottom: 35}}>
<Card.Title style={{margin: 0}}>Pricing</Card.Title>
<Card.Text style={{margin: 0, color: 'gray'}}>Choose Free Event or Paid Event to get money</Card.Text>
<div style={{marginTop: 15}}>
<Button variant="dark" style={{borderRadius: 15,}}>Free</Button>
<Button variant="outline-dark" style={{marginLeft: 10, borderRadius: 15}}>Paid</Button>
</div>
</Form.Group>

<Form.Group style={{marginTop: 35, marginBottom: 35}}>
<Card.Title style={{margin: 0}}>Tickets</Card.Title>
<Card.Text style={{margin: 0, color: 'gray'}}>Choose Free Event or Paid Event to get money</Card.Text>
<div style={{marginTop: 15}}>
<FloatingLabel label="Total Number of Tickets *">
              <Form.Control 
                  type="number"
                  defaultValue={500}
                  placeholder="Total tickets"/>
              </FloatingLabel>
</div>
</Form.Group>

<Form.Group style={{marginTop: 35, marginBottom: 35}}>
<div style={{marginTop: 15}}>
    {
        Object.keys(eventContent.prices).map((key) => {
            return <Card>
            <Card.Body>
            <Card.Title>{eventContent.prices[key].priceDescription}</Card.Title>
            <Card.Title style={{fontWeight: 'bold', color: 'purple'}}>{eventContent.prices[key].price.toLocaleString(
                "en-US", {style:"currency", currency: eventContent.prices[key].priceCurrency}
            )}</Card.Title>
            <Card.Text style={{fontSize: 15, color: 'black'}}>Display Price</Card.Text>
            <Card.Text style={{fontSize: 15, color: 'black'}}>Sale ending at {eventContent.prices[key].saleEnd.toDate().toLocaleString('en')}</Card.Text>
            </Card.Body>
            </Card>
        })
    }

<div style={{marginTop: 15}}>
<Button variant="outline-dark" style={{}} onClick={()=>{
setShowTicket(true)
}}>+ Add More</Button>
</div>
</div>
</Form.Group>

{/* <Form.Group style={{marginTop: 35, marginBottom: 35}}>
<div className="d-flex align-items-start justify-content-between">
<div>
              <Card.Title style={{margin: 0}}>Coupon</Card.Title>
              <Card.Text style={{margin: 0, color: 'gray'}}>Add your coupon</Card.Text>
          </div>
          <div>
              <Button variant="outline-success" onClick={()=>{
                  setShowCoupon(true)
              }}>Add</Button>
          </div>
</div>
</Form.Group> */}

{/* <Form.Group style={{marginTop: 35, marginBottom: 35}}>
<div style={{marginTop: 15}}>
<Card>
<Card.Body>
<Card.Title>AAAACCCE</Card.Title>
<Card.Title style={{fontWeight: 'bold', color: 'purple'}}>CA$10.00 OFF</Card.Title>
<Card.Text style={{fontSize: 15, color: 'black'}}></Card.Text>
<Card.Text style={{fontSize: 15, color: 'black'}}>Expired Soon</Card.Text>
</Card.Body>
</Card>
</div>
</Form.Group> */}



    </Form>
    
}

function eventFormsSection(){
    return <Form hidden={currentStep != 2}>
    <Form.Group style={{marginTop: 35, marginBottom: 35}}>
<div className="d-flex align-items-start justify-content-between">
<div>
              <Card.Title style={{margin: 0}}>Forms</Card.Title>
              <Card.Text style={{margin: 0, color: 'gray'}}>Create form questions</Card.Text>
          </div>
          <div>
              <Button variant="outline-success" onClick={()=>{
                  setShowForm(true)
              }}>Create</Button>
          </div>
</div>
</Form.Group>

    </Form>
    
}

function eventSettingSection(){
    
}

function eventPreviewSection(){
    
}

  return (
    <>
      <Helmet>
        <title>Create Test Event</title>
        <meta property="og:title" content="Create Test Event" />
        <meta
          property="og:description"
          content="Create Test Event"
        />
        <meta property="og:url" content={"https://evtgo.com/"} />
        <meta
          property="og:site_name"
          content="Create Test Event"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Navbar
          bg="white"
          variant="light"
          expand="lg"
          sticky="top"
          style={{height: isMobile ? 200 : 100}}
        >
          <Container>
          <Card.Title style={{fontSize: isMobile ? 25 : 45}}><b>Create Event</b></Card.Title>
                  <Stepper activeStep={currentStep}>
            <Step label={steps[0]} />
            <Step label={steps[1]} />
            <Step label={steps[2]} />
            <Step label={steps[3]} />
            <Step label={steps[4]} />
          </Stepper>
          </Container>
    </Navbar>

      <Container
        className="d-flex justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: "white",
        }}
      >
          <div style={{ width: maxW, marginTop: "25px", marginBottom: "25px" }}>
          <Card className="border-0" style={{ }}>
              <Card.Body>
                  {
                      sectionGalleryPicker()
                  }
                  {
                      sectionCoupon()
                  }
                  {
                      sectionTicket()
                  }
                  {
                      sectionFormQuestion()
                  }
                 
                  <div style={{marginTop: 25}}>
                      
                      {
                          eventDetailsSection()
                      }
                      {
                          eventTicketsSection()
                      }
                      {
                          eventFormsSection()
                      }
                      {
                          eventSettingSection()
                      }
                      {
                          eventPreviewSection()
                      }
                      

                      
                  </div>
              </Card.Body>
          </Card>
          </div>

          
      </Container>

      <Navbar
          bg="white"
          variant="light"
          expand="lg"
          sticky="bottom"
          style={{height: 100}}
        >
          <Container fluid>
          <><Button 
            variant="dark" 
            hidden={currentStep == 0}
            style={{width: 180, height: 50, fontSize: 18, fontWeight: 'bold', backgroundColor: 'grey'}} 
            onClick={()=>{
                if (currentStep > 0){
                    setCurrentStep(currentStep-1)
                  }
          }}>Previous</Button></>
          <Button 
          variant="success" 
          style={{width: 180, height: 50, fontSize: 18, fontWeight: 'bold'}} onClick={()=>{
              if (currentStep < steps.length - 1){
                setCurrentStep(currentStep+1)
              }
              else{
                  //submit
                  alert("Submission")
              }
          }}>{currentStep < steps.length - 1 ? "Next" : "Submit"}</Button>
          </Container>
    </Navbar>

    </>
  );
}
