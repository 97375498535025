import { Alert } from "react-bootstrap";

export default function SuperAdminAlert(superAdminInEffect) {
  if (superAdminInEffect){
    return (
      superAdminInEffect && (
        <Alert variant="danger">Super admin in effect, you can view all contents now</Alert>
      )
    );
  }
  else{
    return <></>
  }
  
}
