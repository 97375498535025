import { useState } from "react";

const ReceiptTemplate = (
  logoURL,
  eventInfo,
  ticketInfo,
  orgInfo,
  receiptId
) => {
  const logoImg =
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/rileyevents_logo.jpg";

  const [inputValue, setInputValue] = useState({
    note: "",
    date: "",
    issued: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      [name]: value,
    });
  };
  return (
    <div>
      <div id="pspdfkit-header">
        <div className="header-columns">
          <div className="logotype">
            <img className="logo" src={logoURL} alt="logo" />
            <p>{orgInfo.name}</p>
            <br />
          </div>

          <div>
            <p>EventGo Events</p>
          </div>
        </div>
      </div>

      <div className="page">
        <div>
          <h2>Receipt #{receiptId}</h2>
        </div>

        <div className="intro-table">
          <div className="intro-form intro-form-item">
            <p className="intro-table-title">Billed To:</p>
            <p>
              {orgInfo.name}
              <br />
              {/* 205 Placer Ct 3 Floor, North York, ON M2H 0A9
              <br />
              Canada
              <br /> */}
            </p>
          </div>

          <div className="intro-form">
            <div className="intro-form-item-border">
              <p className="intro-table-title">Receipt Date:</p>
              <p>{new Date().toLocaleDateString()}</p>
            </div>

            <div className="intro-form-item-border">
              <p className="intro-table-title">Payment Method:</p>
              <p>{eventInfo.priceType}</p>
            </div>
          </div>
        </div>

        <div className="table-box">
          <table>
            <tbody>
              <tr className="heading">
                <td>Description</td>
                <td>QTY</td>
                <td>Unit Price</td>
                <td>Total</td>
              </tr>

              <tr className="item">
                <td>{orgInfo.name} Events</td>
                <td>1</td>
                <td>CAD ${eventInfo.price}</td>
                <td>CAD ${eventInfo.price}</td>
              </tr>

              <tr className="item">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr className="item">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="summary-box">
          <table>
            <tbody>
              <tr className="item">
                <td></td>
                <td>Subtotal:</td>
                <td>CAD ${eventInfo.price}</td>
              </tr>

              <tr className="item">
                <td></td>
                <td>Discount:</td>
                <td>CAD $0</td>
              </tr>

              <tr className="item">
                <td></td>
                <td>Subtotal Less Discount:</td>
                <td>CAD $0</td>
              </tr>

              <tr className="item">
                <td></td>
                <td>Tax Rate:</td>
                <td>CAD $0</td>
              </tr>

              <tr className="item">
                <td></td>
                <td>Total Tax:</td>
                <td>CAD $0</td>
              </tr>

              <tr className="item">
                <td></td>
                <td>Shipping/Handling:</td>
                <td>CAD $0</td>
              </tr>

              <tr className="no-border-item">
                <td></td>
                <td>Total Due:</td>
                <td>CAD ${eventInfo.price}</td>
              </tr>

              <tr className="total">
                <td></td>
                <td>Amount Paid:</td>
                <td>CAD ${eventInfo.price}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="page" style={{ marginTop: "30px" }}>
        <div>
          <h4>Thank you for your purchase!</h4>
        </div>

        <div className="form">
          <label htmlFor="notes" className="label">
            Notes:
          </label>
          <input
            type="text"
            id="notes"
            className="border-bottom"
            value={inputValue.note}
            onChange={handleChange}
          />
        </div>

        <div className="signer">
          <div className="form signer-item">
            <label htmlFor="date" className="label">
              Date:
            </label>
            <input
              type="text"
              id="date"
              className="border-bottom"
              value={inputValue.date}
              onChange={handleChange}
            />
          </div>

          <div className="form signer-item">
            <label htmlFor="signature" className="label">
              Issued by:
            </label>
            <input
              type="text"
              id="signature"
              className="border"
              value={inputValue.issued}
              onChange={handleChange}
            />
          </div>
        </div>

        <div style={{ marginTop: "50px" }}>
          <div className="d-flex align-items-center justify-content-center">
            <p className="text-center">
              <b>Powered by EventGo</b>
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <p className="text-center">
              205 Placer Ct 3 Floor, North York, ON M2H 0A9
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptTemplate;
