import React, { useEffect, useState } from "react";
import { Form, Button, Card, Container, ListGroup } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useAuth } from "../../contexts/AuthContext";
import { auth, fires } from "../../firebase";
import firebase from "firebase";

export default function AdminAndRoles() {
  const maxW = isMobile ? "100%" : "90%";
  const [adminData, setAdminData] = useState({
      name: '',
      email: '',
      roles: []
  })
  useEffect(() => {
      fires
      .collection('admin')
      .doc(auth.currentUser.uid)
      .get()
      .then((snapshot)=>{
          if (snapshot.exists){
            setAdminData({...snapshot.data()})
          }
          else{
            window.location.href = "/"
          }
      })
  },[])

  return (
    <>
      <Helmet>
        <title>View Admins</title>
        <meta property="og:title" content="View Admins" />
        <meta
          property="og:description"
          content="View Admins"
        />
        <meta property="og:url" content={"https://evtgo.com/"} />
        <meta
          property="og:site_name"
          content="View Admins"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container
        className="d-flex justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: "white",
        }}
      >
        <div style={{ width: maxW, marginTop: "25px", marginBottom: "25px" }}>
          <Card className="border-0" style={{ backgroundColor: "transparent" }}>
            <Card.Body className="align-items-center justify-content-center">
              <div style={{ marginTop: "25px", marginBottom: "45px" }}>
                <Card.Title 
                    className="text-center" 
                    style={{fontSize: '25px', fontWeight: 'bold'}}>
                  View Admin Information
                </Card.Title>
              </div>
              <ListGroup>
                  <ListGroup.Item>
                      <div className="fw-normal">Your Name: </div>
                      <div className="fw-bold">{adminData.name}</div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                      <div className="fw-normal">Your Email: </div>
                      <div className="fw-bold">{adminData.email}</div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                      <div className="fw-normal">Your Roles: </div>
                      <div className="fw-bold">{adminData.roles.join(', ')}</div>
                  </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
