import React from "react";
import { Button, Card, Container, Row, Col, ListGroup } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import "firebase/firestore";

export default function Pricing() {
  const maxW = isMobile ? "100%" : "90%";

  const pricings = {
    free: {
      events: [
        "[Unlimited] Event registration and other options",
        "[Unlimited] Event check-in (realtime update)",
        "[1 Per Week] Event marketing (Email)",
        "Add sponsor to event",
        "Add agenda to event",
        "Add speaker profile to event",
        "After-Event data analytics",
      ],
      membership: [
        "Group webpage showcase",
        "[1 Per Week] Create posts and articles",
        "[1 Per Week] Member-only events",
        "[20 Members] Add member by email",
      ],
      advanced: ["Nothing"],
      support: ["Email support"],
      price: "$0 / Month",
      priceY: "$0 / Year",
      priceName: "FREE PLAN",
      priceDesc: "Free Forever",
    },
    plus: {
      events: [
        "[Unlimited] Event registration with forms and other options",
        "[Unlimited] Event check-in (realtime update)",
        "[1 Per Week] Event marketing (Email)",
        "Add sponsor to event",
        "Add agenda to event",
        "Add speaker profile to event",
        "In-Event photos / status sharing",
        "After-Event data analytics",
      ],
      membership: [
        "Group webpage showcase",
        "[3 Per Week] Create posts and articles",
        "[1 Per Week] Member-only events",
        "[50 Members] Add member by email",
      ],
      advanced: ["Photo recognition using AI"],
      support: ["Email support"],
      price: "$19.99 / Month",
      priceY: "$220 / Year",
      priceName: "ORGANIZER PLUS",
      priceDesc: "Free Forever",
    },
    professional: {
      events: [
        "[Unlimited] Event registration with forms and other options",
        "[Unlimited] Event check-in (realtime update)",
        "[Unlimited] Event marketing (Email)",
        "Add sponsor to event",
        "Add agenda to event",
        "Add speaker profile to event",
        "In-Event photos / status sharing",
        "In-Event lucky draw",
        "In-Event banner page (showcase sponsors, agenda, welcome message)",
        "After-Event data analytics",
        "After-Event feedback collection",
      ],
      membership: [
        "Group webpage showcase",
        "Create posts and articles",
        "Member-only events",
        "Add member by email",
        "Weekly email sent to members",
      ],
      advanced: [
        "Photo recognition using AI",
        "Summarize events content using AI",
        "Riley Workspace, allow multiple roles to work in same organization",
        "Riley Workflow",
        "API endpoint and developer portal",
      ],
      support: ["24/7 Email support", "Group chat support"],
      price: "$59.99 / Month",
      priceY: "$650 / Year",
      priceName: "PROFESSIONAL ORGANIZER",
      priceDesc: "Free Forever",
    },
  };

  return (
    <>
      <Helmet>
        <title>Riley pricing</title>
        <meta property="og:title" content="Riley pricing" />
        <meta
          property="og:description"
          content="Explore the best events and groups in your area"
        />
        <meta property="og:url" content={"https://rileyevents.com/"} />
        <meta
          property="og:site_name"
          content="Riley - Explore the best events and groups in your area"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container
        className="d-flex justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: "white",
        }}
      >
        <div style={{ width: maxW, marginTop: "25px", marginBottom: "25px" }}>
          <Card className="border-0" style={{ backgroundColor: "transparent" }}>
            <Card.Body className="align-items-center justify-content-center">
              <div style={{ marginTop: "25px", marginBottom: "45px" }}>
                <Card.Title className="text-center">
                  <b>Riley Organizer Pricing</b>
                </Card.Title>
              </div>

              <Row md={3}>
                <Col>
                  <Card>
                    <Card.Body className="align-items-center justify-content-center">
                      <Card.Title
                        className="text-center"
                        style={{
                          marginTop: "15px",
                          fontWeight: "bold",
                          fontSize: "25px",
                        }}
                      >
                        {pricings.free.priceName}
                      </Card.Title>
                      <Card.Text
                        className="text-center"
                        style={{
                          marginTop: "5px",
                          fontWeight: "bold",
                          fontSize: "17px",
                        }}
                      >
                        {pricings.free.price}
                      </Card.Text>
                      <Card.Text style={{ marginTop: "15px" }}>
                        <b>Events</b>
                      </Card.Text>
                      <ListGroup className="border-0">
                        {pricings.free.events.map((itm) => {
                          return (
                            <ListGroup.Item className="border-0">
                              • {itm}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                      <Card.Text style={{ marginTop: "15px" }}>
                        <b>Membership</b>
                      </Card.Text>
                      <ListGroup className="border-0">
                        {pricings.free.membership.map((itm) => {
                          return (
                            <ListGroup.Item className="border-0">
                              • {itm}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                      <Card.Text style={{ marginTop: "15px" }}>
                        <b>Advanced</b>
                      </Card.Text>
                      <ListGroup className="border-0">
                        {pricings.free.advanced.map((itm) => {
                          return (
                            <ListGroup.Item className="border-0">
                              • {itm}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>

                      <Card.Text style={{ marginTop: "15px" }}>
                        <b>Support</b>
                      </Card.Text>
                      <ListGroup className="border-0">
                        {pricings.free.support.map((itm) => {
                          return (
                            <ListGroup.Item className="border-0">
                              • {itm}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ marginTop: "15px" }}
                      >
                        <Button
                          className="w-100"
                          variant="dark"
                          style={{ height: "40px", borderRadius: "20px" }}
                          onClick={() => {}}
                        >
                          Compare Plan
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Body className="align-items-center justify-content-center">
                      <Card.Title
                        className="text-center"
                        style={{
                          marginTop: "15px",
                          fontWeight: "bold",
                          fontSize: "25px",
                        }}
                      >
                        {pricings.plus.priceName}
                      </Card.Title>
                      <Card.Text
                        className="text-center"
                        style={{
                          marginTop: "5px",
                          fontWeight: "bold",
                          fontSize: "17px",
                        }}
                      >
                        {pricings.plus.price}
                      </Card.Text>
                      <Card.Text style={{ marginTop: "15px" }}>
                        <b>Events</b>
                      </Card.Text>
                      <ListGroup className="border-0">
                        {pricings.plus.events.map((itm) => {
                          return (
                            <ListGroup.Item className="border-0">
                              • {itm}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                      <Card.Text style={{ marginTop: "15px" }}>
                        <b>Membership</b>
                      </Card.Text>
                      <ListGroup className="border-0">
                        {pricings.plus.membership.map((itm) => {
                          return (
                            <ListGroup.Item className="border-0">
                              • {itm}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                      <Card.Text style={{ marginTop: "15px" }}>
                        <b>Advanced</b>
                      </Card.Text>
                      <ListGroup className="border-0">
                        {pricings.plus.advanced.map((itm) => {
                          return (
                            <ListGroup.Item className="border-0">
                              • {itm}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>

                      <Card.Text style={{ marginTop: "15px" }}>
                        <b>Support</b>
                      </Card.Text>
                      <ListGroup className="border-0">
                        {pricings.plus.support.map((itm) => {
                          return (
                            <ListGroup.Item className="border-0">
                              • {itm}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>

                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ marginTop: "15px" }}
                      >
                        <Button
                          className="w-100"
                          variant="dark"
                          style={{ height: "40px", borderRadius: "20px" }}
                          onClick={() => {}}
                        >
                          Compare Plan
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Body className="align-items-center justify-content-center">
                      <Card.Title
                        className="text-center"
                        style={{
                          marginTop: "15px",
                          fontWeight: "bold",
                          fontSize: "25px",
                        }}
                      >
                        {pricings.professional.priceName}
                      </Card.Title>
                      <Card.Text
                        className="text-center"
                        style={{
                          marginTop: "5px",
                          fontWeight: "bold",
                          fontSize: "17px",
                        }}
                      >
                        {pricings.professional.price}
                      </Card.Text>
                      <Card.Text style={{ marginTop: "15px" }}>
                        <b>Events</b>
                      </Card.Text>
                      <ListGroup className="border-0">
                        {pricings.professional.events.map((itm) => {
                          return (
                            <ListGroup.Item className="border-0">
                              • {itm}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                      <Card.Text style={{ marginTop: "15px" }}>
                        <b>Membership</b>
                      </Card.Text>
                      <ListGroup className="border-0">
                        {pricings.professional.membership.map((itm) => {
                          return (
                            <ListGroup.Item className="border-0">
                              • {itm}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                      <Card.Text style={{ marginTop: "15px" }}>
                        <b>Advanced</b>
                      </Card.Text>
                      <ListGroup className="border-0">
                        {pricings.professional.advanced.map((itm) => {
                          return (
                            <ListGroup.Item className="border-0">
                              • {itm}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>

                      <Card.Text style={{ marginTop: "15px" }}>
                        <b>Support</b>
                      </Card.Text>
                      <ListGroup className="border-0">
                        {pricings.professional.support.map((itm) => {
                          return (
                            <ListGroup.Item className="border-0">
                              • {itm}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>

                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ marginTop: "15px" }}
                      >
                        <Button
                          className="w-100"
                          variant="dark"
                          style={{ height: "40px", borderRadius: "20px" }}
                          onClick={() => {}}
                        >
                          Compare Plan
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
