import React, { useRef, useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import ReceiptTemplate from "./ReceiptTemplate";
import "./Styles.css";
import { useAuth } from "../../contexts/AuthContext";
import { auth, fires } from "../../firebase";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import firebase from "firebase";
import "firebase/firestore";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";

export default function ReceiptPage() {
  const { id, ticketId } = useParams();
  const { currentUser, logout } = useAuth();
  var uid = currentUser.uid;

  const [eventData, setEventData] = useState({});
  const [orgData, setOrgData] = useState({});
  const [ticketData, setTicketData] = useState({});
  const [customerData, setCustomerData] = useState({});

  const handleCaptureClick = async () => {
    const canvas = await html2canvas(document.getElementById("receipt"));
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, "ticket-receipt-" + ticketId + ".png", "image/png");
  };

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const routeToBack = () => {
    let path = "/";
    window.location.href = path;
  };

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const userData = snapshot.data();
          var uorgId = userData.orgId;

          fires
            .collection("events")
            .doc(id)
            .get()
            .then((snapshotB) => {
              if (snapshotB.exists) {
                var aData = {};
                var artiData = snapshotB.data();
                aData = artiData;
                setEventData(aData);

                if (ticketId != null) {
                  if (ticketId != "") {
                    fires
                      .collection("events")
                      .doc(id)
                      .collection("tickets")
                      .doc(ticketId)
                      .get()
                      .then((snapshotC) => {
                        if (snapshotC.exists) {
                          const cId = snapshotC.id;
                          const cData = snapshotC.data();
                          setTicketData({ ...cData, id: cId });
                        } else {
                          routeToBack();
                        }
                      });
                  }
                } else {
                  routeToBack();
                }

                const orgId = aData.orgId;
                if (orgId != null) {
                  if (orgId != "") {
                    if (uorgId == orgId) {
                      fires
                        .collection("groups")
                        .doc(orgId)
                        .get()
                        .then((snapshotA) => {
                          if (snapshotA.exists) {
                            const orgD = snapshotA.data();
                            setOrgData({ ...orgD });
                          } else {
                            routeToBack();
                          }
                        });
                    } else {
                      //id is not match
                      routeToBack();
                    }
                  } else {
                    //event orgId empty
                    routeToBack();
                  }
                } else {
                  routeToBack();
                }
              }
            });
        } else {
          //deleted
          routeToLogout();
        }
      });
  }, []);

  return (
    <div>
      <button className="button" onClick={handleCaptureClick}>
        生成图片
      </button>
      <div style={{ margin: "200px" }} id="receipt">
        <div>
          {ReceiptTemplate(
            orgData.profileURL,
            eventData,
            ticketData,
            orgData,
            ticketId
          )}
        </div>
      </div>
    </div>
  );
}
