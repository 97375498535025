import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  Navbar,
  Modal,
  ListGroup,
  Dropdown,
  DropdownButton,
  Badge,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { auth, fires } from "../firebase";
import { Link, Route, useParams, Redirect } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { Helmet } from "react-helmet";
import rgba from "color-rgba";
import { QRCodeSVG } from "qrcode.react";

import uuid from "react-uuid";
import Youtube from "react-youtube";
import ReactAudioPlayer from "react-audio-player";
import firebase from "firebase/app";
import "firebase/firestore";
import { width } from "dom-helpers";
import { SocialIcon } from "react-social-icons";
import Footer from "./Footer";
import { First } from "react-bootstrap/esm/PageItem";

export default function NameCard() {
  const [success, setSuccess] = useState("");
  const [alertSuccess, setAlertSuccess] = useState("");
  const [error, setError] = useState("");
  const [index, setIndex] = useState(0);
  const { currentUser, logout } = useAuth();

  const [posts, setPosts] = useState([]);
  const [orgPosts, setOrgPosts] = useState([]);
  const [orgExps, setOrgExps] = useState([]);
  const [orgEvents, setOrgEvents] = useState([]);

  let { id } = useParams();
  const maxW = isMobile ? "100%" : "50%";

  var lang = "zh";

  const [styles, setStyles] = useState({
    feedbacks: [],
    youtube: [],
    gallery: [],
    articles: [],
    events: [],
    posts: [],
    navigation: true,
    voice: false,
    voices: [],
  });

  const [navDetails, setNavDetails] = useState([
    {
      name: lang == "en" ? "Intro" : "简介",
      ref: useRef(),
      id: "intro",
      hidden: false,
    },
    {
      name: lang == "en" ? "Contact us" : "社交媒体",
      ref: useRef(),
      id: "contact",
      hidden: false,
    },
    {
      name: lang == "en" ? "Connect with us" : "联系我们",
      ref: useRef(),
      id: "connect",
      hidden: false,
    },
    {
      name: lang == "en" ? "Articles" : "文章",
      ref: useRef(),
      id: "article",
      hidden: false,
    },
    {
      name: lang == "en" ? "Posts" : "动态",
      ref: useRef(),
      id: "post",
      hidden: false,
    },
    {
      name: lang == "en" ? "Events" : "活动",
      ref: useRef(),
      id: "event",
      hidden: false,
    },
  ]);

  //wechat
  const [wechatShow, setWechatShow] = useState(false);
  const [qrString, setQRString] = useState("");

  const handleWechatClose = () => setWechatShow(false);
  const handleWechatShow = () => setWechatShow(true);

  //pager component
  let pagerMethods = null;
  const next_page_handle = () => {
    if (pagerMethods !== null) pagerMethods.next();
  };
  const previous_page_handle = () => {
    if (pagerMethods !== null) pagerMethods.previous();
  };
  const set_page_handle = (pageIndex) => {
    if (pagerMethods !== null) pagerMethods.setPage(pageIndex);
  };

  var currentUA = "";

  function configPostStyle(post) {
    var feedbacks = [];
    var youtube = [];
    var gallery = [];
    var userArticles = [];
    var userPosts = [];
    var userEvents = [];
    var voices = [];
    var navigation = false;
    var voice = false;

    var bottomBar = [];
    if (post.style != null) {
      if (post.style.feedback != null) {
        if (post.style.feedback) {
          if (post.style.feedbacks != null) {
            post.style.feedbacks.forEach((feedB) => {
              feedbacks.push({ title: "dd" });
            });
          }
        }
      }
      if (post.style.youtube != null) {
        if (post.style.youtube) {
          if (post.style.youtubeId != null) {
            youtube.push(post.style.youtubeId);
          }
        }
      }
      if (post.style.gallery != null) {
        if (post.style.gallery) {
          if (post.style.photos != null) {
            post.style.photos.forEach((photo) => {
              gallery.push(photo);
            });
          }
        }
      }
      if (post.style.navigation != null) {
        navigation = post.style.navigation;
      } else {
        navigation = false;
      }

      if (post.style.voice != null) {
        voice = post.style.voice;
        if (post.voiceURL != null) {
          if (post.voiceURL != "") {
            voices.push(post.voiceURL);
          }
        }
      } else {
        voice = false;
      }

      if (post.style.article != null) {
        if (post.style.article) {
          userArticles.push("");
        } else {
          userArticles = [];
        }
      } else {
        userArticles = [];
      }

      if (post.style.event != null) {
        if (post.style.event) {
          userEvents.push("");
        } else {
          userEvents = [];
        }
      } else {
        userEvents = [];
      }
      setStyles({
        feedbacks: feedbacks,
        youtube: youtube,
        gallery: gallery,
        articles: userArticles,
        events: userEvents,
        posts: userPosts,
        navigation: navigation,
        voice: voice,
        voices: voices,
      });

      lang = post.lang;
      setNavDetails([
        {
          name: lang == "en" ? "Intro" : "简介",
          ref: navDetails[0].ref,
          id: "intro",
          hidden: false,
        },
        {
          name: lang == "en" ? "Contact us" : "社交媒体",
          ref: navDetails[1].ref,
          id: "contact",
          hidden: false,
        },
        {
          name: lang == "en" ? "Connect with us" : "联系我们",
          ref: navDetails[2].ref,
          id: "connect",
          hidden: feedbacks.length == 0,
        },
        {
          name: lang == "en" ? "Articles" : "文章",
          ref: navDetails[3].ref,
          id: "article",
          hidden: userArticles.length == 0,
        },
        {
          name: lang == "en" ? "Posts" : "动态",
          ref: navDetails[4].ref,
          id: "post",
          hidden: userPosts.length == 0,
        },
        {
          name: lang == "en" ? "Events" : "活动",
          ref: navDetails[5].ref,
          id: "event",
          hidden: userEvents.length == 0,
        },
      ]);
    } else {
      setStyles({
        feedbacks: feedbacks,
        youtube: youtube,
        gallery: gallery,
        articles: userArticles,
        events: userEvents,
        posts: userPosts,
        navigation: navigation,
        voice: voice,
        voices: voices,
      });
    }
  }

  async function handleContactFormSubmit(e) {
    e.preventDefault();
    var emailVal = e.target[0].value;
    var nameVal = e.target[1].value;
    var contentVal = e.target[2].value;

    var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

    try {
      await fires
        .collection("groups")
        .doc(id)
        .collection("feedbacks")
        .add({
          name: nameVal,
          email: emailVal,
          content: contentVal,
          timestamp: timestampNow,
        });
      var email_subject = "有一位来自乐源的访客发来了信息";
      var email_content =
        "电子邮箱: " +
        emailVal +
        "\n姓名: " +
        nameVal +
        "\n内容: " +
        contentVal +
        "\n\n" +
        "请您直接发邮件联系";
      if (posts.length > 0) {
        if (posts[0].info.email != null) {
          sendEmailTo(posts[0].info.email, email_subject, email_content);
        }
      }
      setSuccess("提交成功，我们会联系您");
      e.target[0].value = "";
      e.target[1].value = "";
      e.target[2].value = "";
    } catch {
      setError("提交失败，请重试");
    }
  }

  const routeRedirect404 = () => {
    let path = "/404";
    window.location.href = path;
  };

  function onClickLink(contactL) {
    var ua = window.navigator.userAgent.toLowerCase();
    currentUA = ua;

    var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    const platformName = "web";
    var deviceName = "ios";
    if (isAndroid) {
      deviceName = "android";
    } else if (isIOS) {
      deviceName = "ios";
    } else {
      deviceName = "web";
    }
    var fuid = "";
    if (auth.currentUser != null) {
      fuid = auth.currentUser.uid;
    }
    const viewData = {
      fromUID: fuid,
      type: "link",
      linkId: contactL.url,
      fromUA: ua,
      platform: platformName,
      device: deviceName,
      timestamp: timestampNow,
    };
    fires.collection("groups").doc(id).collection("views").add(viewData);
    window.location.href = contactL.url;
    //window.open(contactL.url)
  }

  useEffect(() => {
    fires
      .collection("groups")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const posts = [];
          const postData = snapshot.data();
          const uid = postData.fromUID;
          if (postData.live != null) {
            if (postData.live) {
              // live user
              // add views
              var ua = window.navigator.userAgent.toLowerCase();
              currentUA = ua;
              var timestampNow = firebase.firestore.Timestamp.fromDate(
                new Date()
              );
              const platformName = "web";
              var deviceName = "ios";
              if (isAndroid) {
                deviceName = "android";
              } else if (isIOS) {
                deviceName = "ios";
              } else {
                deviceName = "web";
              }
              var fuid = "";
              if (auth.currentUser != null) {
                fuid = auth.currentUser.uid;
                if (fuid != "") {
                  fires
                    .collection("users")
                    .doc(fuid)
                    .collection("visits")
                    .add({ orgId: id, fromUA: ua, timestamp: timestampNow });
                }
              }
              const viewData = {
                fromUID: fuid,
                type: "page",
                fromUA: ua,
                platform: platformName,
                device: deviceName,
                timestamp: timestampNow,
              };
              fires
                .collection("groups")
                .doc(id)
                .collection("views")
                .add(viewData);

              if (postData.length == 0) {
                posts.push({
                  id: id,
                  error: "NoAccess",
                  error_message: "没有访问权限",
                  error_code: 404,
                });
                setPosts(posts);
              } else {
                posts.push({ ...postData, id: id });
                setPosts(posts);

                configPostStyle(postData);

                fires
                  .collection("posts")
                  .where("orgId", "==", id)
                  .orderBy("timestamp", "desc")
                  .get()
                  .then((snapshotA) => {
                    var orgPosts = [];
                    snapshotA.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgPosts.push({ ...contentData, cid: contentId });
                    });
                    setOrgPosts(orgPosts);
                  });
                fires
                  .collection("exps")
                  .where("orgId", "==", id)
                  .orderBy("timestamp", "desc")
                  .get()
                  .then((snapshotA) => {
                    var orgExps = [];
                    snapshotA.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      if (contentData.status == "live") {
                        orgExps.push({ ...contentData, cid: contentId });
                      }
                    });
                    setOrgExps(orgExps);
                  });

                fires
                  .collection("events")
                  .where("orgId", "==", id)
                  .orderBy("timestamp", "desc")
                  .get()
                  .then((snapshotA) => {
                    var orgEvents = [];
                    snapshotA.docs.forEach((doc) => {
                      const eventData = doc.data();
                      orgEvents.push({ ...eventData });
                    });
                    setOrgEvents(orgEvents);
                  });
              }
            } else {
              //not live
              routeRedirect404();
              // posts.push({ id: id, error: "NotLive", error_message: "没有访问权限", error_code: 404 })
              // setPosts(posts)
            }
          } else {
            //not found
            //deleted
            routeRedirect404();
            // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
            // setPosts(posts)
          }
        } else {
          //deleted
          routeRedirect404();
          // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
          // setPosts(posts)
        }
      });
  }, []);

  var vCard = function () {
    var start = "BEGIN:VCARD\nVERSION:3.0";
    var end = "END:VCARD";
    var data = "";

    var init = function () {
      data = "";
    };
    var name = function (surname, lastname) {
      data += "N:" + lastname + ";" + surname;
      data += "\n";
    };

    var cell = function (cell) {
      data += "TEL;TYPE=CELL:" + cell;
      data += "\n";
    };

    var work = function (work) {
      data += "TEL;TYPE=WORK,VOICE:" + work;
      data += "\n";
    };

    var other = function (work) {
      data += "TEL;TYPE=OTHER:" + work;
      data += "\n";
    };

    var email = function (email) {
      data += "EMAIL;TYPE=PREF,INTERNET:" + email;
      data += "\n";
    };

    var company = function (companyName) {
      data += "ORG;CHARSET=UTF-8:" + companyName;
      data += "\n";
    };

    var get = function () {
      return start + "\n" + data + end;
    };

    return {
      init: init,
      name: name,
      cell: cell,
      work: work,
      other: other,
      email: email,
      company: company,
      get: get,
    };
  };

  function downloadToFile(content, filename, contentType) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });

    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();

    URL.revokeObjectURL(a.href);
  }

  function sendEmailTo(email, messageSubject, messageTexts) {
    const messageObj = {
      subject: messageSubject,
      text: messageTexts,
    };
    fires
      .collection("mail")
      .add({
        to: [email],
        message: messageObj,
      })
      .then((docRef) => {
        const sentId = docRef.id;
      });
  }

  return (
    <div>
      {posts.map((post) => {
        var error = post.error;
        var coverImgURL = "";
        var profileURL = "";
        var professionalType = "";
        var orgContacts = [];

        var expCount = 0;
        var postCount = 0;
        expCount = orgExps.length;
        postCount = orgPosts.length;
        var locationStr = "";
        var countryStr = "";

        var expItems = [];
        var postItems = [];

        const opts = {
          height: isMobile ? "200px" : "300px",
          width: "100%",
          playerVars: {
            autoplay: 0,
          },
        };

        const galleryStyle = {
          marginTop: "50px",
          borderRadius: "50px",
          borderWidth: "3px",
          borderColor: "transparent",
          height: isMobile ? "200px" : "300px",
          width: "100%",
        };

        if (post.coverURL == null) {
          coverImgURL =
            "url(https://images.unsplash.com/photo-1487088678257-3a541e6e3922?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80)";
        } else {
          coverImgURL = "url(" + post.coverURL + ")";
        }
        profileURL = post.profileURL;
        professionalType = post.typeCN;

        if (profileURL != "") {
          let link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
          }
          link.href = profileURL;
        }

        if (post.lang != null) {
          lang = post.lang;
        }

        if (post.contacts != null) {
          orgContacts = post.contacts;
        }

        if (post.location != null) {
          locationStr = post.location;
        }
        if (post.country != null) {
          countryStr = post.country;
        }

        //configure styles

        if (error == null) {
          if (isMobile) {
            return (
              <>
                <Helmet>
                  <title>{post.name}</title>
                  <meta property="og:title" content={post.name} />
                  <meta property="og:description" content={post.description} />
                  <meta
                    property="og:url"
                    content={"https://letjoy.app/" + post.id}
                  />
                  <meta property="og:site_name" content={post.name} />
                  <meta property="og:locale" content="en_US" />
                  <meta property="og:type" content="article" />
                  <meta property="og:image" content={post.profileURL} />
                </Helmet>
                <Container
                  className="d-flex justify-content-center"
                  style={{ minHeight: "100vh", backgroundImage: coverImgURL }}
                >
                  <Card
                    className="border-0 align-items-center justify-content-center"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "15px!important",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Card.Img
                      className="rounded-circle shadow-4-strong"
                      variant="top"
                      src={post.profileURL}
                      style={{
                        height: "100px",
                        width: "100px",
                        marginTop: "25px",
                        borderColor: "#ffffff",
                      }}
                      ref={navDetails[0].ref}
                    />
                    <Card.Body className="align-items-center justify-content-center">
                      <Card.Title
                        className="text-center"
                        style={{
                          fontSize: "40px",
                          fontWeight: "bold",
                          color: "#ffffff",
                        }}
                      >
                        {post.name}
                      </Card.Title>
                      <div className="d-flex justify-content-center">
                        <Card.Text
                          className="text-center"
                          style={{
                            marginTop: "15px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#000000",
                            padding: "8px",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                        >
                          {professionalType}
                        </Card.Text>
                      </div>

                      <div className="d-flex align-items-center justify-content-center">
                        <ListGroup
                          className="border-0"
                          defaultActiveKey="#1"
                          hidden={!styles.navigation}
                          style={{
                            marginTop: "35px",
                            overflowX: "scroll",
                            whiteSpace: "nowrap",
                            overflow: "scroll",
                            backgroundColor: "transparent",
                          }}
                          horizontal
                        >
                          {navDetails.map((navItm) => {
                            return (
                              <ListGroup.Item
                                className="border-0"
                                action
                                href={"#" + navItm.id}
                                hidden={navItm.hidden}
                                onClick={() => {
                                  navItm.ref.current.scrollIntoView({
                                    behavior: "smooth",
                                    block: "end",
                                  });
                                }}
                              >
                                {navItm.name}
                              </ListGroup.Item>
                            );
                          })}
                        </ListGroup>
                      </div>

                      {styles.youtube.map((youtubeId) => {
                        return (
                          <Youtube
                            videoId={youtubeId}
                            opts={opts}
                            style={{ marginTop: "50px", borderRadius: "50px" }}
                          />
                        );
                      })}

                      <Carousel
                        variant="top"
                        hidden={styles.gallery.length == 0}
                        style={galleryStyle}
                      >
                        {styles.gallery.map((galleryItm) => {
                          return (
                            <Carousel.Item interval={2000}>
                              <img
                                src={galleryItm}
                                alt="-"
                                style={{
                                  height: galleryStyle.height,
                                  width: galleryStyle.width,
                                  objectFit: "cover",
                                }}
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                      <Card.Text
                        style={{
                          marginTop: "35px",
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#000000",
                          backgroundColor: "#ffffff",
                          padding: "8px",
                          borderRadius: "12px",
                          textAlign: "start",
                        }}
                        ref={navDetails[1].ref}
                        hidden={post.description == ""}
                      >
                        {post.description}
                      </Card.Text>

                      {orgContacts.map((contact) => {
                        return (
                          <Button
                            className="w-100 mt3"
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                              backgroundColor: "transparent",
                              borderRadius: "25px",
                              borderColor: "#ffffff",
                              borderWidth: "2px",
                              fontSize: "20px",
                              fontWeight: "bold",
                              minHeight: "50px",
                              marginTop: "15px",
                            }}
                            onClick={() => onClickLink(contact)}
                          >
                            {contact.name}
                          </Button>
                        );
                      })}

                      <Form
                        hidden={styles.feedbacks.length == 0}
                        onSubmit={handleContactFormSubmit}
                        style={{
                          marginTop: "50px",
                          padding: "15px",
                          borderRadius: "25px",
                          borderColor: "white",
                          borderWidth: "3px",
                          padding: "10px",
                        }}
                        ref={navDetails[2].ref}
                      >
                        <h5
                          className="text-center mb-4"
                          style={{ color: "white" }}
                        >
                          联系我们
                        </h5>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {success && <Alert variant="success">{success}</Alert>}
                        <Form.Group id="email" style={{ marginTop: "15px" }}>
                          <Form.Label
                            style={{
                              color: "white",
                              fontSize: "17px",
                              fontWeight: "bold",
                            }}
                          >
                            电子邮箱
                          </Form.Label>
                          <Form.Control
                            style={{
                              backgroundColor: "transparent",
                              borderWidth: "3px",
                              color: "white",
                              height: "50px",
                              borderRadius: "25px",
                            }}
                            type="email"
                            placeholder="请输入您的电子邮箱"
                            required
                          />
                        </Form.Group>
                        <Form.Group id="name" style={{ marginTop: "15px" }}>
                          <Form.Label
                            style={{
                              color: "white",
                              fontSize: "17px",
                              fontWeight: "bold",
                            }}
                          >
                            姓名
                          </Form.Label>
                          <Form.Control
                            style={{
                              backgroundColor: "transparent",
                              borderWidth: "3px",
                              color: "white",
                              height: "50px",
                              borderRadius: "25px",
                            }}
                            type="text"
                            placeholder="请输入您的名字"
                            required
                          />
                        </Form.Group>
                        <Form.Group id="content" style={{ marginTop: "15px" }}>
                          <Form.Label
                            style={{
                              color: "white",
                              fontSize: "17px",
                              fontWeight: "bold",
                            }}
                          >
                            请输入内容
                          </Form.Label>
                          <Form.Control
                            style={{
                              backgroundColor: "transparent",
                              borderWidth: "3px",
                              color: "white",
                              height: "120px",
                              borderRadius: "25px",
                            }}
                            as="textarea"
                            rows={10}
                            required
                          />
                        </Form.Group>
                        <Button
                          className="w-100 p3"
                          type="submit"
                          style={{
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: "#111",
                            borderRadius: "25px",
                            borderColor: "#ffffff",
                            borderWidth: "3px",
                            fontSize: "15px",
                            fontWeight: "bold",
                            height: "50px",
                            marginTop: "15px",
                          }}
                        >
                          提交
                        </Button>
                      </Form>

                      <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: "15px" }}
                        hidden={styles.articles.length == 0}
                      >
                        <Card.Text
                          className="text-center"
                          style={{
                            marginTop: "5px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#000000",
                            padding: "8px",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                          hidden={styles.articles.length == 0}
                          ref={navDetails[3].ref}
                        >
                          {lang == "en" ? "Articles" : "文章"}
                        </Card.Text>
                      </div>
                      <div hidden={styles.articles.length == 0}>
                        {orgExps.map((expItm) => {
                          return (
                            <Card
                              className="align-items-start"
                              style={{
                                marginTop: "15px",
                                marginBottom: "15px",
                                padding: "10px",
                              }}
                              onClick={() => {
                                window.location.href = "/exp/" + expItm.id;
                              }}
                            >
                              <div className="d-flex justify-content-between align-items-start">
                                <div className="fw-bold">{expItm.title}</div>
                              </div>
                              <div className="fw-normal" alignRight>
                                {expItm.viewCount + " 阅读"}
                              </div>
                              <div
                                className="fw-normal"
                                style={{ marginTop: "5px" }}
                              >
                                发布于
                                {expItm.timestamp
                                  .toDate()
                                  .toLocaleDateString("zh")}
                              </div>
                            </Card>
                          );
                        })}
                      </div>

                      <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: "15px" }}
                        hidden={styles.events.length == 0}
                      >
                        <Card.Text
                          className="text-center"
                          style={{
                            marginTop: "5px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#000000",
                            padding: "8px",
                            backgroundColor: "#ffffff",
                            borderRadius: "12px",
                          }}
                          hidden={styles.events.length == 0}
                          ref={navDetails[5].ref}
                        >
                          {lang == "en"
                            ? "Events(" + orgEvents.length + ")"
                            : "活动(" + orgEvents.length + ")"}
                        </Card.Text>
                      </div>
                      <div hidden={styles.events.length == 0}>
                        {orgEvents.map((eventItm) => {
                          var eventLive = false;
                          var nowD = new Date();
                          if (eventItm.endDate != null) {
                            if (eventItm.endDate.toDate() < nowD) {
                              eventLive = false;
                            } else {
                              eventLive = true;
                            }
                          }
                          if (eventItm.status == "ended") {
                            eventLive = false;
                          }

                          return (
                            <Card
                              className="border-0 justify-content-center"
                              style={{
                                marginTop: "25px",
                                backgroundColor: "#ffffff",
                                borderRadius: "15px",
                              }}
                            >
                              <Card.Img
                                variant="top"
                                style={{ maxHeight: "250px" }}
                                src={eventItm.photoURL}
                              />
                              <Card.Title
                                className="text-center"
                                style={{
                                  margin: "15px",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                {eventItm.title}
                                {eventLive ? "(正在进行)" : "(已完结)"}
                              </Card.Title>
                              <div
                                className="justify-content-center align-items-center"
                                style={{ alignSelf: "center", margin: "15px" }}
                              >
                                <Badge
                                  bg={eventLive ? "success" : "danger"}
                                  pill
                                >
                                  {eventLive ? "正在进行" : "已完结"}
                                </Badge>
                              </div>
                              <Card.Text
                                className="text-center"
                                style={{
                                  marginLeft: "15px",
                                  marginRight: "15px",
                                  fontSize: "15px",
                                  fontWeight: "normal",
                                }}
                              >
                                {eventItm.description}
                              </Card.Text>
                              <Card.Text
                                style={{
                                  marginLeft: "15px",
                                  marginRight: "15px",
                                  fontSize: "15px",
                                  fontWeight: "normal",
                                }}
                              >
                                活动开始:{" "}
                                {eventItm.startDate
                                  .toDate()
                                  .toLocaleString("zh")}
                              </Card.Text>
                              <Card.Text
                                style={{
                                  marginLeft: "15px",
                                  marginRight: "15px",
                                  fontSize: "15px",
                                  fontWeight: "normal",
                                }}
                              >
                                活动结束:{" "}
                                {eventItm.endDate.toDate().toLocaleString("zh")}
                              </Card.Text>
                              <Card.Text
                                style={{
                                  marginLeft: "15px",
                                  marginRight: "15px",
                                  fontSize: "15px",
                                  fontWeight: "normal",
                                }}
                              >
                                活动地址: {eventItm.address.fullAddress}
                              </Card.Text>
                              <div
                                className="justify-content-center align-items-center"
                                style={{ alignSelf: "center" }}
                              >
                                <Button
                                  style={{
                                    backgroundColor: "transparent",
                                    borderRadius: "25px",
                                    borderColor: "#000000",
                                    borderWidth: "2px",
                                    fontSize: "20px",
                                    fontWeight: "normal",
                                    height: "50px",
                                    marginTop: "25px",
                                    marginBottom: "15px",
                                    color: "black",
                                    width: "200px",
                                  }}
                                  onClick={() => {
                                    window.location.href =
                                      "https://events.letjoy.app/" +
                                      eventItm.id;
                                  }}
                                >
                                  查看活动
                                </Button>
                              </div>
                            </Card>
                          );
                        })}
                      </div>

                      <Modal show={wechatShow} onHide={handleWechatClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>微信打开二维码</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="justify-content-center align-items-center">
                          {alertSuccess && (
                            <Alert variant="success">{alertSuccess}</Alert>
                          )}
                          <QRCodeSVG
                            id="qrcode-svg"
                            value={qrString}
                            size={128}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                            imageSettings={{
                              src: post.profileURL,
                              height: 24,
                              width: 24,
                              excavate: true,
                            }}
                          />
                          <div
                            className="fw-bold text-center"
                            style={{ marginTop: "15px" }}
                          >
                            您可以复制微信号或保存二维码
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="primary"
                            onClick={() => {
                              if (post.info.wechat != null) {
                                navigator.clipboard.writeText(post.info.wechat);
                                setAlertSuccess("微信号已复制，请打开微信搜索");
                              } else {
                                if (post.info.phone != null) {
                                  navigator.clipboard.writeText(
                                    post.info.phone
                                  );
                                  setAlertSuccess(
                                    "手机号已复制，请打开微信搜索"
                                  );
                                }
                              }
                            }}
                          >
                            复制微信号
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={handleWechatClose}
                          >
                            关闭
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Card.Body>
                  </Card>
                </Container>
                <Navbar bg="dark" variant="dark" expand="lg" sticky="bottom">
                  <Container fluid>
                    <Navbar.Brand>{post.name}</Navbar.Brand>
                    <Button
                      id="downloadEL"
                      variant="outline-success"
                      onClick={() => {
                        var vC = vCard();
                        vC.init();
                        if (post.info != null) {
                          vC.name(post.info.firstName, post.info.lastName);
                          vC.email(post.info.email);
                          vC.cell(post.info.phone);
                          vC.company(post.info.work);
                          var vString = vC.get();

                          var ua = window.navigator.userAgent.toLowerCase();
                          if (ua.includes("micromessenger")) {
                            setQRString(vString);
                            setWechatShow(true);
                          } else {
                            downloadToFile(vString, "vcard.vcf", "text/vcard");
                          }
                        }
                      }}
                    >
                      {lang == "en" ? "Add Contact" : "添加到通讯录"}
                    </Button>
                  </Container>
                </Navbar>
              </>
            );
          } else {
            return (
              <div>
                <Helmet>
                  <title>{post.name}</title>
                  <meta property="og:title" content={post.name} />
                  <meta property="og:description" content={post.description} />
                  <meta
                    property="og:url"
                    content={"https://letjoy.app/" + post.id}
                  />
                  <meta property="og:site_name" content={post.name} />
                  <meta property="og:locale" content="en_US" />
                  <meta property="og:type" content="article" />
                  <meta property="og:image" content={post.profileURL} />
                </Helmet>
                <Container
                  className="d-flex justify-content-center"
                  style={{
                    minWidth: "100vw",
                    minHeight: "100vh",
                    backgroundImage: coverImgURL,
                  }}
                >
                  <div className="w-100" style={{ maxWidth: maxW }}>
                    <Card
                      className="border-0 align-items-center justify-content-center"
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "15px!important",
                        backgroundColor: "transparent",
                      }}
                    >
                      <Card.Img
                        className="rounded-circle shadow-4-strong"
                        variant="top"
                        src={post.profileURL}
                        style={{
                          height: "100px",
                          width: "100px",
                          marginTop: "50px",
                          borderColor: "#ffffff",
                        }}
                        ref={navDetails[0].ref}
                      />
                      <Card.Body className="align-items-center justify-content-center">
                        <Card.Title
                          className="text-center"
                          style={{
                            fontSize: "35px",
                            fontWeight: "bold",
                            color: "#ffffff",
                          }}
                        >
                          {post.name}
                        </Card.Title>

                        <div className="d-flex justify-content-center">
                          <Card.Text
                            className="text-center"
                            style={{
                              marginTop: "5px",
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#000000",
                              padding: "8px",
                              backgroundColor: "#ffffff",
                              borderRadius: "12px",
                            }}
                            ref={navDetails[1].ref}
                          >
                            {professionalType}
                          </Card.Text>
                        </div>

                        <div className="d-flex align-items-center justify-content-center">
                          <ListGroup
                            className="border-0"
                            defaultActiveKey="#1"
                            hidden={!styles.navigation}
                            style={{
                              marginTop: "35px",
                              overflowX: "scroll",
                              whiteSpace: "nowrap",
                              overflow: "scroll",
                              backgroundColor: "transparent",
                            }}
                            horizontal
                          >
                            {navDetails.map((navItm) => {
                              return (
                                <ListGroup.Item
                                  className="border-0"
                                  action
                                  href={"#" + navItm.id}
                                  hidden={navItm.hidden}
                                  onClick={() => {
                                    navItm.ref.current.scrollIntoView({
                                      behavior: "smooth",
                                      block: "end",
                                    });
                                  }}
                                >
                                  {navItm.name}
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </div>

                        <Card.Text
                          style={{
                            marginTop: "35px",
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "#000000",
                            backgroundColor: "#ffffff",
                            padding: "8px",
                            borderRadius: "12px",
                            textAlign: "start",
                          }}
                          ref={navDetails[0].ref}
                          hidden={post.description == ""}
                        >
                          {post.description}
                        </Card.Text>

                        {styles.youtube.map((youtubeId) => {
                          return (
                            <Youtube
                              videoId={youtubeId}
                              opts={opts}
                              style={{
                                marginTop: "50px",
                                borderRadius: "50px",
                              }}
                            />
                          );
                        })}

                        <Carousel
                          variant="top"
                          hidden={styles.gallery.length == 0}
                          style={galleryStyle}
                        >
                          {styles.gallery.map((galleryItm) => {
                            return (
                              <Carousel.Item interval={2000}>
                                <img
                                  className="ratio ratio-4x3"
                                  src={galleryItm}
                                  alt="-"
                                  style={{
                                    height: galleryStyle.height,
                                    width: galleryStyle.width,
                                    objectFit: "cover",
                                  }}
                                />
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>

                        <div style={{ marginTop: "50px" }}>
                          {orgContacts.map((contact) => {
                            return (
                              <Button
                                className="w-100 p3"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  backgroundColor: "transparent",
                                  borderRadius: "25px",
                                  borderColor: "#ffffff",
                                  borderWidth: "2px",
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  minHeight: "50px",
                                  marginTop: "15px",
                                }}
                                onClick={() => onClickLink(contact)}
                              >
                                {contact.name}
                              </Button>
                            );
                          })}
                        </div>

                        <Form
                          hidden={styles.feedbacks.length == 0}
                          onSubmit={handleContactFormSubmit}
                          style={{
                            marginTop: "50px",
                            padding: "15px",
                            borderRadius: "25px",
                            borderColor: "white",
                            borderWidth: "3px",
                            padding: "10px",
                          }}
                          ref={navDetails[2].ref}
                        >
                          <h5
                            className="text-center mb-4"
                            style={{ color: "white" }}
                          >
                            联系我们
                          </h5>
                          {error && <Alert variant="danger">{error}</Alert>}
                          {success && (
                            <Alert variant="success">{success}</Alert>
                          )}
                          <Form.Group id="email" style={{ marginTop: "15px" }}>
                            <Form.Label
                              style={{
                                color: "white",
                                fontSize: "17px",
                                fontWeight: "bold",
                              }}
                            >
                              电子邮箱
                            </Form.Label>
                            <Form.Control
                              style={{
                                backgroundColor: "transparent",
                                borderWidth: "3px",
                                color: "white",
                                height: "50px",
                                borderRadius: "25px",
                              }}
                              type="email"
                              placeholder="请输入您的电子邮箱"
                              required
                            />
                          </Form.Group>
                          <Form.Group id="name" style={{ marginTop: "15px" }}>
                            <Form.Label
                              style={{
                                color: "white",
                                fontSize: "17px",
                                fontWeight: "bold",
                              }}
                            >
                              姓名
                            </Form.Label>
                            <Form.Control
                              style={{
                                backgroundColor: "transparent",
                                borderWidth: "3px",
                                color: "white",
                                height: "50px",
                                borderRadius: "25px",
                              }}
                              type="text"
                              placeholder="请输入您的名字"
                              required
                            />
                          </Form.Group>
                          <Form.Group
                            id="content"
                            style={{ marginTop: "15px" }}
                          >
                            <Form.Label
                              style={{
                                color: "white",
                                fontSize: "17px",
                                fontWeight: "bold",
                              }}
                            >
                              请输入内容
                            </Form.Label>
                            <Form.Control
                              style={{
                                backgroundColor: "transparent",
                                borderWidth: "3px",
                                color: "white",
                                height: "120px",
                                borderRadius: "25px",
                              }}
                              as="textarea"
                              rows={10}
                              required
                            />
                          </Form.Group>
                          <Button
                            className="w-100 p3"
                            type="submit"
                            style={{
                              alignItems: "center",
                              justifyContent: "space-between",
                              backgroundColor: "#111",
                              borderRadius: "25px",
                              borderColor: "#ffffff",
                              borderWidth: "3px",
                              fontSize: "15px",
                              fontWeight: "bold",
                              minHeight: "50px",
                              marginTop: "15px",
                            }}
                          >
                            提交
                          </Button>
                        </Form>

                        <div
                          className="d-flex justify-content-center"
                          style={{ marginTop: "15px" }}
                          hidden={styles.articles.length == 0}
                        >
                          <Card.Text
                            className="text-center"
                            style={{
                              marginTop: "5px",
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#000000",
                              padding: "8px",
                              backgroundColor: "#ffffff",
                              borderRadius: "12px",
                            }}
                            hidden={styles.articles.length == 0}
                            ref={navDetails[3].ref}
                          >
                            {lang == "en" ? "Articles" : "文章"}
                          </Card.Text>
                        </div>
                        <div hidden={styles.articles.length == 0}>
                          {orgExps.map((expItm) => {
                            return (
                              <Card
                                className="align-items-start"
                                style={{
                                  marginTop: "15px",
                                  marginBottom: "15px",
                                  padding: "10px",
                                }}
                                onClick={() => {
                                  window.location.href = "/exp/" + expItm.id;
                                }}
                              >
                                <div className="d-flex justify-content-between align-items-start">
                                  <div className="fw-bold">{expItm.title}</div>
                                </div>
                                <div className="fw-normal" alignRight>
                                  {"阅读量 " + expItm.viewCount}
                                </div>
                                <div
                                  className="fw-normal"
                                  style={{ marginTop: "5px" }}
                                >
                                  发布于
                                  {expItm.timestamp
                                    .toDate()
                                    .toLocaleDateString("zh")}
                                </div>
                              </Card>
                            );
                          })}
                        </div>

                        <div
                          className="d-flex justify-content-center"
                          style={{ marginTop: "15px" }}
                        >
                          <Card.Text
                            className="text-center"
                            style={{
                              marginTop: "5px",
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#000000",
                              padding: "8px",
                              backgroundColor: "#ffffff",
                              borderRadius: "12px",
                            }}
                            hidden={styles.events.length == 0}
                            ref={navDetails[5].ref}
                          >
                            {lang == "en" ? "Events" : "活动"}
                          </Card.Text>
                        </div>
                        <div hidden={styles.events.length == 0}>
                          {orgEvents.map((eventItm) => {
                            var eventLive = false;
                            var nowD = new Date();
                            if (eventItm.endDate != null) {
                              if (eventItm.endDate.toDate() < nowD) {
                                eventLive = false;
                              } else {
                                eventLive = true;
                              }
                            }
                            if (eventItm.status == "ended") {
                              eventLive = false;
                            }

                            return (
                              <Card
                                className="border-0 justify-content-center"
                                style={{
                                  marginTop: "25px",
                                  backgroundColor: "#ffffff",
                                  borderRadius: "15px",
                                }}
                              >
                                <Card.Img
                                  variant="top"
                                  style={{ maxHeight: "250px" }}
                                  src={eventItm.photoURL}
                                />
                                <Card.Title
                                  className="text-center"
                                  style={{
                                    margin: "15px",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {eventItm.title}
                                  {eventLive ? "(正在进行)" : "(已完结)"}
                                </Card.Title>
                                <div
                                  className="justify-content-center align-items-center"
                                  style={{
                                    alignSelf: "center",
                                    margin: "15px",
                                  }}
                                >
                                  <Badge
                                    bg={eventLive ? "success" : "danger"}
                                    pill
                                  >
                                    {eventLive ? "正在进行" : "已完结"}
                                  </Badge>
                                </div>
                                <Card.Text
                                  className="text-center"
                                  style={{
                                    marginLeft: "15px",
                                    marginRight: "15px",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {eventItm.description.substring(0, 200)}
                                </Card.Text>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginRight: "15px",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  活动开始:{" "}
                                  {eventItm.startDate
                                    .toDate()
                                    .toLocaleString("zh")}
                                </Card.Text>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginRight: "15px",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  活动结束:{" "}
                                  {eventItm.endDate
                                    .toDate()
                                    .toLocaleString("zh")}
                                </Card.Text>
                                <Card.Text
                                  style={{
                                    marginLeft: "15px",
                                    marginRight: "15px",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  活动地址: {eventItm.address.fullAddress}
                                </Card.Text>

                                <div
                                  className="justify-content-center align-items-center"
                                  style={{ alignSelf: "center" }}
                                >
                                  <Button
                                    style={{
                                      backgroundColor: "transparent",
                                      borderRadius: "25px",
                                      borderColor: "#000000",
                                      borderWidth: "2px",
                                      fontSize: "20px",
                                      fontWeight: "normal",
                                      height: "50px",
                                      marginTop: "25px",
                                      marginBottom: "15px",
                                      color: "black",
                                      width: "200px",
                                    }}
                                    onClick={() => {
                                      window.location.href =
                                        "https://events.letjoy.app/" +
                                        eventItm.id;
                                    }}
                                  >
                                    查看活动
                                  </Button>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Container>
              </div>
            );
          }
        } else {
          if (error == "NotFound") {
            return (
              <div>
                <Helmet>
                  <title>Not Found</title>
                  <meta name="description" content="" />
                </Helmet>
              </div>
            );
          } else if (error == "NoAccess") {
            return (
              <div>
                <Helmet>
                  <title>Permission Denied</title>
                  <meta name="description" content="" />
                </Helmet>
              </div>
            );
          }
        }
      })}
    </div>
  );
}
