import React, { useState, useEffect } from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { fires } from "../firebase";
import { useParams, Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { SocialIcon } from "react-social-icons";

export default function UserProfile() {
  const [posts, setPosts] = useState([]);
  let { id } = useParams();
  const maxW = isMobile ? "100%" : "60%";

  useEffect(() => {
    return fires
      .collection("userIDs")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const posts = [];
          const postData = snapshot.data();
          if (postData.length === 0) {
            posts.push({
              id,
              error: "NoAccess",
              error_message: "没有访问权限",
              error_code: 404,
            });
            setPosts(posts);
            document.title = "没有访问权限";
            document.description = "请您查看是否具有访问权限";
          } else {
            posts.push({ ...postData, id: id });
            setPosts(posts);
            document.title = postData.userName;
            document.description = postData.userName;
          }
          return;
        }
        //deleted
        posts.push({
          id,
          error: "NotFound",
          error_message: "没有访问权限",
          error_code: 404,
        });
        setPosts(posts);
        document.title = "没有访问权限";
        document.description = "请您查看是否具有访问权限";
      });
  }, []);

  return (
    <div>
      {posts.map((post) => {
        const error = post.error;
        let coverImgURL = "";
        coverImgURL =
          post.coverURL == null
            ? "url(https://images.unsplash.com/photo-1487088678257-3a541e6e3922?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80)"
            : `url(${post.coverURL})`;

        if (error == null) {
          return isMobile ? (
            <Container
              className="d-flex justify-content-center"
              style={{ minHeight: "100vh", backgroundImage: coverImgURL }}
            >
              <Card
                className="border-0 align-items-center justify-content-center"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "15px!important",
                  backgroundColor: "transparent",
                }}
              >
                <Card.Img
                  className="rounded-circle shadow-4-strong"
                  variant="top"
                  src={post.profileURL}
                  style={{
                    height: "100px",
                    width: "100px",
                    marginTop: "25px",
                  }}
                />
                <Card.Body className="align-items-center justify-content-center">
                  <Card.Title
                    className="text-center"
                    style={{
                      fontSize: "40px",
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    {post.userName}
                  </Card.Title>
                  <Card.Text
                    className="text-center"
                    style={{
                      marginTop: "15px",
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#000000",
                      backgroundColor: "#ffffff",
                      padding: "8px",
                      borderRadius: "8px",
                    }}
                  >
                    {post.userIntro}
                  </Card.Text>

                  <Button
                    className="w-100 mt3"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: "transparent",
                      borderRadius: "20px",
                      borderColor: "#ffffff",
                      fontSize: "15px",
                      fontWeight: "bold",
                      height: "40px",
                      marginTop: "15px",
                    }}
                    onClick={() =>
                      window.open(`https://leyuan.page.link/u/${id}`)
                    }
                  >
                    详细信息
                  </Button>
                </Card.Body>
              </Card>
            </Container>
          ) : (
            <div style={{ backgroundImage: coverImgURL }}>
              <Container
                className="d-flex justify-content-center"
                style={{
                  backgroundImage: coverImgURL,
                  minHeight: "100vh",
                  minWidth: "100vh",
                }}
              >
                <div
                  className="w-100"
                  style={{
                    maxWidth: maxW,
                    borderRadius: "35px!important",
                    backgroundColor: "transparent",
                  }}
                >
                  <Card
                    className="border-0 align-items-center justify-content-center"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "25px",
                      borderRadius: "35px!important",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Card.Img
                      className="rounded-circle shadow-4-strong"
                      variant="top"
                      src={post.profileURL}
                      style={{
                        height: "150px",
                        width: "150px",
                        marginTop: "15px",
                      }}
                    />
                    <Card.Body className="align-items-center justify-content-center">
                      <Card.Title
                        className="text-center"
                        style={{
                          fontSize: "40px",
                          fontWeight: "bold",
                          color: "#ffffff",
                        }}
                      >
                        {post.userName}
                      </Card.Title>
                      <Row md={5} style={{ marginTop: "15px" }}>
                        <Col className="d-flex justify-content-center">
                          <SocialIcon url="https://facebook.com/fb" />
                        </Col>
                        <Col className="d-flex justify-content-center">
                          <SocialIcon url="https://twitter.com/jaketrent" />
                        </Col>
                        <Col className="d-flex justify-content-center">
                          <SocialIcon url="https://twitter.com/jaketrent" />
                        </Col>
                        <Col className="d-flex justify-content-center">
                          <SocialIcon url="https://twitter.com/jaketrent" />
                        </Col>
                        <Col className="d-flex justify-content-center">
                          <SocialIcon url="123456789" />
                        </Col>
                      </Row>
                      <Card.Text
                        className="text-center"
                        style={{
                          marginTop: "15px",
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#000000",
                          backgroundColor: "#ffffff",
                          padding: "8px",
                          borderRadius: "8px",
                        }}
                      >
                        {post.userIntro}
                      </Card.Text>

                      <Button
                        className="w-100 mt3"
                        style={{
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: "transparent",
                          borderRadius: "20px",
                          borderColor: "#ffffff",
                          fontSize: "15px",
                          fontWeight: "bold",
                          height: "40px",
                          marginTop: "15px",
                        }}
                        onClick={() => window.open("https://facebook.com")}
                      >
                        详细信息
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
              </Container>
            </div>
          );
        } else if (error === "NotFound") {
          return <Navigate to="/404" />;
        } else if (error === "NoAccess") {
          return <Navigate to="/404" />;
        }
      })}
    </div>
  );
}
