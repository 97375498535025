import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  ListGroup,
  Accordion,
  Dropdown,
  DropdownButton,
  Tab,
  TabContainer,
  Col,
  Row,
  ButtonGroup,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { fires, auth, storageRef } from "../firebase";
import firebase from "firebase/app";
import uuid from "react-uuid";
import "firebase/firestore";
import validator from "validator";

import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import ReactAudioPlayer from "react-audio-player";
import YouTube from "react-youtube";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function ExperienceEditor() {
  const expTitleRef = useRef();
  const expContentRef = useRef();
  const expAddLinkRef = useRef();
  const expAddYoutubeRef = useRef();
  const expDescriptionRef = useRef();
  const expLinkURLRef = useRef();
  const expCountryRef = useRef();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  //0,1,2
  const [expContentType, setExpContentType] = useState(2);

  const [posts, setPosts] = useState({});
  const [gData, setGData] = useState({});

  const history = useNavigate();
  let { id } = useParams();

  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedExpImage, setSelectedExpImage] = useState(null);
  const [selectedExpImageURL, setSelectedExpImageURL] = useState("");

  const maxW = isMobile ? "100%" : "100%";

  const { currentUser } = useAuth();

  const [images, setImages] = useState([]);
  const [contentList, setContentList] = useState([]);

  var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
  var publishStatus = false;
  const [imgUrl, setImgUrl] = useState(null);
  const [uploadS, setUploadS] = useState("请点击上传图片开始上传");
  const [progresspercent, setProgresspercent] = useState(0);
  const [uploadAudio, setUploadAudio] = useState("请点击录音键开始录音并上传");
  const recorderControls = useAudioRecorder();

  const [contentModify, setContentModify] = useState(false);

  var orgId = "";

  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  const routeBack = () => {
    let path = "/";
    window.location.href = path;
  };
  const routePreview = () => {
    let path = "/exp/";
    window.location.href = path;
  };

  const reloadAllContents = () => {
    var newContentSet = [];
    var newCount = 0;
    contentList.sort((a, b) => {
      return a.id - b.id;
    });
    contentList.forEach((contentItm) => {
      contentItm.id = newCount;
      newContentSet.push({ ...contentItm });
      newCount += 1;
    });
    setContentList(newContentSet);
    setSuccess("文章预览已更新 [" + contentList.length + "]");
  };

  useEffect(() => {
    return fires
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          var users = [];
          const userData = snapshot.data();
          orgId = userData.orgId;
          setPosts({ ...userData });
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotA) => {
              if (snapshotA.exists) {
                var gData = [];
                var groupData = snapshotA.data();
                orgId = groupData.id;
                setGData({ ...groupData });
              }
            });
          if (id != null) {
            if (id != "") {
              //id exist

              fires
                .collection("exps")
                .doc(id)
                .get()
                .then((snapshotB) => {
                  if (snapshotB.exists) {
                    var expId = snapshotB.id;
                    var expData = snapshotB.data();
                    if (expData.orgId != null) {
                      if (expData.orgId == orgId) {
                        expTitleRef.current.value = expData.title;
                        expDescriptionRef.current.value = expData.description;
                        setSelectedExpImageURL(expData.imageURL);
                        fires
                          .collection("exps")
                          .doc(id)
                          .collection("contents")
                          .get()
                          .then((snapshotC) => {
                            var expContents = [];
                            snapshotC.docs.forEach((doc) => {
                              const contentId = doc.id;
                              const contentData = doc.data();
                              expContents.push({
                                ...contentData,
                                activeId: contentId,
                              });
                            });
                            expContents.sort((a, b) => {
                              return a.id - b.id;
                            });
                            setContentList(expContents);
                          });
                      } else {
                        routeBack();
                      }
                    } else {
                      routeBack();
                    }
                  }
                });
            }
          }
        } else {
          routeBack();
        }
      });
  }, []);

  async function handleSubmit() {
    setLoading(false);
    const title = expTitleRef.current.value;
    const description = expDescriptionRef.current.value;
    const fromUID = currentUser.uid;
    const imgURL = selectedExpImageURL;
    timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    var expContent = {
      approved: false,
      contentCount: contentList.length,
      country: "加拿大",
      title: title,
      description: description,
      orgId: gData.id,
      fromUID: fromUID,
      location: "",
      imageURL: imgURL,
      status: "pending",
      user: {
        userName: posts.userName,
        userOrg: gData.name,
        profileURL: gData.profileURL,
      },
      topics: [],
      viewBy: {},
      viewCount: 0,
      timestamp: timestampNow,
      contentTimestamp: timestampNow,
    };
    if (title == "" || contentList.length == 0) {
      setError("无法创建新文章，请输入标题，上传图片和创作内容");
    } else {
      var expId = "";

      if (id == null) {
        const expFSItm = fires.collection("exps").add(expContent);
        expId = (await expFSItm).id;

        contentList.map((contentItm) => {
          var textType = -1;
          if (contentItm.text_type != null) {
            textType = contentItm.text_type;
          }
          fires.collection("exps").doc(expId).collection("contents").add({
            content: contentItm.content,
            id: contentItm.id,
            type: contentItm.type,
            text_type: textType,
            timestamp: timestampNow,
          });
        });

        if (expId != "") {
          var pStatus = "pending";
          if (publishStatus) {
            pStatus = "live";
          }
          fires
            .collection("exps")
            .doc(expId)
            .update({
              shareURL: "https://letjoy.app/exp/" + expId,
              publishTimestamp: timestampNow,
              id: expId,
              timestamp: timestampNow,
              status: pStatus,
            })
            .then((error) => {
              if (error == null) {
                window.location.href = "/";
              } else {
                setError("无法发布");
              }
            });
        }
      } else {
        expId = id;
        fires
          .collection("exps")
          .doc(expId)
          .update({ title: title, description: description, imageURL: imgURL });
        contentList.map((contentItm) => {
          var textType = -1;
          if (contentItm.text_type != null) {
            textType = contentItm.text_type;
          }
          if (contentItm.activeId != null) {
            if (contentItm.activeId != "") {
              fires
                .collection("exps")
                .doc(expId)
                .collection("contents")
                .doc(contentItm.activeId)
                .update({
                  content: contentItm.content,
                  id: contentItm.id,
                  type: contentItm.type,
                  text_type: textType,
                  timestamp: timestampNow,
                });
            }
          } else {
            fires.collection("exps").doc(expId).collection("contents").add({
              content: contentItm.content,
              id: contentItm.id,
              type: contentItm.type,
              text_type: textType,
              timestamp: timestampNow,
            });
          }
        });

        if (expId != "") {
          var pStatus = "pending";
          if (publishStatus) {
            pStatus = "live";
          }
          fires
            .collection("exps")
            .doc(expId)
            .update({
              shareURL: "https://letjoy.app/exp/" + expId,
              publishTimestamp: timestampNow,
              id: expId,
              timestamp: timestampNow,
              status: pStatus,
            })
            .then((error) => {
              if (error == null) {
                window.location.href = "/";
              } else {
                setError("无法发布");
              }
            });
        }
      }
    }
  }

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);

    timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    const file = blob;
    if (!file) return;
    var uploadTask = storageRef
      .child("expresources")
      .child(currentUser.uid + "_" + uuid() + ".mp4")
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            setUploadAudio("上传已停止");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            setUploadAudio("已上传: " + progress + "%");
            break;
        }
      },
      (error) => {
        setError("上传错误");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          fires
            .collection("users")
            .doc(currentUser.uid)
            .collection("resources")
            .add({ type: "audio", url: downloadURL, timestamp: timestampNow });
          contentList.push({
            content: downloadURL,
            type: "audio",
            type_name: "录音已上传",
            id: contentList.length,
            timestamp: timestampNow,
          });
          setContentList(contentList);
          setSuccess("录音[" + contentList.length + "]");
        });
      }
    );
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleExpImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedExpImage(file);
  };

  function handleTextChange(e) {
    e.preventDefault();
    setSelectedImage(null);
    const wordCountent = expContentRef.current.value;
    if (wordCountent != "") {
      contentList.push({
        content: wordCountent,
        type: "text",
        type_name: "文字",
        text_type: expContentType,
        id: contentList.length,
        timestamp: timestampNow,
      });
      setContentList(contentList);
      expContentRef.current.value = "";
      setSuccess("文字已添加[" + contentList.length + "]");
    } else {
      setError("无法添加文字，不能为空");
    }
  }

  const handleExpImageSubmit = (e) => {
    e.preventDefault();
    const file = selectedExpImage;
    if (!file) return;
    var uploadTask = storageRef
      .child("expresources")
      .child(currentUser.uid + "_" + uuid() + "_cover" + ".jpg")
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            setUploadS("上传已停止");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            setUploadS("已上传: " + progress + "%");
            break;
        }
      },
      (error) => {
        setError("上传错误");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setSelectedExpImageURL(downloadURL);
          setSelectedExpImage(null);
          fires
            .collection("users")
            .doc(currentUser.uid)
            .collection("resources")
            .add({ type: "image", url: downloadURL, timestamp: timestampNow });
        });
      }
    );
  };

  function isImgUrl(url) {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onerror = () => resolve(false);
      img.onload = () => resolve(true);
    });
  }

  const handleImageURLSubmit = (e) => {
    e.preventDefault();
    const imgURL = expLinkURLRef.current.value;
    var validImg = false;
    if (imgURL == "") {
      setError("无法添加图片链接");
    } else {
      isImgUrl(imgURL).then((relv) => {
        validImg = relv;

        if (imgURL != "" && validImg) {
          contentList.push({
            content: imgURL,
            type: "image",
            type_name: "图片已上传",
            id: contentList.length,
            timestamp: timestampNow,
          });
          setContentList(contentList);
          expLinkURLRef.current.value = "";
          setSuccess("图片[" + contentList.length + "]");
        } else {
          setError("无法添加图片链接 - 无法生成图片");
        }
      });
    }
  };

  const handleImageSubmit = (e) => {
    e.preventDefault();
    const file = selectedImage;

    if (!file) return;

    var uploadTask = storageRef
      .child("expresources")
      .child(currentUser.uid + "_" + uuid() + ".jpg")
      .put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            setUploadS("上传已停止");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            setUploadS("已上传: " + progress + "%");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        setError("上传错误");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          contentList.push({
            content: downloadURL,
            type: "image",
            type_name: "图片已上传",
            id: contentList.length,
            timestamp: timestampNow,
          });
          setContentList(contentList);
          setSelectedImage(null);
          fires
            .collection("users")
            .doc(currentUser.uid)
            .collection("resources")
            .add({ type: "image", url: downloadURL, timestamp: timestampNow });
        });
      }
    );
  };

  return (
    <>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh", minWidth: "100vw" }}
      >
        <div
          className="w-100"
          style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
        >
          <Card className="border-0">
            <Card.Body>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}

              <Form>
                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                  <Card.Title
                    className="text-center"
                    style={{
                      fontSize: "45px",
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    {id != null ? "修改文章" : "写文章"}
                  </Card.Title>
                  <DropdownButton
                    alignRight
                    hidden={loading}
                    title={"发布文章"}
                    id="dropdown-menu-align-right"
                    variant="outline-dark"
                    onSelect={(e) => {
                      if (e == "publish") {
                        publishStatus = true;
                        handleSubmit();
                      } else {
                        //savequit
                        publishStatus = false;
                        handleSubmit();
                      }
                    }}
                  >
                    <Dropdown.Item
                      eventKey="publish"
                      style={{
                        color: "black",
                        fontSize: "17px",
                        fontWeight: "bold",
                      }}
                    >
                      发布文章
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      eventKey="savequit"
                      style={{
                        color: "red",
                        fontSize: "17px",
                        fontWeight: "bold",
                      }}
                    >
                      保存并退出
                    </Dropdown.Item>
                  </DropdownButton>
                </ListGroup.Item>

                <Tab.Container id="preview-tabs" style={{ marginTop: "15px" }}>
                  <Row style={{ marginTop: "20px" }}>
                    <Col sm={8}>
                      <Accordion id="all-control">
                        <Accordion.Item eventKey="A">
                          <Accordion.Header>文章基本信息</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group
                              id="exp-title"
                              style={{ margin: "15px" }}
                            >
                              <Form.Label>文章标题</Form.Label>
                              <Form.Control
                                as="textarea"
                                ref={expTitleRef}
                                rows={1}
                                maxLength={100}
                                required
                              />
                            </Form.Group>
                            <Form.Group
                              id="exp-description"
                              style={{ margin: "15px" }}
                            >
                              <Form.Label>文章介绍</Form.Label>
                              <Form.Control
                                as="textarea"
                                ref={expDescriptionRef}
                                rows={5}
                              />
                            </Form.Group>
                            <Form.Group
                              id="exp-image"
                              style={{ margin: "15px" }}
                            >
                              <Form.Label>文章图片(正方形 100X100)</Form.Label>
                              <div>
                                {selectedExpImage && (
                                  <div>
                                    <img
                                      alt="not fount"
                                      width={"250px"}
                                      src={URL.createObjectURL(
                                        selectedExpImage
                                      )}
                                    />
                                    <br />
                                    <Alert variant="primary">{uploadS}</Alert>
                                    <div>
                                      <button
                                        onClick={() =>
                                          setSelectedExpImage(null)
                                        }
                                      >
                                        移除
                                      </button>
                                      <button onClick={handleExpImageSubmit}>
                                        上传图片
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <br />
                                <input
                                  type="file"
                                  name="coverImage"
                                  title="选择图片"
                                  onChange={handleExpImageChange}
                                />
                                <div>
                                  <img
                                    alt="cover"
                                    width={"100px"}
                                    height={"100px"}
                                    src={selectedExpImageURL}
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="B">
                          <Accordion.Header>增加内容</Accordion.Header>
                          <Accordion.Body>
                            <div className="d-flex align-items-center justify-content-center">
                              <ListGroup
                                defaultActiveKey="#1"
                                style={{
                                  marginTop: "15px",
                                  overflowX: "scroll",
                                  whiteSpace: "nowrap",
                                  overflow: "scroll",
                                }}
                                horizontal
                              >
                                <ListGroup.Item action href="#1">
                                  文字
                                </ListGroup.Item>
                                <ListGroup.Item action href="#2">
                                  图片
                                </ListGroup.Item>
                                <ListGroup.Item action href="#3">
                                  链接
                                </ListGroup.Item>
                                <ListGroup.Item action href="#4">
                                  录音
                                </ListGroup.Item>
                                <ListGroup.Item action href="#5">
                                  视频
                                </ListGroup.Item>
                                <ListGroup.Item action href="#6">
                                  其他
                                </ListGroup.Item>
                              </ListGroup>
                            </div>

                            <Form.Group
                              hidden={!window.location.href.endsWith("#1")}
                              id="exp-text"
                              style={{ margin: "15px" }}
                            >
                              <Form.Label>文字内容</Form.Label>
                              <br />
                              <ButtonGroup className="align-items-center justify-content-center">
                                <Button
                                  variant={
                                    expContentType == 0
                                      ? "dark"
                                      : "outline-dark"
                                  }
                                  onClick={() => {
                                    setExpContentType(0);
                                  }}
                                >
                                  大标题
                                </Button>
                                <Button
                                  variant={
                                    expContentType == 1
                                      ? "dark"
                                      : "outline-dark"
                                  }
                                  onClick={() => {
                                    setExpContentType(1);
                                  }}
                                >
                                  小标题
                                </Button>
                                <Button
                                  variant={
                                    expContentType == 2
                                      ? "dark"
                                      : "outline-dark"
                                  }
                                  onClick={() => {
                                    setExpContentType(2);
                                  }}
                                >
                                  文字内容
                                </Button>
                              </ButtonGroup>
                              <Form.Control
                                as="textarea"
                                ref={expContentRef}
                                style={{
                                  fontSize:
                                    expContentType == 0
                                      ? "35px"
                                      : expContentType == 1
                                      ? "25px"
                                      : expContentType == 2
                                      ? "17px"
                                      : "17px",
                                  fontWeight:
                                    expContentType == 0
                                      ? "bold"
                                      : expContentType == 1
                                      ? "bold"
                                      : "normal",
                                  marginTop: "10px",
                                }}
                                onChange={(e) => {}}
                                rows={10}
                              />
                              <div style={{ marginTop: "15px" }}>
                                <Button
                                  variant="dark"
                                  onClick={handleTextChange}
                                >
                                  添加文字
                                </Button>
                              </div>
                            </Form.Group>

                            <Form.Group
                              hidden={!window.location.href.endsWith("#2")}
                              id="exp-image"
                              style={{ margin: "15px" }}
                            >
                              <Form.Label>图片内容(添加链接)</Form.Label>
                              <Form.Control
                                as="textarea"
                                ref={expLinkURLRef}
                                rows={1}
                                placeholder="添加图片链接"
                              />
                              <Button
                                variant="dark"
                                style={{ marginTop: "10px" }}
                                onClick={handleImageURLSubmit}
                              >
                                添加图片链接
                              </Button>
                              <br />
                              <br />
                              <br />
                              <div style={{ marginTop: "15px" }}>
                                <Form.Label>图片内容(上传图片)</Form.Label>
                                {selectedImage && (
                                  <div>
                                    <img
                                      alt="not found"
                                      width={"250px"}
                                      src={URL.createObjectURL(selectedImage)}
                                    />
                                    <br />
                                    <Alert variant="primary">{uploadS}</Alert>
                                    <div>
                                      <button
                                        onClick={() => setSelectedImage(null)}
                                      >
                                        移除
                                      </button>
                                      <button onClick={handleImageSubmit}>
                                        上传图片
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <br />
                                <input
                                  type="file"
                                  name="myImage"
                                  title="选择图片"
                                  onChange={handleImageChange}
                                />
                              </div>
                            </Form.Group>

                            <Form.Group
                              hidden={!window.location.href.endsWith("#3")}
                              id="exp-link"
                              style={{ margin: "15px" }}
                            >
                              <Form.Label>添加链接</Form.Label>
                              <Form.Control
                                as="textarea"
                                ref={expAddLinkRef}
                                rows={2}
                                placeholder="https://www.google.com"
                              />
                              <div style={{ marginTop: "10px" }}>
                                <Button
                                  variant="dark"
                                  onClick={() => {
                                    const likURL = expAddLinkRef.current.value;
                                    if (validator.isURL(likURL)) {
                                      contentList.push({
                                        content: likURL,
                                        type: "url",
                                        type_name: "图片已上传",
                                        id: contentList.length,
                                        timestamp: timestampNow,
                                      });
                                      setContentList(contentList);
                                      expAddLinkRef.current.value = "";
                                      setSuccess(
                                        "链接已添加[" + contentList.length + "]"
                                      );
                                    }
                                  }}
                                >
                                  添加链接
                                </Button>
                              </div>
                            </Form.Group>

                            <Form.Group
                              hidden={!window.location.href.endsWith("#4")}
                              id="exp-audio"
                              style={{ margin: "15px" }}
                            >
                              <div style={{ marginTop: "15px" }}>
                                <ListGroup.Item className="align-items-start">
                                  <Card.Text
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "normal",
                                      marginTop: "15px",
                                    }}
                                  >
                                    {"录制语音"}
                                  </Card.Text>
                                  <Alert variant="primary">{uploadAudio}</Alert>
                                </ListGroup.Item>
                                <AudioRecorder
                                  onRecordingComplete={addAudioElement}
                                  recorderControls={recorderControls}
                                />
                                <button
                                  hidden={!recorderControls.isPaused}
                                  onClick={recorderControls.stopRecording}
                                >
                                  上传语音
                                </button>
                              </div>
                            </Form.Group>

                            <Form.Group
                              hidden={!window.location.href.endsWith("#5")}
                              id="exp-link"
                              style={{ margin: "15px" }}
                            >
                              <Form.Label>添加YouTube</Form.Label>
                              <Form.Control
                                as="textarea"
                                ref={expAddYoutubeRef}
                                rows={2}
                                placeholder="https://youtube.com/"
                              />
                              <div style={{ marginTop: "15px" }}>
                                <Button
                                  variant="dark"
                                  onClick={() => {
                                    const youtubeURL =
                                      expAddYoutubeRef.current.value;
                                    if (validator.isURL(youtubeURL)) {
                                      var video_id = youtubeURL.split("v=")[1];
                                      var ampersandPosition =
                                        video_id.indexOf("&");
                                      if (ampersandPosition != -1) {
                                        video_id = video_id.substring(
                                          0,
                                          ampersandPosition
                                        );
                                      }
                                      contentList.push({
                                        content: video_id,
                                        type: "video",
                                        type_name: "YouTube链接已上传",
                                        id: contentList.length,
                                        timestamp: timestampNow,
                                      });
                                      setContentList(contentList);
                                      expAddYoutubeRef.current.value = "";
                                      expAddLinkRef.current.value = "";
                                      setSuccess(
                                        "YouTube视频已添加[" +
                                          contentList.length +
                                          "]"
                                      );
                                    }
                                  }}
                                >
                                  添加YouTube视频
                                </Button>
                              </div>
                            </Form.Group>

                            <Form.Group
                              hidden={!window.location.href.endsWith("#6")}
                              id="exp-text"
                              style={{ margin: "15px" }}
                            >
                              <Form.Label>分页符</Form.Label>
                              <br />
                              <div className="border-top my-3"></div>
                              <Button
                                variant="dark"
                                onClick={() => {
                                  contentList.push({
                                    content: "divider",
                                    type: "divider",
                                    type_name: "分页符已添加",
                                    id: contentList.length,
                                    timestamp: timestampNow,
                                  });
                                  setContentList(contentList);
                                  setSuccess(
                                    "分页符已添加[" + contentList.length + "]"
                                  );
                                }}
                              >
                                添加分页符
                              </Button>
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>

                    <Col sm={4}>
                      <ListGroup variant="dark">
                        <ListGroup.Item
                          className="d-flex justify-content-between align-items-start"
                          style={{ backgroundColor: "#EEE" }}
                        >
                          <div className="fw-bold">文章预览</div>
                          <div className="fw-bold">
                            {contentList.length}个内容
                          </div>
                        </ListGroup.Item>
                        {contentList.map((contentItm) => {
                          if (contentItm.type == "image") {
                            return (
                              <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                <img
                                  alt="图片正在加载"
                                  src={contentItm.content}
                                  width="75%"
                                />
                                <DropdownButton
                                  alignRight
                                  title={"选项"}
                                  style={{ width: "20%" }}
                                  id="dropdown-menu-align-right"
                                  variant="outline-dark"
                                  onSelect={(e) => {
                                    if (e == "delete") {
                                      if (contentItm.activeId != "") {
                                        fires
                                          .collection("exps")
                                          .doc(id)
                                          .collection("contents")
                                          .doc(contentItm.activeId)
                                          .delete();
                                      }
                                      contentList.splice(contentItm.id, 1);
                                      setContentList(contentList);
                                      setSuccess(
                                        "图片已删除[" + contentList.length + "]"
                                      );
                                      reloadAllContents();
                                    }
                                  }}
                                >
                                  <Dropdown.Item
                                    eventKey="modify"
                                    style={{
                                      color: "black",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    修改
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    eventKey="delete"
                                    style={{
                                      color: "red",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    删除
                                  </Dropdown.Item>
                                </DropdownButton>
                              </ListGroup.Item>
                            );
                          } else if (contentItm.type == "text") {
                            return (
                              <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                <Card.Text
                                  style={{
                                    width: "75%",
                                    fontSize:
                                      contentItm.text_type == 0
                                        ? "35px"
                                        : contentItm.text_type == 1
                                        ? "25px"
                                        : contentItm.text_type == 2
                                        ? "17px"
                                        : "17px",
                                    fontWeight:
                                      contentItm.text_type == 0
                                        ? "bold"
                                        : contentItm.text_type == 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {contentItm.content}
                                </Card.Text>
                                <DropdownButton
                                  alignRight
                                  title={"选项"}
                                  id="dropdown-menu-align-right"
                                  variant="outline-dark"
                                  style={{ width: "20%" }}
                                  onSelect={(e) => {
                                    if (e == "modify") {
                                    } else if (e == "delete") {
                                      if (contentItm.activeId != "") {
                                        fires
                                          .collection("exps")
                                          .doc(id)
                                          .collection("contents")
                                          .doc(contentItm.activeId)
                                          .delete();
                                      }
                                      contentList.splice(contentItm.id, 1);
                                      setContentList(contentList);
                                      setSuccess(
                                        "文字已删除[" + contentList.length + "]"
                                      );
                                      reloadAllContents();
                                    }
                                  }}
                                >
                                  <Dropdown.Item
                                    eventKey="modify"
                                    style={{
                                      color: "black",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    修改
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    eventKey="delete"
                                    style={{
                                      color: "red",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    删除
                                  </Dropdown.Item>
                                </DropdownButton>
                              </ListGroup.Item>
                            );
                          } else if (contentItm.type == "url") {
                            return (
                              <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                <Card.Link
                                  style={{ width: "75%" }}
                                  href={contentItm.content}
                                >
                                  {contentItm.content}
                                </Card.Link>
                                <DropdownButton
                                  alignRight
                                  title={"选项"}
                                  id="dropdown-menu-align-right"
                                  variant="outline-dark"
                                  style={{ width: "20%" }}
                                  onSelect={(e) => {
                                    if (e == "modify") {
                                    } else if (e == "delete") {
                                      if (contentItm.activeId != "") {
                                        fires
                                          .collection("exps")
                                          .doc(id)
                                          .collection("contents")
                                          .doc(contentItm.activeId)
                                          .delete();
                                      }
                                      contentList.splice(contentItm.id, 1);
                                      setContentList(contentList);
                                      setSuccess(
                                        "链接已删除[" + contentList.length + "]"
                                      );
                                      reloadAllContents();
                                    }
                                  }}
                                >
                                  <Dropdown.Item
                                    eventKey="modify"
                                    style={{
                                      color: "black",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    修改
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    eventKey="delete"
                                    style={{
                                      color: "red",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    删除
                                  </Dropdown.Item>
                                </DropdownButton>
                              </ListGroup.Item>
                            );
                          } else if (contentItm.type == "audio") {
                            return (
                              <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                <ReactAudioPlayer
                                  src={contentItm.content}
                                  controls
                                />
                                <DropdownButton
                                  alignRight
                                  title={"选项"}
                                  id="dropdown-menu-align-right"
                                  variant="outline-dark"
                                  style={{ width: "20%" }}
                                  onSelect={(e) => {
                                    if (e == "modify") {
                                    } else if (e == "delete") {
                                      if (contentItm.activeId != "") {
                                        fires
                                          .collection("exps")
                                          .doc(id)
                                          .collection("contents")
                                          .doc(contentItm.activeId)
                                          .delete();
                                      }
                                      contentList.splice(contentItm.id, 1);
                                      setContentList(contentList);
                                      setSuccess(
                                        "语音已删除[" + contentList.length + "]"
                                      );
                                      reloadAllContents();
                                    }
                                  }}
                                >
                                  <Dropdown.Item
                                    eventKey="modify"
                                    style={{
                                      color: "black",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    修改
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    eventKey="delete"
                                    style={{
                                      color: "red",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    删除
                                  </Dropdown.Item>
                                </DropdownButton>
                              </ListGroup.Item>
                            );
                          } else if (contentItm.type == "video") {
                            return (
                              <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                <YouTube
                                  videoId={contentItm.content}
                                  opts={opts}
                                  style={{
                                    marginTop: "50px",
                                    borderRadius: "50px",
                                  }}
                                />
                                <DropdownButton
                                  alignRight
                                  title={"选项"}
                                  id="dropdown-menu-align-right"
                                  variant="outline-dark"
                                  style={{ width: "20%" }}
                                  onSelect={(e) => {
                                    if (e == "modify") {
                                    } else if (e == "delete") {
                                      if (contentItm.activeId != "") {
                                        fires
                                          .collection("exps")
                                          .doc(id)
                                          .collection("contents")
                                          .doc(contentItm.activeId)
                                          .delete();
                                      }
                                      contentList.splice(contentItm.id, 1);
                                      setContentList(contentList);
                                      setSuccess(
                                        "视频已删除[" + contentList.length + "]"
                                      );
                                      reloadAllContents();
                                    }
                                  }}
                                >
                                  <Dropdown.Item
                                    eventKey="modify"
                                    style={{
                                      color: "black",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    修改
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    eventKey="delete"
                                    style={{
                                      color: "red",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    删除
                                  </Dropdown.Item>
                                </DropdownButton>
                              </ListGroup.Item>
                            );
                          } else if (contentItm.type == "divider") {
                            return (
                              <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                <div className="border-top my-3">分页符</div>
                                <DropdownButton
                                  alignRight
                                  title={"选项"}
                                  id="dropdown-menu-align-right"
                                  variant="outline-dark"
                                  style={{ width: "20%" }}
                                  onSelect={(e) => {
                                    if (e == "modify") {
                                    } else if (e == "delete") {
                                      if (contentItm.activeId != "") {
                                        fires
                                          .collection("exps")
                                          .doc(id)
                                          .collection("contents")
                                          .doc(contentItm.activeId)
                                          .delete();
                                      }
                                      contentList.splice(contentItm.id, 1);
                                      setContentList(contentList);
                                      setSuccess(
                                        "分页符已删除[" +
                                          contentList.length +
                                          "]"
                                      );
                                      reloadAllContents();
                                    }
                                  }}
                                >
                                  <Dropdown.Item
                                    eventKey="modify"
                                    style={{
                                      color: "black",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    修改
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    eventKey="delete"
                                    style={{
                                      color: "red",
                                      fontSize: "17px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    删除
                                  </Dropdown.Item>
                                </DropdownButton>
                              </ListGroup.Item>
                            );
                          } else {
                            return (
                              <Accordion.Item eventKey={contentItm.id}>
                                <Accordion.Header>
                                  {contentItm.type_name}
                                </Accordion.Header>
                                <Accordion.Body></Accordion.Body>
                              </Accordion.Item>
                            );
                          }
                        })}
                      </ListGroup>
                    </Col>
                  </Row>
                </Tab.Container>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
