import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  ListGroup,
  Accordion,
  Dropdown,
  DropdownButton,
  Tab,
  TabContainer,
  Col,
  Row,
  ButtonGroup,
  Modal,
  FloatingLabel,
  Badge,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { fires, auth, storageRef } from "../firebase";
import firebase from "firebase/app";
import uuid from "react-uuid";
import "firebase/firestore";
import validator from "validator";

import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import ReactAudioPlayer from "react-audio-player";
import YouTube from "react-youtube";

import ReactDatePicker from "react-datepicker";
import DateTimePicker from "react-datetime-picker";
import Geocode from "react-geocode";
import isEmail from "validator/lib/isEmail";
import { stripeAPICreatePrice } from "../contexts/API";
import Autocomplete from "react-google-autocomplete";

export default function CreateEvent() {
  //references
  //basic info
  const eventTitleRef = useRef();
  const eventPhotoURLRef = useRef();
  const eventDescriptionRef = useRef();
  const eventDescriptionENRef = useRef();
  const eventShareURLRef = useRef();
  const eventTermsConditionsRef = useRef();
  const eventIDRef = useRef();
  //address
  const addressSearchRef = useRef();
  const addressNameRef = useRef();
  const addressAddressRef = useRef();
  const addressCityRef = useRef();
  const addressProvRef = useRef();
  const addressCountryRef = useRef();
  const addressUnitRef = useRef();
  const addressPostalRef = useRef();
  //time
  const eventTimeStartRef = useRef();
  const eventTimeEndRef = useRef();
  //other info
  const eventPINRef = useRef();
  const eventLiveRef = useRef();
  const eventTicketRef = useRef();

  //sponsor info
  const eventSponsorNameRef = useRef();
  const eventSponsorDescriptionRef = useRef();
  const eventSponsorBannerRef = useRef();
  const eventSponsorURLRef = useRef();

  //agenda
  const eventAgendaTitleRef = useRef();
  const eventAgendaDescriptionRef = useRef();
  const eventAgendaRoomRef = useRef();
  const [eventAgendaTime, setEventAgendaTime] = useState(
    firebase.firestore.Timestamp.fromDate(new Date())
  );

  //speakers
  const eventSpeakerNameRef = useRef();
  const eventSpeakerIntroRef = useRef();
  const [eventSpeakerImageURL, setEventSpeakerImageURL] = useState("");

  //invitees
  const inviteeName = useRef();
  const inviteeEmail = useRef();
  const inviteePhone = useRef();

  //draws
  const drawNameRef = useRef();
  const drawDescriptionRef = useRef();
  const drawImageURLRef = useRef();
  const drawURLRef = useRef();
  const drawCountRef = useRef();

  //3rdparty
  const event3EBRef = useRef();

  //pricing
  const eventPriceRef = useRef();
  const eventPriceEmailRef = useRef();

  //questions
  const questionTitleRef = useRef();
  const questionDescRef = useRef();

  //ticket price description
  const ticketPriceDesc = useRef();
  const [priceEditId, setPriceEditId] = useState("default-price");
  const [priceEditSuccess, setPriceEditSuccess] = useState("")
  const [priceEditData, setPriceEditData] = useState({
        priceType: "CREDIT",
        id: "default-price",
        order: 0,
        price: 0,
        priceOriginal: 0,
        priceTax: 0,
        priceCurrency: "CAD",
        priceEMTEmail: "",
        priceId: "",
        productId: "",
        max: 25,
        min: 1,
        priceDescription: "Free Admission",
        priceDescriptions: [],
        priceIncludeTax: false,
        saleStart: firebase.firestore.Timestamp.fromDate(new Date()),
        saleEnd: firebase.firestore.Timestamp.fromDate(new Date()),
  })

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  //sponsor modal
  const [showSponsor, setShowSponsor] = useState(false);
  const handleCloseSponsor = () => setShowSponsor(false);
  const handleShowSponsor = () => setShowSponsor(true);
  const [sponsorModifyIndex, setSponsorModifyIndex] = useState(-1);
  const [sponsorMofifySet, setSponsorModifySet] = useState({
    name: "",
    description: "",
    imageURL: "",
    order: -1,
    url: "",
  });

  //agenda modal
  const [showAgenda, setShowAgenda] = useState(false);
  const handleCloseAgenda = () => setShowAgenda(false);
  const handleShowAgenda = () => setShowAgenda(true);
  const [agendaModifyIndex, setAgendaModifyIndex] = useState(-1);
  const [agendaMofifySet, setAgendaModifySet] = useState({
    title: "",
    description: "",
    location: "",
    date: firebase.firestore.Timestamp.fromDate(new Date()),
    order: -1,
  });

  //confirmation modal
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleCloseConfirmation = () => setShowConfirmation(false);
  const handleShowConfirmation = () => setShowConfirmation(true);

  const [showInvitee, setShowInvitee] = useState(false);
  const handleCloseInvitee = () => setShowInvitee(false);
  const handleShowInvitee = () => setShowInvitee(true);

  const [showDraw, setShowDraw] = useState(false);
  const handleCloseDraw = () => setShowDraw(false);
  const handleShowDraw = () => setShowDraw(true);

  const [showImages, setShowImages] = useState(false);
  const handleCloseImages = () => setShowImages(false);
  const handleShowImages = () => setShowImages(true);

  const [showBgImage, setShowBgImage] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [galleryType, setGalleryType] = useState("banner");

  const [messageCounter, setMessageCounter] = useState(0);

  const [basicInfoMessage, setBasicInfoMessage] = useState("");
  const [timeMessage, setTimeMessage] = useState("");
  const [addressMessage, setAddressMessage] = useState("");
  const [otherInfoMessage, setOtherInfoMessage] = useState("");
  const [sponsorMessage, setSponsorMessage] = useState("");
  const [agendaMessage, setAgendaMessage] = useState("");
  const [drawMessage, setDrawMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [orgMembers, setOrgMembers] = useState([]);

  const [uData, setUData] = useState({});
  const [gData, setGData] = useState({});

  const [gDataId, setGDataId] = useState("");
  const [gDataName, setGDataName] = useState("");

  const history = useNavigate();
  let { id } = useParams();

  const [selectedImage, setSelectedImage] = useState(null);
  const [allGroups, setAllGroups] = useState({});

  const [selectedExpImage, setSelectedExpImage] = useState(null);
  const [selectedBannerImageURL, setSelectedBannerImageURL] = useState("");

  const [selectedEventImage, setSelectedEventImage] = useState(null);
  const [selectedEventImageURL, setSelectedEventImageURL] = useState("");
  const eventNewImageRef = useRef();

  const maxW = isMobile ? "100%" : "100%";

  const { currentUser } = useAuth();

  const [images, setImages] = useState([]);

  const [agendas, setAgendas] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [draws, setDraws] = useState({});
  const [invitees, setInvitees] = useState([]);
  const [inviteeSet, setInviteeSet] = useState([]);

  const [orgEmails, setOrgEmails] = useState([]);

  const [orgAddresses, setOrgAddresses] = useState([]);

  const [contentList, setContentList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  //paid
  const [priceList, setPriceList] = useState([]);

  const [drawCount, setDrawCount] = useState(0);
  const [drawImageUrl, setDrawImageUrl] = useState("");

  //additional questions
  const [questionList, setQuestionList] = useState([]);
  const [questionType, setQuestionType] = useState(0);
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [questionRequired, setQuestionRequired] = useState(false);

  var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

  const [startDate, setStartDate] = useState(
    firebase.firestore.Timestamp.fromDate(new Date())
  );
  const [endDate, setEndDate] = useState(
    firebase.firestore.Timestamp.fromDate(new Date())
  );
  const [startDateString, setStartDateString] = useState("");
  const [endDateString, setEndDateString] = useState("");

  const [timeInterval, setTimeInterval] = useState(0);

  const [originPrice, setOriginPrice] = useState(0);
  const [calculatedPrice, setCalulatedPrice] = useState(0);
  const [calculatedAddOnPrice, setCalulatedAddOnPrice] = useState(0);

  var publishStatus = false;
  const [imgUrl, setImgUrl] = useState(null);
  const [uploadS, setUploadS] = useState("请点击上传图片开始上传");
  const [progresspercent, setProgresspercent] = useState(0);
  const [uploadAudio, setUploadAudio] = useState("请点击录音键开始录音并上传");
  const recorderControls = useAudioRecorder();

  var orgId = "";

  const [eventContent, setEventContent] = useState({
    approved: false,
    address: {
      fullAddress: "",
      name: "",
      city: "",
      country: "",
      state: "",
      street: "",
      zipCode: "",
      unit: "",
      online: false,
    },
    agenda: [],
    sponsor: [],
    speakers: [],
    invitees: [],
    inviteeSet: [],
    drawItems: {},
    country: "",
    title: "",
    description: "",
    descriptions: {},
    orgId: "",
    id: "",
    fromUID: currentUser.uid,
    type: "tour",
    priceType: "FREE",
    price: 0,
    priceOriginal: 0,
    priceCurrency: "CAD",
    priceEMTEmail: "",
    priceId: "",
    productId: "",
    priceDescription: "",
    saleStart: firebase.firestore.Timestamp.fromDate(new Date()),
    saleEnd: startDate,
    startDate: startDate,
    endDate: endDate,
    startDateString: "",
    endDateString: "",
    duration: "",
    prices: {
      "default-price": {
        priceType: "FREE",
        id: "default-price",
        order: 0,
        price: 0,
        priceOriginal: 0,
        priceTax: 0,
        priceCurrency: "CAD",
        priceEMTEmail: "",
        priceId: "",
        productId: "",
        max: 25,
        min: 1,
        priceDescription: "Free Admission",
        priceDescriptions: [],
        priceIncludeTax: false,
        saleStart: firebase.firestore.Timestamp.fromDate(new Date()),
        saleEnd: startDate,
      },
    },
    location: "",
    photoURL: "",
    photoURLs: [],
    status: "live",
    pin: "",
    live: false,
    liveURL: "",
    ticket: false,
    ticketURL: "",
    user: {
      userName: "",
      userOrg: "",
      imageURL: "",
      uid: "",
      cohosts: [],
    },
    topics: [],
    options: {
      poll: false,
      draw: false,
      namecard: false,
      review: false,
      comment: true,
      picture: true,
      "register-feedback": false,
      "multi-ticket-checkout": true,
      max: 500,
    },
    questions: [],
    viewCount: 0,
    timestamp: timestampNow,
  });

  const [cohosts, setCohosts] = useState([]);

  const [galleryItems, setGalleryItems] = useState([]);
  const [systemImages, setSystemImages] = useState([]);

  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  const routeBack = () => {
    let path = "/";
    window.location.href = path;
  };

  const [showAddNewPrice, setShowAddNewPrice] = useState(false);
  const createNewPriceListModal = () => {
    return (
      <Modal show={showAddNewPrice} onHide={setShowAddNewPrice(false)}>
        <Modal.Header closeButton>
          <Modal.Title>创建新价格</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              const priceDesc = e.target[0].value;
              const priceItem = e.target[1].value;
              if (priceItem !== "") {
                const orgName = gData.name;
                const orgEmail = auth.currentUser.email;
                const unitAmount = Number(priceItem) * 100;
                if (unitAmount > 99){
                  stripeAPICreatePrice(
                    gData.id,
                    unitAmount,
                    gData.productId,
                    "cad"
                  );
                }
                else{
                  alert('should be more than 0.99')
                }
                
              }
              setShowAddNewPrice(false)
            }}
          >
            <Form.Group style={{ marginBottom: "25px" }}>
              <FloatingLabel
                controlId="priceDescription"
                label="价格描述"
                className="mb-3"
                style={{ marginTop: "15px" }}
              >
                <Form.Control
                  type="text"
                  placeholder="请输入您想添加的价格描述"
                  rows={1}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group style={{ marginBottom: "25px" }}>
              <FloatingLabel
                controlId="priceDescription"
                label="价格"
                className="mb-3"
                style={{ marginTop: "15px" }}
              >
                <Form.Control type="text" placeholder="$CAD/USD" rows={1} />
              </FloatingLabel>
            </Form.Group>

            <Button variant="dark" className="w-100" type="submit">
              提交价格申请
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  function handleIncomingEventbriteURL(e) {
    const eventb = event3EBRef.current.value;
    if (eventb != null) {
      var eventbid = eventb.replace(/^\D+/g, "");
      const goAhead = window.confirm(
        "我们找到了您提供的Eventbrite链接" + "ID " + eventbid + "，现在生成？"
      );
      if (goAhead) {
        handleEventbriteLink(eventbid);
        window.alert("无法完成生成，请您手动输入");
      }
    }
  }

  async function handleEventbriteLink(eventBriteID) {
    // POST request using fetch with async/await
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + process.env.REACT_APP_EVENTBRITE,
      },
    };
    const response = await fetch(
      "https://www.eventbriteapi.com/v3/events/" +
        eventBriteID +
        "?token=" +
        process.env.REACT_APP_EVENTBRITE,
      requestOptions
    );
    const data = await response.json();

    const newTitle = data.name.text;
    const newDescription = data.description.text;
    const newTicketURL = data.url;
    const newLogoURL = data.logo.url;

    eventTitleRef.current.value = newTitle;
    eventDescriptionRef.current.value = newDescription;
    eventTicketRef.current.value = newTicketURL;
    eventPhotoURLRef.current.value = newLogoURL;

    var eventtContent = eventContent;
    eventtContent.title = newTitle;
    eventtContent.description = newDescription;
    eventtContent.photoURL = newLogoURL;
    setEventContent(eventtContent);

    setMessageCounter(messageCounter + 1);
    setBasicInfoMessage("信息已填写[" + messageCounter + "]");
  }

  async function createEventPriceAndSetPrice(orgId, unit_amount, product_id) {
    auth.currentUser.getIdToken(false).then((idToken) => {
      const dataBody = `org_id=${orgId}&unit_amount=${unit_amount}&currency=cad&product_id=${product_id}&user_uid=${auth.currentUser.uid}&user_token=${idToken}`;
      fetch(
        "https://organizerapicreatestripeonetimeprice-verqhozorq-uc.a.run.app?" +
          dataBody,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      ).then((response) => {
        response.json().then((data) => {
          var dataS = {
            success: false,
            status: "",
            productId: "",
            priceId: "",
          };
          dataS = data;
          if (dataS.success) {
            if (dataS.priceId != "") {
              var eventtContent = eventContent;

              const totalAmountH = unit_amount;
              const addedonAmountH = totalAmountH - (totalAmountH - 30) / 1.029;
              const addedonAmount =
                Math.round((addedonAmountH / 100) * 100) / 100;
              const totalAmount = totalAmountH / 100;
              const originAmount = totalAmount - addedonAmount;

              setCalulatedPrice(totalAmount);
              setCalulatedAddOnPrice(addedonAmount);
              setOriginPrice(originAmount);

              eventtContent.price = totalAmount;
              eventtContent.priceOriginal = originAmount;
              eventtContent.priceId = dataS.priceId;
              eventtContent.priceCurrency = "CAD";
              eventtContent.productId = product_id;

              const custom_message = ticketPriceDesc.current.value;

              eventtContent.prices[priceEditId] = {
                priceType: "CREDIT",
                id: priceEditId,
                price: totalAmount,
                priceOriginal: originAmount,
                priceTax: 0,
                priceCurrency: "CAD",
                priceEMTEmail: "",
                priceId: dataS.priceId,
                productId: "",
                max: 25,
                min: 1,
                priceDescription:
                  custom_message == "" ? "Paid Admission" : custom_message,
                priceDescriptions: [],
                priceIncludeTax: false,
                saleStart: firebase.firestore.Timestamp.fromDate(new Date()),
                saleEnd: startDate,
              };

              setEventContent(eventtContent);
              setMessageCounter(messageCounter + 1);
              setOtherInfoMessage("信息已填写[" + messageCounter + "]");

              setEventContent(eventtContent);
            } else {
              return "";
            }
          } else {
            return "";
          }
        });
      });
    });
  }

  useEffect(() => {
    fires
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          var users = [];
          const userData = snapshot.data();
          orgId = userData.orgId;
          const userN = userData.userName;
          setUData({ ...userData });

          fires
            .collection("admin")
            .doc(currentUser.uid)
            .get()
            .then((snapAdmin) => {
              const superAdmin = snapAdmin.exists;
              if (!superAdmin) {
                
              } else {
                loadEventGroups();
              }
            });

          fires
            .collection("users")
            .doc(currentUser.uid)
            .collection("resources")
            .get()
            .then((snapshotA) => {
              var galleryItms = [];
              snapshotA.docs.map((docItm) => {
                const docData = docItm.data();
                let imageUrl = docData.url;
                if (imageUrl != null) {
                  galleryItms.push(imageUrl);
                }
              });
              setGalleryItems(galleryItms);
            });

          fires
            .collection("settings")
            .doc("event-images")
            .get()
            .then((snapshotA) => {
              var settingImages = [];
              if (snapshotA.exists) {
                let docData = snapshotA.data();
                if (docData.bgImages != null) {
                  settingImages = docData.bgImages;
                }
                setSystemImages(settingImages);
              }
            });

          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotA) => {
              if (snapshotA.exists) {
                var gData = [];
                var groupData = snapshotA.data();
                orgId = groupData.id;
                const userOrg = groupData.name;
                const imageURL = groupData.profileURL;
                setGData({ ...groupData });
                setGDataId(orgId);
                setGDataName(userOrg);
                if (groupData.bannerURL != null) {
                  setSelectedBannerImageURL(groupData.bannerURL);
                }

                var eventD = eventContent;
                eventD.orgId = orgId;
                eventD.user = {
                  userName: userOrg,
                  userOrg: userOrg,
                  imageURL: imageURL,
                  uid: currentUser.uid,
                };

                setEventContent(eventD);

                if (id != "") {
                  if (id != null) {
                    fires
                      .collection("events")
                      .doc(id)
                      .get()
                      .then((snapshotB) => {
                        if (snapshotB.exists) {
                          var eData = snapshotB.data();
                          let eId = snapshotB.id;

                          if (eData.speakers != null) {
                            setEventContent({ ...eData, id: eId });
                          } else {
                            setEventContent({
                              ...eData,
                              id: eId,
                              speakers: [],
                            });
                          }

                          eventTitleRef.current.value = eData.title;
                          eventDescriptionRef.current.value = eData.description;
                          eventPhotoURLRef.current.value = eData.photoURL;
                          eventIDRef.current.value = eData.id;

                          eventPINRef.current.value = eData.pin;
                          eventLiveRef.current.value = eData.liveURL;
                          eventTicketRef.current.value = eData.ticketURL;

                          eventPriceRef.current.value = eData.price;

                          if (eData.agenda != null) {
                            setAgendas(eData.agenda);
                          }
                          if (eData.sponsor != null) {
                            setSponsors(eData.sponsor);
                          }

                          if (
                            eData.startDate != null &&
                            eData.endDate != null
                          ) {
                            setStartDate(eData.startDate);
                            setEndDate(eData.endDate);
                            const dateDifferent =
                              eData.endDate.toDate() - eData.startDate.toDate();
                            const dateSeconds = Math.abs(dateDifferent / 1000);
                            setTimeInterval(dateSeconds);
                          }

                          if (eData.drawItems != null) {
                            setDraws(eData.drawItems);
                          }

                          if (eData.inviteeSet != null) {
                            setInviteeSet(eData.inviteeSet);
                          }

                          if (eData.invitees != null) {
                            setInvitees(eData.invitees);
                          }
                        } else {
                          //not exist
                        }
                      });
                  }
                }

                //get list of product price
                loadPriceList(groupData);
                //
                loadGroupAddress(orgId);
                //
                loadGroupFollowers(orgId);

                loadGroupMembers(orgId);
              }
            });
        } else {
          routeBack();
        }
      });
  }, []);

  async function handleEventModify() {
    setLoading(false);

    const eTitle = eventContent.title;
    const eDescription = eventContent.description;
    const fromUID = currentUser.uid;

    const imgURL = selectedBannerImageURL;

    const pin = eventContent.pin;

    timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

    const now = new Date();
    var dateValid = true;

    if (
      eTitle == "" ||
      pin == "" ||
      (eventContent.address.fullAddress == "" &&
        !eventContent.address.online) ||
      (eventContent.liveURL == "" && eventContent.address.online) ||
      (eventContent.priceType == "EMT" &&
        (eventContent.priceEMTEmail == "" || eventContent.price == 0)) ||
      (eventContent.priceType == "CREDIT" && eventContent.price == 0) ||
      // (eventContent.priceType == "CREDIT") ||
      !dateValid
    ) {
      if (eTitle == "") {
        setError("无法创建新活动，请您输入标题");
      } else if (pin == "") {
        setError("无法创建新活动，请您自动生成签到ID");
      } else if (
        eventContent.address.fullAddress == "" &&
        !eventContent.address.online
      ) {
        setError("无法创建新活动，请您输入地址并生效");
      } else if (eventContent.liveURL == "" && eventContent.address.online) {
        setError("无法创建新活动，请您输入直播网址");
      } else if (!dateValid) {
        setError(
          "无法创建新活动，请您选择正确的时间并生效，时间必须是未来时间，结束时间必须大于开始时间"
        );
      } else if (
        eventContent.priceType == "EMT" &&
        (eventContent.priceEMTEmail == "" || eventContent.price <= 0)
      ) {
        setError("无法创建新活动，请您在选择EMT的时候修改金额和填写EMAIL");
      } else if (
        eventContent.priceType == "CREDIT" &&
        eventContent.price <= 0
      ) {
        setError("无法创建新活动，请您选择CREDIT的时候修改金额");
      } else if (
        eventContent.priceType == "CREDIT" ||
        eventContent.priceType == "EMT"
      ) {
        setError("无法创建新活动，请您选择免费活动，您的地区暂时不支持收费");
      } else {
        setError(
          "无法创建新活动，请您输入标题, 选择时间，选择地点，输入签到ID"
        );
      }
    } else {
      if (id != "" && id != null) {
        setEventContent({...eventContent, id: id})
        fires
          .collection("events")
          .doc(id)
          .update(eventContent)
          .then((error) => {
            if (error == null) {
              window.location.href = "/";
            } else {
              setError("无法修改");
            }
          });
      } else {
        setError("无法修改");
      }
    }
  }

  async function loadEventGroups() {
    fires
      .collection("groups")
      .orderBy("timestamp", "desc")
      .get()
      .then((snapshotA) => {
        var groupItms = {};
        snapshotA.docs.map((docItm) => {
          const docId = docItm.id;
          const docData = docItm.data();
          groupItms[docId] = docData;
        });
        setAllGroups(groupItms);
      });
  }

  async function loadPriceList(groupData) {
    if (groupData.productId != null) {
      const prodId = groupData.productId;
      if (prodId != "") {
        var pricing = [];
        fires
          .collection("service-stripe-products")
          .doc(prodId)
          .collection("prices")
          .get()
          .then((priceSnapshot) => {
            priceSnapshot.docs.forEach((doc) => {
              const priceData = doc.data();
              const priceId = doc.id;
              pricing.push({ ...priceData, id: priceId });
            });
          });
        setPriceList(pricing);
      }
    }
  }

  async function loadGroupAddress(orgId) {
    fires
      .collection("groups")
      .doc(orgId)
      .collection("address")
      .get()
      .then((snapshotB) => {
        var orgAddress = [];
        snapshotB.docs.forEach((doc) => {
          const addressId = doc.id;
          const addressContentt = doc.data();
          if (addressContentt.fullAddress != null) {
            if (addressContentt.fullAddress != "") {
              orgAddress.push(addressContentt);
            }
          }
        });
        setOrgAddresses(orgAddress);
      });
  }

  async function loadGroupFollowers(orgId) {
    fires
      .collection("groups")
      .doc(orgId)
      .collection("followers")
      .get()
      .then((snapshotB) => {
        var orgFollows = [];
        snapshotB.docs.forEach((doc) => {
          const contentId = doc.id;
          const contentData = doc.data();
          var email = "";
          var userName = "";
          if (contentData.email != null) {
            email = contentData.email;
          }
          if (contentData.userName != null) {
            userName = contentData.userName;
          }
          if (email != "") {
            orgFollows.push({
              email: email,
              name: userName,
              order: orgFollows.length,
            });
          }
        });
        setOrgEmails(orgFollows);
      });
  }

  async function loadGroupMembers(orgId) {
    fires
      .collection("groups")
      .doc(orgId)
      .collection("members")
      .get()
      .then((snapshotB) => {
        var orgMems = [];
        snapshotB.docs.forEach((doc) => {
          const contentId = doc.id;
          const contentData = doc.data();
          var email = "";
          var name = "";
          if (contentData.email != null) {
            email = contentData.email;
          }
          if (contentData.name != null) {
            name = contentData.name;
          }
          orgMems.push(contentData);
        });
        setOrgMembers(orgMems);
      });
  }

  async function handleEventPublish() {
    setLoading(false);

    const eTitle = eventTitleRef.current.value;
    const eDescription = eventDescriptionRef.current.value;
    const fromUID = currentUser.uid;

    const imgURL = selectedBannerImageURL;

    const pin = eventPINRef.current.value;

    var eventtContent = eventContent;
    eventtContent.pin = pin;
    eventtContent.startDate = startDate;
    eventtContent.endDate = endDate;
    eventtContent.title = eTitle;
    eventtContent.description = eDescription;

    setEventContent(eventtContent);

    timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

    const now = new Date();
    var dateValid = false;
    if (startDate.toDate() > now && endDate.toDate() > startDate.toDate()) {
      dateValid = true;
    }

    if (
      eTitle == "" ||
      pin == "" ||
      (eventContent.address.fullAddress == "" &&
        !eventContent.address.online) ||
      (eventContent.liveURL == "" && eventContent.address.online) ||
      (eventContent.priceType == "EMT" &&
        (eventContent.priceEMTEmail == "" || eventContent.price == 0)) ||
      (eventContent.priceType == "CREDIT" && eventContent.price == 0) ||
      // (eventContent.priceType == "EMT" && eventContent.price == 0) ||
      // (eventContent.priceType == "CREDIT") ||
      !dateValid
    ) {
      if (eTitle == "") {
        setError("Could not create event, missing title");
      } else if (pin == "") {
        setError("Could not create event, missing checkin ID");
      } else if (
        eventContent.address.fullAddress == "" &&
        !eventContent.address.online
      ) {
        setError("Could not create event, missing address");
      } else if (eventContent.liveURL == "" && eventContent.address.online) {
        setError("Could not create event, missing live url");
      } else if (!dateValid) {
        setError(
          "Could not create event, must be future time"
        );
      } else if (
        eventContent.priceType == "CREDIT" &&
        eventContent.price <= 0
      ) {
        setError("Could not create event, price need to be more than 0.");
      } else if (
        eventContent.priceType == "CREDIT" ||
        eventContent.priceType == "EMT"
      ) {
        setError("Could not create event");
      } else {
        setError(
          "Could not create event, must be future time"
        );
      }
    } else {
      var eventId = "";
      // if (eventIDRef.current.value != null && eventIDRef.current.value != "") {
      //   eventId = eventIDRef.current.value;
      // }
      if (eventId != "") {
        if (!(await fires.collection("events").doc(eventId).get()).exists) {
          const eventFSItm = fires
            .collection("events")
            .doc(eventId)
            .set(eventContent);
          await eventFSItm;
        } else {
          const eventFSItm = fires.collection("events").add(eventContent);
          eventId = (await eventFSItm).id;
        }
      } else {
        const eventFSItm = fires.collection("events").add(eventContent);
        eventId = (await eventFSItm).id;
      }

      if (eventId != "") {
        var pStatus = "pending";
        if (publishStatus) {
          pStatus = "live";
        }

        let shareUrl = "https://evtgo.com/e/" + eventId
        if (eventShareURLRef.current.value != null){
          shareUrl = eventShareURLRef.current.value
        }

        let termsConditionsText = ""
        let termsConditionsTexts = []
        if (eventTermsConditionsRef.current.value != null){
          termsConditionsText = eventTermsConditionsRef.current.value
          let allTerms = termsConditionsText.split('\n')
          termsConditionsTexts = allTerms.filter((fStr) => {
            return fStr != "" || fStr != " "
          })
        }


        fires
          .collection("events")
          .doc(eventId)
          .update({
            shareURL: shareUrl,
            publishTimestamp: timestampNow,
            id: eventId,
            published: publishStatus,
            timestamp: timestampNow,
            statusUpdate: timestampNow,
            status: pStatus,
            terms: {
              text: termsConditionsText,
              texts: termsConditionsTexts,
            }
          })
          .then((error) => {
            if (error == null) {
              if (questionList.length > 0) {
                questionList.forEach((qItm) => {
                  fires
                    .collection("events")
                    .doc(eventId)
                    .collection("forms")
                    .add(qItm);
                });
              }

              window.location.href = "/";
            } else {
              setError("无法发布");
            }
          });

        // const currentUID = auth.currentUser.uid
        // var currentUserSet = {}
        // currentUserSet[currentUID] = {timestamp:timestampNow}
        // var newGroupChat = {"roomTitle":eventContent.title,
        //             "roomImageURL":eventContent.photoURL,
        //             "roomBgURL":eventContent.photoURL,
        //             "roomDescription":eventContent.description,
        //             "roomCountry":"加拿大",
        //             "roomTopics":[],
        //             "roomSearchable":false,
        //             "roomMute":false,
        //             "roomAddContact":true,
        //             "roomInviteOnly":false,
        //             "roomTheme": "default",
        //             "messTimestamp":timestampNow,
        //             "messPreview":"活动群<"+eventContent.title+">已创建",
        //             "status":"open",
        //             "totalUsers":500,
        //             "visible":"contact",
        //             "eventId":eventId,
        //             "uid":currentUID,
        //             "users":currentUserSet,
        //             "timestamp":timestampNow}

        //             fires.collection("group_messages").add(newGroupChat).then((docRef)=>{
        //               const gmId = docRef.id

        //             })
      }
    }
  }

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleExpImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedExpImage(file);
  };

  const handleEventImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedEventImage(file);
  };

  function sendEmailTo(emails, messageSubject, messageTexts) {
    const messageObj = { subject: messageSubject, text: messageTexts };
    fires
      .collection("mail")
      .add({ to: emails, message: messageObj })
      .then((docRef) => {
        const sentId = docRef.id;
      });
  }

  const [showChangePrice, setShowChangePrice] = useState(false)
  const changePriceItemModal = () => {
    return <Modal
    show={showChangePrice}
    hidden={eventContent.priceType != "CREDIT"}
    onHide={() => {
      setShowChangePrice(false);
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>Change price for {priceEditId}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {priceEditSuccess && <Alert variant="success">{priceEditSuccess}</Alert>}
      <Form>
      <Form.Group
        style={{ margin: "15px" }}
        hidden={eventContent.priceType != "CREDIT"}
      >
      <Form.Label
      style={{
        fontWeight: "normal",
        marginTop: "15px",
      }}
      hidden={eventContent.priceType != "CREDIT"}
    >
      Price Description *
    </Form.Label>
    <Form.Control
      type="text"
      placeholder="Please set description for price"
      ref={ticketPriceDesc}
      onChange={(e)=>{
        const priceDesc = e.target.value
        var priceItm = priceEditData
        priceItm.priceDescription = priceDesc
        setPriceEditData(priceItm)
      }}
      required
    />                        
      </Form.Group>

                            <Form.Group
                              style={{ margin: "15px" }}
                              hidden={eventContent.priceType != "CREDIT"}
                            >
                              <Form.Label
                                style={{ fontWeight: "normal" }}
                                hidden={eventContent.priceType != "CREDIT"}
                              >
                                Select Price (Total Price) *
                              </Form.Label>
                              <DropdownButton
                                alignRight
                                title={"Select Price - $" + priceEditData.price}
                                id="dropdown-menu-align-right"
                                variant="outline-success"
                                onSelect={(e) => {
                                  if (e == "cancel") {
                                    
                                  } else if (e == "custom-pricing") {
                                    
                                  } else {
                                    // var eventtContent = eventContent;
                                    // eventtContent.priceId = e;
                                    var priceEdittData = priceEditData
                                    const cPrice = priceList.filter((pitem) => {
                                      return pitem.id == e;
                                    });
                                    if (cPrice.length > 0) {
                                      const totalAmountH =
                                        cPrice[0].unit_amount;
                                      const addedonAmountH =
                                        totalAmountH -
                                        (totalAmountH - 30) / 1.029;
                                      const addedonAmount =
                                        Math.round(
                                          (addedonAmountH / 100) * 100
                                        ) / 100;
                                      const totalAmount = totalAmountH / 100;
                                      const originAmount =
                                        totalAmount - addedonAmount;

                                      setCalulatedPrice(totalAmount);
                                      setCalulatedAddOnPrice(addedonAmount);
                                      setOriginPrice(originAmount);

                                      priceEdittData.price = totalAmount;
                                      priceEdittData.priceOriginal =
                                        originAmount;
                                      priceEdittData.priceCurrency = "CAD";
                                      priceEdittData.priceId = e
                                      

                                      const custom_message =
                                        ticketPriceDesc.current.value;
                                      ticketPriceDesc.current.value = "";
                                      priceEdittData.priceDescription = custom_message

                                      setPriceEditData(priceEdittData)
                                      setPriceEditSuccess("Price selected: " + priceEdittData.price)
                                      setMessageCounter(messageCounter + 1);
                                      
                                    }
                                  }
                                }}
                              >
                                {priceList.map((priceItm) => {
                                  var priceDescription = "";
                                  if (priceItm.description != null) {
                                    priceDescription = priceItm.description;
                                  } else {
                                    priceDescription = "Price";
                                  }
                                  return (
                                    <Dropdown.Item
                                      eventKey={priceItm.id}
                                      style={{
                                        color: "black",
                                        fontSize: "17px",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {priceDescription} - $
                                      {priceItm.unit_amount / 100}
                                    </Dropdown.Item>
                                  );
                                })}
                                
                                
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  eventKey="cancel"
                                  style={{
                                    color: "black",
                                    fontSize: "17px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  Cancel
                                </Dropdown.Item>
                              </DropdownButton>
                            </Form.Group>

                            <Form.Group
                              id="price-include-tax"
                              style={{ margin: "15px", width: "250px" }}
                              hidden={eventContent.priceType == "FREE"}
                            >
                               <Form.Label
                                style={{ fontWeight: "normal" }}
                                hidden={eventContent.priceType == "FREE"}
                              >
                                Price with tax *
                              </Form.Label>
                              <DropdownButton
                                title={eventContent.prices[priceEditId].priceIncludeTax ? 'With Tax' :  "Without Tax"}
                                id="dropdown-menu-align-right"
                                variant="outline-dark"
                                hidden={eventContent.priceType == "FREE"}
                                onSelect={(e) => {
                                  var priceData = priceEditData
                                  if (e == 'include-tax'){
                                    priceData.priceIncludeTax = true
                                    priceData.priceTax = Math.round(priceData.price * 0.13 * 100) / 100
                                  }
                                  else{
                                    priceData.priceIncludeTax = false
                                    priceData.priceTax = 0
                                  }
                                  setPriceEditData(priceData)
                                  setPriceEditSuccess(`Price display tax: ${priceData.priceIncludeTax}`)
                                }}
                              >
                                <Dropdown.Item
                                  eventKey="include-tax"
                                  style={{
                                    color: "black",
                                    fontSize: "17px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  Display Tax
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  eventKey="not-include-tax"
                                  style={{
                                    color: "black",
                                    fontSize: "17px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  Without Tax
                                </Dropdown.Item>
                              </DropdownButton>
                              <Form.Label
                                style={{ fontWeight: "normal", fontSize: 12, marginTop: '5px' }}
                                hidden={eventContent.priceType == "FREE"}
                              >
                                <b>Price display tax</b> will calculate tax and show tax based on total price.
                              </Form.Label>
                              <Form.Label
                                style={{ fontWeight: "normal", fontSize: 12, marginTop: '5px' }}
                                hidden={eventContent.priceType == "FREE"}
                              >
                                <b>Price without tax</b> will not show tax in the price.
                              </Form.Label>

                            </Form.Group>

                            <Form.Group
                              id="price-total"
                              style={{ margin: "15px", }}
                              hidden={eventContent.priceType == "FREE"}
                            >
                              <div className="d-flex justify-content-between align-items-start">
                                <Form.Label
                                  hidden={!priceEditData.priceIncludeTax}
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  Tax: {" "}
                                </Form.Label>
                                <Form.Label
                                  hidden={!priceEditData.priceIncludeTax}
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                  }}
                                >
                                  ${priceEditData.priceTax}
                                  {eventContent.priceCurrency}
                                </Form.Label>
                              </div>

                              <div className="d-flex justify-content-between align-items-start">
                                <Form.Label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  Total: {" "}
                                </Form.Label>
                                <Form.Label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                  }}
                                >
                                  ${calculatedPrice}
                                  {eventContent.priceCurrency}
                                </Form.Label>
                              </div>
                            </Form.Group>

      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button 
        variant="success"
        disabled={priceEditData.price == 0}
        onClick={(e)=>{
          if (priceEditId != "" && Object.keys(eventContent.prices).includes(priceEditId)){
            
            var eventtContent = eventContent
            var allPrices = eventtContent.prices
            allPrices[priceEditId] = priceEditData
            eventtContent.prices = allPrices
            eventtContent.price = priceEditData.price
            eventtContent.priceId = priceEditData.priceId
            eventtContent.priceType = priceEditData.priceType
            setEventContent(eventtContent)
            setPriceEditId("default-price")
            setShowChangePrice(false)
          }
        }}
      >Confirm Price</Button>
    </Modal.Footer>
  </Modal>
  }

  const handleEventNewImageSubmit = (e) => {
    e.preventDefault();
    const file = selectedExpImage;
    if (!file) return;
    var uploadTask = storageRef
      .child("expresources")
      .child(currentUser.uid + "_" + uuid() + "_cover" + ".jpg")
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            setUploadS("上传已停止");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            setUploadS("已上传: " + progress + "%");
            break;
        }
      },
      (error) => {
        setError("上传错误");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setSelectedEventImage(null);
          setSelectedEventImageURL(downloadURL);

          var imgUrls = eventContent.photoURLs;
          imgUrls.push(downloadURL);

          var eventtContent = eventContent;
          eventtContent.photoURLs = imgUrls;
          setEventContent(eventtContent);
        });
      }
    );
  };

  const handleExpImageSubmit = (e) => {
    e.preventDefault();
    const file = selectedExpImage;
    if (!file) return;
    var uploadTask = storageRef
      .child("expresources")
      .child(`${currentUser.uid}_${uuid()}_cover.jpg`)
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            setUploadS("上传已停止");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            setUploadS("已上传: " + progress + "%");
            break;
        }
      },
      (error) => {
        setError("上传错误");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setSelectedBannerImageURL(downloadURL);
          setSelectedExpImage(null);
          fires
            .collection("users")
            .doc(currentUser.uid)
            .collection("resources")
            .add({ type: "image", url: downloadURL, timestamp: timestampNow });
        });
      }
    );
  };



  function isImgUrl(url) {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onerror = () => resolve(false);
      img.onload = () => resolve(true);
    });
  }

  function convertSecondsToHours(seconds) {
    var outputStr = "";
    outputStr = Math.abs(seconds / 60 / 60) + " 小时";
    return outputStr;
  }

  const handleImageSubmit = (e) => {
    e.preventDefault();
    const file = selectedImage;

    if (!file) return;

    var uploadTask = storageRef
      .child("expresources")
      .child(currentUser.uid + "_" + uuid() + ".jpg")
      .put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            setUploadS("上传已停止");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            setUploadS("已上传: " + progress + "%");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        setError("上传错误");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {});
      }
    );
  };

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <div
        className="w-100"
        style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
      >
        <Card className="border-0">
          <Card.Body>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}

            {
              changePriceItemModal()
            }

            <Form>
              <ListGroup.Item className="d-flex justify-content-between align-items-start">
                <Card.Title
                  className="text-center"
                  style={{
                    fontSize: "45px",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  {id == null ? "Create Event" : "Modify Event"}
                </Card.Title>

                <DropdownButton
                  alignRight
                  hidden={loading}
                  title={id == null ? "Publish" : "Modify"}
                  id="dropdown-menu-align-right"
                  variant="outline-success"
                  onSelect={(e) => {
                    if (e == "publish") {
                      if (id == null) {
                        publishStatus = true;
                        setShowConfirmation(true);
                      } else {
                        publishStatus = true;
                        setShowConfirmation(true);
                      }
                    } else if (e == "preview") {
                    } else {
                      //savequit
                      if (id == null) {
                        publishStatus = false;
                        setShowConfirmation(true);
                      } else {
                        publishStatus = false;
                        setShowConfirmation(true);
                      }
                    }
                  }}
                >
                  <Dropdown.Item
                    eventKey="preview"
                    style={{
                      color: "black",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    Preview
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="publish"
                    style={{
                      color: "black",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    {id == null ? "Publish" : "Modify"}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    eventKey="savequit"
                    style={{
                      color: "red",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    Save and Exit
                  </Dropdown.Item>
                </DropdownButton>
              </ListGroup.Item>

              <Tab.Container
                id="preview-tabs"
                style={{ marginTop: "15px" }}
                defaultActiveKey={
                  window.location.href.includes("#")
                    ? "#" + window.location.href.split("#")[1]
                    : "#basics"
                }
              >
                <Row style={{ marginTop: "15px" }}>
                  <Col sm={2}>
                    <ListGroup variant="dark" style={{ marginTop: "15px" }}>
                      <ListGroup.Item
                        action
                        href="#basics"
                        className="d-flex justify-content-between align-items-start border-0"
                      >
                        <div>Basic Info</div>
                        <Badge
                          bg={eventContent.title != "" ? "success" : "danger"}
                          pill
                        >
                          {eventContent.title != "" ? "✓" : "Required"}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        href="#dates"
                        className="d-flex justify-content-between align-items-start border-0"
                      >
                        <div>Date / Time</div>
                        <Badge
                          bg={timeInterval != 0 ? "success" : "danger"}
                          pill
                        >
                          {timeInterval != "" ? "✓" : "Required"}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        href="#address"
                        className="d-flex justify-content-between align-items-start border-0"
                      >
                        <div>Location</div>
                        <Badge
                          bg={
                            (eventContent.address.online &&
                              eventContent.liveURL != "") ||
                            eventContent.address.fullAddress != ""
                              ? "success"
                              : "danger"
                          }
                          pill
                        >
                          {(eventContent.address.online &&
                            eventContent.liveURL != "") ||
                          eventContent.address.fullAddress != ""
                            ? "✓"
                            : "Required"}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        href="#price"
                        className="d-flex justify-content-between align-items-start border-0"
                      >
                        <div>Price</div>
                        <Badge
                          bg={
                            eventContent.priceType == "FREE"
                              ? "success"
                              : eventContent.priceType == "EMT" &&
                                eventContent.priceEMTEmail != "" &&
                                eventContent.price > 0
                              ? "success"
                              : eventContent.priceType == "CREDIT" &&
                                eventContent.price > 0
                              ? "success"
                              : "danger"
                          }
                          pill
                        >
                          {eventContent.priceType == "FREE"
                            ? "FREE"
                            : "CREDIT($" + eventContent.price + ")"}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        href="#checkin"
                        className="d-flex justify-content-between align-items-start border-0"
                      >
                        <div>Check-In</div>
                        <Badge
                          bg={eventContent.pin != "" ? "success" : "danger"}
                          pill
                        >
                          {eventContent.pin != "" ? "✓" : "Required"}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        href="#photos"
                        className="d-flex justify-content-between align-items-start border-0"
                      >
                        <div>Photos</div>
                        <Badge
                          bg={
                            eventContent.photoURLs.length > 0
                              ? "success"
                              : "dark"
                          }
                          pill
                        >
                          {eventContent.photoURLs.length}
                        </Badge>
                      </ListGroup.Item>
                      {/* <ListGroup.Item  action href="#agenda" className="d-flex justify-content-between align-items-start border-0">
            <div>日程安排</div>
            <Badge bg={eventContent.agenda.length > 0 ? "success" : 'dark'} pill>{eventContent.agenda.length}</Badge>
          </ListGroup.Item>
          <ListGroup.Item  action href="#sponsor" className="d-flex justify-content-between align-items-start border-0">
            <div>活动赞助商</div>
            <Badge bg={eventContent.sponsor.length > 0 ? "success" : 'dark'} pill>{eventContent.sponsor.length}</Badge>
          </ListGroup.Item>
          <ListGroup.Item  action href="#luckydraw" className="d-flex justify-content-between align-items-start border-0">
            <div>幸运大抽奖</div>
            <Badge bg={Object.keys(eventContent.drawItems).length > 0 ? "success" : 'dark'} pill>{Object.keys(eventContent.drawItems).length}</Badge>
          </ListGroup.Item>
          <ListGroup.Item  action href="#invitation" className="d-flex justify-content-between align-items-start border-0">
            <div>邀请成员参加</div>
            <Badge bg={inviteeSet.length > 0 ? "success" : 'dark'} pill>{inviteeSet.length}</Badge>
          </ListGroup.Item> */}
                    </ListGroup>
                  </Col>

                  <Col sm={10}>
                    <Tab.Content style={{ marginTop: "15px" }}>
                      <Tab.Pane eventKey="#basics">
                        <Form>
                          {basicInfoMessage && (
                            <Alert variant="success">{basicInfoMessage}</Alert>
                          )}
                          <Row>
                            <Col sm={7}>
                              <div className="d-flex justify-content-between align-items-start">
                                <Card.Title
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Basic Information
                                </Card.Title>
                              </div>

                              <Form.Group
                                id="event-title"
                                style={{ margin: "15px" }}
                              >
                                <DropdownButton
                                  hidden={loading}
                                  title={"Organizer: " + gDataName}
                                  id="dropdown-menu-align-right"
                                  variant="outline-dark"
                                  disabled={Object.keys(allGroups).length == 0}
                                  onSelect={(e) => {
                                    if (e == "") {
                                    } else {
                                      const groupItm = allGroups[e];
                                      if (groupItm.name != null) {
                                        setGDataId(e);
                                        setGDataName(groupItm.name);
                                        setGData(groupItm);

                                        if (groupItm.bannerURL != null) {
                                          setSelectedBannerImageURL(
                                            groupItm.bannerURL
                                          );
                                        } else {
                                          setSelectedBannerImageURL("");
                                        }
                                        loadPriceList(groupItm);
                                        var eventD = eventContent;
                                        eventD.orgId = e;
                                        eventD.user = {
                                          userName: groupItm.name,
                                          userOrg: groupItm.name,
                                          imageURL: groupItm.profileURL,
                                          uid: currentUser.uid,
                                        };
                                        setEventContent(eventD);

                                        setMessageCounter(messageCounter + 1);
                                        setBasicInfoMessage(
                                          "信息已填写[" + messageCounter + "]"
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {Object.keys(allGroups).map((groupId) => {
                                    const groupItm = allGroups[groupId];
                                    return (
                                      <Dropdown.Item
                                        eventKey={groupId}
                                        style={{
                                          color: "black",
                                          fontSize: "17px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {groupItm.name}
                                      </Dropdown.Item>
                                    );
                                  })}
                                </DropdownButton>
                              </Form.Group>

                              <Form.Group
                                id="event-title"
                                style={{ margin: "15px" }}
                              >
                                <FloatingLabel
                                  label="Event Title *"
                                  style={{ fontWeight: "normal" }}
                                >
                                  <Form.Control
                                    type="text"
                                    ref={eventTitleRef}
                                    placeholder="Event Title *"
                                    style={{ fontWeight: "bold" }}
                                    rows={1}
                                    required
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group
                                id="event-description"
                                style={{ margin: "15px" }}
                              >
                                <FloatingLabel
                                  label="Event Description (Chinese) *"
                                  style={{ fontWeight: "normal" }}
                                >
                                  <Form.Control
                                    as="textarea"
                                    ref={eventDescriptionRef}
                                    placeholder="Event Description (Chinese) *"
                                    style={{ fontWeight: "bold" }}
                                    rows={15}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group
                                id="event-description-en"
                                style={{ margin: "15px" }}
                              >
                                <FloatingLabel
                                  label="Event Description (English)"
                                  style={{ fontWeight: "normal" }}
                                >
                                  <Form.Control
                                    as="textarea"
                                    ref={eventDescriptionENRef}
                                    placeholder="Event Description (English)"
                                    style={{ fontWeight: "bold" }}
                                    rows={15}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group
                                id="event-description-en"
                                style={{ margin: "15px" }}
                              >
                                <FloatingLabel
                                  label="Event Redirect Link"
                                  style={{ fontWeight: "normal" }}
                                >
                                  <Form.Control
                                    type="text"
                                    ref={eventShareURLRef}
                                    placeholder="Redirect Link"
                                    style={{ fontWeight: "bold" }}
                                    rows={15}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group
                                id="event-description-en"
                                style={{ margin: "15px" }}
                              >
                                <FloatingLabel
                                  label="Event Terms"
                                  style={{ fontWeight: "normal" }}
                                >
                                  <Form.Control
                                    as="textarea"
                                    ref={eventTermsConditionsRef}
                                    placeholder="Event Terms and Conditions"
                                    style={{ fontWeight: "bold" }}
                                    rows={25}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              {/* <Form.Group
                                id="event-id"
                                style={{ margin: "15px" }}
                              >
                                <FloatingLabel
                                  label="Event ID"
                                  style={{ fontWeight: "normal" }}
                                >
                                  <Form.Control
                                    type="text"
                                    ref={eventIDRef}
                                    rows={1}
                                    placeholder="Event ID"
                                    style={{ fontWeight: "bold" }}
                                    required
                                  />
                                </FloatingLabel>
                              </Form.Group> */}
                              {/* <div style={{ margin: "15px" }}>
                                <Button
                                  variant="dark"
                                  onClick={() => {
                                    const eveTitle =
                                      eventTitleRef.current.value;
                                    if (eveTitle != "") {
                                      const newEveId = eveTitle
                                        .toLowerCase()
                                        .replaceAll(" ", "-");
                                      eventIDRef.current.value = newEveId;
                                    }
                                  }}
                                >
                                  Click to generate EventID
                                </Button>
                              </div> */}
                            </Col>
                            <Col sm={5}>
                              <div className="d-flex justify-content-between align-items-start">
                                <Card.Title
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Background Image
                                </Card.Title>
                              </div>

                              <div style={{}}>
                                <Button
                                  variant="dark"
                                  style={{}}
                                  onClick={() => {
                                    setGalleryType("banner");
                                    setShowGallery(true);
                                  }}
                                >
                                  Gallery
                                </Button>
                                <Button
                                  variant="dark"
                                  style={{ marginLeft: "5px" }}
                                  onClick={() => {
                                    setShowBgImage(true);
                                  }}
                                >
                                  Upload
                                </Button>
                              </div>
                              <div style={{ marginTop: "15px" }}>
                                <img
                                  alt="cover"
                                  width={"100%"}
                                  maxHeight={"500px"}
                                  src={selectedBannerImageURL}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Modal
                            show={showGallery}
                            onHide={() => {
                              setShowGallery(false);
                            }}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Gallery</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {success && (
                                <Alert variant="success">{success}</Alert>
                              )}
                              <Card.Text>Background</Card.Text>
                              <Row
                                md={3}
                                style={{ rowGap: "0px", columnGap: "0px" }}
                              >
                                {systemImages.map((imgItm) => {
                                  return (
                                    <Col>
                                      <img
                                        src={imgItm}
                                        width="150px"
                                        height="150px"
                                        style={{ objectFit: "cover" }}
                                        onClick={() => {
                                          if (isImgUrl(imgItm)) {
                                            if (galleryType == "banner") {
                                              setSelectedBannerImageURL(imgItm);
                                            } else if (
                                              galleryType == "speakers"
                                            ) {
                                              setEventSpeakerImageURL(imgItm);
                                            } else if (
                                              galleryType == "photos"
                                            ) {
                                              var imgUrls =
                                                eventContent.photoURLs;
                                              imgUrls.push(imgItm);

                                              // var eventtContent = eventContent
                                              // eventtContent.photoURLs = imgUrls
                                              // setEventContent(eventtContent)

                                              eventNewImageRef.current.value =
                                                "";

                                              setMessageCounter(
                                                messageCounter + 1
                                              );
                                              setSuccess(
                                                "图片已添加" +
                                                  "[" +
                                                  messageCounter +
                                                  "]"
                                              );
                                            } else {
                                            }
                                            setShowGallery(false);
                                          } else {
                                            setShowGallery(false);
                                          }
                                        }}
                                      />
                                    </Col>
                                  );
                                })}
                              </Row>
                              <Card.Text style={{ marginTop: "15px" }}>
                                Uploaded
                              </Card.Text>
                              <Row
                                md={3}
                                style={{ rowGap: "0px", columnGap: "0px" }}
                              >
                                {galleryItems.map((imgItm) => {
                                  return (
                                    <Col>
                                      <img
                                        src={imgItm}
                                        width="150px"
                                        height="150px"
                                        style={{ objectFit: "cover" }}
                                        onClick={() => {
                                          if (isImgUrl(imgItm)) {
                                            if (galleryType == "banner") {
                                              setSelectedBannerImageURL(imgItm);
                                            } else if (
                                              galleryType == "speakers"
                                            ) {
                                              setEventSpeakerImageURL(imgItm);
                                            } else if (
                                              galleryType == "photos"
                                            ) {
                                              var imgUrls =
                                                eventContent.photoURLs;
                                              imgUrls.push(imgItm);

                                              // var eventtContent = eventContent
                                              // eventtContent.photoURLs = imgUrls
                                              // setEventContent(eventtContent)

                                              setMessageCounter(
                                                messageCounter + 1
                                              );
                                              setSuccess(
                                                "图片已添加" +
                                                  "[" +
                                                  messageCounter +
                                                  "]"
                                              );
                                            }
                                            setShowGallery(false);
                                          } else {
                                            setShowGallery(false);
                                          }
                                        }}
                                      />
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Modal.Body>
                          </Modal>

                          <Modal
                            show={showBgImage}
                            onHide={() => {
                              setShowBgImage(false);
                            }}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Add New Photo</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form.Group
                                id="event-bg"
                                style={{ margin: "15px" }}
                              >
                                <FloatingLabel
                                  label="活动背景图片(Banner) *"
                                  style={{ fontWeight: "normal" }}
                                >
                                  <Form.Control
                                    type="url"
                                    rows={1}
                                    ref={eventPhotoURLRef}
                                    placeholder="背景图片链接"
                                    style={{ fontWeight: "bold" }}
                                    onChange={() => {
                                      const eventPhotoURL =
                                        eventPhotoURLRef.current.value;
                                      if (isImgUrl(eventPhotoURL)) {
                                        setSelectedBannerImageURL(
                                          eventPhotoURL
                                        );
                                      } else {
                                        setBasicInfoMessage("无法识别图片");
                                      }
                                    }}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <div
                                style={{ margin: "15px" }}
                                hidden={selectedExpImage == ""}
                              >
                                {selectedExpImage && (
                                  <div>
                                    <img
                                      alt="banner"
                                      width={"250px"}
                                      src={URL.createObjectURL(
                                        selectedExpImage
                                      )}
                                    />
                                    <br />
                                    <Alert variant="primary">{uploadS}</Alert>
                                    <div>
                                      <button
                                        onClick={() =>
                                          setSelectedExpImage(null)
                                        }
                                      >
                                        移除
                                      </button>
                                      <button onClick={handleExpImageSubmit}>
                                        上传图片
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <br />
                                <input
                                  type="file"
                                  name="coverImage"
                                  title="选择图片"
                                  onChange={handleExpImageChange}
                                />
                                <div>
                                  <img
                                    alt="cover"
                                    width={"100%"}
                                    maxHeight={"500px"}
                                    style={{ marginTop: "15px" }}
                                    src={selectedBannerImageURL}
                                  />
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer></Modal.Footer>
                          </Modal>

                          <div
                            id="event-basic-info-button"
                            style={{ margin: "15px", marginTop: "45px" }}
                          >
                            <Button
                              variant="success"
                              onClick={(e) => {
                                const eTitle = eventTitleRef.current.value;
                                const eDescription =
                                  eventDescriptionRef.current.value;
                                const imageURL = selectedBannerImageURL;

                                var newDescs = eDescription.split("\n");
                                newDescs = newDescs.filter(Boolean);

                                var eventtContent = eventContent;
                                eventtContent.title = eTitle;
                                eventtContent.description = eDescription;
                                eventtContent.descriptions = {
                                  zh: newDescs,
                                  en: newDescs,
                                };
                                eventtContent.photoURL = imageURL;
                                setEventContent(eventtContent);

                                window.location.href = "#dates";

                                setMessageCounter(messageCounter + 1);
                                setBasicInfoMessage(
                                  "信息已填写[" + messageCounter + "]"
                                );
                              }}
                            >
                              Confirm
                            </Button>
                          </div>
                        </Form>
                      </Tab.Pane>

                      <Tab.Pane eventKey="#dates">
                        <div className="d-flex justify-content-between align-items-start">
                          <Card.Title
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Event Date/Time
                          </Card.Title>
                        </div>

                        <Form>
                          {timeMessage && (
                            <Alert variant="success">{timeMessage}</Alert>
                          )}
                          <Form.Group
                            id="exp-start-date"
                            style={{ margin: "15px" }}
                          >
                            <Form.Label style={{ fontWeight: "bold" }}>
                              Start Date *
                            </Form.Label>
                            <br />
                            <DateTimePicker
                              value={startDate.toDate()}
                              required
                              onChange={(date) => {
                                const year = date.getFullYear();
                                const month = `0${date.getMonth() + 1}`.slice(-2);
                                const day = `0${date.getDate()}`.slice(-2);
                                const hour = `0${date.getHours()}`.slice(-2);
                                const minute = `0${date.getMinutes()}`.slice(-2);
                                const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;
                                setStartDateString(formattedDate)

                                setStartDate(
                                  firebase.firestore.Timestamp.fromDate(date)
                                );
                              }}
                            />
                          </Form.Group>

                          <Form.Group
                            id="exp-end-date"
                            style={{ margin: "15px" }}
                          >
                            <Form.Label style={{ fontWeight: "bold" }}>
                              End Date *
                            </Form.Label>
                            <br />
                            <DateTimePicker
                              value={endDate.toDate()}
                              minDate={startDate.toDate()}
                              required
                              onChange={(date) => {

                                const year = date.getFullYear();
                                const month = `0${date.getMonth() + 1}`.slice(-2);
                                const day = `0${date.getDate()}`.slice(-2);
                                const hour = `0${date.getHours()}`.slice(-2);
                                const minute = `0${date.getMinutes()}`.slice(-2);
                                const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;
                                setEndDateString(formattedDate)

                                setEndDate(
                                  firebase.firestore.Timestamp.fromDate(date)
                                );
                              }}
                            />
                          </Form.Group>
                          <Form.Group
                            id="exp-date-button"
                            style={{ margin: "15px" }}
                          >
                            <Button
                              variant="success"
                              onClick={(e) => {
                                const dateDifferent =
                                  endDate.toDate() - startDate.toDate();
                                const dateSeconds = Math.abs(
                                  dateDifferent / 1000
                                );
                                setTimeInterval(dateSeconds);
                                var eventtContent = eventContent;
                                eventtContent.startDate = startDate;
                                eventtContent.endDate = endDate;
                                eventtContent.startDateString = startDateString
                                eventtContent.endDateString = endDateString
                                setEventContent(eventtContent);

                                setMessageCounter(messageCounter + 1);
                                setBasicInfoMessage(
                                  "信息已填写[" + messageCounter + "]"
                                );
                              }}
                            >
                              Confirm
                            </Button>
                          </Form.Group>
                        </Form>
                      </Tab.Pane>

                      <Tab.Pane eventKey="#address">
                        <Row>
                          <Col sm={8}>
                            <div className="d-flex justify-content-between align-items-start">
                              <Card.Title
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                Location
                              </Card.Title>
                            </div>

                            <Form>
                              {addressMessage && (
                                <Alert variant="success">
                                  {addressMessage}
                                </Alert>
                              )}

                              <Form.Group
                                className="d-flex align-items-center justify-content-center"
                                id="select-type"
                                style={{ marginTop: "15px" }}
                              >
                                <ButtonGroup className="align-items-center justify-content-center">
                                  <Button
                                    variant={
                                      !eventContent.address.online
                                        ? "dark"
                                        : "outline-dark"
                                    }
                                    onClick={() => {
                                      var eventtContent = eventContent;
                                      eventtContent.address.online = false;
                                      setEventContent(eventtContent);

                                      setMessageCounter(messageCounter + 1);
                                      setAddressMessage("已选择线下活动");
                                    }}
                                  >
                                    Offline
                                  </Button>
                                  <Button
                                    variant={
                                      eventContent.address.online
                                        ? "dark"
                                        : "outline-dark"
                                    }
                                    onClick={() => {
                                      var eventtContent = eventContent;
                                      eventtContent.address.online = true;
                                      setEventContent(eventtContent);

                                      setMessageCounter(messageCounter + 1);
                                      setAddressMessage("已选择线上活动");
                                    }}
                                  >
                                    Online
                                  </Button>
                                </ButtonGroup>
                              </Form.Group>
                              <Form.Group
                                id="address-online-link"
                                style={{ margin: "15px" }}
                                hidden={!eventContent.address.online}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  网上活动网址 *
                                </Form.Label>
                                <Form.Control
                                  type="url"
                                  rows={1}
                                  placeholder="https://"
                                  ref={eventLiveRef}
                                  required
                                />
                                <Form.Label style={{ fontWeight: "normal" }}>
                                  如果您有直播平台的链接，可以分享给所有观众
                                </Form.Label>
                              </Form.Group>

                              <Form.Group
                                id="address-street"
                                style={{ margin: "15px" }}
                                hidden={eventContent.address.online}
                              >

                                
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  Please type keywords to select address
                                </Form.Label>

                                <Autocomplete
                                style={{width: '100%'}}
                                placeholder={"Search address"}
                                apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
                                options={{
                                  types: [],
                                  componentRestrictions: { country: "ca" },
                                }}
                                onPlaceSelected={(place) => {
                                  const placeAddress = place.formatted_address
                                  const placeId = place.place_id
                                  const { lat, lng } =
                                            place.geometry
                                              .location;
                                  let city = ""
                                  let state = ""
                                  let country = ""
                                  let street_name = ""
                                  let street_number = ""
                                  let unit_number = ""
                                  let zipcode = ""

                                  console.log(place.address_components)

                                  place.address_components.forEach((component) => {

                                    if (
                                      component.types.includes(
                                        "locality"
                                      )
                                    )
                                      city =
                                        component.long_name;
                                    else if (
                                      component.types.includes(
                                        "administrative_area_level_1"
                                      )
                                    )
                                      state =
                                        component.long_name;
                                    else if (
                                      component.types.includes(
                                        "country"
                                      )
                                    )
                                      country =
                                        component.long_name;
                                    else if (
                                      component.types.includes(
                                        "postal_code"
                                      )
                                    )
                                      zipcode =
                                        component.long_name;
                                    else if (
                                      component.types.includes(
                                        "street_number"
                                      )
                                    )
                                      street_number =
                                        component.long_name;

                                    else if (
                                          component.types.includes(
                                            "route"
                                          )
                                        )
                                          street_name =
                                            component.long_name;
                                    
                
                                    
                                  })
                                  
                                    const streetConfirm = window.confirm(
                                      "Are you sure to import address " +
                                        placeAddress +
                                        " ?"
                                    );
                                    if (streetConfirm){
                                      addressAddressRef.current.value = street_number + " " + street_name;
                                      addressCityRef.current.value = city;
                                      addressProvRef.current.value =
                                      state;
                                      addressCountryRef.current.value =
                                      country;
                                      addressPostalRef.current.value =
                                      zipcode;
                                    }
                                }}
                              />

                                {/* <Form.Control
                                  hidden={true}
                                  type="address"
                                  rows={1}
                                  placeholder="请输入地址查询"
                                  ref={addressSearchRef}
                                /> */}

                                
                              </Form.Group>

                              <Form.Group
                                id="address-street"
                                style={{ margin: "15px", marginTop: "45px" }}
                                hidden={eventContent.address.online}
                              >
                                <FloatingLabel label="Street Address *">
                                  <Form.Control
                                    type="address"
                                    rows={1}
                                    placeholder="101 The Front Street"
                                    ref={addressAddressRef}
                                    required
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group
                                id="address-street"
                                style={{ margin: "15px" }}
                                hidden={eventContent.address.online}
                              >
                                <FloatingLabel label="City *">
                                  <Form.Control
                                    type="address"
                                    rows={1}
                                    placeholder="Toronto"
                                    ref={addressCityRef}
                                    required
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group
                                id="address-street"
                                style={{ margin: "15px" }}
                                hidden={eventContent.address.online}
                              >
                                <FloatingLabel label="Prov / State *">
                                  <Form.Control
                                    type="address"
                                    rows={1}
                                    placeholder="ON"
                                    ref={addressProvRef}
                                    required
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group
                                id="address-street"
                                style={{ margin: "15px" }}
                                hidden={eventContent.address.online}
                              >
                                <FloatingLabel label="Country *">
                                  <Form.Control
                                    type="address"
                                    rows={1}
                                    placeholder="Canada"
                                    ref={addressCountryRef}
                                    required
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group
                                id="address-street"
                                style={{ margin: "15px" }}
                                hidden={eventContent.address.online}
                              >
                                <FloatingLabel label="Postal Code *">
                                  <Form.Control
                                    type="address"
                                    rows={1}
                                    placeholder="A1A A1A"
                                    ref={addressPostalRef}
                                    required
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group
                                id="address-street"
                                style={{ margin: "15px" }}
                                hidden={eventContent.address.online}
                              >
                                <FloatingLabel label="Unit #">
                                  <Form.Control
                                    type="address"
                                    rows={1}
                                    placeholder="#101"
                                    ref={addressUnitRef}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group
                                id="address-street"
                                style={{ margin: "15px" }}
                                hidden={eventContent.address.online}
                              >
                                <FloatingLabel label="Location Name ">
                                  <Form.Control
                                    type="address"
                                    rows={1}
                                    placeholder="eg. Taller Building"
                                    ref={addressNameRef}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group
                                id="address-street"
                                style={{ margin: "15px" }}
                              >
                                <Form.Label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  {eventContent.address.fullAddress != ""
                                    ? "✅ Full address: " +
                                      eventContent.address.fullAddress
                                    : eventContent.address.online
                                    ? "✅ Online URL: " + eventContent.liveURL
                                    : " (Unfinished)"}
                                </Form.Label>
                                <br />
                                <Button
                                  variant="success"
                                  onClick={(e) => {
                                    const addressStreet =
                                      addressAddressRef.current.value;
                                    const addressCity =
                                      addressCityRef.current.value;
                                    const addressProv =
                                      addressProvRef.current.value;
                                    const addressCountry =
                                      addressCountryRef.current.value;
                                    const addressPostal =
                                      addressPostalRef.current.value;
                                    const addressUnit =
                                      addressUnitRef.current.value;
                                    const addressName =
                                      addressNameRef.current.value;

                                    const liveUrl = eventLiveRef.current.value;

                                    const fullAddress =
                                      addressStreet +
                                      ", " +
                                      addressCity +
                                      ", " +
                                      addressProv +
                                      " " +
                                      addressCountry +
                                      ", " +
                                      addressPostal;

                                    var eventAddress = {
                                      fullAddress: fullAddress,
                                      name: addressName,
                                      city: addressCity,
                                      country: addressCountry,
                                      state: addressProv,
                                      street: addressStreet,
                                      zipCode: addressPostal,
                                      unit: addressUnit,
                                      online: eventContent.address.online,
                                      lat: "0.0",
                                      lng: "0.0",
                                    };

                                    if (eventContent.address.online) {
                                      if (liveUrl != "") {
                                        var eventtContent = eventContent;
                                        eventtContent.liveURL = liveUrl;
                                        eventtContent.live = true;
                                        setEventContent(eventtContent);

                                        setMessageCounter(messageCounter + 1);
                                        setAddressMessage(
                                          "信息已填写[" + messageCounter + "]"
                                        );
                                      } else {
                                      }
                                    } else {
                                      Geocode.fromAddress(
                                        fullAddress,
                                        process.env.REACT_APP_GOOGLE_API
                                      ).then(
                                        (response) => {
                                          const { lat, lng } =
                                            response.results[0].geometry
                                              .location;
                                          eventAddress.lat = "" + lat;
                                          eventAddress.lng = "" + lng;
                                          if (
                                            addressStreet != "" &&
                                            addressCity != "" &&
                                            addressProv != "" &&
                                            addressCountry != "" &&
                                            addressPostal != ""
                                          ) {
                                            const eventtContent = {
                                              ...eventContent,
                                              address: eventAddress,
                                            };
                                            setEventContent(eventtContent);

                                            if (gData.id != null) {
                                              var addressList = [];
                                              orgAddresses.forEach(
                                                (orgAddrItem) => {
                                                  if (
                                                    orgAddrItem.fullAddress !=
                                                    ""
                                                  ) {
                                                    addressList.push(
                                                      orgAddrItem.fullAddress
                                                    );
                                                  }
                                                }
                                              );
                                              if (
                                                !addressList.includes(
                                                  eventAddress.fullAddress
                                                )
                                              ) {
                                                fires
                                                  .collection("groups")
                                                  .doc(gData.id)
                                                  .collection("address")
                                                  .add(eventAddress);
                                              }
                                            }

                                            setMessageCounter(
                                              messageCounter + 1
                                            );
                                            setAddressMessage(
                                              "信息已填写[" +
                                                messageCounter +
                                                "]"
                                            );
                                          } else {
                                            alert("请填写地址");
                                          }
                                        },
                                        (error) => {
                                          console.error(error);
                                          alert(
                                            "地址错误！无法识别您输入的地址，请检查是否为正确地址"
                                          );
                                        }
                                      );
                                    }
                                  }}
                                  style={{ marginTop: "15px" }}
                                >
                                  Confirm
                                </Button>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col sm={4} hidden={eventContent.address.online}>
                            <div className="d-flex justify-content-between align-items-start">
                              <Card.Title
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                Saved Address ({orgAddresses.length})
                              </Card.Title>
                            </div>
                            <ListGroup>
                              {orgAddresses.map((addressItm) => {
                                return (
                                  <ListGroup.Item
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                    onClick={() => {
                                      eventContent.address = addressItm;
                                      addressAddressRef.current.value =
                                        eventContent.address.street;
                                      addressCityRef.current.value =
                                        eventContent.address.city;
                                      addressProvRef.current.value =
                                        eventContent.address.state;
                                      addressCountryRef.current.value =
                                        eventContent.address.country;
                                      addressPostalRef.current.value =
                                        eventContent.address.zipCode;

                                      setMessageCounter(messageCounter + 1);
                                      setAddressMessage(
                                        "信息已填写[" + messageCounter + "]"
                                      );
                                    }}
                                  >
                                    {addressItm.fullAddress}
                                  </ListGroup.Item>
                                );
                              })}
                            </ListGroup>
                          </Col>
                        </Row>
                      </Tab.Pane>

                      <Tab.Pane eventKey="#price">
                        <div className="d-flex justify-content-between align-items-start">
                          <Card.Title
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Ticket Prices (
                            {eventContent.priceType == "FREE"
                              ? "Free"
                              : eventContent.priceType}
                            )
                          </Card.Title>
                        </div>

                        <Form.Group
                          className="d-flex align-items-center justify-content-center"
                          id="select-type"
                          style={{ marginTop: "15px" }}
                        >
                          <ButtonGroup className="align-items-center justify-content-center">
                            <Button
                              variant={
                                eventContent.priceType == "FREE"
                                  ? "dark"
                                  : "outline-dark"
                              }
                              onClick={() => {
                                var eventtContent = eventContent;
                                eventtContent.priceType = "FREE";
                                eventtContent.price = 0;
                                eventtContent.priceEMTEmail = "";
                                eventtContent.priceOriginal = 0;
                                eventtContent.prices = {
                                  "default-price": {
                                    priceType: "FREE",
                                    id: "default-price",
                                    price: 0,
                                    priceOriginal: 0,
                                    priceTax: 0,
                                    priceCurrency: "CAD",
                                    priceEMTEmail: "",
                                    priceId: "",
                                    productId: "",
                                    max: 25,
                                    min: 1,
                                    order: 0,
                                    priceDescription: "FREE Admission",
                                    priceDescriptions: [],
                                    priceIncludeTax: false,
                                    saleStart:
                                      firebase.firestore.Timestamp.fromDate(
                                        new Date()
                                      ),
                                    saleEnd: endDate,
                                  },
                                };
                                setEventContent(eventtContent);

                                setMessageCounter(messageCounter + 1);
                                setOtherInfoMessage(
                                  "信息已填写[" + messageCounter + "]"
                                );
                              }}
                            >
                              Free Ticket
                            </Button>
                            <Button
                              variant={
                                eventContent.priceType == "CREDIT"
                                  ? "dark"
                                  : "outline-dark"
                              }
                              onClick={() => {
                                var eventtContent = eventContent;
                                eventtContent.priceType = "CREDIT";
                                eventtContent.prices["default-price"] = {
                                  priceType: "CREDIT",
                                  id: "default-price",
                                  price: 0,
                                  priceOriginal: 0,
                                  priceTax: 0,
                                  priceCurrency: "CAD",
                                  priceEMTEmail: "",
                                  priceId: "",
                                  productId: "",
                                  max: 25,
                                  min: 1,
                                  order: 0,
                                  priceDescription: "Paid Admission",
                                  priceDescriptions: [],
                                  priceIncludeTax: false,
                                  saleStart:
                                    firebase.firestore.Timestamp.fromDate(
                                      new Date()
                                    ),
                                  saleEnd: endDate,
                                };
                                setEventContent(eventtContent);

                                setMessageCounter(messageCounter + 1);
                                setOtherInfoMessage(
                                  "信息已填写[" + messageCounter + "]"
                                );
                              }}
                            >
                              Paid Ticket
                            </Button>
                          </ButtonGroup>
                        </Form.Group>

                        <Row>
                          <Col sm={8}>
                            <ListGroup style={{ marginTop: "25px" }}>
                              {Object.keys(eventContent.prices).map(
                                (priceKey) => {
                                  const priceItm =
                                    eventContent.prices[priceKey];
                                  let priceIncludeTax = false
                                  if (priceItm.priceIncludeTax != null){
                                    priceIncludeTax = priceItm.priceIncludeTax
                                  }

                                  return (
                                    <ListGroup.Item>
                                      <div>Price ID: {priceItm.id}</div>
                                      <div>
                                        Price: <b>${priceItm.price}
                                        {priceItm.priceCurrency}</b>
                                      </div>
                                      <div>Description: <b>{priceItm.priceDescription}</b></div>
                                      <div>
                                        {priceItm.id == "default-price"
                                          ? "Default Price"
                                          : ""}
                                      </div>
                                      <div>
                                        <b>{priceIncludeTax
                                          ? "Display Tax"
                                          : "Price Only"}</b>
                                      </div>
                                      <div>
                                        <Card.Link
                                          onClick={() => {
                                            setShowChangePrice(true)
                                            setPriceEditId(priceItm.id);
                                            setPriceEditData(priceItm)
                                            setMessageCounter(
                                              messageCounter + 1
                                            );
                                            setOtherInfoMessage(
                                              "Edit price [" + messageCounter + "]"
                                            );
                                          }}
                                        >
                                          Edit Price
                                        </Card.Link>
                                        <Card.Link
                                          style={{ color: "red" }}
                                          hidden={
                                            priceItm.id == "default-price"
                                          }
                                          onClick={() => {
                                            var eventtContent = eventContent;
                                            delete eventtContent.prices[
                                              priceItm.id
                                            ];
                                            setEventContent(eventtContent);

                                            setPriceEditId("default-price");

                                            setMessageCounter(
                                              messageCounter + 1
                                            );
                                            setOtherInfoMessage(
                                              "价格已删除[" +
                                                messageCounter +
                                                "]"
                                            );
                                          }}
                                        >
                                          Delete Price
                                        </Card.Link>
                                      </div>
                                      <div></div>
                                    </ListGroup.Item>
                                  );
                                }
                              )}
                            </ListGroup>

                            <Form.Group
                              id="address-street"
                              style={{ marginTop: "15px" }}
                              hidden={eventContent.priceType == "FREE"}
                            >
                              <Button
                                variant="success"
                                style={{ marginTop: "5px" }}
                                onClick={(e) => {
                                  const newPriceId = uuid();
                                  var eventtContent = eventContent;
                                  eventtContent.prices[newPriceId] = {
                                    priceType: "CREDIT",
                                    id: newPriceId,
                                    order: Object.keys(eventtContent.prices).length,
                                    price: 0,
                                    priceOriginal: 0,
                                    priceTax: 0,
                                    priceCurrency: "CAD",
                                    priceEMTEmail: "",
                                    priceId: "",
                                    productId: "",
                                    max: 25,
                                    min: 1,
                                    priceDescription: "",
                                    priceDescriptions: [],
                                    priceIncludeTax: false,
                                    saleStart:
                                      firebase.firestore.Timestamp.fromDate(
                                        new Date()
                                      ),
                                    saleEnd: startDate,
                                  };
                                  setEventContent(eventtContent);
                                  setMessageCounter(messageCounter + 1);
                                  setOtherInfoMessage(
                                    "信息已填写[" + messageCounter + "]"
                                  );
                                }}
                              >
                                + Add More Price
                              </Button>
                            </Form.Group>
                          </Col>
                          <Col sm={4}>
                          <div className="d-flex justify-content-between align-items-start">
                          <Card.Title
                            style={{
                              fontSize: "17px",
                              fontWeight: "normal",
                            }}
                          >
                            Create more price
                          </Card.Title>
                          </div>
                          <Form style={{marginTop: '15px'}} onSubmit={(e) => {
                            createEventPriceAndSetPrice(
                              gData.id,
                              299,
                              gData.productId)
                          }}>
                            <Form.Group>
                              <Form.Control placeholder="Price" type="text"/>
                              <Button variant="outline-success" type="submit" style={{marginTop: '5px'}}>Add</Button>
                            </Form.Group>
                          </Form>
                          <ListGroup style={{marginTop: '15px'}}>
                          {priceList.map((priceItm) => {
                            return <ListGroup.Item>
                              ${Math.round(priceItm.unit_amount / 100 * 100) / 100} CAD
                            </ListGroup.Item>
                          })
                          }
                          </ListGroup>
                          </Col>
                        </Row>
                      </Tab.Pane>

                      <Tab.Pane eventKey="#checkin">
                        <div className="d-flex justify-content-between align-items-start">
                          <Card.Title
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Check-In Information
                          </Card.Title>
                        </div>
                        <Form>
                          {otherInfoMessage && (
                            <Alert variant="success">{otherInfoMessage}</Alert>
                          )}
                          <Form.Group
                            id="address-street"
                            style={{ margin: "15px" }}
                          >
                            <Form.Label style={{ fontWeight: "bold" }}>
                              Check-In ID *
                            </Form.Label>
                            <Form.Control
                              type="number"
                              rows={1}
                              placeholder="xxx-xxx-xxx"
                              required
                              ref={eventPINRef}
                              disabled
                            />
                            <Form.Label style={{ fontWeight: "normal" }}>
                              Check-In ID help you to go through fast checkin, please don't disclose your ID until the event date
                            </Form.Label>
                            <br />
                            <Button
                              variant="dark"
                              style={{ marginTop: "5px" }}
                              onClick={(e) => {
                                const fNumber = Math.floor(
                                  Math.random() * (999 - 100 + 1) + 100
                                );
                                const sNumber = Math.floor(
                                  Math.random() * (999 - 100 + 1) + 100
                                );
                                const tNumber = Math.floor(
                                  Math.random() * (999 - 100 + 1) + 100
                                );
                                const idString =
                                  fNumber.toString() +
                                  sNumber.toString() +
                                  tNumber.toString();
                                alert("Generated checkIn ID " + idString);
                                eventPINRef.current.value = idString;
                                setEventContent({
                                  ...eventContent,
                                  pin: idString,
                                });
                              }}
                            >
                              Generate
                            </Button>
                          </Form.Group>

                          <Form.Group
                            id="event-ticket-size"
                            style={{ margin: "15px" }}
                          >
                            <Form.Label style={{ fontWeight: "bold" }}>
                              Max Attendees
                            </Form.Label>
                            <DropdownButton
                              title={eventContent.options.max.toString()}
                              id="dropdown-menu-align-right"
                              variant="outline-dark"
                              style={{ marginTop: "15px" }}
                              onSelect={(e) => {
                                if (e == "50") {
                                  var eventtContent = eventContent;
                                  eventtContent.options.max = 50;
                                  setEventContent(eventtContent);
                                } else if (e == "100") {
                                  var eventtContent = eventContent;
                                  eventtContent.options.max = 100;
                                  setEventContent(eventtContent);
                                } else if (e == "150") {
                                  var eventtContent = eventContent;
                                  eventtContent.options.max = 150;
                                  setEventContent(eventtContent);
                                } else if (e == "200") {
                                  var eventtContent = eventContent;
                                  eventtContent.options.max = 200;
                                  setEventContent(eventtContent);
                                } else if (e == "300") {
                                  var eventtContent = eventContent;
                                  eventtContent.options.max = 300;
                                  setEventContent(eventtContent);
                                } else if (e == "500") {
                                  var eventtContent = eventContent;
                                  eventtContent.options.max = 500;
                                  setEventContent(eventtContent);
                                } else if (e == "1000") {
                                  var eventtContent = eventContent;
                                  eventtContent.options.max = 1000;
                                  setEventContent(eventtContent);
                                }
                                setMessageCounter(messageCounter + 1);
                                setOtherInfoMessage(
                                  "信息已填写[" + messageCounter + "]"
                                );
                              }}
                            >
                              <Dropdown.Item
                                eventKey="50"
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                50
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="100"
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                100
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="150"
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                150
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="200"
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                200
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="300"
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                300
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="500"
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                500
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="1000"
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                1000
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                eventKey="contact-us"
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                Contact us
                              </Dropdown.Item>
                            </DropdownButton>
                            <Form.Label style={{ fontWeight: "normal" }}>
                              Please select the max attendees
                            </Form.Label>
                          </Form.Group>

                          

                          

                          <Form.Group
                            id="address-street"
                            style={{ margin: "15px" }}
                          >
                            <Button
                              variant="success"
                              style={{ marginTop: "15px" }}
                              onClick={() => {
                                const pinValue = eventPINRef.current.value;
                                const ticketURL = eventTicketRef.current.value;

                                if (pinValue != "") {
                                  const eventtContent = eventContent;
                                  eventtContent.pin = pinValue;

                                  if (ticketURL == "") {
                                    eventtContent.ticketURL = "";
                                    eventtContent.ticket = false;
                                  } else {
                                    eventtContent.ticketURL = ticketURL;
                                    eventtContent.ticket = true;
                                  }
                                  setEventContent(eventtContent);

                                  setMessageCounter(messageCounter + 1);
                                  setOtherInfoMessage(
                                    "信息已填写[" + messageCounter + "]"
                                  );
                                }
                              }}
                            >
                              Confirm
                            </Button>
                          </Form.Group>
                        </Form>
                      </Tab.Pane>
                      <Tab.Pane eventKey="#photos">
                        <div className="d-flex justify-content-between align-items-start">
                          <Card.Title
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            活动照片
                          </Card.Title>
                        </div>

                        <Modal show={showImages} onHide={handleCloseImages}>
                          <Modal.Header closeButton>
                            <Modal.Title>添加图片</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group
                                id="event-add-image"
                                style={{ margin: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  添加活动图片 *
                                </Form.Label>
                                <Form.Control
                                  type="url"
                                  rows={1}
                                  placeholder="图片链接"
                                  ref={eventNewImageRef}
                                  onChange={() => {
                                    const eventNewImageURL =
                                      eventNewImageRef.current.value;
                                    if (isImgUrl(eventNewImageURL)) {
                                      setSelectedEventImageURL(
                                        eventNewImageURL
                                      );
                                    }
                                  }}
                                />

                                <div>
                                  <Button
                                    variant="success"
                                    style={{ marginTop: "10px" }}
                                    onClick={() => {
                                      const eventNewImageURL =
                                        eventNewImageRef.current.value;

                                      if (eventNewImageURL != "") {
                                        if (isImgUrl(eventNewImageURL)) {
                                          var imgUrls = eventContent.photoURLs;
                                          imgUrls.push(eventNewImageURL);

                                          var eventtContent = eventContent;
                                          eventtContent.photoURLs = imgUrls;
                                          setEventContent(eventtContent);

                                          eventNewImageRef.current.value = "";

                                          setMessageCounter(messageCounter + 1);
                                          setOtherInfoMessage(
                                            "信息已填写[" + messageCounter + "]"
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    Add Image
                                  </Button>
                                </div>
                                <div>
                                  {selectedEventImage && (
                                    <div>
                                      <img
                                        alt="banner"
                                        width={"250px"}
                                        src={URL.createObjectURL(
                                          selectedEventImage
                                        )}
                                      />
                                      <br />
                                      <Alert variant="primary">{uploadS}</Alert>
                                      <div>
                                        <button
                                          onClick={() =>
                                            setSelectedEventImage(null)
                                          }
                                        >
                                          Remove
                                        </button>
                                        <button
                                          onClick={handleEventNewImageSubmit}
                                        >
                                          Upload
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  <br />
                                  <input
                                    type="file"
                                    name="coverImage"
                                    title="选择图片"
                                    onChange={handleEventImageChange}
                                  />
                                  <div>
                                    <img
                                      alt="cover"
                                      width={"240px"}
                                      height={"100px"}
                                      src={selectedEventImageURL}
                                    />
                                  </div>
                                </div>
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={() => {
                                setShowImages(false);
                              }}
                            >
                              关闭
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        <ListGroup variant="dark" style={{ marginTop: "15px" }}>
                          <ListGroup.Item
                            className="d-flex justify-content-between align-items-start"
                            style={{ backgroundColor: "#EEE" }}
                          >
                            <div className="fw-bold">活动图片</div>
                            <div>
                              <Button
                                variant="success"
                                onClick={() => {
                                  setShowImages(true);
                                }}
                              >
                                上传图片
                              </Button>
                              <Button
                                variant="success"
                                style={{ marginLeft: "5px" }}
                                onClick={() => {
                                  setGalleryType("photos");
                                  setShowGallery(true);
                                }}
                              >
                                从素材库添加图片
                              </Button>
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                        <Row md={3} style={{ marginTop: "15px" }}>
                          {eventContent.photoURLs.map((photoURL) => {
                            return (
                              <Col>
                                <Card>
                                  <Card.Img
                                    src={photoURL}
                                    variant="top"
                                    style={{ maxHeight: "500px" }}
                                  />
                                  <div
                                    className="d-flex justify-content-between align-items-start"
                                    style={{
                                      marginTop: "20px",
                                      marginBottom: "10px",
                                      padding: "15px",
                                    }}
                                  >
                                    <div className="fw-bold">图片</div>
                                    <Button
                                      variant="danger"
                                      onClick={() => {
                                        var imgUrls = eventContent.photoURLs;
                                        const imgIndex =
                                          imgUrls.indexOf(photoURL);
                                        if (
                                          imgIndex >= 0 &&
                                          imgIndex < imgUrls.length
                                        ) {
                                          imgUrls.splice(imgIndex, 1);

                                          var eventtContent = eventContent;
                                          eventtContent.photoURLs = imgUrls;
                                          setEventContent(eventtContent);

                                          setMessageCounter(messageCounter + 1);
                                          setOtherInfoMessage(
                                            "信息已填写[" + messageCounter + "]"
                                          );
                                        } else {
                                          window.alert("无法删除");
                                        }
                                      }}
                                    >
                                      删除图片
                                    </Button>
                                  </div>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="#agenda">
                        <div className="d-flex justify-content-between align-items-start">
                          <Card.Title
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            日程安排({eventContent.agenda.length})
                          </Card.Title>
                        </div>

                        <ListGroup variant="dark" style={{ marginTop: "15px" }}>
                          <ListGroup.Item
                            className="d-flex justify-content-between align-items-start"
                            style={{ backgroundColor: "#EEE" }}
                          >
                            <div className="fw-bold">活动日程</div>
                            <Button
                              variant="success"
                              onClick={handleShowAgenda}
                            >
                              添加日程
                            </Button>
                          </ListGroup.Item>

                          <Modal show={showAgenda} onHide={handleCloseAgenda}>
                            <Modal.Header closeButton>
                              <Modal.Title>
                                {agendaModifyIndex == -1
                                  ? "添加活动日程"
                                  : "修改活动日程"}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form>
                                {agendaMessage && (
                                  <Alert variant="success">
                                    {agendaMessage}
                                  </Alert>
                                )}
                                <Form.Group id="exp-title" style={{}}>
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    日程标题 *
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    rows={1}
                                    ref={eventAgendaTitleRef}
                                    defaultValue={agendaMofifySet.title}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="exp-description"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    日程详情 *
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={4}
                                    ref={eventAgendaDescriptionRef}
                                    defaultValue={agendaMofifySet.description}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="exp-description"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    房间或备注
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={4}
                                    ref={eventAgendaRoomRef}
                                    defaultValue={agendaMofifySet.location}
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="exp-start-date"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    开始时间 *
                                  </Form.Label>
                                  <br />
                                  <DateTimePicker
                                    value={eventAgendaTime.toDate()}
                                    required
                                    onChange={(date) => {
                                      setEventAgendaTime(
                                        firebase.firestore.Timestamp.fromDate(
                                          date
                                        )
                                      );
                                    }}
                                  />
                                </Form.Group>
                              </Form>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={handleCloseAgenda}
                              >
                                关闭
                              </Button>
                              <Button
                                variant="success"
                                onClick={(e) => {
                                  const agendaTitle =
                                    eventAgendaTitleRef.current.value;
                                  const agendaDescription =
                                    eventAgendaDescriptionRef.current.value;
                                  const agendaRoom =
                                    eventAgendaRoomRef.current.value;
                                  const agendaDate = eventAgendaTime;
                                  var currentAgendas = agendas;

                                  if (agendaMofifySet.order == -1) {
                                    currentAgendas.push({
                                      title: agendaTitle,
                                      description: agendaDescription,
                                      location: agendaRoom,
                                      date: agendaDate,
                                      order: currentAgendas.length,
                                    });
                                    setAgendas(currentAgendas);

                                    var eventtContent = eventContent;
                                    eventtContent.agenda = agendas;
                                    setEventContent(eventtContent);
                                  } else {
                                    currentAgendas[agendaMofifySet.order] = {
                                      title: agendaTitle,
                                      description: agendaDescription,
                                      location: agendaRoom,
                                      date: agendaDate,
                                      order: agendaMofifySet.order,
                                    };
                                    setAgendas(currentAgendas);
                                    var eventtContent = eventContent;
                                    eventtContent.agenda = currentAgendas;
                                    setEventContent(eventtContent);
                                  }

                                  setMessageCounter(messageCounter + 1);
                                  setAgendaMessage(
                                    "信息已填写[" + messageCounter + "]"
                                  );

                                  eventAgendaTitleRef.current.value = "";
                                  eventAgendaDescriptionRef.current.value = "";
                                  eventAgendaRoomRef.current.value = "";

                                  setAgendaModifyIndex(-1);
                                  setAgendaModifySet({
                                    title: "",
                                    description: "",
                                    location: "",
                                    date: firebase.firestore.Timestamp.fromDate(
                                      new Date()
                                    ),
                                    order: -1,
                                  });
                                }}
                              >
                                {agendaModifyIndex == -1
                                  ? "添加活动日程"
                                  : "修改活动日程"}
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </ListGroup>

                        <ListGroup>
                          {agendaMessage && (
                            <Alert variant="success">{agendaMessage}</Alert>
                          )}
                          {agendas.map((agendaItm) => {
                            return (
                              <ListGroup.Item>
                                <Card.Text style={{}}>
                                  {agendaItm.date.toDate().toLocaleString()}
                                </Card.Text>
                                <Card.Title style={{}}>
                                  {agendaItm.title}
                                </Card.Title>
                                <Card.Text style={{}}>
                                  {agendaItm.description}
                                </Card.Text>
                                <div style={{ marginTop: "15px" }}>
                                  <Button
                                    variant="danger"
                                    style={{}}
                                    onClick={() => {
                                      if (agendaItm.order < agendas.length) {
                                        var currentAgendas = agendas;
                                        currentAgendas.splice(
                                          agendaItm.order,
                                          1
                                        );
                                        var orderI = 0;
                                        var newAgenda = [];
                                        currentAgendas.map((agendaI) => {
                                          agendaI.order = orderI;
                                          orderI = orderI + 1;
                                          newAgenda.push(agendaI);
                                        });
                                        setAgendas(newAgenda);
                                        var eventtContent = eventContent;
                                        eventtContent.agenda = newAgenda;
                                        setEventContent(eventtContent);

                                        setMessageCounter(messageCounter + 1);
                                        setAgendaMessage(
                                          "日程已删除[" + messageCounter + "]"
                                        );
                                      }
                                    }}
                                  >
                                    删除此日程
                                  </Button>
                                  <Button
                                    variant="dark"
                                    style={{ marginLeft: "5px" }}
                                    onClick={() => {
                                      if (agendaItm.order < agendas.length) {
                                        setAgendaModifyIndex(agendaItm.order);
                                        setAgendaModifySet(agendaItm);
                                        handleShowAgenda();
                                      }
                                    }}
                                  >
                                    修改日程
                                  </Button>
                                </div>
                              </ListGroup.Item>
                            );
                          })}
                        </ListGroup>
                      </Tab.Pane>
                      <Tab.Pane eventKey="#sponsor">
                        <div className="d-flex justify-content-between align-items-start">
                          <Card.Title
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            赞助商({eventContent.sponsor.length})
                          </Card.Title>
                        </div>

                        <ListGroup variant="dark" style={{ marginTop: "15px" }}>
                          <ListGroup.Item
                            className="d-flex justify-content-between align-items-start"
                            style={{ backgroundColor: "#EEE" }}
                          >
                            <div className="fw-bold">活动赞助商</div>
                            <Button
                              variant="success"
                              onClick={handleShowSponsor}
                            >
                              添加活动赞助商
                            </Button>
                          </ListGroup.Item>

                          <Modal
                            show={showSponsor}
                            onHide={() => {
                              setSponsorModifyIndex(-1);
                              setSponsorModifySet({
                                name: "",
                                description: "",
                                imageURL: "",
                                order: -1,
                                url: "",
                              });
                              handleCloseSponsor();
                            }}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>
                                {sponsorMofifySet.order == -1 ? "添加" : "修改"}
                                活动赞助商(
                                {sponsorMofifySet.order == -1
                                  ? "新建"
                                  : sponsorMofifySet.order + 1}
                                )
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form>
                                {sponsorMessage && (
                                  <Alert variant="success">
                                    {sponsorMessage}
                                  </Alert>
                                )}
                                <Form.Group id="exp-image">
                                  <img
                                    src={sponsorMofifySet.imageURL}
                                    style={{
                                      width: "100%",
                                      maxHeight: "300px",
                                    }}
                                  />
                                </Form.Group>
                                <Form.Group id="exp-title" style={{}}>
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    赞助商名称 *
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    rows={1}
                                    ref={eventSponsorNameRef}
                                    defaultValue={sponsorMofifySet.name}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="exp-description"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    赞助商介绍 *
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={8}
                                    ref={eventSponsorDescriptionRef}
                                    defaultValue={sponsorMofifySet.description}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="exp-start-date"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    赞助商banner图片链接 *
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    rows={1}
                                    ref={eventSponsorBannerRef}
                                    defaultValue={sponsorMofifySet.imageURL}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group
                                  id="exp-start-date"
                                  style={{ marginTop: "15px" }}
                                >
                                  <Form.Label style={{ fontWeight: "bold" }}>
                                    赞助商网站 *
                                  </Form.Label>
                                  <Form.Control
                                    type="url"
                                    rows={1}
                                    ref={eventSponsorURLRef}
                                    defaultValue={sponsorMofifySet.url}
                                    required
                                  />
                                </Form.Group>
                              </Form>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  setSponsorModifyIndex(-1);
                                  setSponsorModifySet({
                                    name: "",
                                    description: "",
                                    imageURL: "",
                                    order: -1,
                                    url: "",
                                  });
                                  handleCloseSponsor();
                                }}
                              >
                                关闭
                              </Button>
                              <Button
                                variant="success"
                                onClick={(e) => {
                                  const sponsorName =
                                    eventSponsorNameRef.current.value;
                                  const sponsorDescription =
                                    eventSponsorDescriptionRef.current.value;
                                  const sponsorBanner =
                                    eventSponsorBannerRef.current.value;
                                  const sponsorURL =
                                    eventSponsorURLRef.current.value;
                                  var currentSponsors = sponsors;

                                  if (sponsorMofifySet.order == -1) {
                                    currentSponsors.push({
                                      name: sponsorName,
                                      description: sponsorDescription,
                                      imageURL: sponsorBanner,
                                      order: sponsors.length,
                                      url: sponsorURL,
                                    });
                                    setSponsors(currentSponsors);

                                    var eventtContent = eventContent;
                                    eventtContent.sponsor = sponsors;
                                    setEventContent(eventtContent);
                                  } else {
                                    currentSponsors[sponsorMofifySet.order] = {
                                      name: sponsorName,
                                      description: sponsorDescription,
                                      imageURL: sponsorBanner,
                                      order: currentSponsors.length,
                                      url: sponsorURL,
                                    };
                                    setSponsors(currentSponsors);
                                    var eventtContent = eventContent;
                                    eventtContent.sponsor = sponsors;
                                    setEventContent(eventtContent);
                                  }
                                  setMessageCounter(messageCounter + 1);
                                  setSponsorMessage(
                                    "信息已填写[" + messageCounter + "]"
                                  );
                                  eventSponsorNameRef.current.value = "";
                                  eventSponsorDescriptionRef.current.value = "";
                                  eventSponsorBannerRef.current.value = "";
                                  eventSponsorURLRef.current.value = "";
                                  setSponsorModifyIndex(-1);
                                  setSponsorModifySet({
                                    name: "",
                                    description: "",
                                    imageURL: "",
                                    order: -1,
                                    url: "",
                                  });
                                }}
                              >
                                {sponsorMofifySet.order == -1 ? "添加" : "修改"}
                              </Button>
                            </Modal.Footer>
                          </Modal>

                          {sponsors.map((sponsorItm) => {
                            return (
                              <ListGroup.Item>
                                <Card.Img
                                  style={{
                                    width: "100%",
                                    maxHeight: "300px",
                                  }}
                                  src={sponsorItm.imageURL}
                                />
                                <Card.Title style={{}}>
                                  {sponsorItm.order + 1}. {sponsorItm.name}
                                </Card.Title>
                                <Card.Text style={{}}>
                                  {sponsorItm.description}
                                </Card.Text>
                                <Card.Link
                                  style={{ color: "black" }}
                                  href={sponsorItm.url}
                                >
                                  {sponsorItm.url}
                                </Card.Link>
                                <div style={{ marginTop: "15px" }}>
                                  <Button
                                    variant="danger"
                                    style={{}}
                                    onClick={() => {
                                      if (sponsorItm.order < sponsors.length) {
                                        var currentSponsors = sponsors;
                                        currentSponsors.splice(
                                          sponsorItm.order,
                                          1
                                        );
                                        var orderI = 0;
                                        var newSponsor = [];
                                        currentSponsors.map((sponsorI) => {
                                          sponsorI.order = orderI;
                                          newSponsor.push(sponsorI);
                                          orderI = orderI + 1;
                                        });
                                        setSponsors(newSponsor);
                                        var eventtContent = eventContent;
                                        eventtContent.sponsor = newSponsor;
                                        setEventContent(eventtContent);

                                        setMessageCounter(messageCounter + 1);
                                        setSponsorMessage(
                                          "赞助商已删除[" + messageCounter + "]"
                                        );
                                      }
                                    }}
                                  >
                                    删除此赞助商
                                  </Button>
                                  <Button
                                    variant="dark"
                                    style={{ marginLeft: "5px" }}
                                    onClick={() => {
                                      if (sponsorItm.order < sponsors.length) {
                                        setSponsorModifyIndex(sponsorItm.order);
                                        setSponsorModifySet(sponsorItm);
                                        handleShowSponsor();
                                      }
                                    }}
                                  >
                                    修改赞助商
                                  </Button>
                                </div>
                              </ListGroup.Item>
                            );
                          })}
                        </ListGroup>
                      </Tab.Pane>
                      <Tab.Pane eventKey="#luckydraw">
                        <div className="d-flex justify-content-between align-items-start">
                          <Card.Title
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            幸运大抽奖
                          </Card.Title>
                        </div>
                        <Card.Text>您可以添加所需要的奖品</Card.Text>
                        <DropdownButton
                          title="选择抽奖奖品"
                          id="dropdown-menu-align-right"
                          variant="outline-dark"
                          style={{ marginTop: "0px" }}
                          onSelect={(e) => {
                            if (e == "add-custom") {
                              handleShowDraw();
                            } else if (e == "add-regular") {
                              var draaws = draws;
                              const newKey = uuid();
                              draaws[newKey] = {
                                name: "幸运大奖",
                                description: "幸运大奖，奖品现在保密",
                                imageURL: "",
                                url: "",
                                order: Object.keys(draaws).length,
                                total: 1,
                                remaining: 1,
                              };
                              setDraws(draaws);

                              var eventtContent = eventContent;
                              eventtContent.drawItems = draws;
                              setEventContent(eventtContent);

                              setMessageCounter(messageCounter + 1);
                              setDrawMessage(
                                "抽奖已添加[" + messageCounter + "]"
                              );
                            } else if (e == "delete-all") {
                              setDraws({});
                              var eventtContent = eventContent;
                              eventtContent.drawItems = {};
                              setEventContent(eventtContent);

                              setMessageCounter(messageCounter + 1);
                              setDrawMessage(
                                "抽奖已删除[" + messageCounter + "]"
                              );
                            }
                          }}
                        >
                          <Dropdown.Item
                            eventKey="add-custom"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            自选抽奖项目
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="add-regular"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            普通抽奖项目
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            eventKey="delete-all"
                            style={{ color: "red", fontWeight: "bold" }}
                          >
                            全部删除
                          </Dropdown.Item>
                        </DropdownButton>

                        <ListGroup style={{ marginTop: "15px" }}>
                          <ListGroup.Item
                            className="d-flex justify-content-between align-items-start"
                            style={{ backgroundColor: "#EEE" }}
                          >
                            <div className="fw-bold">抽奖奖品</div>
                            <div className="fw-bold">
                              {Object.keys(draws).length}种奖品
                            </div>
                          </ListGroup.Item>
                          {Object.keys(draws).map((drawId) => {
                            return (
                              <ListGroup.Item>
                                <Card.Img
                                  style={{ maxHeight: "150px" }}
                                  src={draws[drawId].imageURL}
                                />
                                <Card.Title style={{}}>
                                  {draws[drawId].name}
                                </Card.Title>
                                <Card.Text style={{}}>
                                  {draws[drawId].description}
                                </Card.Text>
                                <Card.Text style={{}}>
                                  数量: {draws[drawId].total}
                                </Card.Text>
                              </ListGroup.Item>
                            );
                          })}
                        </ListGroup>

                        <Modal show={showDraw} onHide={handleCloseDraw}>
                          <Modal.Header closeButton>
                            <Modal.Title>添加抽奖</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group
                                id="draws-image"
                                style={{ marginTop: "15px" }}
                              >
                                <Card.Img
                                  style={{ maxHeight: "150px" }}
                                  src={drawImageUrl}
                                />
                              </Form.Group>
                              <Form.Group
                                id="draws-name"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  奖品名称 *
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  rows={1}
                                  defaultValue=""
                                  ref={drawNameRef}
                                  required
                                />
                              </Form.Group>
                              <Form.Group
                                id="draws-description"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  奖品介绍
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  rows={5}
                                  defaultValue=""
                                  ref={drawDescriptionRef}
                                />
                              </Form.Group>
                              <Form.Group
                                id="draws-banner"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  奖品图片
                                </Form.Label>
                                <Form.Control
                                  type="url"
                                  rows={1}
                                  defaultValue=""
                                  ref={drawImageURLRef}
                                  onChange={() => {
                                    const drawImageurl =
                                      drawImageURLRef.current.value;
                                    if (isImgUrl(drawImageurl)) {
                                      setDrawImageUrl(drawImageurl);
                                    }
                                  }}
                                />
                              </Form.Group>
                              <Form.Group
                                id="draws-url"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  奖品链接
                                </Form.Label>
                                <Form.Control
                                  type="url"
                                  rows={1}
                                  defaultValue=""
                                  ref={drawURLRef}
                                />
                              </Form.Group>
                              <Form.Group
                                id="draws-number"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  奖品数量 *
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  rows={1}
                                  defaultValue={1}
                                  min={1}
                                  ref={drawCountRef}
                                  required
                                />
                              </Form.Group>
                              <Button
                                variant="success"
                                style={{ marginTop: "15px" }}
                                onClick={(e) => {
                                  const drawName = drawNameRef.current.value;
                                  const drawDescription =
                                    drawDescriptionRef.current.value;
                                  const drawImageURL =
                                    drawImageURLRef.current.value;
                                  const drawURL = drawURLRef.current.value;
                                  const drawCount = drawCountRef.current.value;

                                  var dCount = 0;

                                  if (drawName != "") {
                                    var draaws = draws;
                                    const newKey = uuid();
                                    draaws[newKey] = {
                                      name: drawName,
                                      description: drawDescription,
                                      imageURL: drawImageURL,
                                      url: drawURL,
                                      order: Object.keys(draaws).length,
                                      total: drawCount,
                                      remaining: drawCount,
                                    };
                                    setDraws(draaws);
                                  }

                                  var eventtContent = eventContent;
                                  eventtContent.drawItems = draws;
                                  setEventContent(eventtContent);

                                  setMessageCounter(messageCounter + 1);
                                  setDrawMessage(
                                    "抽奖已添加[" + messageCounter + "]"
                                  );
                                }}
                              >
                                添加
                              </Button>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleCloseDraw}
                            >
                              关闭
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Tab.Pane>
                      <Tab.Pane eventKey="#invitation">
                        <div className="d-flex justify-content-between align-items-start">
                          <Card.Title
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            活动邀请
                          </Card.Title>
                        </div>

                        <Card.Text>
                          您可以在这里邀请您的成员，可通过email电子邮件和成员列表
                        </Card.Text>
                        <DropdownButton
                          title="邀请成员"
                          id="dropdown-menu-align-right"
                          variant="outline-success"
                          style={{ marginTop: "0px" }}
                          onSelect={(e) => {
                            if (e == "delete") {
                              setInvitees([]);
                              setInviteeSet([]);
                            } else if (e == "by-phone") {
                              handleShowInvitee();
                            } else if (e == "by-email") {
                              handleShowInvitee();
                            } else if (e == "invite-followers") {
                              var followEmails = [];
                              orgEmails.map((inviteeS) => {
                                followEmails.push(inviteeS.email);
                              });
                              setInvitees(followEmails);
                              setInviteeSet(orgEmails);

                              var eventtContent = eventContent;
                              eventtContent.invitees = followEmails;
                              eventtContent.inviteeSet = orgEmails;

                              setEventContent(eventtContent);
                              inviteeName.current.value = "";
                              inviteeEmail.current.value = "";
                            }
                          }}
                        >
                          <Dropdown.Item
                            eventKey="by-phone"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            通过电子邮件
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="by-email"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            通过电话
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="invite-followers"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            邀请粉丝({orgEmails.length})
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            eventKey="delete"
                            style={{ color: "red", fontWeight: "bold" }}
                          >
                            全部删除
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            eventKey="cancel"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            取消
                          </Dropdown.Item>
                        </DropdownButton>

                        {error && (
                          <Alert variant="success" style={{ marginTop: "5px" }}>
                            {error}
                          </Alert>
                        )}

                        <Row style={{ marginTop: "15px" }}>
                          <Col sm={6}>
                            <ListGroup style={{ marginTop: "15px" }}>
                              <ListGroup.Item>
                                已邀请({inviteeSet.length})
                              </ListGroup.Item>
                              {inviteeSet.map((inviteeItm) => {
                                return (
                                  <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                    <div>
                                      <Card.Title style={{}}>
                                        {inviteeItm.name}
                                      </Card.Title>
                                      <Card.Text style={{}}>
                                        {inviteeItm.email}
                                      </Card.Text>
                                    </div>
                                    <div>
                                      <Button
                                        variant="danger"
                                        onClick={() => {}}
                                      >
                                        删除
                                      </Button>
                                    </div>
                                  </ListGroup.Item>
                                );
                              })}
                            </ListGroup>
                          </Col>
                          <Col sm={6}>
                            <ListGroup style={{ marginTop: "15px" }}>
                              <ListGroup.Item>
                                我的会员({orgMembers.length})
                              </ListGroup.Item>
                              {orgMembers.map((memberItm) => {
                                return (
                                  <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                    hidden={invitees.includes(memberItm.email)}
                                  >
                                    <div>
                                      <Card.Title style={{}}>
                                        {memberItm.name}
                                      </Card.Title>
                                      <Card.Text style={{}}>
                                        {memberItm.email}
                                      </Card.Text>
                                    </div>
                                    <div>
                                      <Button
                                        variant="success"
                                        onClick={() => {
                                          if (
                                            !invitees.includes(memberItm.email)
                                          ) {
                                            var currentInvitees = invitees;
                                            var currentInviteeSet = inviteeSet;
                                            currentInvitees.push(
                                              memberItm.email
                                            );
                                            currentInviteeSet.push({
                                              name: memberItm.name,
                                              email: memberItm.email,
                                              order: currentInviteeSet.length,
                                            });
                                            setInvitees(currentInvitees);
                                            setInviteeSet(currentInviteeSet);

                                            var eventtContent = eventContent;
                                            eventtContent.invitees =
                                              currentInvitees;
                                            eventtContent.inviteeSet =
                                              currentInviteeSet;
                                            setEventContent(eventtContent);

                                            setMessageCounter(
                                              messageCounter + 1
                                            );
                                            setError(
                                              "已添加成员" +
                                                memberItm.email +
                                                "[" +
                                                messageCounter +
                                                "]"
                                            );
                                          } else {
                                            //already exist
                                            window.alert(
                                              "该Email已经存在邀请名单中"
                                            );
                                          }
                                        }}
                                      >
                                        添加
                                      </Button>
                                    </div>
                                  </ListGroup.Item>
                                );
                              })}
                            </ListGroup>
                          </Col>
                        </Row>

                        <Modal show={showInvitee} onHide={handleCloseInvitee}>
                          <Modal.Header closeButton>
                            <Modal.Title>增加邀请人</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group
                                id="invitee-name"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  邀请人姓名 *
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  rows={1}
                                  ref={inviteeName}
                                  defaultValue=""
                                  required
                                />
                              </Form.Group>
                              <Form.Group
                                id="invitee-email"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  邀请人电子邮箱 Email *
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  rows={1}
                                  ref={inviteeEmail}
                                  defaultValue=""
                                  required
                                />
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleCloseInvitee}
                            >
                              关闭
                            </Button>
                            <Button
                              variant="success"
                              onClick={(e) => {
                                var currentInvitees = invitees;
                                var currentInviteeSet = inviteeSet;
                                const inviteeN = inviteeName.current.value;
                                const inviteeE = inviteeEmail.current.value;
                                if (
                                  inviteeN != "" &&
                                  inviteeE != "" &&
                                  inviteeE != currentUser.email
                                ) {
                                  currentInvitees.push(inviteeE);
                                  currentInviteeSet.push({
                                    name: inviteeN,
                                    email: inviteeE,
                                    order: currentInviteeSet.length,
                                  });
                                  setInvitees(currentInvitees);
                                  setInviteeSet(currentInviteeSet);
                                  var eventtContent = eventContent;
                                  eventtContent.invitees = currentInvitees;
                                  eventtContent.inviteeSet = currentInviteeSet;
                                  setEventContent(eventtContent);

                                  inviteeName.current.value = "";
                                  inviteeEmail.current.value = "";
                                } else {
                                }
                              }}
                            >
                              添加
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Tab.Pane>

                      <Tab.Pane eventKey="#questions">
                        <div className="d-flex justify-content-between align-items-start">
                          <Card.Title
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            报名问题({questionList.length})
                          </Card.Title>
                        </div>
                        <Row>
                          <Col sm={8}>
                            <ListGroup style={{ marginTop: "15px" }}>
                              {questionList.map((questionItm) => {
                                var qTitle = "";
                                var qPlaceholder = "";
                                var qOptions = "";
                                var qType = "";
                                var qOrder = -1;
                                if (questionItm.placeholder != null) {
                                  qPlaceholder = questionItm.placeholder;
                                }
                                if (questionItm.title != null) {
                                  qTitle = questionItm.title;
                                }
                                if (questionItm.options != null) {
                                  questionItm.options.forEach((optionItm) => {
                                    qOptions += optionItm + "\n";
                                  });
                                }
                                if (questionItm.order != null) {
                                  qOrder = questionItm.order;
                                }
                                return (
                                  <ListGroup.Item>
                                    <Card.Title>标题: {qTitle}</Card.Title>
                                    <Card.Text>说明: {qPlaceholder}</Card.Text>
                                    <Card.Text>问题模式: {qType}</Card.Text>
                                    <Card.Text>选项: {qOptions}</Card.Text>
                                    <Card.Link
                                      style={{ color: "red" }}
                                      onClick={() => {
                                        if (
                                          qOrder >= 0 &&
                                          qOrder < questionList.length
                                        ) {
                                          var currentQuestionList =
                                            questionList;
                                          currentQuestionList.splice(qOrder, 1);

                                          var newQuestions = [];
                                          var indexx = 0;
                                          while (
                                            indexx < currentQuestionList.length
                                          ) {
                                            var questionNew =
                                              currentQuestionList[indexx];
                                            questionNew.order = indexx;
                                            newQuestions.push(questionNew);
                                            indexx += 1;
                                          }
                                          setQuestionList(newQuestions);
                                          setMessageCounter(messageCounter + 1);
                                          setSuccess(
                                            "已删除[" + messageCounter + "]"
                                          );
                                        }
                                      }}
                                    >
                                      删除
                                    </Card.Link>
                                  </ListGroup.Item>
                                );
                              })}
                            </ListGroup>
                          </Col>
                          <Col sm={4}>
                            <Form>
                              <Card.Title className="text-center">
                                添加报名问题
                              </Card.Title>
                              <Form.Group
                                id="speaker-name"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  报名问题标题 *
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  rows={1}
                                  defaultValue=""
                                  ref={questionTitleRef}
                                  required
                                />
                              </Form.Group>
                              <Form.Group
                                id="speaker-intro"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  报名问题介绍 (Placeholder) *
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  defaultValue=""
                                  ref={questionDescRef}
                                  required
                                />
                              </Form.Group>
                              <Form.Group
                                id="speaker-name"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  是否为必填项 *
                                </Form.Label>
                                <div>
                                  <ButtonGroup>
                                    <Button
                                      variant={
                                        questionRequired
                                          ? "dark"
                                          : "outline-dark"
                                      }
                                      onClick={() => {
                                        setQuestionRequired(true);
                                        setMessageCounter(messageCounter + 1);
                                        setSuccess("必填");
                                      }}
                                    >
                                      必填
                                    </Button>
                                    <Button
                                      variant={
                                        questionRequired
                                          ? "outline-dark"
                                          : "dark"
                                      }
                                      onClick={() => {
                                        setQuestionRequired(false);
                                        setMessageCounter(messageCounter + 1);
                                        setSuccess("不必填");
                                      }}
                                    >
                                      不必填
                                    </Button>
                                  </ButtonGroup>
                                </div>
                              </Form.Group>
                              <Form.Group
                                id="speaker-profile"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  报名问题类型 *
                                </Form.Label>
                                <br />
                                <ButtonGroup>
                                  <Button
                                    variant={
                                      questionType == 0
                                        ? "dark"
                                        : "outline-dark"
                                    }
                                    onClick={() => {
                                      setQuestionType(0);
                                      setMessageCounter(messageCounter + 1);
                                      setSuccess("多选");
                                    }}
                                  >
                                    多选
                                  </Button>
                                  <Button
                                    variant={
                                      questionType == 1
                                        ? "dark"
                                        : "outline-dark"
                                    }
                                    onClick={() => {
                                      setQuestionType(1);
                                      setMessageCounter(messageCounter + 1);
                                      setSuccess("问卷");
                                    }}
                                  >
                                    问卷
                                  </Button>
                                </ButtonGroup>
                              </Form.Group>
                              <Form.Group
                                id="speaker-profile"
                                style={{ marginTop: "15px" }}
                                hidden={questionType == 1}
                              >
                                <Form.Label
                                  style={{ fontWeight: "bold" }}
                                  hidden={questionType == 1}
                                >
                                  报名问题选项 *
                                </Form.Label>
                                <br />
                                <Button
                                  variant="dark"
                                  hidden={questionType == 1}
                                  onClick={() => {
                                    var newAnswer = window.prompt("请输入选项");
                                    if (newAnswer != "") {
                                      var answers = questionAnswers;
                                      if (!answers.includes(newAnswer)) {
                                        answers.push(newAnswer);
                                      }
                                      setQuestionAnswers(answers);
                                      setMessageCounter(messageCounter + 1);
                                      setSuccess(
                                        "已添加[" + messageCounter + "]"
                                      );
                                    }
                                  }}
                                >
                                  添加选项
                                </Button>
                                <ListGroup
                                  style={{ marginTop: "10px" }}
                                  hidden={questionType == 1}
                                >
                                  {questionAnswers.map((ansItm) => {
                                    return (
                                      <ListGroup.Item
                                        className="d-flex align-items-start justify-content-between"
                                        hidden={questionType == 1}
                                      >
                                        <Form.Label
                                          style={{ fontWeight: "bold" }}
                                          hidden={questionType == 1}
                                        >
                                          {ansItm}
                                        </Form.Label>
                                        <Button
                                          variant="danger"
                                          hidden={questionType == 1}
                                        >
                                          删除
                                        </Button>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>
                              </Form.Group>
                              <Button
                                className="w-100"
                                variant="success"
                                style={{ marginTop: "20px" }}
                                onClick={() => {
                                  let qTitle = questionTitleRef.current.value;
                                  let qDesc = questionDescRef.current.value;

                                  if (qTitle != "" && qTitle != null) {
                                    const questionItm = {
                                      optionType:
                                        questionType == 0 ? "options" : "text",
                                      options: questionAnswers,
                                      order: questionList.length,
                                      placeholder: qDesc,
                                      title: qTitle,
                                      required: questionRequired,
                                      valueType: "text",
                                      timestamp: timestampNow,
                                    };
                                    if (
                                      questionType == 0 &&
                                      questionAnswers.length == 0
                                    ) {
                                      window.alert("请添加问题和回答");
                                    } else {
                                      var newQList = questionList;
                                      newQList.push(questionItm);
                                      setQuestionAnswers([]);
                                      setQuestionList(newQList);
                                      setQuestionRequired(false);
                                      setQuestionType(0);
                                      setMessageCounter(messageCounter + 1);
                                      setSuccess(
                                        "新问题[" + messageCounter + "]"
                                      );

                                      questionTitleRef.current.value = "";
                                      questionDescRef.current.value = "";
                                    }
                                  } else {
                                    window.alert("请添加问题");
                                  }
                                }}
                              >
                                添加问题
                              </Button>
                            </Form>
                          </Col>
                        </Row>
                      </Tab.Pane>

                      <Tab.Pane eventKey="#speakers">
                        <div className="d-flex justify-content-between align-items-start">
                          <Card.Title
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            演讲嘉宾({eventContent.speakers.length})
                          </Card.Title>
                        </div>
                        <Row>
                          <Col sm={8}>
                            <ListGroup>
                              {eventContent.speakers.map((speakerItm) => {
                                var speakerImageURL = "";
                                var speakerName = "";
                                var speakerIntro = "";
                                var speakerOrder = 0;
                                if (speakerItm.name != null) {
                                  speakerName = speakerItm.name;
                                }
                                if (speakerItm.intro != null) {
                                  speakerIntro = speakerItm.intro;
                                }
                                if (speakerItm.imageURL != null) {
                                  speakerImageURL = speakerItm.imageURL;
                                }
                                if (speakerItm.order != null) {
                                  speakerOrder = speakerItm.order;
                                }
                                return (
                                  <ListGroup.Item style={{ width: "100%" }}>
                                    <Card.Text
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        color: "black",
                                        margin: 0,
                                      }}
                                    >
                                      {speakerOrder + 1}. {speakerName}
                                    </Card.Text>
                                    <Card.Text
                                      style={{
                                        fontSize: "17px",
                                        fontWeight: "normal",
                                        color: "black",
                                        margin: 0,
                                      }}
                                    >
                                      {speakerIntro}
                                    </Card.Text>
                                    <Card.Link href={speakerImageURL}>
                                      查看图片
                                    </Card.Link>
                                    <div style={{ marginTop: "15px" }}>
                                      <Card.Link
                                        style={{ color: "red" }}
                                        onClick={() => {
                                          var currentSpeakers =
                                            eventContent.speakers;
                                          var eventContentt = eventContent;
                                          if (
                                            speakerOrder <
                                              currentSpeakers.length &&
                                            speakerOrder >= 0
                                          ) {
                                            currentSpeakers.splice(
                                              speakerOrder,
                                              1
                                            );
                                            var newSpeakers = [];
                                            var indexx = 0;
                                            while (
                                              indexx < currentSpeakers.length
                                            ) {
                                              var speakerNew =
                                                currentSpeakers[indexx];
                                              speakerNew.order = indexx;
                                              newSpeakers.push(speakerNew);
                                              indexx += 1;
                                            }
                                            eventContentt.speakers =
                                              newSpeakers;
                                            setEventContent(eventContentt);
                                            setMessageCounter(
                                              messageCounter + 1
                                            );
                                            setSuccess(
                                              "已删除演讲者 " +
                                                speakerName +
                                                " [" +
                                                messageCounter +
                                                "]"
                                            );
                                          }
                                        }}
                                      >
                                        删除演讲者
                                      </Card.Link>
                                    </div>
                                  </ListGroup.Item>
                                );
                              })}
                            </ListGroup>
                          </Col>
                          <Col sm={4}>
                            <Form>
                              <Card.Title className="text-center">
                                添加演讲嘉宾
                              </Card.Title>
                              <Form.Group
                                id="speaker-name"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  演讲嘉宾姓名 *
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  rows={1}
                                  defaultValue=""
                                  ref={eventSpeakerNameRef}
                                  required
                                />
                              </Form.Group>
                              <Form.Group
                                id="speaker-intro"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  演讲嘉宾介绍 *
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  defaultValue=""
                                  ref={eventSpeakerIntroRef}
                                  required
                                />
                              </Form.Group>
                              <Form.Group
                                id="speaker-profile"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{ fontWeight: "bold" }}>
                                  演讲嘉宾照片 *
                                </Form.Label>
                                <img
                                  src={eventSpeakerImageURL}
                                  width="100%"
                                  maxHeight="500px"
                                />
                                <Button
                                  className="w-100"
                                  variant="dark"
                                  style={{ marginTop: "10px" }}
                                  onClick={() => {
                                    setGalleryType("speakers");
                                    setShowGallery(true);
                                  }}
                                >
                                  从素材库选取
                                </Button>
                              </Form.Group>
                              <Button
                                className="w-100"
                                variant="success"
                                style={{ marginTop: "20px" }}
                                onClick={() => {
                                  var speakerName =
                                    eventSpeakerNameRef.current.value;
                                  var speakerIntro =
                                    eventSpeakerIntroRef.current.value;
                                  var eventContentt = eventContent;
                                  var currentSpeakers = eventContentt.speakers;
                                  currentSpeakers.push({
                                    name: speakerName,
                                    intro: speakerIntro,
                                    imageURL: eventSpeakerImageURL,
                                    order: currentSpeakers.length,
                                    timestamp: timestampNow,
                                  });
                                  eventContentt.speakers = currentSpeakers;
                                  setEventContent(eventContentt);

                                  eventSpeakerNameRef.current.value = "";
                                  eventSpeakerIntroRef.current.value = "";
                                  setEventSpeakerImageURL("");

                                  setMessageCounter(messageCounter + 1);
                                  setSuccess(
                                    "已添加演讲者 " +
                                      speakerName +
                                      "[" +
                                      messageCounter +
                                      "]"
                                  );
                                }}
                              >
                                添加演讲者
                              </Button>
                            </Form>
                          </Col>
                        </Row>
                      </Tab.Pane>

                      <Tab.Pane eventKey="#cohost"></Tab.Pane>
                      <Tab.Pane eventKey="#thirdparty"></Tab.Pane>
                    </Tab.Content>

                    <Accordion
                      id="all-control"
                      defaultActiveKey="A"
                      style={{ marginTop: "15px" }}
                      hidden
                    >
                      {/* <Accordion.Item eventKey="Z">
              <Accordion.Header>
                <div className="fw-bold">第三方平台导入 Eventbrite Meetup等</div>
              </Accordion.Header>
              <Accordion.Body>
                <Form>
                <Form.Group id="event-basic-info-button" style={{margin: "15px"}}>
                <Form.Label style={{fontWeight: 'bold'}}>请输入Eventbrite的链接或者ID</Form.Label>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Eventbrite链接样式 https://www.eventbrite.ca/e/xxx-xxxxxx"
                  className="mb-3"
                  >
                <Form.Control type="text" ref={event3EBRef} rows={1} required />
                </FloatingLabel>
                  
                <Button variant="dark" style={{marginTop: '15px'}} onClick={(e) => {
                  handleIncomingEventbriteURL(e)
                }}>
                导入并填充
              </Button>
              </Form.Group>
                </Form>
              </Accordion.Body>
            </Accordion.Item> */}

                      <Accordion.Item eventKey="A">
                        <Accordion.Header>
                          <div className="fw-bold">活动基本信息(必填)</div>
                          <div
                            style={{
                              color: eventContent.title != "" ? "green" : "red",
                            }}
                          >
                            {eventContent.title != ""
                              ? " ✅<" + eventContent.title + ">"
                              : " (未填写)"}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body></Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="B">
                        <Accordion.Header>
                          <div className="fw-bold">活动时间(必填)</div>
                          <div
                            style={{
                              color: timeInterval != 0 ? "green" : "red",
                            }}
                          >
                            {timeInterval != 0
                              ? " ✅" +
                                "Event Start: " +
                                startDate.toDate().toLocaleDateString() +
                                " Duration: " +
                                convertSecondsToHours(timeInterval)
                              : " (未填写)"}
                          </div>
                        </Accordion.Header>

                        <Accordion.Body></Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="C">
                        <Accordion.Header>
                          <div className="fw-bold">活动地点(必填)</div>
                          <div
                            style={{
                              color:
                                eventContent.address.fullAddress != ""
                                  ? "green"
                                  : eventContent.address.online
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {eventContent.address.fullAddress != ""
                              ? " ✅" + eventContent.address.fullAddress
                              : eventContent.address.online
                              ? " ✅Online Event URL: " + eventContent.liveURL
                              : " Unfilled"}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body></Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="D">
                        <Accordion.Header>
                          <div className="fw-bold">活动签到信息(必填)</div>
                          <div
                            style={{
                              color: eventContent.pin != "" ? "green" : "red",
                            }}
                          >
                            {eventContent.pin != ""
                              ? " ✅ 签到ID已设置"
                              : " (未填写)"}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body></Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="X">
                        <Accordion.Header>
                          <div className="fw-bold">
                            活动图片({eventContent.photoURLs.length})
                          </div>
                        </Accordion.Header>
                        <Accordion.Body></Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="E">
                        <Accordion.Header>
                          <div className="fw-bold">
                            {"日程安排(" + agendas.length + ")"}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body></Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="F">
                        <Accordion.Header>
                          <div className="fw-bold">
                            {"活动赞助商(" + sponsors.length + ")"}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body></Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="G">
                        <Accordion.Header>
                          <div className="fw-bold">
                            {"幸运大抽奖(" + Object.keys(draws).length + ")"}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body></Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="H">
                        <Accordion.Header>
                          <div className="fw-bold">
                            {"邀请成员(" + invitees.length + ")"}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body></Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
              </Tab.Container>
            </Form>
          </Card.Body>
        </Card>
      </div>

      <Modal show={showConfirmation} onHide={handleCloseConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>{id == null ? "Publish Event" : "Modify Event"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {confirmationMessage && (
              <Alert variant="success">{confirmationMessage}</Alert>
            )}
            {error && <Alert variant="danger">{error}</Alert>}
            <ListGroup.Item>
              <div className="fw-normal">Background Image</div>
              <img
                alt="背景图片没有上传"
                width={"240px"}
                height={"100px"}
                src={eventContent.photoURL}
              />
            </ListGroup.Item>

            <ListGroup.Item>
              <div className="fw-normal">Event Title *</div>
              <div className="fw-bold">{eventContent.title}</div>
            </ListGroup.Item>

            <ListGroup.Item>
              <div className="fw-normal">Pricing *</div>
              <div className="fw-normal">
                {eventContent.priceType == "FREE"
                  ? "Free Ticket"
                  : eventContent.priceType == "EMT"
                  ? "Email Transfer"
                  : eventContent.priceType == "CREDIT"
                  ? "Credit Card " + Object.keys(eventContent.prices).length + " Prices" 
                  : ""}
              </div>
              <div className="fw-bold">
                ${eventContent.price}
                {eventContent.priceCurrency}
              </div>
              <div className="fw-bold">
                {eventContent.priceType == "FREE"
                  ? ""
                  : eventContent.priceType == "EMT"
                  ? "EMT给: " + eventContent.priceEMTEmail
                  : eventContent.priceType == "CREDIT"
                  ? "Powered by Stripe"
                  : ""}
              </div>
            </ListGroup.Item>

            <ListGroup.Item>
              <div className="fw-normal">Intro</div>
              <div className="fw-bold">{eventContent.description}</div>
            </ListGroup.Item>

            <ListGroup.Item>
              <div className="fw-normal">Event Start Date *</div>
              <div className="fw-bold">
                {eventContent.startDate.toDate().toLocaleString()}
              </div>
            </ListGroup.Item>

            <ListGroup.Item>
              <div className="fw-normal">Event End Date *</div>
              <div className="fw-bold">
                {eventContent.endDate.toDate().toLocaleString()}
              </div>
            </ListGroup.Item>

            <ListGroup.Item>
              <div className="fw-normal">Full Address *</div>
              <div className="fw-bold">
                {eventContent.address.fullAddress == ""
                  ? "在线活动"
                  : eventContent.address.fullAddress}
              </div>
            </ListGroup.Item>

            <ListGroup.Item>
              <div className="fw-normal">Online Link *</div>
              <div className="fw-bold">{eventContent.liveURL}</div>
            </ListGroup.Item>

            <ListGroup.Item>
              <div className="fw-normal">CheckIn ID *</div>
              <div className="fw-bold">{eventContent.pin}</div>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmation}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={(e) => {
              if (id == null) {
                publishStatus = true;
                handleEventPublish();
              } else {
                publishStatus = true;
                handleEventModify();
              }
            }}
          >
            {id == null ? "Publish" : "Publish"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
