import React, { useState, useEffect } from "react";
import { Card, Tab, Row, Col, Container, ListGroup } from "react-bootstrap";
import { fires } from "../firebase";
import { isMobile } from "react-device-detect";

export default function AllEvents() {
  const [events, setEvents] = useState([]);
  const maxW = "100%";

  useEffect(() => {
    fires
      .collection("events")
      .where("status", "==", "live")
      .orderBy("endDate", "desc")
      .get()
      .then((snapshot) => {
        const allEvents = [];
        snapshot.forEach((doc) => {
          const eventData = doc.data();
          if (
            eventData.status != null &&
            eventData.status === "live" &&
            eventData.approved != null &&
            eventData.approved
          ) {
            allEvents.push({ ...eventData, id: doc.id });
          }
        });
        setEvents(allEvents);
      });
  }, []);

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <div
        className="w-100"
        style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
      >
        <Tab.Container
          id="list-group-tabs-example"
          defaultActiveKey="#services"
          style={{ marginTop: "20px" }}
        >
          <Row>
            <Col sm={2}>
              <ListGroup variant="dark" className="border-0">
                <ListGroup.Item className="border-0" action href="#services">
                  发现
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey="#services">
                  {events.map((event) => {
                    const eId = event.id;
                    return isMobile ? (
                      <Card
                        style={{ maxWidth: "75%", marginBottom: "15px" }}
                        onClick={() => {
                          window.location.href = `https://events.letjoy.app/${eId}`;
                        }}
                      >
                        <Card.Img
                          variant="top"
                          style={{ maxHeight: "300px" }}
                          src={event.photoURL}
                        />
                        <Card.Body>
                          <Card.Title
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            {event.title}
                          </Card.Title>
                          <Card.Text>
                            {event.description.substring(0, 200)}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    ) : (
                      <Col
                        className="d-flex justify-content-center align-items-center"
                        style={{ marginTop: "15px" }}
                      >
                        <Card
                          style={{ maxWidth: "75%" }}
                          onClick={() => {
                            window.location.href = `https://events.letjoy.app/${eId}`;
                          }}
                        >
                          <Card.Img
                            variant="top"
                            style={{ maxHeight: "300px" }}
                            src={event.photoURL}
                          />
                          <Card.Body>
                            <Card.Title
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {event.title}
                            </Card.Title>
                            <Card.Text>
                              {event.description.substring(0, 200)}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </Container>
  );
}
