import React, { useState, useEffect } from "react";
import { Button, Card, Container, Row, Col, ListGroup } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { fires } from "../../firebase";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import "firebase/firestore";
import firebase from "firebase";

export default function Plan() {
  const { currentUser } = useAuth();

  const [planData, setPlanData] = useState({
    email: "",
    bean: 0,
    type: "free",
    typeCN: "",
    expiredOn: firebase.firestore.Timestamp.fromDate(new Date()),
  });

  const maxW = isMobile ? "100%" : "90%";

  useEffect(() => {
    fires
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const userData = snapshot.data();
          let orgId = userData.orgId;
          fires
            .collection("groups")
            .doc(orgId)
            .collection("private")
            .doc("plan")
            .get()
            .then((snapshotA) => {
              if (snapshotA.exists) {
                const planInfo = snapshotA.data();
                setPlanData({ ...planInfo });
              }
            });
        }
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Riley pricing</title>
        <meta property="og:title" content="Riley pricing" />
        <meta
          property="og:description"
          content="Explore the best events and groups in your area"
        />
        <meta property="og:url" content={"https://rileyevents.com/"} />
        <meta
          property="og:site_name"
          content="Riley - Explore the best events and groups in your area"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container
        className="d-flex justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: "white",
        }}
      >
        <div style={{ width: maxW, marginTop: "25px", marginBottom: "25px" }}>
          <Card className="border-0" style={{ backgroundColor: "transparent" }}>
            <Card.Body className="align-items-center justify-content-center">
              <div style={{ marginTop: "25px", marginBottom: "45px" }}>
                <Card.Title className="text-center">
                  <b>Riley Organizer Pricing</b>
                </Card.Title>
                <Card.Text className="text-center">
                  {"Current Plan: " + planData.typeCN}
                </Card.Text>
                <Card.Text className="text-center">
                  {"Expired On: " +
                    planData.expiredOn.toDate().toLocaleDateString() +
                    " " +
                    planData.expiredOn.toDate().toLocaleTimeString()}
                </Card.Text>
              </div>

              <Row md={3}>
                <Col>
                  <Card>
                    <Card.Body className="align-items-center justify-content-center">
                      <Card.Title
                        className="text-center"
                        style={{
                          marginTop: "15px",
                          fontWeight: "bold",
                          fontSize: "25px",
                        }}
                      >
                        FREE PLAN
                      </Card.Title>
                      <Card.Text
                        className="text-center"
                        style={{
                          marginTop: "5px",
                          fontWeight: "bold",
                          fontSize: "17px",
                        }}
                      >
                        $0 / MONTH
                      </Card.Text>
                      <ListGroup className="border-0">
                        <ListGroup.Item className="border-0">
                          • Handly crafted event pages (events and agendas)
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Unlimited event attendees
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Team workspace with 5 team members
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Membership with 100 members
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Exclusive organizer events and parties
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • FAQ pages support
                        </ListGroup.Item>
                      </ListGroup>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ marginTop: "15px" }}
                      >
                        <Button
                          className="w-100"
                          variant="dark"
                          style={{ height: "40px", borderRadius: "20px" }}
                          onClick={() => {}}
                        >
                          {planData.type == "free"
                            ? "Current Plan"
                            : "Select this plan"}
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Body className="align-items-center justify-content-center">
                      <Card.Title
                        className="text-center"
                        style={{
                          marginTop: "15px",
                          fontWeight: "bold",
                          fontSize: "25px",
                        }}
                      >
                        ORGANIZER PLUS
                      </Card.Title>
                      <Card.Text
                        className="text-center"
                        style={{
                          marginTop: "5px",
                          fontWeight: "bold",
                          fontSize: "17px",
                        }}
                      >
                        $19.99 / MONTH
                      </Card.Text>
                      <ListGroup className="border-0">
                        <ListGroup.Item className="border-0">
                          • Handly crafted event pages (events and agendas)
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Unlimited event attendees
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Team workspace with 20 team members
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Membership with 500 members
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Exclusive organizer events and parties
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Create lucky draw within event
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Add sponsor information
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Add speaker information
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Email support
                        </ListGroup.Item>
                      </ListGroup>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ marginTop: "15px" }}
                      >
                        <Button
                          className="w-100"
                          variant="dark"
                          style={{ height: "40px", borderRadius: "20px" }}
                          onClick={() => {}}
                        >
                          {planData.type == "plus"
                            ? "Current Plan"
                            : "Select this plan"}
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Body className="align-items-center justify-content-center">
                      <Card.Title
                        className="text-center"
                        style={{
                          marginTop: "15px",
                          fontWeight: "bold",
                          fontSize: "25px",
                        }}
                      >
                        PROFESSIONAL ORGANIZER
                      </Card.Title>
                      <Card.Text
                        className="text-center"
                        style={{
                          marginTop: "5px",
                          fontWeight: "bold",
                          fontSize: "17px",
                        }}
                      >
                        $59.99 / MONTH
                      </Card.Text>
                      <ListGroup className="border-0">
                        <ListGroup.Item className="border-0">
                          • Handly crafted event pages (events and agendas)
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Unlimited event attendees
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Team workspace with 20 team members
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Membership with 500 members
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Exclusive organizer events and parties
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Create lucky draw within event
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Add sponsor information
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Add speaker information
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Add multiple location/address information
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Customization on organizer page
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Event marketplace access
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • AI support (Planning)
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          • Email/Phone support
                        </ListGroup.Item>
                      </ListGroup>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ marginTop: "15px" }}
                      >
                        <Button
                          className="w-100"
                          variant="dark"
                          style={{ height: "40px", borderRadius: "20px" }}
                          onClick={() => {}}
                        >
                          {planData.type === "professional"
                            ? "Current Plan"
                            : "Select this plan"}
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
