import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Tab, Tabs, Carousel, Image, Container, Row, Col, ListGroup, Navbar } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { auth, fires} from "../firebase"
import { Link, useNavigate, Route, useParams } from "react-router-dom"
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
import uuid from 'react-uuid';
import Youtube from 'react-youtube';
import { height, width } from "dom-helpers"
import firebase from 'firebase/app';
import 'firebase/firestore';
import Countdown from 'react-countdown';
import LetjoyDownloadNavBar from "./DownloadNavbar"
import { Helmet } from "react-helmet"


export default function EventDetails() {
    const [error, setError] = useState("")
    const [index, setIndex] = useState(0);
    const { currentUser, logout } = useAuth()
    const navigate = useNavigate()

    const [posts, setPosts] = useState([]);
    const [contents, setContents] = useState([]);
    const [agendas, setAgendas] = useState([]);

    let { id } = useParams();
    const maxW = isMobile ? "100%" : "50%"

    
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <span>countdown finished</span>;
      } else {
        // Render a countdown
        return <span style={{fontSize: '25px', fontWeight: 'bold', color: 'green'}}>{days}天{hours}小时{minutes}分{seconds}秒</span>;
      }
    };

    useEffect(() => {
        return fires.collection('events').doc(id).get().then((snapshot) => {
            if (snapshot.exists) {
                const posts = [];
                const postData = snapshot.data();

                var viewByData = postData.viewBy;
                var viewCount = postData.viewCount;
                
                var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                const platformName = 'web'
                var deviceName = 'ios'
                if (isAndroid){
                  deviceName = 'android'
                }
                else if (isIOS) {
                  deviceName = 'ios'
                }
                else {
                  deviceName = 'web'
                }
                var fuid = ''
                if (auth.currentUser != null) {
                  fuid = auth.currentUser.uid
                }
                const viewData = {'fromUID':fuid, 'platform': platformName, 'device': deviceName, 'timestamp':timestampNow}
                viewCount = viewCount + 1
                fires.collection('events').doc(id).collection('views').add(viewData)
                fires.collection('events').doc(id).update({'viewCount':viewCount})
                
                
                if (postData.length == 0){
                  posts.push({ id: id, error: "NoAccess", error_message: "没有访问权限", error_code: 404 })
                    setPosts(posts)
                    document.title = "没有访问权限"
                    document.querySelector('meta[name="description"]').setAttribute("content","请您查看是否具有访问权限");
                    document.description = "请您查看是否具有访问权限"
                }
                else{
                    posts.push({ ...postData, id: id })
                    setPosts(posts)
                    document.title = postData.title
                    document.querySelector('meta[name="description"]').setAttribute("content",postData.description);
                    
                    if (postData.agendas != null) {
                      setAgendas(postData.agendas)
                    }


                    if (postData.photoURL != ""){
                        let link = document.querySelector("link[rel~='icon']");
                      if (!link) {
                        link = document.createElement('link');
                        link.rel = 'icon';
                        document.getElementsByTagName('head')[0].appendChild(link);
                      }
                      link.href = postData.photoURL;
                      }
                }

                
                
            }
            else{
                //deleted
                posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                setPosts(posts)
                document.title = "没有访问权限"
                document.description = "请您查看是否具有访问权限"
            }
        });
      }, []);

      return (
        <div>
        {posts.map((post) => {

        var error = post.error;

        var postTopics = post.topics
        const viewByData = post.viewBy

        

        
        if (error == "NotFound" || error == "NoAccess") {

            return <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >

      <div className="w-100" style={{ maxWidth: maxW }}>
      <Card>
      <Helmet>
          <title>无法显示</title>
          <meta name="description" content="无法显示" />
      </Helmet>
            <Card.Body>
              <Card.Title>404</Card.Title>
              <Card.Text>该用户已删除或内容不存在</Card.Text>
              <Card.Text>User deleted or the post doesn't exist</Card.Text>
              <Card.Link href="/">返回首页</Card.Link>
            </Card.Body>
            </Card>;
          </div>
          </Container>
        }
        else{
          return <>
          <Helmet>
          <title>{post.title}</title>
          <meta name="description" content={post.description} />
          <meta name="twitter:card" content={post.photoURL} />        
          <meta name="twitter:site" content="@user" />        
          <meta name="twitter:creator" content="@user" />        
          <meta name="twitter:title" content={post.title} />        
          <meta name="twitter:description" content={post.description} />        
          <meta name="twitter:image" content={post.photoURL}/>        
          <meta property="og:title" content={post.title} />        
          <meta property="og:description" content={post.description} />        
          <meta property="og:image" content={post.photoURL} />
        <meta property="og:url" content={"https://letjoy.app/event/"+post.id} />
        <meta property="og:site_name" content={post.title} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
          </Helmet>
          <Container
          className="d-flex justify-content-center"
          style={{ minHeight: "100vh", minWidth: "100vw" }}
        >
          <div className="w-100" style={{ maxWidth: maxW, marginTop: '25px', marginBottom: '25px' }}>

          <div>
                    <Card className={isMobile ? 'border-0' : 'border-0'}>
                    
                <Card.Body>
                  <Card.Img variant="top" src={post.photoURL} style={{maxHeight: '420px'}}/>
                  <Card.Text style={{marginTop: '40px', fontSize: '17px', fontWeight: 'bold', marginBottom: '5px'}}>{post.startDate.toDate().toLocaleDateString("zh")}</Card.Text>
                  <Card.Title style={{fontSize: '30px', fontWeight: 'bold'}}>{post.title}</Card.Title>
                  <Card.Text>
                  发布于 {post.timestamp.toDate().toLocaleDateString("zh")}
                  </Card.Text>

                  
                  <div className="d-flex justify-content-between align-items-start" style={{marginTop: '25px', marginBottom: '10px'}}>
                    <Card.Text className="text-center" style={{ fontSize: "20px", fontWeight:'bold', color: "#000000"}}>距离活动开始还有</Card.Text>
                    </div>
                  <Countdown date={post.startDate.toDate()}
                  renderer={renderer}>
                  <span style={{fontWeight: 'bold', fontSize: '15px', color: 'green'}}>活动正在进行</span>
                  </Countdown>

                  <Tabs
                    defaultActiveKey="info"
                    id="event-tab"
                    className="mb-3"
                    style={{marginTop: '25px'}}
                  >
                    <Tab eventKey="info" title="活动信息">
                    <div className="d-flex justify-content-between align-items-start" style={{marginTop: '25px', marginBottom: '10px'}}>
                    <Card.Text className="text-center" style={{ fontSize: "20px", fontWeight:'bold', color: "#000000"}}>基本信息</Card.Text>
                    </div>

                  <ListGroup style={{marginTop: '15px'}}>
                    <ListGroup.Item className="align-items-start">
                        <div>活动时间</div>
                        <div style={{marginTop: '5px', fontSize: '17px', fontWeight: 'bold'}}>{post.startDate.toDate().toLocaleString("en")}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="align-items-start">
                        <div>活动地点</div>
                        <div style={{marginTop: '5px', fontSize: '17px', fontWeight: 'bold'}}>{post.address.fullAddress}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="align-items-start">
                        <div>参与人数</div>
                        <div style={{marginTop: '5px', fontSize: '17px', fontWeight: 'bold'}}>100-150</div>
                    </ListGroup.Item>
                </ListGroup>

                <div className="d-flex justify-content-between align-items-start" style={{marginTop: '25px', marginBottom: '10px'}}>
                    <Card.Text className="text-center" style={{ fontSize: "20px", fontWeight:'bold', color: "#000000"}}>详细介绍</Card.Text>
                    </div>
                  <Card.Text style={{marginTop: '25px', fontSize: '20px',fontWeight: 'normal'}}>
                  {post.description}
                  </Card.Text>

                    </Tab>
                    <Tab eventKey="agenda" title="今日日程">

                    <div className="d-flex justify-content-between align-items-start" style={{marginTop: '25px', marginBottom: '10px'}}>
                    <Card.Text className="text-center" style={{ fontSize: "20px", fontWeight:'bold', color: "#000000"}}>日程安排</Card.Text>
                    </div>
                
                    <ListGroup style={{marginTop: '15px'}}>
                    {
                      agendas.forEach((agenda) => {
                        return <ListGroup.Item className="align-items-start">
                        <div>{agenda.title}</div>
                        <div style={{marginTop: '5px', fontSize: '17px', fontWeight: 'bold'}}>A</div>
                        </ListGroup.Item>
                      })
                    }
                    
                </ListGroup>

                    </Tab>
                    <Tab eventKey="attendent" title="完成签到">
                    </Tab>
                    <Tab eventKey="live-event" title="现场互动">
                    </Tab>
                    <Tab eventKey="message1" title="留言反馈">
                    </Tab>
                    </Tabs>

                </Card.Body>
                </Card>

                
                </div>
          </div>
          </Container>
          <Navbar bg="dark" variant="dark" expand="lg" sticky="bottom">
        <Container fluid>
          <Navbar.Brand>{post.user.userOrg}</Navbar.Brand>
          <Button id='event' variant="success" onClick={()=>{
          }}>{'签到'}</Button>
        </Container>
      </Navbar>
          </>
            
        }

        
    })}
        
        
</div>
        
     )
}