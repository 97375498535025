import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  Container,
  ListGroup,
  Badge,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { fires } from "../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "firebase/firestore";

export default function ArticleAnalytics() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [viewTypes, setViewTypes] = useState([]);
  const [views, setViews] = useState([]);
  const [todayViews, setTodayViews] = useState([]);

  const [articleData, setArticleData] = useState({});
  const [articleComments, setArticleComments] = useState([]);
  const [articleLikes, setArticleLikes] = useState([]);

  const [links, setLinks] = useState([]);

  const [linkCounts, setLinkCounts] = useState({});

  const maxW = isMobile ? "100%" : "100%";

  var uid = currentUser.uid;
  var cEmail = currentUser.email;

  let { id } = useParams();

  const routeToLogout = () => {
    logout();
    let path = "/";
    window.location.href = path;
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  useEffect(() => {
    return fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          var orgId = userData.orgId;
          fires
            .collection("exps")
            .doc(id)
            .get()
            .then((snapshotB) => {
              if (snapshotB.exists) {
                var aData = {};
                var artiData = snapshotB.data();
                aData = artiData;
                setArticleData(aData);
                fires
                  .collection("exps")
                  .doc(id)
                  .collection("comments")
                  .get()
                  .then((snapshotA) => {
                    var allComments = [];
                    snapshotA.docs.forEach((doc) => {
                      const commentId = doc.id;
                      const commentData = doc.data();

                      var userName = "";
                      var profileURL = "";
                      if (commentData.fromName != null) {
                        userName = commentData.fromName;
                      }
                      if (commentData.profileURL != null) {
                        profileURL = commentData.profileURL;
                      }

                      allComments.push({
                        ...commentData,
                        id: commentId,
                        fromName: userName,
                        profileURL: profileURL,
                      });
                    });
                    setArticleComments(allComments);
                  });

                fires
                  .collection("exps")
                  .doc(id)
                  .collection("likes")
                  .get()
                  .then((snapshotA) => {
                    var allLikes = [];
                    snapshotA.docs.forEach((doc) => {
                      const likeId = doc.id;
                      const likeData = doc.data();

                      var userName = "";
                      var userProfileURL = "";
                      if (likeData.userName != null) {
                        userName = likeData.userName;
                      }
                      if (likeData.userProfileURL != null) {
                        userProfileURL = likeData.userProfileURL;
                      }
                      allLikes.push({
                        ...likeData,
                        id: likeId,
                        userName: userName,
                        userProfileURL: userProfileURL,
                      });
                    });
                    setArticleLikes(allLikes);
                  });

                fires
                  .collection("exps")
                  .doc(id)
                  .collection("views")
                  .orderBy("timestamp", "desc")
                  .get()
                  .then((snapshotA) => {
                    var allViews = [];
                    var todayView = [];

                    var viewTypes = [];

                    var linkeCounts = {};

                    var todayTotal = 0;
                    var todayWeb = 0;
                    var hisTotal = 0;
                    var hisWeb = 0;

                    var todayLinkTotal = 0;
                    var todayLinkWeb = 0;
                    var hisLinkTotal = 0;
                    var hisLinkWeb = 0;

                    snapshotA.docs.forEach((doc) => {
                      const viewId = doc.id;
                      const viewData = doc.data();
                      allViews.push({ ...viewData, vid: viewId });
                    });

                    allViews.forEach((vItm) => {
                      const vItmDate = vItm.timestamp.toDate();
                      const vURL = vItm.linkId;
                      var vUA = "";
                      var uaString = "";
                      if (vItm.fromUA != null) {
                        vUA = vItm.fromUA;

                        if (vUA.includes("safari")) {
                          uaString = "Safari浏览器";
                        } else if (vUA.includes("micromessenger")) {
                          uaString = "微信";
                        } else if (vUA.includes("instagram")) {
                          uaString = "Instagram应用";
                        } else if (vUA.includes("fb")) {
                          uaString = "Facebook应用";
                        } else if (vUA.includes("linkedin")) {
                          uaString = "领英应用LinkedIn";
                        } else {
                        }
                      }

                      if (
                        isToday(vItmDate) &&
                        vItm.platform === "web" &&
                        vItm.type === "page"
                      ) {
                        todayWeb += 1;
                      }
                      if (isToday(vItmDate) && vItm.type === "page") {
                        todayTotal += 1;
                        todayView.push({
                          ...vItm,
                          fromUA: vUA,
                          uaType: uaString,
                        });
                      }
                      if (vItm.platform === "web" && vItm.type == "page") {
                        hisWeb += 1;
                      }
                      if (vItm.type === "page") {
                        hisTotal += 1;
                      }

                      if (
                        isToday(vItmDate) &&
                        vItm.platform === "web" &&
                        vItm.type === "link"
                      ) {
                        todayLinkWeb += 1;
                      }
                      if (isToday(vItmDate) && vItm.type === "link") {
                        todayLinkTotal += 1;
                        todayView.push({
                          ...vItm,
                          fromUA: vUA,
                          uaType: uaString,
                        });
                      }
                      if (vItm.platform === "web" && vItm.type === "link") {
                        hisLinkWeb++;
                      }
                      if (vItm.type === "link") {
                        hisLinkTotal++;

                        if (vURL != null) {
                          if (linkeCounts[vURL] == null) {
                            linkeCounts[vURL] = 0;
                          } else {
                            const currentCount = linkeCounts[vURL];
                            linkeCounts[vURL] = currentCount + 1;
                          }
                        }
                      }
                    });
                    viewTypes.push(todayWeb);
                    viewTypes.push(todayTotal);
                    viewTypes.push(hisWeb);
                    viewTypes.push(hisTotal);
                    viewTypes.push(todayLinkWeb);
                    viewTypes.push(todayLinkTotal);
                    viewTypes.push(hisLinkWeb);
                    viewTypes.push(hisLinkTotal);

                    setViewTypes(viewTypes);

                    setViews(allViews);

                    setTodayViews(todayView);
                  });
              }
            });
        } else {
          //deleted
          posts.push({
            id: uid,
            error: "NotFound",
            error_message: "没有访问权限",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "乐源 LETJOY";
          document.description = "开启你的海外生活";
          routeToLogout();
        }
      });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    Promise.all(promises)
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div
        className="w-100"
        style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
      >
        <Card className="border-0">
          <Card.Body className="align-items-center justify-content-center">
            <Card.Title className="text-center">文章数据分析</Card.Title>

            <div
              className="d-flex align-items-center justify-content-center"
              style={{ alignSelf: "center", display: "flex" }}
            >
              <Image src={articleData.imageURL} width="50px" height="50px" />
              <div
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  window.location.href = "/exp/" + articleData.id;
                }}
              >
                <Card.Text
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginTop: "15px",
                    marginBottom: "0px",
                    color: "black",
                  }}
                >
                  {"[" + articleData.title + "]"}
                </Card.Text>
                <Card.Text
                  class="text-secondary"
                  style={{ fontSize: "12px", fontWeight: "normal" }}
                >
                  发布于
                </Card.Text>
              </div>
            </div>

            <Row style={{ marginTop: "20px" }}>
              <Col sm={4}>
                <Card.Text
                  className="text-center"
                  style={{
                    marginTop: "15px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#000000",
                    padding: "8px",
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                  }}
                >
                  {"文章点击量(" + viewTypes[3] + ")"}
                </Card.Text>
                <ListGroup>
                  <ListGroup.Item className="d-flex justify-content-between align-items-start">
                    <div className="fw-bold">今日手机点击</div>
                    <Badge bg="primary" pill>
                      {viewTypes[1] - viewTypes[0]}
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between align-items-start">
                    <div className="fw-bold">今日网页点击</div>
                    <Badge bg="primary" pill>
                      {viewTypes[0]}
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between align-items-start">
                    <div className="fw-bold">今日总点击</div>
                    <Badge bg="primary" pill>
                      {viewTypes[1]}
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between align-items-start">
                    <div className="fw-bold">历史手机点击</div>
                    <Badge bg="primary" pill>
                      {viewTypes[3] - viewTypes[2]}
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between align-items-start">
                    <div className="fw-bold">历史网页点击</div>
                    <Badge bg="primary" pill>
                      {viewTypes[2]}
                    </Badge>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between align-items-start">
                    <div className="fw-bold">历史总点击</div>
                    <Badge bg="secondary" pill>
                      {viewTypes[3]}
                    </Badge>
                  </ListGroup.Item>
                </ListGroup>
                <Card.Text
                  className="text-center"
                  style={{
                    marginTop: "15px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#000000",
                    padding: "8px",
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                  }}
                >
                  {`今日点击详情(${todayViews.length})`}
                </Card.Text>
                <ListGroup>
                  {todayViews.map((vItm) => {
                    return (
                      <ListGroup.Item className="d-flex justify-content-between align-items-start">
                        <div className="fw-bold">
                          {vItm.platform === "web" ? "网页点击" : "APP点击"} (
                          {vItm.uaType})
                        </div>
                        <div className="fw-normal">
                          {vItm.timestamp.toDate().toLocaleString("zh")}
                        </div>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Col>

              <Col sm={4}>
                <Card.Text
                  className="text-center"
                  style={{
                    marginTop: "15px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#000000",
                    padding: "8px",
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                  }}
                >
                  {`文章点赞数(${articleLikes.length})`}
                </Card.Text>
                <ListGroup>
                  {articleLikes.map((likeItm) => {
                    return (
                      <ListGroup.Item>
                        <div
                          className="align-items-start"
                          style={{ alignSelf: "center", display: "flex" }}
                        >
                          <Image
                            roundedCircle
                            src={likeItm.userProfileURL}
                            width="50px"
                            height="50px"
                          />
                          <div style={{ marginLeft: "5px" }}>
                            <Card.Text
                              style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                                color: "black",
                              }}
                            >
                              {likeItm.userName}
                            </Card.Text>
                            <Card.Text
                              class="text-secondary"
                              style={{
                                fontSize: "12px",
                                fontWeight: "normal",
                              }}
                            >
                              {likeItm.timestamp.toDate().toLocaleString("zh")}
                            </Card.Text>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-start">
                          <div className="fw-bold">喜欢了你的文章</div>
                          <div className="fw-normal">
                            {likeItm.timestamp.toDate().toLocaleString("zh")}
                          </div>
                        </div>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Col>

              <Col sm={4}>
                <Card.Text
                  className="text-center"
                  style={{
                    marginTop: "15px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#000000",
                    padding: "8px",
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                  }}
                >
                  {`文章评论数(${articleComments.length})`}
                </Card.Text>
                <ListGroup>
                  {articleComments.map((commentItm) => {
                    return (
                      <ListGroup.Item>
                        <div
                          className="align-items-start"
                          style={{ alignSelf: "center", display: "flex" }}
                        >
                          <Image
                            roundedCircle
                            src={commentItm.profileURL}
                            width="50px"
                            height="50px"
                          />
                          <div style={{ marginLeft: "5px" }}>
                            <Card.Text
                              style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                                color: "black",
                              }}
                            >
                              {commentItm.fromName}
                            </Card.Text>
                            <Card.Text
                              class="text-secondary"
                              style={{
                                fontSize: "12px",
                                fontWeight: "normal",
                              }}
                            >
                              {"评论于 " +
                                commentItm.timestamp
                                  .toDate()
                                  .toLocaleString("zh")}
                            </Card.Text>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-start">
                          <div className="fw-bold">{commentItm.comment}</div>
                        </div>

                        <div style={{ marginTop: "15px" }}>
                          <Card.Link
                            style={{ color: "black", fontWeight: "bold" }}
                            onClick={() => {}}
                          >
                            置顶评论
                          </Card.Link>
                          <Card.Link
                            style={{ color: "black", fontWeight: "bold" }}
                            onClick={() => {
                              if (commentItm.id != null) {
                                if (commentItm.status == "pending") {
                                  fires
                                    .collection("exps")
                                    .doc(id)
                                    .collection("comments")
                                    .doc(commentItm.id)
                                    .update({ status: "approved" });
                                } else {
                                  fires
                                    .collection("exps")
                                    .doc(id)
                                    .collection("comments")
                                    .doc(commentItm.id)
                                    .update({ status: "pending" });
                                }
                              }
                            }}
                          >
                            {commentItm.status == "pending"
                              ? "精选此评论"
                              : "取消精选"}
                          </Card.Link>
                        </div>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}
