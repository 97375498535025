import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Container,
  Accordion,
  ListGroup,
  Badge,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  Image,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { auth, fires } from "../../firebase";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "firebase/firestore";
import SuperAdminAlert from "../SuperAdminAlert";
import { createTicketId, getNow } from "../Helper";
import QRScannerModal from "./QRScannerModal";
import ButtonCheckinOne from "./ButtonCheckinOne";
import ButtonCancelCheckinAll from "./ButtonCancelCheckinAll";

export default function EventCheckIn() {
  const { currentUser, logout } = useAuth();
  const [posts, setPosts] = useState([]);
  const [eventData, setEventData] = useState({});
  const [eventDisplayMembers, setEventDisplayMembers] = useState([]);
  const [eventMembers, setEventMembers] = useState([]);
  const [eventInvitees, setEventInvitees] = useState([]);
  const [eventInviteeEmails, setEventInviteeEmails] = useState([]);
  const [showInvitees, setShowInvitees] = useState(false);
  const [showAddMembers, setShowAddMembers] = useState(false);
  const [showQRScanner, setShowQRScanner] = useState(false);

  const [totalParticipants, setTotalParticipants] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const [checkedInTickets, setCheckedInTickets] = useState(0);

  const eventInviteeEmailRef = useRef();
  const eventInviteeFirstNameRef = useRef();
  const eventInviteeLastNameRef = useRef();

  const eventMemberEmailRef = useRef();
  const eventMemberFirstNameRef = useRef();
  const eventMemberLastNameRef = useRef();
  const eventMemberInviteeEmailRef = useRef();
  const eventMemberNumberRef = useRef();

  const fullScreenHandle = useFullScreenHandle();
  const [enteredFullScreen, setEnteredFullScreen] = useState(false);
  const [displayMode, setDisplayMode] = useState(0);
  const [superAdminInEffect, setSuperAdminInEffect] = useState(false);
  var uid = currentUser.uid;
  const [inviteeDisplayType, setInviteeDisplayType] = useState(0);
  const [orgData, setOrgData] = useState({});
  const [checkinTicketDetails, setCheckinTicketDetails] = useState({})
  const [checkinTickets, setCheckinTickets] = useState([]);
  const [uncheckinTickets, setUnCheckinTickets] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  let { id } = useParams();

  const routeToLogout = () => {
    logout();
    window.location.href = "/";
  };

  const routeToBack = () => {
    window.location.href = "/";
  };

  function registerTicket(ticket, eTitle, eId, eOrgName) {
    if (ticket === "") return;
    const ticketInfo = {
      validated: true,
      eventTitle: eTitle,
      eventId: eId,
      eventOrgName: eOrgName,
      timestamp: getNow(),
    };
    fires.collection("event_tickets").doc(ticket).set(ticketInfo);
  }

  function sendEmailTo(
    email,
    orgName,
    eventTitle,
    eventStartDate,
    eventAddress,
    userName,
    ticketId,
    eventId,
    ticketIds
  ) {
    const messageSubject = `<${orgName}>的活动 - ${eventTitle} 马上就要开始啦`;

    const htmlSubject = `<h2>您即将参加${orgName}的活动<b>${eventTitle}</b></h2>`;
    const body01 = `<p>您好 ${userName},</p><br>`;
    const body02 =
      "<p>您的活动<b>" +
      eventTitle +
      "</b>马上就要开始啦，收到这封信意味着您将参加活动并有机会参与抽奖。请认准本次的门票号码「" +
      ticketId +
      "」</p>";
    const body03 = "<p>以下是本次活动的详细信息</p>";
    const body04 = `<p>时间: <b>${eventStartDate}</b></p>`;
    const body05 = `<p>地址: <b>${eventAddress}</b></p>`;
    const body06 = `<p>门票: <b>${ticketId}</b></p>`;
    const bodyAdd = `<p>门票数量: <b>${ticketIds.length + 1}</b></p>`;
    let ticketBody = "";
    ticketIds.forEach((ticketN) => {
      ticketBody += `<p> ${ticketN} </p>`;
    });
    const body07 =
      "<h4>详细内容请点击<a href='https://events.letjoy.app/" +
      eventId +
      "'>活动链接</a>并于活动当天展示此信息签到</h4><br>";
    const body08 =
      "<p>发现更多活动，欢迎下载<a href='https://leyuan.page.link/app'>乐源APP</a></p>";
    const body09 = `<br><h3><b>${orgName}</b></h3>`;
    const htmlString =
      htmlSubject +
      body01 +
      body02 +
      body03 +
      body04 +
      body05 +
      bodyAdd +
      body06 +
      ticketBody +
      body07 +
      body08 +
      body09;

    if (email !== "") {
      const messageObj = {
        subject: messageSubject,
        html: htmlString,
      };
      fires
        .collection("mail")
        .add({
          to: [email],
          message: messageObj,
        })
        .then((docRef) => {
          const sentId = docRef.id;
          fires
            .collection("events")
            .doc(id)
            .collection("members")
            .doc(email)
            .update({ mailSentId: sentId });
        });
    }
  }

  useEffect(() => {
    fires
      .collection("events")
      .doc(id)
      .collection("tickets")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshotA) => {
        const allTicketsD = {};
        const uncheckedTicketIds = [];
        const checkedTicketIds = [];
        snapshotA.docs.forEach((doc) => {
          const ticketId = doc.id;
          const ticketData = doc.data();
          let checkinStatus = false;
          if (ticketData.checkin == null) {
            checkinStatus = false;
          } else if (ticketData.checkin) {
            checkinStatus = ticketData.checkin;
          } else {
            checkinStatus = false;
          }
          if (checkinStatus) {
            checkedTicketIds.push(ticketId);
          } else {
            uncheckedTicketIds.push(ticketId);
          }
          allTicketsD[ticketId] = ticketData;
        });
        setUnCheckinTickets(uncheckedTicketIds);
        setCheckinTickets(checkedTicketIds);
      });

    fires
      .collection("events")
      .doc(id)
      .collection("members")
      .orderBy("name", "asc")
      .onSnapshot((snapshotA) => {
        const allMembers = [];
        let totalTicketCount = 0;
        let checkedInTicketCount = 0;
        let totalParticipantsCount = 0;

        snapshotA.docs.forEach((doc) => {
          const memberData = doc.data();
          const total = memberData.quantity ?? 1;
          const checkinCount = memberData.checkinCount ?? 0;

          totalTicketCount += total;
          checkedInTicketCount += checkinCount;
          totalParticipantsCount++;

          allMembers.push({ ...memberData, id: doc.id });
        });

        setEventMembers(allMembers);
        setTotalTickets(totalTicketCount);
        setCheckedInTickets(checkedInTicketCount);
        setTotalParticipants(totalParticipantsCount);

        const filteredMembers = allMembers.filter((member) => {
          const lowerCaseQuery = searchQuery.toLowerCase();
          return (
            member.name.toLowerCase().includes(lowerCaseQuery) ||
            member.email.toLowerCase().includes(lowerCaseQuery) ||
            (member.tickets ?? []).some((ticket) =>
              ticket.toLowerCase().includes(lowerCaseQuery)
            )
          );
        });

        if (window.location.href.endsWith("#showuncheck")) {
          const uncheckMembers = filteredMembers.filter((memItm) => {
            const total = memItm.quantity ?? 1;
            const checkinCount = memItm.checkinCount ?? 0;
            const uncheckedin = Math.max(0, total - checkinCount);
            return uncheckedin === total;
          });
          setEventDisplayMembers(uncheckMembers);
        } else if (window.location.href.endsWith("#showall")) {
          setEventDisplayMembers(filteredMembers);
        } else if (window.location.href.endsWith("#showchecked")) {
          const checkedMembers = filteredMembers.filter((memItm) => {
            const total = memItm.quantity ?? 1;
            const checkinCount = memItm.checkinCount ?? 0;
            const uncheckedin = Math.max(0, total - checkinCount);
            return uncheckedin === 0;
          });
          setEventDisplayMembers(checkedMembers);
        } else if (window.location.href.endsWith("#showpartial")) {
          const partialCheckinMembers = filteredMembers.filter((memItm) => {
            const total = memItm.quantity ?? 1;
            const checkinCount = memItm.checkinCount ?? 0;
            const uncheckedin = Math.max(0, total - checkinCount);
            return uncheckedin > 0 && uncheckedin < total;
          });
          setEventDisplayMembers(partialCheckinMembers);
        } else {
          setEventDisplayMembers(filteredMembers);
        }
      });
  }, [searchQuery, id]);

  useEffect(() => {
    loadAllMembersRealTime("name", "asc");
  }, []);

  function loadAllMembersRealTime(filterBy, orderBy) {
    // Members
    fires
      .collection("events")
      .doc(id)
      .collection("tickets")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshotA) => {
        const allTicketsD = {};
        const uncheckedTicketIds = [];
        const checkedTicketIds = [];
        
        snapshotA.docs.forEach((doc) => {
          const ticketId = doc.id;
          const ticketData = doc.data();
          let checkinStatus = false;
          if (ticketData.checkin == null) {
            checkinStatus = false;
          } else if (ticketData.checkin) {
            checkinStatus = ticketData.checkin;
          } else {
            checkinStatus = false;
          }
          if (checkinStatus) {
            checkedTicketIds.push(ticketId);
          } else {
            uncheckedTicketIds.push(ticketId);
          }
          allTicketsD[ticketId] = ticketData;
        });
        setUnCheckinTickets(uncheckedTicketIds);
        setCheckinTickets(checkedTicketIds);
        setCheckinTicketDetails(allTicketsD)
      });

    fires
      .collection("events")
      .doc(id)
      .collection("members")
      .orderBy(filterBy, orderBy)
      .onSnapshot((snapshotA) => {
        const allMembers = [];
        let totalTicketCount = 0;
        let checkedInTicketCount = 0;
        let totalParticipantsCount = 0;

        snapshotA.docs.forEach((doc) => {
          const memberData = doc.data();
          const total = memberData.quantity ?? 1;
          const checkinCount = memberData.checkinCount ?? 0;

          totalTicketCount += total;
          checkedInTicketCount += checkinCount;
          totalParticipantsCount++; // Counting each member as a participant

          allMembers.push({ ...memberData, id: doc.id });
        });

        setEventMembers(allMembers);
        setTotalTickets(totalTicketCount);
        setCheckedInTickets(checkedInTicketCount);
        setTotalParticipants(totalParticipantsCount);

        if (window.location.href.endsWith("#showuncheck")) {
          const uncheckMembers = allMembers.filter((memItm) => {
            const total = memItm.quantity ?? 1;
            const checkinCount = memItm.checkinCount ?? 0;
            const uncheckedin = Math.max(0, total - checkinCount);
            return uncheckedin === total;
          });
          setEventDisplayMembers(uncheckMembers);
        } else if (window.location.href.endsWith("#showall")) {
          setEventDisplayMembers(allMembers);
        } else if (window.location.href.endsWith("#showchecked")) {
          const checkedMembers = allMembers.filter((memItm) => {
            const total = memItm.quantity ?? 1;
            const checkinCount = memItm.checkinCount ?? 0;
            const uncheckedin = Math.max(0, total - checkinCount);
            return uncheckedin === 0;
          });
          setEventDisplayMembers(checkedMembers);
        } else if (window.location.href.endsWith("#showpartial")) {
          const partialCheckinMembers = allMembers.filter((memItm) => {
            const total = memItm.quantity ?? 1;
            const checkinCount = memItm.checkinCount ?? 0;
            const uncheckedin = Math.max(0, total - checkinCount);
            return uncheckedin > 0 && uncheckedin < total;
          });
          setEventDisplayMembers(partialCheckinMembers);
        } else {
          setEventDisplayMembers(allMembers);
        }
      });
  }

  async function loadSuperAdminEventInformation() {
    fires
      .collection("events")
      .doc(id)
      .get()
      .then((snapshotB) => {
        if (!snapshotB.exists) {
          return;
        }
        let aData = {};
        const artiData = snapshotB.data();
        aData = artiData;
        setEventData(aData);
        const orgId = aData.orgId;
        if (orgId == null) {
          routeToBack();
        } else if (orgId === "") {
          //event orgId empty
          routeToBack();
        } else {
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotA) => {
              if (snapshotA.exists) {
                const orgD = snapshotA.data();
                setOrgData({ ...orgD });
                //real-time
                //loadInviteeRealtime()
                loadAllMembersRealTime("name", "asc");
                return;
              }
              routeToBack();
            });
        }
      });
  }

  async function loadRegularEventInformation(uorgId) {
    fires
      .collection("events")
      .doc(id)
      .get()
      .then((snapshotB) => {
        if (!snapshotB.exists) {
          return;
        }
        let aData = {};
        const artiData = snapshotB.data();
        aData = artiData;
        setEventData(aData);
        const orgId = aData.orgId;
        if (orgId == null) {
          routeToBack();
        } else if (orgId === "") {
          //event orgId empty
          routeToBack();
        } else if (uorgId === orgId) {
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotA) => {
              if (snapshotA.exists) {
                const orgD = snapshotA.data();
                setOrgData({ ...orgD });
                //real-time
                //loadInviteeRealtime()
                loadAllMembersRealTime("name", "asc");
                return;
              }
              routeToBack();
            });
        } else {
          //id is not match
          routeToBack();
        }
      });
  }

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const userData = snapshot.data();
          const uorgId = userData.orgId;
          fires
            .collection("admin")
            .doc(uid)
            .get()
            .then((snap_A) => {
              if (snap_A.exists) {
                //super admin
                setSuperAdminInEffect(true);
                loadSuperAdminEventInformation();
              } else {
                //check events
                loadRegularEventInformation(uorgId);
              }
            });
          return;
        }
        //deleted
        posts.push({
          id: uid,
          error: "NotFound",
          error_message: "没有访问权限",
          error_code: 404,
        });
        setPosts(posts);
        document.title = "EventGo";
        document.description = "EventGo";
        routeToLogout();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullScreen handle={fullScreenHandle}>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh", minWidth: "100vw" }}
      >
        <div
          className="w-100"
          style={{ maxWidth: "100%", marginTop: "25px", marginBottom: "25px" }}
        >
          <Card className="border-0">
            <SuperAdminAlert superAdminInEffect={superAdminInEffect} />
            <Card.Body className="align-items-center justify-content-center">
              <div className="d-flex justify-content-between align-items-start">
                <Card.Title
                  className="text-center"
                  style={{
                    fontSize: isMobile ? "25px" : "45px",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  Checkin
                </Card.Title>

                <div style={{ display: "flex" }}>
                  
                  <Button
                    variant="dark"
                    style={{ marginRight: "15px" }}
                    onClick={() => {
                      setShowQRScanner(true);
                    }}
                  >
                    Scan
                  </Button>

                  <DropdownButton
                    style={{ marginRight: "10px" }}
                    title="Sort"
                    id=""
                    variant="outline-dark"
                    onSelect={(e) => {
                      if (e === "filter-by-name") {
                        loadAllMembersRealTime("name", "asc");
                      } else if (e === "filter-by-timestamp") {
                        loadAllMembersRealTime("timestamp", "desc");
                      }
                    }}
                  >
                    <Dropdown.Item eventKey="filter-by-name">
                      Sort by name
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="filter-by-timestamp">
                      Sort by time
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>

              <Form.Control
                type="text"
                placeholder="Search Name/Email/Ticket"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  width: isMobile ? "100%" : "25%",
                  marginTop: "15px",
                  marginLeft: "auto",
                  marginRight: "0",
                }}
              />

              <div style={{ marginTop: "25px" }}>
                <div className="fw-normal">
                  All People: <b>{totalParticipants}</b>
                </div>
                <div className="fw-normal">
                  All Tickets: <b>{totalTickets}</b>
                </div>
                <div>
                  Already Checkin: <b>{checkedInTickets}</b>
                </div>
                <div>
                  Not Checkin: <b>{totalTickets - checkedInTickets}</b>
                </div>
                <div>
                  Perc: {" "}
                  <b>
                    {totalTickets > 0
                      ? Math.round((checkedInTickets / totalTickets) * 100)
                      : 0}
                    %
                  </b>
                </div>
              </div>

              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  marginTop: "15px",
                  marginBottom: "45px",
                }}
              >
                <Card.Link
                  className="text-center"
                  href={`https://tickets.evtgo.com/${eventData.id}`}
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    marginTop: "15px",
                    marginBottom: "0px",
                    color: "black",
                  }}
                >
                  {eventData.title}
                </Card.Link>

                <QRScannerModal
                  id={id}
                  showQRScanner={showQRScanner}
                  setShowQRScanner={setShowQRScanner}
                  eventMembers={eventMembers}
                  checkinTickets={checkinTickets}
                  uncheckinTickets={uncheckinTickets}
                  checkinTicketDetails={checkinTicketDetails}
                  eventData={eventData}
                />

                

                
              </div>

              <Row style={{ marginTop: "60px" }}>
                <Col sm={2}>
                  <ListGroup variant="dark" defaultActiveKey="#showall">
                    <ListGroup.Item
                      action
                      href="#showall"
                      onClick={(_e) => {
                        setEventDisplayMembers(eventMembers);
                      }}
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div>Show All</div>
                      <Badge bg="success" pill>
                        {eventMembers.length}
                      </Badge>
                    </ListGroup.Item>
                    <ListGroup.Item
                      action
                      href="#showuncheck"
                      onClick={() => {
                        const uncheckMembers = eventMembers.filter((memItm) => {
                          const total = memItm.quantity ?? 1;
                          const checkinCount = memItm.checkinCount ?? 0;
                          const uncheckedin = Math.max(0, total - checkinCount);
                          return uncheckedin === total;
                        });
                        setEventDisplayMembers(uncheckMembers);
                      }}
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div>Show Unchecked</div>
                      <Badge bg="danger" pill>
                        {
                          eventMembers.filter((memItm) => {
                            const total = memItm.quantity ?? 1;
                            const checkinCount = memItm.checkinCount ?? 0;
                            const uncheckedin = Math.max(
                              0,
                              total - checkinCount
                            );
                            return uncheckedin === total;
                          }).length
                        }
                      </Badge>
                    </ListGroup.Item>

                    <ListGroup.Item
                      action
                      href="#showpartial"
                      onClick={() => {
                        const partialCheckinMembers = eventMembers.filter(
                          (memItm) => {
                            const total = memItm.quantity ?? 1;
                            const checkinCount = memItm.checkinCount ?? 0;
                            const uncheckedin = Math.max(
                              0,
                              total - checkinCount
                            );
                            return uncheckedin > 0 && uncheckedin < total;
                          }
                        );
                        setEventDisplayMembers(partialCheckinMembers);
                      }}
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div>Show Partial Checkin</div>
                      <Badge bg="warning" pill>
                        {
                          eventMembers.filter((memItm) => {
                            const total = memItm.quantity ?? 1;
                            const checkinCount = memItm.checkinCount ?? 0;
                            const uncheckedin = Math.max(
                              0,
                              total - checkinCount
                            );
                            return uncheckedin > 0 && uncheckedin < total;
                          }).length
                        }
                      </Badge>
                    </ListGroup.Item>

                    <ListGroup.Item
                      action
                      href="#showchecked"
                      onClick={() => {
                        const checkedMembers = eventMembers.filter((memItm) => {
                          const total = memItm.quantity ?? 1;
                          const checkinCount = memItm.checkinCount ?? 0;
                          const uncheckedin = Math.max(0, total - checkinCount);
                          return uncheckedin === 0;
                        });
                        setEventDisplayMembers(checkedMembers);
                      }}
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div>Show Already Checkin</div>
                      <Badge bg="success" pill>
                        {
                          eventMembers.filter((memItm) => {
                            const total = memItm.quantity ?? 1;
                            const checkinCount = memItm.checkinCount ?? 0;
                            const uncheckedin = Math.max(
                              0,
                              total - checkinCount
                            );
                            return uncheckedin === 0;
                          }).length
                        }
                      </Badge>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col sm={10}>
                  <ListGroup style={{ marginTop: "15px" }} hidden={!isMobile}>
                    {eventDisplayMembers.map((memberItm) => {
                      const checkinnTickets = memberItm.tickets ?? [];
                      const total = memberItm.quantity;
                      const checkinCount = memberItm.checkinCount ?? 0;
                      const uncheckedin = Math.max(0, total - checkinCount);
                      return (
                        <ListGroup.Item key={memberItm.id}>
                          <div className="d-flex align-items-start justify-content-between">
                            <Card.Text>
                              <b>
                                {memberItm.name}({memberItm.quantity})
                              </b>
                            </Card.Text>
                            <ButtonCheckinOne
                              id={id}
                              memberItm={memberItm}
                              checkinCount={checkinCount}
                            />
                          </div>
                          <Card.Text>未签/已签/全部</Card.Text>
                          <Card.Text>{uncheckedin}/{checkinCount}/{total}</Card.Text>
                          <Card.Text>{memberItm.email}</Card.Text>
                          {checkinnTickets.map((ticketId) => {
                            return (
                              <div key={ticketId}>
                                <Card.Link
                                  href={'https://tickets.evtgo.com/ticket-id/'+ticketId}
                                  style={{ color: "black", fontSize: 17 }}
                                >
                                  <b>
                                    {checkinTickets.includes(ticketId)
                                      ? "✓ "
                                      : ""}
                                  </b>
                                  {ticketId}
                                </Card.Link>
                                <br />
                              </div>
                            );
                          })}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>

                  {/* Web Display */}

                  <ListGroup
                    style={{ marginTop: "15px" }}
                    hidden={displayMode === 1 || isMobile}
                  >
                    <ListGroup.Item
                      className=""
                      style={{ backgroundColor: "#EEE" }}
                    >
                      <Row md={6}>
                        <Col>
                          <Card.Text>Name</Card.Text>
                        </Col>
                        <Col>
                          <Card.Text>未签/已签/全部</Card.Text>
                        </Col>
                        <Col>
                          <Card.Text>Email</Card.Text>
                        </Col>
                        <Col>
                          <Card.Text>Ticket</Card.Text>
                        </Col>
                        <Col>
                          <Card.Text>Lang</Card.Text>
                        </Col>
                        <Col>
                          <Card.Text>Order</Card.Text>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    {eventDisplayMembers.map((memberItm) => {
                      const langId = memberItm.lang ?? "";
                      const total = memberItm.quantity;
                      const checkinCount = memberItm.checkinCount ?? 0;
                      const uncheckedin = Math.max(0, total - checkinCount);

                      return (
                        <ListGroup.Item key={memberItm.id}>
                          <Row md={6}>
                            <Col>
                              <Card.Text>{memberItm.name}</Card.Text>
                            </Col>
                            <Col>
                              <Card.Text>
                                {uncheckedin}/{checkinCount}/{total}
                              </Card.Text>
                            </Col>
                            <Col>
                              <Card.Text>{memberItm.email}</Card.Text>
                            </Col>
                            <Col>
                              <Card.Text>{memberItm.ticketId}</Card.Text>
                            </Col>
                            <Col>
                              <Card.Text>
                                {langId === ""
                                  ? "中文"
                                  : langId === "en"
                                  ? "English"
                                  : "中文"}
                              </Card.Text>
                            </Col>
                            <Col>
                              <div>
                                <ButtonCheckinOne
                                  id={id}
                                  memberItm={memberItm}
                                  checkinCount={checkinCount}
                                />
                                <ButtonCancelCheckinAll
                                  id={id}
                                  memberItm={memberItm}
                                />
                              </div>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>

                  <ListGroup hidden={displayMode === 0}>
                    <Row md={3}>
                      {eventDisplayMembers.map((memberItm) => {
                        const memberTickets = memberItm.tickets ?? [];
                        const memberQuantity = memberItm.quantity ?? 1;
                        const checkinTicketss = memberItm.checkinTickets ?? [];

                        return (
                          <Col key={memberItm.id}>
                            <ListGroup.Item style={{ marginBottom: "25px" }}>
                              <div>
                                <div
                                  className="align-items-start"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                  }}
                                >
                                  <Image
                                    roundedCircle
                                    src={memberItm.profileURL}
                                    width="50px"
                                    height="50px"
                                  />
                                  <div style={{ marginLeft: "5px" }}>
                                    <Card.Text
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        marginBottom: "0px",
                                        color: "black",
                                      }}
                                    >
                                      {memberItm.name}
                                    </Card.Text>
                                    <Card.Text
                                      class="text-secondary"
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {memberItm.timestamp
                                        .toDate()
                                        .toLocaleString("zh")}
                                    </Card.Text>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start">
                                  <Card.Text style={{}}>
                                    电子邮箱: {memberItm.email}
                                  </Card.Text>
                                </div>
                                <div className="d-flex justify-content-between align-items-start">
                                  <Card.Text style={{}}>
                                    出票ID: {memberItm.ticketId}
                                  </Card.Text>
                                </div>
                                <div className="d-flex justify-content-between align-items-start">
                                  <Card.Text style={{}}>
                                    总人数: {memberQuantity}
                                  </Card.Text>
                                </div>
                                <div className="d-flex justify-content-between align-items-start">
                                  <Card.Text style={{}}>
                                    待签到人数:
                                    {memberTickets.length -
                                      checkinTicketss.length}
                                  </Card.Text>
                                </div>
                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{ marginTop: "5px" }}
                                >
                                  <Card.Text
                                    style={{
                                      color: eventInviteeEmails.includes(
                                        memberItm.email
                                      )
                                        ? "green"
                                        : "red",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {eventInviteeEmails.includes(
                                      memberItm.email
                                    )
                                      ? "已邀请"
                                      : "未邀请"}
                                  </Card.Text>
                                </div>

                                <Accordion
                                  defaultActiveKey="A"
                                  style={{
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {memberTickets.map((ticketItm) => {
                                    return (
                                      <Accordion.Item
                                        key={ticketItm}
                                        hidden={checkinTicketss.includes(
                                          ticketItm
                                        )}
                                      >
                                        <Accordion.Header>
                                          {ticketItm}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <div>
                                            <Card.Link
                                              onClick={() => {
                                                if (
                                                  !checkinTicketss.includes(
                                                    ticketItm
                                                  )
                                                ) {
                                                  checkinTicketss.push(
                                                    ticketItm
                                                  );
                                                }

                                                if (
                                                  checkinTicketss.length ===
                                                  memberTickets.length
                                                ) {
                                                  //             fires.
                                                  // collection('events').
                                                  // doc(id).
                                                  // collection('members').
                                                  // doc(memberItm.email).
                                                  // update({
                                                  //     checkinStatus: true,
                                                  //     checkinBy: 'admin',
                                                  //     checkinTickets: memberTickets,
                                                  //     checkinTimestamp: getNow()
                                                  // }).
                                                  //     then(()=>{
                                                  // })
                                                } else {
                                                  fires
                                                    .collection("events")
                                                    .doc(id)
                                                    .collection("members")
                                                    .doc(memberItm.email)
                                                    .update({
                                                      checkinTickets:
                                                        checkinTicketss,
                                                    });
                                                }
                                              }}
                                            >
                                              签到
                                            </Card.Link>
                                          </div>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    );
                                  })}
                                </Accordion>

                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{ marginTop: "5px" }}
                                >
                                  <Card.Text
                                    style={{
                                      color: memberItm.checkinStatus
                                        ? "green"
                                        : "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {memberItm.checkinStatus
                                      ? "已签到"
                                      : "未签到"}
                                  </Card.Text>
                                  <div>
                                    <Card.Link
                                      onClick={() => {
                                        if (memberItm.checkinStatus) {
                                          return;
                                        }
                                        //checkin

                                        const checkinnTickets =
                                          memberItm.tickets ?? [];

                                        const checkinAlert = window.confirm(
                                          `你想要帮${memberItm.name}签到吗?`
                                        );
                                        if (
                                          checkinAlert &&
                                          memberItm.email !== ""
                                        ) {
                                          fires
                                            .collection("events")
                                            .doc(id)
                                            .collection("members")
                                            .doc(memberItm.email)
                                            .update({
                                              checkinStatus: true,
                                              checkinBy: "admin",
                                              checkinMethod: "Click",
                                              checkinTickets: checkinnTickets,
                                              checkinTimestamp: getNow(),
                                            })
                                            .then(() => {});
                                        }
                                      }}
                                    >
                                      替TA签到
                                    </Card.Link>
                                  </div>
                                </div>
                              </div>
                            </ListGroup.Item>
                          </Col>
                        );
                      })}
                    </Row>
                  </ListGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </FullScreen>
  );
}
