import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Container,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { fires } from "../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import firebase from "firebase";

export default function InteractionViewer() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [viewTypes, setViewTypes] = useState([]);
  const [views, setViews] = useState([]);

  const [gData, setGData] = useState([]);
  const [qData, setQData] = useState({});
  const [questions, setQuestions] = useState([]);

  const [linkCounts, setLinkCounts] = useState({});

  const [answerId, setAnswerId] = useState("");
  const [answerShow, setAnswerShow] = useState(false);
  const handleAnswerClose = () => setAnswerShow(false);
  const handleAnswerShow = () => setAnswerShow(true);

  const maxW = isMobile ? "100%" : "60%";

  var uid = currentUser.uid;
  let { id } = useParams();
  var cEmail = currentUser.email;

  async function handleAnswerQuestion(e) {
    e.preventDefault();
    var answerVal = e.target[0].value;
    var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    try {
      if (answerId != "") {
        await fires
          .collection("interactions")
          .doc(id)
          .collection("questions")
          .doc(answerId)
          .update({ answer: answerVal, answerTime: timestampNow });
        e.target[0].value = "";
        handleAnswerClose();
      }
    } catch {}
  }

  useEffect(() => {
    return fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const users = [];
          const userData = snapshot.data();
          var orgId = userData.orgId;
          fires
            .collection("groups")
            .doc(orgId)
            .get()
            .then((snapshotA) => {
              if (snapshotA.exists) {
                var gData = [];
                var groupData = snapshotA.data();
                gData.push({ ...groupData, id: orgId });
                setGData(groupData);
                fires
                  .collection("interactions")
                  .doc(id)
                  .get()
                  .then((snapshotB) => {
                    if (snapshotB.exists) {
                      setQData({ ...snapshotB.data(), id: id });
                    }
                  });
                fires
                  .collection("interactions")
                  .doc(id)
                  .collection("views")
                  .get()
                  .then((snapshotC) => {
                    var allViews = [];
                    snapshotC.docs.forEach((doc) => {
                      const qId = doc.id;
                      const qData = doc.data();
                      allViews.push({ ...qData, id: qId });
                    });
                    setViews(allViews);
                  });
                fires
                  .collection("interactions")
                  .doc(id)
                  .collection("questions")
                  .get()
                  .then((snapshotC) => {
                    var allQuestions = [];
                    snapshotC.docs.forEach((doc) => {
                      const qId = doc.id;
                      const qData = doc.data();
                      allQuestions.push({ ...qData, id: qId });
                    });
                    setQuestions(allQuestions);
                  });
              }
            });
        } else {
          //deleted
          posts.push({
            id: uid,
            error: "NotFound",
            error_message: "没有访问权限",
            error_code: 404,
          });
          setPosts(posts);
          document.title = "乐源 LETJOY";
          document.description = "开启你的海外生活";
        }
      });
  }, []);

  return (
    <>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh", minWidth: "100vw" }}
      >
        <div
          className="w-100"
          style={{ maxWidth: maxW, marginTop: "25px", marginBottom: "25px" }}
        >
          <Card>
            <Card.Body className="align-items-center justify-content-center">
              <Card.Title className="text-center">{"匿名Q&A"}</Card.Title>
              <Card.Text
                className="text-center"
                style={{ marginTop: "10px", fontWeight: "bold" }}
              >
                {qData.title}
              </Card.Text>

              <Card.Text
                className="text-center"
                style={{
                  marginTop: "15px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#000000",
                  padding: "8px",
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                }}
              >
                基本信息
              </Card.Text>
              <ListGroup>
                <ListGroup.Item className="align-items-start">
                  <div className="fw-normal">问答标题</div>
                  <div className="fw-bold" style={{ marginTop: "10px" }}>
                    {qData.title}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="align-items-start">
                  <div className="fw-normal">问答题目</div>
                  <div className="fw-bold" style={{ marginTop: "10px" }}>
                    {qData.description}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="align-items-start">
                  <div className="fw-normal">问答匿名性</div>
                  <div className="fw-bold" style={{ marginTop: "10px" }}>
                    {qData.isAnonymous ? "匿名" : "实名"}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="align-items-start">
                  <div className="fw-normal">问答语言</div>
                  <div className="fw-bold" style={{ marginTop: "10px" }}>
                    {qData.lang == "en" ? "English" : "中文"}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="align-items-start">
                  <div className="fw-normal">点击数量</div>
                  <div className="fw-bold" style={{ marginTop: "10px" }}>
                    {views.length}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="align-items-start">
                  <div className="fw-normal">回答数量</div>
                  <div className="fw-bold" style={{ marginTop: "10px" }}>
                    {questions.length}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  className="align-items-start"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "https://letjoy.app/interaction/" + qData.id
                    );
                    window.location.href =
                      "https://letjoy.app/interaction/" + qData.id;
                  }}
                >
                  <div className="fw-normal">复制链接</div>
                  <div className="fw-bold" style={{ marginTop: "10px" }}>
                    {"https://letjoy.app/interaction/" + qData.id}
                  </div>
                </ListGroup.Item>
              </ListGroup>

              <Card.Text
                className="text-center"
                style={{
                  marginTop: "15px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#000000",
                  padding: "8px",
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                }}
              >
                {"Q&A 匿名回答"}
              </Card.Text>
              <ListGroup>
                {questions.map((quesItem) => {
                  return (
                    <ListGroup.Item className="align-items-start">
                      <div
                        className="fw-bold"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: 20,
                        }}
                      >
                        Q: {quesItem.question}
                      </div>
                      <div className="fw-normal" style={{ marginTop: "10px" }}>
                        {quesItem.answer == null ? "还未回复" : quesItem.answer}
                      </div>
                      <div className="fw-normal">
                        提问于{" "}
                        {quesItem.timestamp.toDate().toLocaleString("zh")}
                      </div>

                      <div style={{ marginTop: "15px" }}>
                        <Card.Link
                          style={{ color: "darkgreen", fontWeight: "bold" }}
                          onClick={() => {
                            setAnswerId(quesItem.id);
                            handleAnswerShow();
                          }}
                        >
                          {quesItem.answer == null ? "回复" : "修改回复"}
                        </Card.Link>
                        <Card.Link
                          style={{ color: "red", fontWeight: "bold" }}
                          onClick={() => {
                            setAnswerId(quesItem.id);
                            try {
                              if (answerId != "") {
                                fires
                                  .collection("interactions")
                                  .doc(id)
                                  .collection("questions")
                                  .doc(answerId)
                                  .delete();
                                window.location.reload();
                              }
                            } catch {}
                          }}
                        >
                          删除
                        </Card.Link>
                      </div>

                      <Modal show={answerShow} onHide={handleAnswerClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>回复此问题</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="justify-content-center align-items-center">
                          <Form onSubmit={handleAnswerQuestion}>
                            <Form.Label
                              style={{ fontSize: "17px", fontWeight: "bold" }}
                            >
                              Q: {quesItem.question}
                            </Form.Label>
                            <Form.Group
                              id="reply"
                              style={{ marginTop: "15px" }}
                            >
                              <Form.Label
                                style={{ fontSize: "17px", fontWeight: "bold" }}
                              >
                                请输入回复内容
                              </Form.Label>
                              <Form.Control
                                style={{
                                  backgroundColor: "transparent",
                                  borderWidth: "3px",
                                  color: "black",
                                  height: "120px",
                                  borderRadius: "25px",
                                }}
                                as="textarea"
                                rows={10}
                                required
                              />
                            </Form.Group>
                            <Button
                              className="w-100 p3"
                              type="submit"
                              style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                                backgroundColor: "#111",
                                borderRadius: "25px",
                                borderColor: "#ffffff",
                                borderWidth: "3px",
                                fontSize: "15px",
                                fontWeight: "bold",
                                minHeight: "50px",
                                marginTop: "15px",
                              }}
                            >
                              回复
                            </Button>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleAnswerClose}
                          >
                            关闭
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
